<template>
  <ElTable
    class="mi-simple-table"
    :data="data"
    :max-height="maxHeight"
    :empty-text="emptyText ?? $t('Common.NoData')"
    :default-sort="defaultSort"
    :size="size"
    :stripe="stripe"
    :show-header="showHeader"
    v-bind="$attrs"
    :height="height"
    :row-key="rowKey"
    v-loading="loading"
    :border="border"
    :row-class-name="rowClassName"
    :tree-props="treeProps"
    :default-expand-all="defaultExpandAll"
    ref="elTable"
    @row-click="$emit('row-click', $event)">
    <template v-if="$slots.empty" #empty>
      <slot name="empty"></slot>
    </template>
    <slot></slot>
  </ElTable>
</template>

<script setup lang="ts">
import { Sort, ElTable, TableProps } from 'element-plus';
import { ref } from 'vue';

import { AnyObject } from '~shared/types';

interface Props extends Partial<TableProps<AnyObject>> {
  eslintFix?: string;
  loading?: boolean;
  tableClass?: string;
  maxHeight?: number | string;
  defaultSort?: Sort;
  height?: number | string;
  border?: boolean;
  showHeader?: boolean;
  emptyText?: string;
  stripe?: boolean;
  rowClassName?: TableProps<AnyObject>['rowClassName'];
  rowKey?: string;
  treeProps?: {
    hasChildren?: string;
    children?: string;
  };
}

const elTable = ref<InstanceType<typeof ElTable> | null>(null);

withDefaults(defineProps<Props>(), {
  showHeader: true,
});

defineEmits<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (e: 'row-click', event: any): void;
}>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toggleRowSelection = (row: any, selected: boolean): void => {
  elTable.value?.toggleRowSelection(row, selected);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toggleRowExpansion = (row: any, expanded?: boolean): void => {
  elTable.value?.toggleRowExpansion(row, expanded);
};

defineExpose({ toggleRowSelection, toggleRowExpansion });
</script>

<style lang="scss" src="./index.scss"></style>
