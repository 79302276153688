import { ROUTE_PATH } from '~shared/config';
import { getRedirectTokenMiddleware } from '~shared/lib';

const REDIRECT_ROUTE_NAME = 'Redirect';

export const REDIRECT_ROUTE = {
  name: REDIRECT_ROUTE_NAME,
  path: ROUTE_PATH.redirect,
  component: () => import('./ui'),
  beforeEnter: [getRedirectTokenMiddleware],
  meta: {
    title: 'Base.Authorization',
  },
};
