<template>
  <MiDialog
    :title="$t('Patients.PhotographingPatient')"
    class="take-photo-modal"
    width="780"
    @close="handleCloseModal"
    @open="handleOpenModal"
    v-model="isModalVisible">
    <div class="take-photo-modal__body">
      <MiCard v-loading="loading" class="take-photo-modal__image-box">
        <img
          class="take-photo-modal__image"
          v-if="currentImg"
          :src="currentImg"
          alt="Фото пациента" />
        <video
          @canplay="getSizes"
          class="take-photo-modal__video"
          v-show="!currentImg"
          autoplay
          ref="refVideo" />
        <canvas v-show="false" class="take-photo-modal__canvas" ref="refCanvas" />
      </MiCard>

      <MiCard class="take-photo-modal__hint-block">
        <div class="take-photo-modal__hint-title">{{ $t('MyId.MakeSureThePatient') }}</div>
        <div class="take-photo-modal__hint-list">
          <div>
            <li>{{ $t('MyId.WithoutHeaddress') }}</li>
            <li>{{ $t('MyId.WithoutSunglasses') }}</li>
            <li>{{ $t('MyId.FaceClearlyVisible') }}</li>
          </div>
          <div>
            <li>{{ $t('MyId.TheFaceFitsCompletelyIntoTheLens') }}</li>
            <li>{{ $t('MyId.EyesDirectedAtTheCamera') }}</li>
          </div>
        </div>
      </MiCard>

      <MiCard>
        <FormActionsWrapper class="take-photo-modal__action-wrapper" align="right" no-margin>
          <MiSelectWithOption
            class="take-photo-modal__video-input-select"
            v-model="selectedDevice"
            :options="videoInputOptions" />
          <MiButton v-loading="loading" @click="handleTakePhotoBtnClick" type="primary">{{
            $t('MyId.TakePhoto')
          }}</MiButton>
        </FormActionsWrapper>
      </MiCard>
    </div>
  </MiDialog>
</template>

<script setup lang="ts">
import { ElNotification } from 'element-plus';
import { computed, onMounted, ref, shallowRef, watch } from 'vue';

import { I18nService } from '~shared/lib';
import { FormActionsWrapper } from '~shared/ui/form';
import { MiCard, MiButton, MiSelectWithOption, MiDialog } from '~shared/ui';
import { misApi } from '~shared/api';
import { PatientResource } from '~shared/api/gen/v2';

const props = withDefaults(
  defineProps<{
    patientId: number;
    modelValue: boolean;
    weight?: number;
    videoAspect?: number;
    videoParams?: Partial<MediaTrackConstraints>;
  }>(),
  {
    weight: 700,
    videoParams: () => ({}),
  }
);

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void;
  (event: 'patient-update-successful', patient: PatientResource): void;
}>();

const isModalVisible = computed({
  get() {
    return props.modelValue;
  },
  set(e: boolean) {
    emit('update:modelValue', e);
  },
});

const loading = shallowRef(false);

const refVideo = ref<HTMLVideoElement>();
const refCanvas = ref<HTMLCanvasElement>();

const currentImg = ref<string | null>(null);

const videoInputOptions = ref<{ value: string; label: string }[]>([]);
const selectedDevice = shallowRef<string>();

const photoHeight = computed(() => {
  if (refVideo.value && refCanvas.value) {
    return (refVideo.value.videoHeight / refVideo.value.videoWidth) * props.weight;
  } else {
    return '';
  }
});

const stopWebcam = async () => {
  try {
    if (!refVideo.value) return;
    const mediaStream = refVideo.value?.srcObject as MediaStream;
    mediaStream?.getTracks().forEach((track) => track.stop());
  } catch (err) {
    ElNotification({
      type: 'error',
      title: I18nService.t('Notifications.Error'),
    });
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

const startWebcam = async () => {
  try {
    loading.value = true;
    if (!refVideo.value) return;

    await stopWebcam();

    const streamVideo = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: selectedDevice.value ?? undefined,
        aspectRatio: props.videoAspect,
        ...props.videoParams,
      },
      audio: false,
    });
    refVideo.value.srcObject = streamVideo;
    refVideo.value.play();
  } catch (err) {
    ElNotification({
      type: 'error',
      title: I18nService.t('Notifications.Error'),
    });
    // eslint-disable-next-line no-console
    console.log(err);
  } finally {
    loading.value = false;
  }
};

const updatePatientPhoto = async () => {
  if (!currentImg.value) return;

  const response = await misApi.gen.v2.patients.patientUpdateImageInMyidBox(props.patientId, {
    image: currentImg.value,
  });

  currentImg.value = null;

  if (response?.data.data) {
    emit('patient-update-successful', response.data.data);
    emit('update:modelValue', false);
  }
};

const drawImage = () => {
  const video = refVideo.value;
  const canvas = refCanvas.value;
  if (!video || !canvas) return;

  const context = canvas.getContext('2d');
  if (props.weight && photoHeight.value && context) {
    canvas.width = props.weight;
    canvas.height = photoHeight.value;
    context.drawImage(video, 0, 0, props.weight, photoHeight.value);
  }
};

const handleTakePhotoBtnClick = () => {
  if (!refCanvas.value) return;
  drawImage();
  currentImg.value = refCanvas.value.toDataURL('image/png');

  updatePatientPhoto();
};

const getSizes = () => {
  if (refVideo.value && refCanvas.value) {
    refVideo.value.setAttribute('width', String(props.weight));
    refVideo.value.setAttribute('height', String(photoHeight.value));
    refCanvas.value.setAttribute('width', String(props.weight));
    refCanvas.value.setAttribute('height', String(photoHeight.value));
  }
};

const handleCloseModal = async () => {
  currentImg.value = null;
  await stopWebcam();
};
const handleOpenModal = async () => {
  await startWebcam();
};

watch(selectedDevice, () => {
  startWebcam();
});

onMounted(async () => {
  const inputDevices = await navigator.mediaDevices.enumerateDevices();
  const videoInputDevices = inputDevices.filter((device) => device.kind === 'videoinput');
  videoInputOptions.value = videoInputDevices.map((device) => {
    return {
      label: device.label,
      value: device.deviceId,
    };
  });
});
</script>
<style lang="scss" src="./index.scss"></style>
