<template>
  <component :session-user="sessionUser" :is="currentComponent" />
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { MiPrintHeaderB2G } from '../MiPrintHeaderB2G';
import { MiPrintHeaderG2G } from '../MiPrintHeaderG2G';

import { UserFullDto } from '~shared/api';

const props = defineProps<{
  sessionUser?: UserFullDto | null;
  isG2GInterface?: boolean;
}>();

const currentComponent = computed(() =>
  props.isG2GInterface ? MiPrintHeaderG2G : MiPrintHeaderB2G
);
</script>

<style scoped></style>
