import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';

import { localStorageUtils } from '~shared/lib';
import { AnyObject, TableNameEnum } from '~shared/types';

type UserConfig = {
  layouts?: {
    main?: {
      navBarCollapsed?: boolean;
    };
  };
  tables?: Record<
    string,
    | {
        filters?: AnyObject;
        queryParams?: AnyObject;
        filtersHidden?: boolean;
      }
    | undefined
  >;
};

export const useUserConfigStore = defineStore('userConfigStore', () => {
  const userConfigFromStorage = <UserConfig | null>localStorageUtils.getParsedItem('user_config');
  const userConfig = ref<UserConfig>(userConfigFromStorage ?? {});

  const mainNavBarCollapsed = computed(() =>
    Boolean(userConfig.value.layouts?.main?.navBarCollapsed)
  );

  function toggleMainNavBarCollapsed() {
    const prevValue = mainNavBarCollapsed.value;

    userConfig.value = {
      ...userConfig.value,
      layouts: {
        ...userConfig.value.layouts,
        main: { ...userConfig.value.layouts?.main, navBarCollapsed: !prevValue },
      },
    };
  }

  function toggleTableFiltersHidden(tableName: TableNameEnum) {
    const value = !userConfig.value.tables?.[tableName]?.filtersHidden;

    userConfig.value = {
      ...userConfig.value,
      tables: {
        ...userConfig.value.tables,
        [tableName]: { ...userConfig.value.tables?.[tableName], filtersHidden: value },
      },
    };
  }

  function setTableFilters(tableName: TableNameEnum, filters: AnyObject | undefined) {
    userConfig.value = {
      ...userConfig.value,
      tables: {
        ...userConfig.value.tables,
        [tableName]: { ...userConfig.value.tables?.[tableName], filters },
      },
    };
  }

  function updateTableFilters(tableName: TableNameEnum, filters: AnyObject | undefined) {
    const currentFilters = { ...userConfig.value.tables?.[tableName]?.filters, ...filters };
    userConfig.value = {
      ...userConfig.value,
      tables: {
        ...userConfig.value.tables,
        [tableName]: { ...userConfig.value.tables?.[tableName], filters: currentFilters },
      },
    };
  }

  function setTableQueryParams(tableName: TableNameEnum, queryParams: AnyObject | undefined) {
    userConfig.value = {
      ...userConfig.value,
      tables: {
        ...userConfig.value.tables,
        [tableName]: { ...userConfig.value.tables?.[tableName], queryParams },
      },
    };
  }

  watch(
    userConfig,
    (v) => {
      localStorageUtils.setItemSafely('user_config', v);
    },
    { deep: true }
  );

  return {
    setTableFilters,
    updateTableFilters,
    setTableQueryParams,
    toggleTableFiltersHidden,
    toggleMainNavBarCollapsed,
    mainNavBarCollapsed,
    userConfig,
  };
});
