<template>
  <div>
    <MiButton class="search-by-photo-button" v-bind="$props" @click.stop="isModalVisible = true">
      <template v-if="$slots.default">
        <slot />
      </template>
      <template v-if="!$slots.default" #icon><MiIcon icon="CAMERA" /> </template>
      <span v-if="!$slots.default">{{ $t('Patients.TakePhotographPatient') }}</span>
    </MiButton>

    <SearchByPhotoModal
      v-model="isModalVisible"
      @patient-search-successful="$emit('patient-search-successful', $event)"
      @try-document-search="$emit('try-document-search')" />
  </div>
</template>

<script setup lang="ts">
import { shallowRef } from 'vue';

import { SearchByPhotoModal } from './SearchByPhotoModal';

import { MiButton, MiButtonProps, MiIcon } from '~shared/ui';
import { PatientResource } from '~shared/api/gen/v2';

withDefaults(defineProps<MiButtonProps>(), {
  size: '',
  type: 'primary',
  plain: false,
});

defineEmits<{
  (event: 'patient-search-successful', patient: PatientResource): void;
  (event: 'try-document-search'): void;
}>();

const isModalVisible = shallowRef(false);
</script>
