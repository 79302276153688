import { ElNotification } from 'element-plus';

import { ProductDto, ProductWithCountDto, ProductWithWarehousesDto } from '../types';

import { apiService, MetaDto } from '~shared/api';
import { joinQueryParams, getApiErrorMessage, clearObjectEmptyValues } from '~shared/lib';
import { QueryParams } from '~shared/types';

const urlPrefix = 'products';

export const getItems = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{ data: ProductDto[]; meta: MetaDto }>(
      joinQueryParams({
        url: urlPrefix,
        query: clearObjectEmptyValues(query),
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getItemsWithCountByWarehouseId = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{
      data: ProductWithCountDto[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPrefix}/withCount`,
        query: clearObjectEmptyValues(query),
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getItemsWithWarehouses = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{
      data: ProductWithWarehousesDto[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPrefix}/byWarehouses`,
        query: clearObjectEmptyValues(query),
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getCountExistsItemsWithCountByWarehouseId = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{
      data: ProductWithWarehousesDto[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPrefix}/withCount`,
        query: clearObjectEmptyValues({
          ...query,
          count_exists: 1,
        }),
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getItemById = async (id: number) => {
  try {
    const response = await apiService.api.instance.get<{ data: ProductDto }>(
      joinQueryParams({
        url: `${urlPrefix}/${id}`,
        query: clearObjectEmptyValues({}),
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createItem = async (payload: ProductDto) => {
  try {
    const response = await apiService.api.instance.post<{ data: ProductDto }>(
      `${urlPrefix}/create`,
      payload
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const updateItem = async (id: number, payload: ProductDto) => {
  try {
    const response = await apiService.api.instance.put<{ data: ProductDto }>(
      `${urlPrefix}/${id}/update`,
      payload
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const deleteItem = async (id: number) => {
  try {
    const response = await apiService.api.instance.delete<{ data: ProductDto }>(
      `${urlPrefix}/${id}/delete`
    );

    return {
      response: response,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
