<template>
  <div class="consultation-form">
    <FieldGroupWrapper grid-columns="five-columns">
      <div class="consultation-form__left-field-group">
        <FormTextField
          field-name="title.ru"
          :label="$t('Common.NamingInRu')"
          maxlength="255"
          required />
        <FormTextField
          field-name="title.uz"
          :label="$t('Common.NamingInUz')"
          maxlength="255"
          required />
        <FormTextField
          field-name="title.en"
          :label="$t('Common.NamingInEn')"
          maxlength="255"
          required />
      </div>
      <div />
      <div class="consultation-form__center-field-group">
        <FormSearchSelectField
          field-name="category_id"
          field-object-name="category"
          :fetch-data="ServiceCategory.find.bind(ServiceCategory)"
          :label="$t('Services.CategoryOfService')"
          required />

        <FormSearchSelectField
          field-name="doctor_ids"
          field-object-name="doctors"
          :fetch-data="misApi.common.doctors.getList"
          multiple
          label-key="name"
          :label="$t('Doctors.Doctors')"
          required />
        <FormTextField
          field-name="slot_duration"
          maxlength="3"
          :label="$t('Common.Duration')"
          mask-name="int-12"
          required />
        <FormTextField field-name="price" :label="$t('Common.Price')" mask-name="int-12" required />
      </div>
      <div />
      <div class="consultation-form__right-field-group">
        <FormTextField field-name="vat_percent" :label="$t('Services.VAT')" mask-name="float-2.2" />
        <FormTextField
          field-name="ikpu_code"
          :label="$t('Services.IKPUCode')"
          maxlength="11"
          mask-name="int-12" />
        <FormTextField
          field-name="package_code"
          :label="$t('Services.PackageCode')"
          mask-name="int-12"
          maxlength="7" />
      </div>
    </FieldGroupWrapper>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ConsultationForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { misApi } from '~shared/api';

import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import { ServiceCategory } from '@/models/ServiceCategory.model';
</script>

<style lang="scss" src="./index.scss" />
