import { RouteLocationNormalized } from 'vue-router';

import { ROUTE_PATH } from '~shared/config';
import { amplitudeService } from '~shared/lib';
import {
  ACTIVE_APPOINTMENTS,
  PROVIDED_APPOINTMENTS,
  CANCELED_APPOINTMENTS,
} from '~shared/config/tableNames';

export const logPageVisit = (to: RouteLocationNormalized) => {
  switch (to.matched?.[0]?.path || to.path) {
    case ROUTE_PATH.accounting.services:
      amplitudeService.logEvent('view_services_list');
      break;
    case ROUTE_PATH.users.employeeDoctorProfile.rawPath ||
      ROUTE_PATH.users.employeeDoctorProfile.byId(to.params.id as string):
      amplitudeService.logEvent('view_doctors_dashboard');
      break;
    case ROUTE_PATH.appointmentSchedule:
      amplitudeService.logEvent('view_appointment_callendar');
      break;
    case ROUTE_PATH.laboratory.orders:
      amplitudeService.logEvent('laboratory_orders.view');
      break;
    case ROUTE_PATH.cashRegister.invoices:
      amplitudeService.logEvent('view_cash');
      break;
    case ROUTE_PATH.appointments.activeList:
      amplitudeService.logEvent(`${ACTIVE_APPOINTMENTS}.open`);
      break;
    case ROUTE_PATH.appointments.providedList:
      amplitudeService.logEvent(`${PROVIDED_APPOINTMENTS}.open`);
      break;
    case ROUTE_PATH.appointments.canceledList:
      amplitudeService.logEvent(`${CANCELED_APPOINTMENTS}.open`);
      break;
    case ROUTE_PATH.patients.medicalCard.rawPath:
      amplitudeService.logEvent('view_patient_medcard', {
        patient_id: (to.params.id as string) ?? '',
      });
      break;
    default:
      amplitudeService.logEvent(to.matched?.[0]?.path || to.path);
      break;
  }
};
