<template>
  <ElTable :data="items" v-bind="$attrs">
    <MiTableColumn prop="subject.title" :label="$t('Common.Naming')" />
    <MiTableColumn prop="subject.price" :label="$t('Common.Price')">
      <template #default="{ row }">
        {{ row.subject.price }}
      </template>
    </MiTableColumn>
    <MiTableColumn prop="count" :label="$t('Analytics.Quantity')">
      <template #default="{ row }">
        <span v-if="!editable">{{ row.count }}</span>
        <ElInput
          v-if="editable"
          :model-value="row.count"
          type="number"
          min="1"
          @update:modelValue="updateItemCount(row, $event)" />
      </template>
    </MiTableColumn>

    <MiTableColumn prop="actions" width="66px">
      <template #default="{ row }">
        <div class="payment-subjects-table__actions">
          <MiButton v-show="deletable" text size="small" @click="$emit('item:remove', row)">
            <template #icon> <MiIcon icon="CANCEL" /> </template>
          </MiButton>
        </div>
      </template>
    </MiTableColumn>
  </ElTable>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { ElTable, ElInput } from 'element-plus';

// eslint-disable-next-line import/no-internal-modules
import { Invoice } from '../../models/Invoice.model';

import { MiIcon, MiTableColumn, MiButton } from '~shared/ui';

export default {
  components: { ElTable, ElInput, MiTableColumn, MiIcon, MiButton },
  name: 'PaymentSubjectsTable',
  emits: ['item:update', 'item:remove'],
  props: {
    invoice: [Invoice, Object],
    /** @type {Array<InvoicePaymentSubject|object>} */
    items: Array,
    editable: Boolean,
    deletable: Boolean,
  },

  methods: {
    updateItemCount(item, count) {
      const cloneItem = cloneDeep(item);

      this.$emit('item:update', {
        paymentSubject: {
          ...cloneItem,
          count: +count,
        },
        oldPaymentSubject: cloneItem,
      });
    },
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
