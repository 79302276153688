import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';
import { ROUTE_PATH } from '~shared/config';

export const STAFF_LIST_ROUTE = {
  name: 'StaffList',
  path: ROUTE_PATH.users.staffList,
  component: () => import('./StaffList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['accountant', 'director'])],
  meta: {
    title: 'User.Employees',
  },
};

export const EMPLOYEE_DOCTOR_LIST_ROUTE = {
  name: 'EmployeeDoctorList',
  path: ROUTE_PATH.users.employeeDoctorList,
  component: () => import('./EmployeeDoctorList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['accountant', 'director'])],
  meta: {
    title: 'Common.Doctors',
  },
};

export const EMPLOYEE_PROFILE_ROUTE = {
  name: 'EmployeeProfile',
  path: ROUTE_PATH.users.employeeProfile.rawPath,
  component: () => import('./EmployeeProfile/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['accountant', 'manager', 'director']),
  ],
  meta: {
    title: 'User.Employee',
  },
};

export const EMPLOYEE_DOCTOR_PROFILE_ROUTE = {
  name: 'EmployeeDoctorProfile',
  path: ROUTE_PATH.users.employeeDoctorProfile.rawPath,
  component: () => import('./EmployeeDoctorProfile/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['accountant', 'doctor', 'director']),
  ],
  meta: {
    title: 'Common.Doctor',
  },
};
