import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const WOMEN_DASHBOARD_ROUTE = {
  name: 'WomenDashboard',
  path: ROUTE_PATH.womenDashboard,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['minister'])],
  meta: {
    title: 'Analytics.Women',
  },
};
