<template>
  <MainLayout content-class="v-warehouses-content" fix-height>
    <MiFilterBox>
      <ElDatePicker
        class="v-doctors-content-header__date"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <MiSelectSearch
        class="v-warehouses-content__products"
        v-model="productIds.value"
        v-model:model-object-value="products"
        :fetch-data="misApi.common.product.getItems"
        :placeholder="$t('Income.SearchGoods')"
        raw-fetch-result
        multiple
        clearable />

      <MiSelectSearch
        :model-value="warehouse.value.id"
        v-model:model-object-value="warehouse.value"
        :fetch-data="misApi.common.warehouse.getItems"
        :placeholder="$t('Income.Warehouse')"
        raw-fetch-result
        clearable />

      <template #actions>
        <MiButton type="primary" @click="onCreateButtonClick">
          {{ $t('TransferGoods.TransferGoods') }}
        </MiButton>
      </template>
    </MiFilterBox>

    <GoodsStockTable
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :items="items"
      :total="total" />
  </MainLayout>
</template>

<script lang="ts">
export default {
  name: 'VGoodsStock',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ElDatePicker } from 'element-plus';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { MainLayout } from '~widgets/layouts';
import { MiButton, MiFilterBox, MiSelectSearch } from '~shared/ui';
import {
  useQuery,
  useDatePeriod,
  usePage,
  usePerPage,
  useModel,
  compareQueriesThenLoadData,
  getMonthPeriod,
} from '~shared/lib';
import { DATE_FORMAT } from '~shared/config';
import { misApi } from '~shared/api';
import { ProductDto } from '~shared/api/common';

import TransferGoodsCrudModal from '@/components/warehouse/modals/TransferGoodsCrudModal/index.vue';
import GoodsStockTable from '@/components/warehouse/tables/GoodsStockTable/index.vue';

const store = useStore();
const loading = ref(false);
const perPage = usePerPage();
const page = usePage();
const warehouse = useModel({
  fieldNames: {
    id: 'warehouse_id',
    name: 'warehouse_name',
  },
});

const date = useDatePeriod(getMonthPeriod()); // TODO типизировать useDatePeriod
const productIds = useQuery({ field: 'product_ids', valueIsNumberArray: true });
const products = ref<ProductDto[]>([]);
const total = computed(() => store.state.goodsStock.total); // TODO типизировать store
const items = computed(() => store.state.goodsStock.data); // TODO типизировать store
const query = computed(() => ({
  per_page: perPage.value,
  page: page.value,
  start_at: date.value[0],
  end_at: date.value[1],
  warehouse_id: warehouse.value.id,
  product_ids: productIds.value,
}));

const getGoodsStock = async () => {
  loading.value = true;
  const response = await misApi.common.product.getItemsWithCountByWarehouseId(query.value);
  if (response?.data) {
    const { data, meta } = response.data;
    store.dispatch('goodsStock/setData', {
      // TODO: передаю индекс чтобы не схлопывались строки с одинаковыми товарами, нужно переделать бэк
      items: data.map((item, index) => ({ ...item, id: index })),
      total: +meta.total,
      overwriteDataState: true,
    });
  }
  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      // TODO типизировать compareQueriesThenLoadData
      query: value,
      oldQuery: oldValue,
      getData: getGoodsStock,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);

const onCreateButtonClick = async () => {
  await store.dispatch('modalAndDrawer/openModal', TransferGoodsCrudModal);
};
</script>

<style lang="scss">
.v-warehouses-content {
  .layout-content-header-main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
