<template>
  <ElCheckbox
    v-model="value"
    class="ui-checkbox"
    v-bind="$attrs"
    :label="props.label"
    :validate-event="false"
    size="large" />
</template>

<script lang="ts">
export default {
  name: 'FormCheckboxField',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ElCheckbox } from 'element-plus';
import { useField } from 'vee-validate';
import { withDefaults } from 'vue';

type Props = {
  fieldName: string;
  label?: string;
};

const props = withDefaults(defineProps<Props>(), {
  label: '',
});

// eslint-disable-next-line vue/no-setup-props-destructure
const { value } = useField<boolean>(props.fieldName);
</script>
