import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const FERTILITY_ROUTE = {
  name: 'FertilityPage',
  path: ROUTE_PATH.fertility,
  component: () => import('./ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['minister', 'doctor', 'director']),
  ],
  meta: {
    title: 'Analytics.Fertility',
  },
};
