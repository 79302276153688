import { User } from './User.model';

import { apiService } from '~shared/api';
import { I18nService, joinQueryParams, cyrillicToEng } from '~shared/lib';

/**
 * @class Patient
 * @extends User
 */
export class Patient extends User {
  static modelName = 'patient';
  static tableName = 'g2g/patients';

  /**
   * @typedef {UserConstructorPayload|object} PatientConstructorPayload
   * @property {AmbulatoryCard} ambulatory_card
   * @property {string} place_residence
   * @property {boolean} has_treatment
   * @property {boolean} is_foreign - иностранный пациент
   * @property {Array<number>} files_ids
   * @property {Array<File>} files
   */
  /** @param {PatientConstructorPayload} [payload] */
  constructor(payload) {
    super(payload);

    this.role = User.enum.roles.Patient;
    this.ambulatory_card = payload?.ambulatory_card || null;
    this.place_residence = payload?.place_residence || null;
    this.has_treatment = payload?.has_treatment ?? true;
    this.is_foreign = payload?.is_foreign ?? false;

    this.files_ids = payload?.files_ids ?? [];
    this.files = payload?.files ?? [];
  }

  /**
   * @param {object} payload
   * @param {boolean} isChildren
   * @return {Promise<{data: response.data, response: AxiosResponse<*>}>}
   */
  static async create(payload, isChildren = false) {
    return super.create(payload, {
      url: isChildren || payload?.parent_id ? `${this.tableName}/child` : null,
    });
  }

  /**
   * Проверяет существование пациента
   * @param {string} phone
   * @return {Promise<{data: any, response: AxiosResponse<any>, patient: Patient, attach_clinic: boolean}>}
   */
  static async checkPatient({ phone }) {
    try {
      const response = await apiService.api.instance.post(`${this.tableName}/check`, { phone });
      return {
        response: response,
        data: response.data,
        patient: response.data.data.user,
        attach_clinic: response.data.data.attach_clinic,
      };
    } catch (err) {
      if (err.response?.status !== 404) throw new Error(err);

      return {
        response: null,
        data: null,
        patient: null,
        attach_clinic: false,
      };
    }
  }

  /**
   * Привязка пациента к нашей клинике
   * @param {number|string} patient_id
   * @return {Promise<{data: any, response: AxiosResponse<any>, patient: Patient}>}
   */
  static async attachPatient({ patient_id }) {
    const response = await apiService.api.instance.post(`${this.tableName}/${patient_id}/attach`);
    return {
      response: response,
      data: response.data,
      patient: response.data.data,
    };
  }

  /**
   * @param {string} phone
   * @return {Promise<{response: AxiosResponse<any>, data: response.data}>}
   */
  static async sendCodeOnPhone({ phone }) {
    const response = await apiService.api.instance.post(`${this.tableName}/phone/code`, {
      phone: phone,
    });
    return { response: response, data: response.data };
  }

  /**
   * @param {string} phone
   * @param {string} code
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async checkCodeFromPhone({ phone, code }) {
    const response = await apiService.api.instance.post(`${this.tableName}/phone/code/check`, {
      phone,
      code,
    });
    return { response, data: response.data };
  }

  /**
   * @param {Patient} patient
   * @param {string} code
   * @return {Promise<{data: any, response: AxiosResponse<any>}>}
   */
  static async rebinding({ patient, code }) {
    const response = await apiService.api.instance.post(`${this.tableName}/phone/rebinding`, {
      code,
      ...patient,
    });
    return {
      response: response,
      data: response.data,
      patient: response.data.data,
    };
  }

  /**
   * Прикрепляем файл к пациенту
   * @param {string} payload.patient_id
   * @param {string} payload.file_id
   * @return {Promise<{data: any}>}
   */
  static async attachFile(payload) {
    const response = await apiService.api.instance.post(
      `${this.tableName}/${payload.patient_id}/files/attach`,
      {
        file_id: payload.file_id,
      }
    );
    return {
      response: response,
      data: response.data,
      documents: response.data.data,
    };
  }

  /**
   * Находит пациента по данным из браслета, которые приходят после сканирования
   * @param {string} payload
   * @return {Promise<Patient|User|object>}
   */
  static async getByBraceletPayload(payload) {
    try {
      // Если включена руская раскладка, печатается русским алфавитом
      let text = payload;
      const textOnCyrillic = payload.match(/[а-яА-Я]/gim);
      if (textOnCyrillic) text = cyrillicToEng(text);

      const matches = Array.from(text.matchAll(/\?oneTimeToken=(?<token>[^&]*)/gim));
      const token = matches[0]?.groups?.token ?? null;
      if (!token) throw new Error(I18nService.t('Common.InvalidQrCode'));

      const { data } = await apiService.api.instance.get(
        `patients/getByOneTimeToken?oneTimeToken=${token}`
      );
      return data.data;
    } catch (err) {
      throw new Error(I18nService.t('Common.InvalidQrCode'));
    }
  }

  /**
   * @param {number} userId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getQrCodeLinkByUserId(userId) {
    const response = await apiService.api.instance.get(`printer/${userId}/link`);
    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getCountStatisticByPeriod({ startAt, endAt }) {
    const response = await apiService.api.instance.get(
      joinQueryParams({
        url: `statistics/patients/count`,
        query: { start_at: startAt, end_at: endAt },
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @param {string|number} [params.doctorId]
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getRepeatStatisticByPeriod({ startAt, endAt, doctorId }) {
    const query = { start_at: startAt, end_at: endAt };

    if (doctorId) {
      query.doctor_id = doctorId;
    }

    const response = await apiService.api.instance.get(
      joinQueryParams({
        url: `statistics/patients/returned`,
        query,
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getSeparationStatistic() {
    const response = await apiService.api.instance.get(`statistics/patients/total`);
    return { response, data: response.data };
  }

  /**
   * Получает амбулаторную карту пациента
   * @param {number} patientId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getAmbulatoryCardByPatientId(patientId) {
    const response = await apiService.api.instance.get(`${this.tableName}/${patientId}/card`);
    return { response, data: response.data };
  }

  /**
   * Получает назначения для пациента
   * @param {number} patientId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getRecommendations(patientId) {
    const response = await apiService.api.instance.get(`/recommendations/${patientId}/get`);
    return { response, data: response.data };
  }
}
