import { AppLocalesDto } from '../types';

export const LOCALES: AppLocalesDto = {
  ru: {
    id: 'ru',
    label: 'Русский',
  },
  uz: {
    id: 'uz',
    label: "О'zbekcha",
  },
  en: {
    id: 'en',
    label: 'English',
  },
};

export const DEFAULT_LOCALE = LOCALES.ru.id;
export const FALLBACK_LOCALE = LOCALES.ru.id;

export const LANG_AND_REGION = {
  [LOCALES.ru.id]: 'ru_RU',
  [LOCALES.uz.id]: 'uz_UZ',
};
