<template>
  <ElInput
    ref="elInputRef"
    class="mi-input"
    v-bind="$props"
    v-model="value"
    @keydown="handleEnterKeyDown">
    <template v-if="$slots.prefix" #prefix>
      <slot name="prefix" />
    </template>
    <template v-if="$slots.suffix" #suffix>
      <slot name="suffix" />
    </template>
    <template v-if="$slots.prepend" #prepend>
      <slot name="prepend" />
    </template>
    <template v-if="$slots.append" #append>
      <slot name="append" />
    </template>
  </ElInput>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { ElInput, InputProps } from 'element-plus';

export type MiInputProps = Partial<InputProps>;

const props = withDefaults(defineProps<MiInputProps>(), {
  validateEvent: true,
});
const emit = defineEmits<{
  (event: 'update:modelValue', value: string | number | undefined | null): void;
  (event: 'keyDown:enter'): void;
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val: string | number | undefined | null) {
    emit('update:modelValue', val);
  },
});

const handleEnterKeyDown = (e: KeyboardEvent | Event) => {
  if ('code' in e && e.code === 'Enter') {
    emit('keyDown:enter');
  }
};

const elInputRef = ref<InstanceType<typeof ElInput> | null>(null);

const setFocus = () => {
  elInputRef.value?.focus();
};

defineExpose({
  setFocus,
});
</script>

<style lang="scss" src="./index.scss" />
