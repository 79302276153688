<template>
  <div class="support-button-box">
    <div class="support-button-description">
      <div class="support-button-description__phone">+998 71 202-50-00</div>
      <div class="support-button-description__name">{{ $t('Common.SupportService') }}</div>
    </div>

    <MiButton class="support-button" @click="handleBtnClick">
      <ElIcon><MiIcon icon="TELEGRAM_V2" /></ElIcon>
    </MiButton>
  </div>
</template>

<script setup lang="ts">
import { ElIcon } from 'element-plus';

import { MiButton, MiIcon } from '~shared/ui';

const handleBtnClick = async () => {
  window.open('https://t.me/dmedcc_bot', '_blank');
};
</script>

<style lang="scss">
.support-button-box {
  display: flex;
  gap: 16px;
  align-items: center;

  .support-button-description {
    width: 158px;
    text-align: center;

    &__phone {
      font-size: var(--text-font-size-base);
      line-height: 18px;
      font-weight: var(--text-weight-medium);
    }
    &__name {
      font-size: var(--text-font-size-extra-small);
      line-height: 14px;
      color: var(--text-secondary-color);
    }
  }

  .support-button {
    height: 35px;
    border-radius: 16px;
  }
}
</style>
