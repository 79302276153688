<template>
  <MiTable
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <MiTableColumn prop="article" :label="$t('Nomenclature.VendorCode')" />
    <MiTableColumn prop="title" :label="$t('Nomenclature.NameOfProduct')" />
    <MiTableColumn prop="count" :label="$t('Nomenclature.Qty')" />
    <MiTableColumn prop="warehouse.title" :label="$t('Income.Warehouse')" />
    <MiTableColumn prop="next_income_date" :label="$t('Nomenclature.NextDeliveryDate')" />
  </MiTable>
</template>
<script lang="ts">
import { PropType } from 'vue';

import { MiTableColumn, MiTable } from '~shared/ui';
import { WarehouseDto } from '~shared/api/common';

export default {
  name: 'GoodsStockTable',
  components: {
    MiTableColumn,
    MiTable,
  },
  emits: ['update:page', 'update:perPage', 'modal:open'],
  props: {
    loading: { type: Boolean, required: true },
    items: { type: Array as PropType<WarehouseDto[]>, required: true },
    page: { type: Number, required: true },
    perPage: { type: Number, required: true },
    total: { type: Number, required: true },
  },
};
</script>

<style lang="scss" scoped></style>
