<template>
  <MiFormItem
    :class="[
      'form-text-field',
      {
        'form-text-field_in-column': props.inColumn,
        'form-text-field_only-value': props.onlyValue && props.disabled,
        'form-text-field_full-width': props.fullWidth,
      },
    ]"
    :error="props.errorMessage || fieldErrorMessage"
    :label="props.label"
    :required="props.required">
    <template v-if="props.errorMessage" #error>
      <slot name="message-content" />
    </template>
    <template v-if="isLabelShow" #label>
      <slot name="label">
        <div class="form-text-field__label-text">{{ label }}</div>
        <MiTooltip v-if="!!props.description" :description="props.description" />
      </slot>
    </template>
    <div v-if="props.onlyValue && props.disabled">
      <slot>{{ value }}</slot>
    </div>
    <MiInput
      ref="miInputRef"
      v-else
      v-model="value"
      v-bind="$attrs"
      :validate-event="false"
      v-maska:[maskOptions]="maskObject"
      :disabled="props.disabled"
      :data-maska="dataMaska"
      :placeholder="props.placeholder || $t('Common.PleaseInput')"
      :autosize="props.autosize"
      :maxlength="props.maxlength"
      :type="props.type"
      @keyDown:enter="$emit('keyDown:enter')">
      <template v-if="isAppendShow" #append>
        <slot name="append" />
      </template>
      <template v-if="$slots.prefix" #prefix>
        <slot name="prefix" />
      </template>
    </MiInput>
    <div v-if="isDeleteActionShow" class="form-text-field__delete-action">
      <slot name="delete-action" />
    </div>
  </MiFormItem>
</template>

<script lang="ts" setup>
import { vMaska, MaskInputOptions } from 'maska';
import { useField } from 'vee-validate';
import { computed, reactive, ref, toRef, useSlots, watch } from 'vue';

import { MiTooltip, MiInput, MiInputProps, MiFormItem } from '~shared/ui';

const props = withDefaults(
  defineProps<{
    inColumn?: boolean;
    onlyValue?: boolean;
    disabled?: boolean;
    required?: boolean;
    fieldName: string;
    label?: string;
    maskName?:
      | 'float-2.2'
      | 'float-3.2'
      | 'float-12.2'
      | 'int-12'
      | 'int-14'
      | 'int-12-with-minus'
      | 'float-12.2-with-minus'
      | 'phone-uz'
      | 'birthdate'
      | '';
    placeholder?: string;
    description?: string | null;
    dataMaska?: string | null;
    errorMessage?: string;
    type?: 'textarea' | 'number' | 'password';
    autosize?: MiInputProps['autosize'];
    maxlength?: string | number;
    fullWidth?: boolean;
    unmasked?: boolean;
    formatter?: (value: string) => string;
  }>(),
  {
    inColumn: true,
    onlyValue: false,
    disabled: false,
    required: false,
    maskName: '',
    label: '',
    placeholder: '',
    description: '',
    dataMaska: null,
    unmasked: false,
  }
);

defineEmits<{
  (event: 'keyDown:enter'): void;
}>();

const maskOptions: MaskInputOptions = {
  tokens: {
    Z: { pattern: /[A-Z]/ },
    A: { pattern: /[a-zA-Z0-9@._%+-]/, multiple: true, optional: true },
    P: { pattern: /[a-zA-Z0-9]/, multiple: true, optional: true },
    S: { pattern: /[a-zA-Z-]/, multiple: true, optional: true },
    D: { pattern: /\d/, multiple: true },
    d: { pattern: /\d/, optional: true },
    m: { pattern: /-/, optional: true },
  },
  postProcess: (val: string) => {
    if (props.formatter) {
      return props.formatter(val);
    }

    return val;
  },
};

const maskObject = reactive({
  unmasked: '',
  masked: '',
  completed: false,
});

const fieldName = toRef(props, 'fieldName');

const {
  value,
  errorMessage: fieldErrorMessage,
  setValue,
} = useField<string | number | undefined>(fieldName);

if (props.unmasked) {
  const { setValue: setUnmaskedValue } = useField<string | number | undefined>(
    `unmasked_${fieldName.value}`
  );

  watch(maskObject, (val) => {
    if (props.maskName === 'phone-uz') {
      setUnmaskedValue('+998' + val.unmasked);
    } else {
      setUnmaskedValue(val.unmasked);
    }
  });
}

const slots = useSlots();
const isAppendShow = computed(() => !!slots.append);
const isLabelShow = computed(() => !!props.label);
const isDeleteActionShow = computed(() => !!slots['delete-action'] && props.inColumn);

const dataMaska = computed(() => {
  if (props.dataMaska) {
    return props.dataMaska;
  }

  switch (props.maskName) {
    case 'float-2.2':
      return '#d.dd';
    case 'float-3.2':
      return '#dd.dd';
    case 'float-12.2':
      return '#ddddddddddd.dd';
    case 'float-12.2-with-minus':
      return 'm#ddddddddddd.dd';
    case 'int-12':
      return '############';
    case 'int-14':
      return '##############';
    case 'int-12-with-minus':
      return 'm############';
    case 'phone-uz':
      return '+(998) ## ### ## ##';
    case 'birthdate':
      return '##.##.####';
    default:
      return '';
  }
});

const miInputRef = ref<InstanceType<typeof MiInput> | null>(null);

const setFocus = () => {
  miInputRef.value?.setFocus();
};

watch(
  value,
  (val) => {
    if ((typeof val === 'string' && val.length === 0) || val === null) {
      setValue(undefined);
    }
  },
  { immediate: true }
);

defineExpose({
  setValue,
  setFocus,
});
</script>

<style lang="scss" src="./index.scss" />
