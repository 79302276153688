import axios, { AxiosError } from 'axios';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';

import { AnyObject, QueryParams } from '../../types';
import { PER_PAGE, SEARCH, PAGE } from '../../config';

export const clearObjectEmptyValues = <T extends AnyObject>(object: T) => {
  const newObject = { ...object };

  Object.entries(object).forEach(([key, value]) => {
    switch (true) {
      case value === undefined || value === null:
      case typeof value === 'string' && value.length === 0:
      case Array.isArray(value) && value.length === 0:
        delete newObject[key];
        return;
      default:
        return;
    }
  });

  return newObject;
};

export const joinQueryParams = <T extends QueryParams>({
  url,
  query,
}: {
  url: string;
  query: T;
}) => {
  const [urlExcludeQuery, inlineQueries] = url.split('?');

  const preparedQuery = clearObjectEmptyValues(query);

  const queries = new URLSearchParams(
    `${
      (inlineQueries || '') +
      Object.keys(preparedQuery).reduce((acc, key) => acc + `&${key}=${preparedQuery[key]}`, '')
    }`
  );

  return `${urlExcludeQuery}?${queries.toString()}`;
};

export const getApiErrorMessage = (err: unknown) => {
  let message = String(err);
  if (axios.isAxiosError(err)) {
    const error = err as AxiosError<{ message?: string }>;
    if (error.response?.data?.message) {
      message = error.response?.data?.message;
    } else {
      message = err.message;
    }
  }
  return message;
};

const checkIsEqualArrays = (arr1: (string | number)[], arr2: (string | number)[]) => {
  return isEqual(sortBy(arr1), sortBy(arr2));
};

// Проверяет нужно ли сбосить текущию страницу в пагинации и если параметры не изменились не делаеь запрос
export async function compareQueriesThenLoadData<T extends QueryParams>({
  query,
  oldQuery,
  fieldsForResetPage = [],
  resetPage,
  getData,
}: {
  query: T;
  oldQuery: T | undefined;
  fieldsForResetPage?: (keyof T)[];
  resetPage?: () => void;
  getData: () => void;
}) {
  const defaultFieldsForReset = [PER_PAGE, SEARCH];

  const allFieldsForResetPage = [...defaultFieldsForReset, ...fieldsForResetPage];

  const queryKeys = new Set([...Object.keys(query), ...Object.keys(oldQuery ?? {})]);

  const changedParamsKeys: string[] = [];

  if (!oldQuery) {
    return getData();
  }

  queryKeys.forEach((key) => {
    if (Array.isArray(query[key]) && Array.isArray(oldQuery[key])) {
      !checkIsEqualArrays(
        query[key] as (string | number)[],
        oldQuery[key] as (string | number)[]
      ) && changedParamsKeys.push(key);
    } else if (query[key] !== oldQuery[key]) {
      changedParamsKeys.push(key);
    }
  });

  // Если нужно будет сбросить page
  if (changedParamsKeys.some((key) => allFieldsForResetPage.includes(key))) {
    if (resetPage) await resetPage();
    // Не загружать данные так как мы ждем что page обновится и сюда придет еще раз
    if (query[PAGE] !== 1) {
      return;
    }
    return getData();
  }
  // Если не надо сбросить page
  if (changedParamsKeys.length > 0) {
    return getData();
  }
}

export const formatDate = (dateString: string) => {
  const [year, month, day] = dateString.split('-');
  return `${day}.${month}.${year}`;
};

export const formatFullName = (name: string, surname: string) => {
  return `${name} ${surname}`;
};
