import { ElNotification } from 'element-plus';

import { GetPriceListsResponseData } from '../types';

import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';
import { getApiErrorMessage, joinQueryParams } from '~shared/lib';

const urlPath = 'g2g/price_lists';

export const getList = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<GetPriceListsResponseData>(
      joinQueryParams({
        url: urlPath,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
