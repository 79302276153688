import { AxiosError, GenericAbortSignal } from 'axios';
import { ElNotification } from 'element-plus';

import { GetDepartmentDirectoryListResponseData } from '../../types';

import { apiService } from '~shared/api';
import { getApiErrorMessage, joinQueryParams, useAbortable } from '~shared/lib';
import { QueryParams } from '~shared/types';

const urlPath = 'departments';

export const getList = async (query: QueryParams = {}, signal?: GenericAbortSignal) => {
  try {
    const response = await apiService.api.instance.get<GetDepartmentDirectoryListResponseData>(
      joinQueryParams({
        url: urlPath,
        query,
      }),
      {
        signal,
      }
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    if (err instanceof AxiosError && err.code === 'ERR_CANCELED') return;
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
export const abortableGetList = useAbortable(getList);
