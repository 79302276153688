<template>
  <MiDialog
    class="create-appointment-modal"
    :title="$t('Patients.PatientRegistration')"
    width="1440px"
    v-model="modalVisible">
    <div class="create-appointment-form">
      <PatientSearchForm
        :examination-plan="examinationPlan"
        ref="refPatientForm"
        v-model:patient="patient"
        :modal-visible="modalVisible"
        @select="sendAmplitudeSelectPatient" />

      <FormFieldGroupWrapper>
        <div
          class="appointment-fields"
          :class="{ 'appointment-fields--examination-plan': examinationPlan }">
          <FormSearchSelect
            v-if="!examinationPlan"
            clearable
            :label="$t('Common.Clinic')"
            field-name="payloadData.clinic_id"
            field-object-name="clinic"
            :fetch-data="misApi.gen.v2.clinics.clinicListForSelect"
            raw-fetch-result
            @select="handleClinicSelect"
            required />

          <FormSearchSelect
            clearable
            :label="$t('Common.Doctor')"
            field-name="payloadData.doctor_id"
            label-key="name"
            field-object-name="doctor"
            popper-class="appointment-fields__doctor-popper"
            :query="{ clinic_id: values.clinic?.id, is_active: 1 }"
            :fetch-data="misApi.g2g.directory.doctors.getList"
            :disabled="!examinationPlan && !values.payloadData.clinic_id"
            @select="sendAmplitudeChangeDoctor"
            required>
            <template #optionTemplate="{ item }">
              <div class="appointment-fields__doctor-item">
                <div class="appointment-fields__doctor-item-wrapper">
                  <span>{{ item.name }}</span>
                  <span class="appointment-fields__doctor-item-cabinet">{{
                    item.cabinet ?? '-'
                  }}</span>
                </div>
                <p class="appointment-fields__doctor-specialties">
                  <span :key="spec.id" v-for="(spec, idx) in item.specialties">
                    {{ idx === 0 ? '' : ',' }} {{ spec.title }}
                  </span>
                </p>
              </div>
            </template>
          </FormSearchSelect>
          <FormDatePicker
            field-name="date"
            :label="$t('DateAndTime.Date')"
            :value-format="DATE_FORMAT"
            @change="sendAmplitudeChangeDate"
            required />
        </div>
        <FormCheckbox
          v-if="examinationPlan"
          :label="$t('Hospitalization.BedriddenPatient')"
          field-name="payloadData.is_bedridden_patient" />
        <SlotRadioGroup
          :parent-modal-visible="modalVisible"
          ref="refSlotRadioGroup"
          @select-slot="sendAmplitudeSelectSlot" />

        <FormTextField
          v-if="examinationPlan"
          field-name="payloadData.doctor_comment"
          :label="$t('Appointments.DoctorComment')" />
      </FormFieldGroupWrapper>
    </div>
    <FormActionsWrapper align="right">
      <MiButton
        :disabled="submitDisabled"
        :loading="isSubmitting"
        type="primary"
        @click="handleSubmitForm">
        {{
          isDoctorOrDirector
            ? $t('Appointments.AddAnAppointment')
            : $t('Appointments.AddAnAppointmentAndPrintATicket')
        }}
      </MiButton>
    </FormActionsWrapper>
  </MiDialog>
  <AppointmentTalonModal
    v-if="appointmentData"
    v-model="isAppointmentTalonModalVisible"
    :appointment="appointmentData"
    @fetchTable="emit('fetchTable')" />
</template>

<script setup lang="ts">
import { computed, onUnmounted, ref, shallowRef, watch } from 'vue';
import { useForm } from 'vee-validate';
import { storeToRefs } from 'pinia';
import { ElNotification } from 'element-plus';

import { SlotRadioGroup } from '../SlotRadioGroup';
import { PatientSearchForm } from '../PatientSearchForm';
import { createAppointmentValidationSchema } from '../../model';
import { AppointmentTalonModal } from '../AppointmentTalonModal';

import { useSessionStore } from '~entities/session';
import { FormSearchSelect, FormDatePicker, FormCheckbox } from '~shared/ui/form';
import { MiButton, MiDialog } from '~shared/ui';
import { FormActionsWrapper, FormFieldGroupWrapper, FormTextField } from '~shared/ui/form';
import { misApi } from '~shared/api';
import { DATE_FORMAT } from '~shared/config';
import { PatientGenderEnum, PatientSearchResource, SpecialtyResource } from '~shared/api/gen/v2';
import {
  AppointmentResource,
  CreateAppointmentRequestBody,
  DoctorResource,
  PatientResource,
} from '~shared/api/gen/v2';
import { amplitudeService, I18nService } from '~shared/lib';

const props = withDefaults(
  defineProps<{
    visible: boolean;
    defaultDoctor?: boolean;
    initialData: {
      patient?: PatientSearchResource;
      start_at?: string;
      doctor_id?: number;
      doctor?: { id: number; name: string };
      parent_appointment_id?: number;
      medical_case_id?: number;
    };
    examinationPlan?: boolean;
    closeAfterCreate?: boolean;
  }>(),
  {
    initialData: () => ({}),
    examinationPlan: false,
    closeAfterCreate: false,
  }
);

const emit = defineEmits<{
  'update:visible': [v: boolean];
  'created-successfully': [];
  'slot-busy': [];
  'fetchTable': [];
}>();

const refPatientForm = ref<InstanceType<typeof PatientSearchForm> | null>(null);
const appointmentData = ref<
  | (AppointmentResource & {
      doctor: DoctorResource & {
        specialties: SpecialtyResource[];
      };
      user: PatientResource;
    })
  | null
>(null);
const modalVisible = computed({
  get() {
    return props.visible;
  },
  set(v) {
    emit('update:visible', v);
  },
});

const isAppointmentTalonModalVisible = shallowRef(false);
const sessionStore = useSessionStore();
const { user, userRole } = storeToRefs(sessionStore);

const refSlotRadioGroup = ref();

const patient = ref<Partial<PatientSearchResource>>();

const isDoctorOrDirector = computed(
  () => userRole.value === 'doctor' || userRole.value === 'director'
);

// Возвращает доктора если текущий пользователь является доктором
const doctorAsCurrentUser = computed(() => {
  return userRole.value === 'doctor' && props.defaultDoctor ? user.value?.doctor : undefined;
});

// Возвращает id доктора если текущий пользователь является доктором
const doctorIdAsCurrentUser = computed(() => {
  return userRole.value === 'doctor' && props.defaultDoctor ? user.value?.doctor?.id : undefined;
});

const currentDate = computed(() => {
  return userRole.value === 'manager'
    ? new Date().toLocaleString('ru', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      })
    : undefined;
});

const { values, handleSubmit, isSubmitting, meta, errors, resetForm, setValues } = useForm<{
  date: string;
  doctor?: { id: number; name: string };
  clinic?: { id: number; title: string };
  payloadData: CreateAppointmentRequestBody;
  isSlotAvailable?: boolean;
}>({
  validationSchema: createAppointmentValidationSchema,
});

// meta.value.valid отрабатывает некорректно, поэтому использую errors.value
const submitDisabled = computed(
  () => Object.keys(errors.value).length > 0 || !meta.value.dirty || isSubmitting.value
);

const getScheduleSlots = async () => {
  await refSlotRadioGroup.value.getSlots();
};

const sendAmplitudeCreateAppointment = () => {
  amplitudeService.logEvent('create_appointment_modal.create');
};

const handleSubmitForm = handleSubmit(
  async (values) => {
    if (!patient.value?.id && refPatientForm.value) {
      const response = await refPatientForm.value.validate();

      if (!response.valid) return;
    }

    const payload = { ...values.payloadData, survey_plan: props.examinationPlan };

    if (
      !values.payloadData.user_id &&
      patient.value?.name &&
      patient.value?.surname &&
      patient.value?.pinfl
    ) {
      payload.user = {
        birthdate: patient.value?.birthdate ?? null,
        gender: patient.value?.gender as PatientGenderEnum,
        surname: patient.value.surname,
        name: patient.value.name,
        patronymic: patient.value?.patronymic ?? null,
        pinfl: patient.value.pinfl,
      };
    }

    const response = await misApi.gen.v2.appointments.createAppointment(payload);

    if (response?.data.data) {
      sendAmplitudeCreateAppointment();
      appointmentData.value = response.data.data;

      if (userRole.value === 'manager') {
        isAppointmentTalonModalVisible.value = true;
      }

      emit('created-successfully');

      if (props.closeAfterCreate) {
        emit('update:visible', false);
      } else {
        ElNotification({
          type: 'success',
          title: I18nService.t('Appointments.EntryCreated'),
        });
      }
      resetFormBeforeOpen();
      if (!values.payloadData.user_id) {
        sendAmplitudeCreatePatient();
      }
    } else {
      await getScheduleSlots();
      emit('slot-busy');
    }
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);

const sendAmplitudeCreatePatient = () => {
  amplitudeService.logEvent('create_appointment_modal.create_patient');
};

const sendAmplitudeChangeDate = () => {
  amplitudeService.logEvent('create_appointment_modal.change_date');
};

const sendAmplitudeChangeDoctor = () => {
  amplitudeService.logEvent('create_appointment_modal.change_doctor');
};

const sendAmplitudeChangeClinic = () => {
  amplitudeService.logEvent('create_appointment_modal.change_clinic');
};

const handleClinicSelect = () => {
  setValues(
    {
      ...values,
      doctor: undefined,
      payloadData: {
        ...values.payloadData,
        doctor_id: undefined,
      },
    },
    false
  );
  sendAmplitudeChangeClinic();
};

const resetFormAfterClose = () => {
  patient.value = undefined;
  resetForm({
    values: {
      date: props.initialData.start_at?.split(' ')[0] ?? currentDate.value,
    },
  });
};

const resetFormBeforeOpen = () => {
  setValues(
    {
      date: props.initialData.start_at?.split(' ')[0] ?? currentDate.value,
      doctor: props.initialData.doctor ?? doctorAsCurrentUser.value,
      clinic: { id: user.value?.clinic?.id, title: user.value?.clinic?.title },
      payloadData: {
        clinic_id: user.value?.clinic?.id,
        doctor_id: props.initialData.doctor_id ?? doctorIdAsCurrentUser.value,
        start_at: props.initialData.start_at,
        parent_appointment_id: props.initialData.parent_appointment_id,
        medical_case_id: props.initialData.medical_case_id,
        is_bedridden_patient: false,
      },
    },
    false
  );

  if (props.initialData.patient) {
    patient.value = {
      birthdate: props.initialData.patient.birthdate,
      gender: props.initialData.patient.gender,
      id: props.initialData.patient.id,
      surname: props.initialData.patient.surname,
      name: props.initialData.patient.name,
      patronymic: props.initialData.patient.patronymic,
      number_med_card: props.initialData.patient.number_med_card,
      pinfl: props.initialData.patient.pinfl,
      active_attached_clinic: props.initialData.patient.active_attached_clinic,
      active_attached_microdistrict: props.initialData.patient.active_attached_microdistrict,
      active_attached_doctor: props.initialData.patient.active_attached_doctor,
    };
  }
};

onUnmounted(() => {
  emit('update:visible', false);
});

watch(
  () => modalVisible.value,
  (v) => {
    if (!v) {
      resetFormAfterClose();
    } else {
      resetFormBeforeOpen();
    }
  }
);

watch(
  () => patient.value,
  (v) => {
    setValues(
      {
        ...values,
        payloadData: {
          ...values.payloadData,
          user_id: v?.id,
        },
      },
      false
    );
  },
  { deep: true, immediate: true }
);

watch(
  () => values.payloadData.clinic_id,
  (v) => {
    if (!v) {
      setValues(
        {
          ...values,
          doctor: undefined,
          payloadData: {
            ...values.payloadData,
            doctor_id: undefined,
          },
        },
        false
      );
    }
  }
);

const sendAmplitudeSelectPatient = () => {
  amplitudeService.logEvent('create_appointment_modal.search_by_fish');
};

const sendAmplitudeSelectSlot = () => {
  amplitudeService.logEvent('create_appointment_modal.select_slot');
};
</script>

<style lang="scss" src="./index.scss" />
