<template>
  <form class="create-invoice-payment-subject" @submit.prevent="createSubject">
    <UiModelsAutocompleteSearch
      class="create-invoice-payment-subject__service"
      :model-value="subject.subject?.id"
      :model-for-use="ServiceGroup"
      :placeholder="$t('Appointments.SelectServices')"
      label="title"
      required
      :search-query="{ user_id: currentPatientId }"
      collapse-tags
      :disabled="disabled || !currentPatientId"
      @select="subject.subject = $event" />

    <ElInput
      class="create-invoice-payment-subject__count"
      v-model="subject.count"
      :placeholder="$t('Analytics.Quantity')"
      type="number"
      required
      :disabled="disabled"
      min="1" />

    <MiButton type="primary" native-type="submit" :disabled="disabled">
      {{ $t('Common.Add') }}
    </MiButton>
  </form>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { ElInput } from 'element-plus';

// eslint-disable-next-line import/no-internal-modules
import UiModelsAutocompleteSearch from '../../UiModelsAutocompleteSearch/index.vue';
// eslint-disable-next-line import/no-internal-modules
import { Invoice } from '../../models/Invoice.model';
// eslint-disable-next-line import/no-internal-modules
import { ServiceGroup } from '../../models/ServiceGroup.model';
// eslint-disable-next-line import/no-internal-modules
import { InvoicePaymentSubject } from '../../models/InvoicePaymentSubject.model';

import { MiButton } from '~shared/ui';

export default {
  components: { MiButton, UiModelsAutocompleteSearch, ElInput },
  name: 'CreateInvoicePaymentSubject',
  emits: ['subject:create'],
  props: {
    invoice: [Invoice, Object],
    disabled: Boolean,
  },
  data() {
    return {
      subject: new InvoicePaymentSubject({
        type: InvoicePaymentSubject.enum.types.GroupService,
      }),
    };
  },

  computed: {
    currentPatientId() {
      return this.invoice.user_id;
    },
  },

  methods: {
    createSubject() {
      this.$emit('subject:create', cloneDeep(this.subject));
      this.reset();
    },
    reset() {
      this.subject = new InvoicePaymentSubject({
        type: InvoicePaymentSubject.enum.types.GroupService,
      });
    },
  },

  setup: () => ({
    ServiceGroup,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
