import { RouteRecordRaw } from 'vue-router';

import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const SCREENINGS_CVD_ROUTE: RouteRecordRaw = {
  name: 'Screenings-cvd',
  path: ROUTE_PATH.screening.CVD.rawPath,
  component: () => import('./ScreeningsCVD/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Contingent.PatientRecord',
  },
};

export const SCREENINGS_ENDOCRINE_ROUTE: RouteRecordRaw = {
  name: 'Screenings-endocrine',
  path: ROUTE_PATH.screening.endocrine.rawPath,
  component: () => import('./ScreeningsEndocrine/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Contingent.PatientRecord',
  },
};
