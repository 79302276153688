import { ROUTE_PATH } from '~shared/config';
import { onlyUserFromMobileAuthMiddleware } from '~shared/lib';

export const ROLE_SELECTION_ROUTE = {
  name: 'RoleSelection',
  path: ROUTE_PATH.auth.roleSelection,
  component: () => import('./ui'),
  beforeEnter: [onlyUserFromMobileAuthMiddleware],
  meta: {
    title: 'User.RoleSelection',
  },
};
