<template>
  <div class="form-total-amount">
    <div>{{ $t('Common.Total') }}</div>
    <div>
      {{ props.totalAmount }}
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'FormTotalAmount',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
const props = withDefaults(defineProps<{ totalAmount?: number }>(), {
  totalAmount: 0,
});
</script>

<style lang="scss" src="./index.scss"></style>
