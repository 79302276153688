<template>
  <ElSelect
    :model-value="modelValue"
    :placeholder="$t('Appointments.Status')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElOption
      v-for="option in options"
      :key="option.value"
      :label="option.label"
      :value="option.value" />
  </ElSelect>
</template>

<script>
import { ElSelect, ElOption } from 'element-plus';

import { Order } from '@/models/laboratory/Order.model';

export default {
  name: 'SelectOrderStatus',
  emits: ['update:modelValue'],
  components: {
    ElSelect,
    ElOption,
  },
  props: {
    modelValue: [String, Array],
  },

  computed: {
    options() {
      return Object.keys(Order.enum.statuses).map((key) => ({
        label: this.$t(`Laboratory.Order.Statuses.${Order.enum.statuses[key]}`),
        value: Order.enum.statuses[key],
      }));
    },
  },
};
</script>
