export const getDaysInMonth = (dateStr: Date) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  return new Date(year, month, 0).getDate();
};

/**
 * @example 2022-09-30T00:00:00.000Z -> 2022-09-01T00:00:00.000Z
 * @param {string} date ISO format
 * @return {string}
 */
export const resetDaysInISOString = (date: string) => {
  return date.replace(/\d\dT/gm, '01T');
};

export const dateAppFormatToISOString = (date: string) => {
  const [onlyDate, onlyTime] = date.split('-');

  if (!onlyDate) {
    return '-';
  }

  const [hours, minutes] = (onlyTime && onlyTime.split(':')) || [0, 0];
  const [day, month, year] = onlyDate.split('.');
  const yearDef = year?.length === 2 ? `20${year}` : year;

  return new Date(
    Number(yearDef),
    Number(month ?? 0 - 1),
    Number(day),
    Number(hours || 0),
    Number(minutes ?? 0) - new Date().getTimezoneOffset()
  ).toISOString();
};
