<template>
  <div class="service-outcome-form">
    <FieldGroupWrapper>
      <FormDateField
        field-name="expense_date"
        :label="$t('Outcome.Date')"
        :value-format="DATE_FORMAT"
        required />
      <FormSearchSelect
        field-name="expense_type_id"
        field-object-name="expense_type"
        :custom-label="`+ ${$t('Services.AddExpenseType')}`"
        :fetch-data="misApi.common.expenses.getExpenseTypes"
        @openModal="openNestedModal"
        raw-fetch-result
        required>
        <template #customLabel>
          <div class="expense_type">
            <span class="expense_type_label">{{ $t('Outcome.Type') }}</span>
            <MiButton class="expense_type_btn" type="primary" text link @click="openNestedModal">
              {{ `+ ${$t('Services.AddExpenseType')}` }}
            </MiButton>
          </div>
        </template>
      </FormSearchSelect>
      <FormTextField
        :label="$t('Outcome.Recipient')"
        field-name="recipient"
        :placeholder="$t('Outcome.RecipientPlaceholder')"
        required
        maxlength="255" />
      <FormTextField
        field-name="amount"
        :label="$t('Outcome.Summary')"
        :placeholder="$t('Outcome.SettingSum')"
        mask-name="int-12"
        required />
      <FormCheckboxField field-name="auto_accept" :label="$t('Outcome.Auto')" />
    </FieldGroupWrapper>
    <FormActionsWrapper>
      <MiButton type="primary" @click="submitForm">{{ `+ ${$t('Outcome.Add')}` }}</MiButton>
    </FormActionsWrapper>
    <OutcomeTypeModal
      v-if="isNestedModalVisible"
      v-model="isNestedModalVisible"
      @add-outcome="addTypeOutcome"
      append-to-body />
  </div>
</template>

<script lang="ts">
export default {
  name: 'OutcomeForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useField, useForm } from 'vee-validate';
import { ref } from 'vue';

import { ExpenseFormData } from '../model';

import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import { misApi } from '~shared/api';
import { DATE_FORMAT } from '~shared/config';
import { MiButton } from '~shared/ui';
import { FormSearchSelect } from '~shared/ui/form';
import { ExpenseRequest, ExpenseTypeResponse } from '~shared/api/common';

import { validationSchema } from '@/components/accounting/modals/ServiceOutcomeModal/OutcomeForm/validationSchema';
import OutcomeTypeModal from '@/components/accounting/modals/ServiceOutcomeModal/OutcomeTypeModal/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FormCheckboxField from '@/components/common/form/ui/FormCheckboxField/index.vue';
import FormDateField from '@/components/common/form/ui/FormDateField/index.vue';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';

const props = defineProps<{
  formData: ExpenseFormData;
}>();

const emit = defineEmits(['close:modal', 'action']);

const isNestedModalVisible = ref(false);

// eslint-disable-next-line vue/no-setup-props-destructure
const { handleSubmit } = useForm({
  initialValues: props.formData,
  validationSchema,
  validateOnMount: false,
});

const openNestedModal = () => {
  isNestedModalVisible.value = true;
};

const { setValue } = useField<ExpenseFormData['expense_type_id']>('expense_type_id');
const { setValue: setObjectValue } = useField<ExpenseFormData['expense_type']>('expense_type');
const addTypeOutcome = (outcomeType: ExpenseTypeResponse['data']) => {
  // TODO fix type
  setValue(outcomeType.id);
  setObjectValue(outcomeType);
};

const submitForm = handleSubmit(
  async (values) => {
    const requestData: ExpenseRequest = {
      recipient: values.recipient,
      amount: values.amount ?? 0,
      expense_type_id: values.expense_type?.id,
      expense_date: values.expense_date,
      auto_accept: values.auto_accept,
    };

    const response = await misApi.common.expenses.createItem(requestData);
    const responseData = response?.data?.data ?? {};

    emit(
      'action',
      new GlobalModalInstance({
        name: props.formData.id ? 'updated' : 'created',
        data: responseData,
      })
    );
    emit('close:modal');
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);
</script>

<style lang="scss" src="./index.scss" />
