<template>
  <PrinterDocument
    hide-watermark
    class="order-printer-document"
    :meta-blocks="metaBlocks"
    :content-blocks="contentBlocks"
    full-page
    ref="printerDocument">
    <template #meta-sign-value="{ value }">
      <span class="printer-document-content-block__inline-value">
        {{ value }}
      </span>
      <img
        v-if="user.doctor?.sign?.link"
        class="order-printer-document__sign"
        :src="user.doctor?.sign?.link"
        alt="sign" />
    </template>
    <template #append-content>
      <div class="order-printer-document__signature-wrapper">
        <span class="order-printer-document__signature-label">{{ $t('Patients.Signature') }}</span>
        <span class="order-printer-document__signature"></span>
      </div>
    </template>
  </PrinterDocument>
</template>

<script>
import { useSessionStore } from '~entities/session';
import { I18nService, ISOStringToDateAppFormat } from '~shared/lib';
import { EMPTY_VALUE_DASH } from '~shared/config';

import PrinterDocument from '@/components/printer/PrinterDocument/index.vue';
import { PrinterDocumentContentBlock as ContentBlock } from '@/models/client/PrinterDocumentContentBlock.model';
import { Biomaterial } from '@/models/laboratory/Biomaterial.model';
import { Order } from '@/models/laboratory/Order.model';
import { Analysis } from '@/models/laboratory/Analysis.model';

export default {
  name: 'OrderPrinterDocument',
  components: { PrinterDocument },
  props: {
    order: [Order, Object],
  },
  data() {
    return {
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },

    metaBlocks() {
      return [
        [
          {
            label: this.$t('Patients.Patient'),
            value: this.order.user.name,
          },
          {
            label: this.$t('User.Birthdate'),
            value: this.order.user.birthdate,
          },
        ],
        [
          {
            label: this.$t('User.Gender'),
            value: this.$t(`User.Genders.${this.order.user.gender}`),
          },
          {
            label: this.$t('Laboratory.SamplingAt'),
            value: this.order.biomaterial_ids.length
              ? this.order.biomaterials[0].sampling_at
              : EMPTY_VALUE_DASH,
          },
        ],
        [
          {
            label: this.$t('Contingent.Medcard'),
            value: this.order?.appointment?.patient?.number_med_card,
          },
          {
            label: this.$t('Patients.Microdistrict'),
            value: this.order.user?.microdistrict?.title
              ? this.order.user?.microdistrict?.title
              : EMPTY_VALUE_DASH,
          },
        ],
        [
          {
            label: this.$t('DateAndTime.DateOfIssues'),
            value: ISOStringToDateAppFormat(new Date().toISOString()),
          },
          {
            label: this.$t('Doctors.Doctor'),
            value: this.order?.appointment?.doctor?.name ?? this.order.invoice?.doctor?.name,
            uuid: 'sign',
          },
        ],
      ];
    },

    contentBlocks() {
      return [
        {
          label: this.$t('Laboratory.StudiesResults'),
          type: ContentBlock.enum.types.Title,
        },

        ...this.order.biomaterials
          .filter((biomaterial) => biomaterial.sampling_at && biomaterial.confirmed_at)
          .map((biomaterial) =>
            biomaterial.basic_data
              ? biomaterial.type === 'tiny_mce'
                ? {
                    label: Biomaterial.getBiomaterialTitleWithAnalysisCategory(biomaterial),
                    type: ContentBlock.enum.types.Tinymce,
                    basicData: biomaterial.basic_data,
                  }
                : {
                    label: Biomaterial.getBiomaterialTitleWithAnalysisCategory(biomaterial),
                    type: ContentBlock.enum.types.Editor,
                    basicData: biomaterial.basic_data,
                  }
              : {
                  label: Biomaterial.getBiomaterialTitleWithAnalysisCategory(biomaterial),
                  type: ContentBlock.enum.types.Table,
                  class: 'order-printer-document__table',
                  headers: [
                    {
                      label: this.$t('Common.Naming'),
                      prop: 'title',
                    },
                    {
                      label: this.$t('Laboratory.Results'),
                      prop: 'value',
                    },
                    {
                      label: this.$t('Common.Measure'),
                      prop: 'measure',
                      width: '10%',
                    },
                    {
                      label: this.$t('Laboratory.ReferencesInterval'),
                      prop: 'references_value',
                    },
                  ],
                  value: biomaterial.studys
                    .filter((study) => !!this.getValueString(study))
                    .map((study) => ({
                      title: study.analysis.title,
                      category: study.analysis.category,
                      value: this.getValueString(study),
                      measure: study.analysis.measure,
                      references_value: this.getReferencesValueString(study) ?? '',
                    })),
                }
          ),
      ];
    },
  },

  methods: {
    print() {
      this.$refs.printerDocument.print();
    },

    getReferencesValueString(study) {
      if (!study.references_value_id) return null;
      if (study.references_value?.norm_text) return study.references_value.norm_text;
      if (study.value_type === Analysis.enum.valueTypes.Boolean)
        return I18nService.t(`Laboratory.Study.ValueNegative`);

      return `${study.references_value.start_value}-${study.references_value.end_value}`;
    },

    getValueString(study) {
      if (study.value_type === Analysis.enum.valueTypes.File) return study.value_file?.filename;
      if (study.value_type === Analysis.enum.valueTypes.Boolean) {
        const answers = {
          1: I18nService.t(`Laboratory.Study.ValuePositive`),
          0: I18nService.t(`Laboratory.Study.ValueNegative`),
        };
        return answers[study.value];
      }

      return study.value;
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
