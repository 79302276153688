import { ElNotification } from 'element-plus';

import { QueryParams } from '~shared/types';
import { apiService, CreateSurveyRequestPayload, ResultSurveyDto, SurveyDto } from '~shared/api';
import { getApiErrorMessage, joinQueryParams } from '~shared/lib';

const urlPath = 'g2g/surveys';

export const getSurveyList = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get(
      joinQueryParams({
        url: urlPath,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
export const getSurveyPatientList = async (id: string, query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get(
      joinQueryParams({
        url: `${urlPath}/${id}/patients`,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getSurveyById = async (id: string | number) => {
  try {
    const response = await apiService.api.instance.get<{ data: SurveyDto }>(`${urlPath}/${id}`);

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const create = async (id: string | number, payload: CreateSurveyRequestPayload) => {
  try {
    const response = await apiService.api.instance.post<{ data: ResultSurveyDto }>(
      `g2g/survey-results`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getResultsSurveyById = async (id: string | number) => {
  try {
    const response = await apiService.api.instance.get<{ data: ResultSurveyDto }>(
      `g2g/survey-results/${id}`
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
