<template>
  <PrinterDocument
    class="invoice-printer-check"
    :content-blocks="contentBlocks"
    hide-title
    hide-clinic-name
    hide-address
    hide-watermark
    ref="document">
    <template #header>
      <div class="invoice-printer-check__header">
        <img
          class="invoice-printer-check__header-logo"
          :src="user?.clinic?.logo?.link"
          alt="clinic logo" />
        <div class="invoice-printer-check__header-inner">
          <div>{{ invoice.user?.humId }}</div>
          <div>Ваш номер в очереди</div>
        </div>
      </div>
    </template>
    <template #append-content>
      <QrCode :payload="{ data: qrPayload }" />
      <div class="invoice-printer-check__company-title">{{ $t('CompanyTitle') }}</div>
      <img
        class="invoice-printer-check__company-logo"
        src="@/shared/ui/svg/invoice-check-logo.svg"
        alt="logo" />
    </template>
  </PrinterDocument>
</template>

<script>
// eslint-disable-next-line import/no-internal-modules
import PrinterDocument from '../PrinterDocument/index.vue';
// eslint-disable-next-line import/no-internal-modules
import QrCode from '../QrCode/index.vue';
// eslint-disable-next-line import/no-internal-modules
import { Invoice } from '../../models/Invoice.model';
// eslint-disable-next-line import/no-internal-modules
import { PrinterDocumentContentBlock as ContentBlock } from '../../models/PrinterDocumentContentBlock.model';

import { useSessionStore } from '~entities/session';
import { formatPrice } from '~shared/lib';

export default {
  name: 'InvoicePrinterCheck',
  components: { QrCode, PrinterDocument },
  props: {
    invoice: [Invoice, Object],
    qrPayload: String,
  },
  data() {
    return {
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },

    contentBlocks() {
      return [
        {
          label: this.$t('Common.Clinic'),
          value: this.user.clinic.title,
        },
        {
          label: this.$t('DateAndTime.Date'),
          value: this.invoice.created_at?.split(' ')[0],
        },
        {
          label: this.$t('Common.Patient'),
          value: this.invoice.user?.name,
        },
        {
          label: this.$t('User.Birthdate'),
          value: this.invoice.user?.birthdate,
        },
        {
          label: this.$t('CashRegister.Invoice'),
          value: '#' + this.invoice.id,
        },
        {
          label: this.$t('DateAndTime.Time'),
          value: this.invoice.created_at?.split(' ')[1],
        },
        {
          label: this.$t('Creator'),
          value: this.invoice.creator?.name,
          withDivider: true,
        },
        ...(this.invoice.appointments.length
          ? this.invoice.appointments
              .map((appointment, index) => {
                const paymentSubjects = this.invoice.payment_subjects.filter(
                  (f) => f.appointment_id === appointment.id
                );

                return [
                  {
                    type: ContentBlock.enum.types.Table,
                    label: `${this.$t('Appointment')} №${index + 1}`,
                    headers: [
                      { prop: 'title', label: this.$t('Common.Services') },
                      { prop: 'price', label: this.$t('Common.Price') },
                    ],
                    class: 'invoice-printer-check__content-block-table',
                    value: paymentSubjects.map((payment) => ({
                      title: payment.subject?.title,
                      price: `${formatPrice({
                        price: payment.subject?.price,
                      })} ${payment.count > 1 ? `(${payment.count})` : ''}`,
                    })),
                  },
                  {
                    label: this.$t('Common.Doctor'),
                    value: appointment.doctor.name,
                  },
                  {
                    label: this.$t('Common.Cabinet'),
                    value: appointment.doctor.cabinet,
                  },
                  {
                    label: this.$t('Appointment'),
                    value: appointment.start_at,
                    withDivider: true,
                  },
                ];
              })
              .reduce((acc, current) => [...acc, ...current], [])
          : []),
        ...(this.invoice.appointments.length === 0
          ? [
              {
                type: ContentBlock.enum.types.Table,
                headers: [
                  { prop: 'title', label: this.$t('Common.Services') },
                  { prop: 'price', label: this.$t('Common.Price') },
                ],
                class: 'invoice-printer-check__content-block-table',
                value: this.invoice.payment_subjects.map((payment) => ({
                  title: payment.subject?.title,
                  price: `${formatPrice({
                    price: payment.subject?.price,
                  })} ${payment.count > 1 ? `(${payment.count})` : ''}`,
                })),
              },
            ]
          : []),
        {
          label: this.$t('Common.Total'),
          value:
            formatPrice({ price: this.invoice.discounted_amount }) + ' ' + this.$t('Common.Sum'),
          class: 'invoice-printer-check__content-block-with-total-amount',
        },
        {
          label: this.$t('Common.Discount'),
          value: (this.invoice.discount ?? 0) + '%',
          withDivider: true,
        },
        {
          label: this.$t('Paid'),
          value: `${formatPrice({
            price: this.invoice.discounted_amount - this.invoice.left_pay,
          })} ${this.$t('Common.Sum')}`,
          class: 'invoice-printer-check__content-block-with-total-amount',
        },
      ];
    },
  },

  methods: {
    print() {
      this.$refs.document.print();
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
