<template>
  <div
    :class="['ui-user-avatar-info', { 'ui-user-avatar-info_link': !!link }]"
    @click="clickHandler">
    <UiAvatar
      class="ui-user-avatar-info__avatar"
      :image="user?.avatar"
      size="small"
      :gender="user?.gender" />

    <div class="ui-user-avatar-info__content ui-user-avatar-info-content">
      <span
        v-if="!showName"
        :class="[
          'ui-user-avatar-info-content__name',
          { 'ui-user-avatar-info-content__name_bold': bold },
        ]">
        {{ user?.name }}
      </span>

      <span v-show="secondaryFieldKey" class="ui-user-avatar-info-content__secondary">
        {{ user[secondaryFieldKey] }}
      </span>
    </div>

    <ElTag
      v-show="showHasTreatment && user.has_treatment"
      class="ui-user-avatar-info__tag"
      type="success"
      effect="dark"
      round>
      {{ $t('Patients.HasTreatment') }}
    </ElTag>
  </div>
</template>

<script>
import { ElTag } from 'element-plus';

export default {
  name: 'UiUserAvatarInfo',
  components: {
    ElTag,
  },
  props: {
    user: [Object],
    secondaryFieldKey: String, // ключ-столбец от модели пользователя
    size: {
      // нигде не учитывается пока
      type: String,
      default: 'medium',
    },
    showHasTreatment: Boolean,
    bold: {
      // имя жирным
      type: Boolean,
      default: true,
    },

    link: String,
    showName: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    clickHandler() {
      if (!!this.link) {
        this.$router.push(this.link);
      }
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
