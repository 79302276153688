<template>
  <ElCard :class="['patient-card', { 'patient-card_grid': grid }]" :shadow="shadow">
    <UiUserAvatarInfo class="patient-card__user" :user="patient" />

    <div :class="['patient-card__content', { 'patient-card__content_grid': contentGrid }]">
      <div class="patient-card__info patient-card-info" v-for="item in items" :key="item.value">
        <div class="patient-card-info__label">
          <slot name="label" :item="item">{{ item.label }}</slot>
        </div>
        <div class="patient-card-info__value">
          <slot name="value" :item="item">{{ item.value ?? '...' }}</slot>
        </div>
      </div>
    </div>

    <div class="patient-card__actions patient-card-actions">
      <slot name="actions"></slot>
    </div>
  </ElCard>
</template>

<script>
import { ElCard } from 'element-plus';

export default {
  name: 'PatientCardRow',
  components: {
    ElCard,
  },
  props: {
    patient: [Object],
    /** @type {Array<{label: string, value: string|number}>} items */
    items: Array,
    shadow: {
      default: 'never',
      type: String,
    },
    grid: Boolean,
    contentGrid: Boolean,
  },
};
</script>

<style lang="scss" src="./index.scss" />
