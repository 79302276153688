import { ROUTE_PATH } from '~shared/config';
import { onlyUserHasRoleMiddleware, onlyUserIsAuthMiddleware } from '~shared/lib';

export const DIRECTOR_DASHBOARD_ROUTE = {
  name: 'DirectorDashboard',
  path: ROUTE_PATH.directorDashboard,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director'])],
  meta: {
    title: 'Common.DirectorDashboard',
  },
};
