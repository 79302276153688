<template>
  <ElFormItem
    :class="[
      'ui-date-picker-with-validation',
      {
        'ui-date-picker-with-validation_in-column': props.inColumn,
        'ui-date-picker-with-validation_only-value': props.onlyValue && props.disabled,
        'ui-date-picker-with-validation_in-line-space-between':
          !props.inColumn && !!props.label && props.spaceBetween,
      },
    ]"
    :error="errorMessage"
    :label="props.label"
    :required="required">
    <div v-if="props.onlyValue && props.disabled">{{ value }}</div>
    <ElDatePicker
      v-else
      v-model="value"
      :disabled="props.disabled"
      :format="props.format"
      :value-format="props.valueFormat"
      :validate-event="false"
      :placeholder="props.placeholder || $t('Common.PleaseSelect')"
      @change="$emit('change')" />
  </ElFormItem>
</template>

<script lang="ts">
export default {
  name: 'FormDateField',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { withDefaults } from 'vue';
import { ElFormItem, ElDatePicker } from 'element-plus';

import { FULL_DATE_FORMAT } from '~shared/config';

type Props = {
  onlyValue?: boolean;
  inColumn?: boolean;
  spaceBetween?: boolean;
  disabled?: boolean;
  required?: boolean;
  fieldName: string;
  label?: string;
  placeholder?: string;
  format?: string;
  valueFormat?: string;
};

const props = withDefaults(defineProps<Props>(), {
  onlyValue: false,
  inColumn: true,
  spaceBetween: false,
  disabled: false,
  required: false,
  label: '',
  format: FULL_DATE_FORMAT,
  valueFormat: FULL_DATE_FORMAT,
  placeholder: '',
});

// eslint-disable-next-line vue/no-setup-props-destructure
const { value, errorMessage } = useField<string>(props.fieldName);
</script>

<style lang="scss" src="./index.scss" />
