import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const DEMOGRAPHY_MAIN_LIST_ROUTE = {
  name: 'DemographyMainList',
  path: ROUTE_PATH.demography.mainList,
  component: () => import('./DemographyMainList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Analytics.Demography',
  },
};
