import axios, { AxiosError, AxiosResponse } from 'axios';
import { ElNotification } from 'element-plus';

import { Api as V2Api } from './v2/api.generated';
import { Api as PrescriptionsApi } from './prescriptions/api.generated';

import { getApiErrorMessage } from '~shared/lib';
import { PRESENTATION_MODE } from '~shared/config';

type Api = V2Api<unknown> | PrescriptionsApi<unknown>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApiMethodWithErrorHandler<T extends (...args: any[]) => Promise<AxiosResponse>> = (
  ...args: Parameters<T>
) => Promise<ReturnType<T> | undefined>;

type ApiModules<T extends Api> = Omit<T, 'setSecurityData' | 'request' | 'instance'>;

type ApiModulesWithErrorHandler<T extends Api> = {
  [TModuleKey in keyof ApiModules<T>]: {
    [TMethodKey in keyof ApiModules<T>[TModuleKey]]: ApiModules<T>[TModuleKey][TMethodKey] extends (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...args: any[]
    ) => Promise<AxiosResponse>
      ? ApiMethodWithErrorHandler<ApiModules<T>[TModuleKey][TMethodKey]>
      : undefined;
  };
};

type ApiWithErrorHandler<T extends Api> = Pick<T, 'setSecurityData' | 'request' | 'instance'> &
  ApiModulesWithErrorHandler<T>;

export const getApiMethodWithErrorHandler = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TMethod extends (...args: any[]) => Promise<AxiosResponse>,
>(
  fn: (...args: Parameters<TMethod>) => ReturnType<TMethod>
): ApiMethodWithErrorHandler<TMethod> => {
  return async (...args: Parameters<TMethod>) => {
    try {
      const response = await fn(...args);

      return response;
    } catch (err) {
      let notificationType: 'info' | 'error' = 'error';
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        notificationType =
          error?.response?.headers?.[PRESENTATION_MODE] === 'true' ? 'info' : 'error';
      }
      ElNotification({
        type: notificationType,
        title: getApiErrorMessage(err),
      });
    }
  };
};

export const getApiWithErrorHandler = <TApi extends Api>(api: TApi): ApiWithErrorHandler<TApi> => {
  const apiMethodsWithErrorHandler = (Object.keys(api) as [keyof TApi]).reduce<
    ApiModulesWithErrorHandler<TApi>
  >((modulesAcc, moduleName) => {
    if (moduleName === 'request' || moduleName === 'setSecurityData' || moduleName === 'instance') {
      return modulesAcc;
    }

    const apiModule = api[moduleName];

    if (!apiModule) {
      return modulesAcc;
    }

    return {
      ...modulesAcc,
      [moduleName]: Object.entries(apiModule).reduce((moduleMethodsAcc, [methodName, method]) => {
        return {
          ...moduleMethodsAcc,
          [methodName]:
            typeof method === 'function'
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                getApiMethodWithErrorHandler(method as (...args: any[]) => Promise<AxiosResponse>)
              : undefined,
        };
      }, {}),
    };
  }, {} as ApiModulesWithErrorHandler<TApi>);

  return {
    ...apiMethodsWithErrorHandler,
    request: api.request,
    setSecurityData: api.setSecurityData,
    instance: api.instance,
  };
};
