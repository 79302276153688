import { clearObjectEmptyValues } from '~shared/lib';
import { LoginResponseData, LoginUserRequestData, UserCredentialsData } from '~shared/api';
import { apiService } from '~shared/api';

const urlPath = '/auth';

export const login = async (payload: LoginUserRequestData | UserCredentialsData) => {
  const response = await apiService.api.instance.post<LoginResponseData>(`${urlPath}/login`, {
    ...clearObjectEmptyValues({
      email: payload.email,
      password: payload.password,
      key_activating: 'key_activating' in payload ? payload.key_activating : '',
    }),
  });

  return {
    ...response.data,
  };
};
