import { EMPTY_VALUE_DASH } from '~shared/config';
import { I18nService } from '~shared/lib';

export function separateThousands(
  value: string | number | null | undefined,
  params: {
    delimiter?: string;
  } = {
    delimiter: ' ',
  }
) {
  return (value ?? 0)
    .toString()
    .split(/(?=(?:\d{3})+(?:\.|$))/g)
    .join(params.delimiter);
}

export function formatFullnameToShort(value?: string | null) {
  return value
    ? value
        .split(' ')
        .slice(0, 3)
        .map((word, index) => (index === 0 ? `${word} ` : `${word[0]?.toLocaleUpperCase()}.`))
        .join('')
    : '';
}

export function joinFullname(payload?: {
  surname?: string | null;
  name?: string | null;
  patronymic?: string | null;
}) {
  return payload
    ? [payload.surname?.trim(), payload.name?.trim(), payload.patronymic?.trim()]
        .filter((f) => !!f)
        .join(' ')
    : EMPTY_VALUE_DASH;
}

const cyrillicToEngMap: Record<string, string> = {
  'й': 'q',
  'ц': 'w',
  'у': 'e',
  'к': 'r',
  'е': 't',
  'н': 'y',
  'г': 'u',
  'ш': 'i',
  'щ': 'o',
  'з': 'p',
  'х': '[',
  'ъ': ']',
  'Х': '{',
  'Ъ': '}',
  // \: `\\`,
  'ф': 'a',
  'ы': 's',
  'в': 'd',
  'а': 'f',
  'п': 'g',
  'р': 'h',
  'о': 'j',
  'л': 'k',
  'д': 'l',
  'ж': ';',
  'Ж': ':',
  'э': "'",
  'Э': '"',
  'я': 'z',
  'ч': 'x',
  'с': 'c',
  'м': 'v',
  'и': 'b',
  'т': 'n',
  'ь': 'm',
  'б': ',',
  'Б': '<',
  'ю': '.',
  'Ю': '>',
  '.': '/',
  ',': '?',

  // numbers chars
  '!': '!',
  '"': '@',
  '№': '#',
  ';': '$',
  '%': '%',
  ':': '^',
  '?': '&',
  '*': '*',
  '(': '(',
  ')': ')',
  '_': '_',
  '+': '=',
};
export function cyrillicToEng(text: string, saveChartCase?: boolean) {
  return text
    .split('')
    .map(function (char) {
      if (cyrillicToEngMap[char]) {
        return cyrillicToEngMap[char];
      } else if (cyrillicToEngMap[char.toLowerCase()]) {
        return saveChartCase
          ? cyrillicToEngMap[char.toLowerCase()]?.toUpperCase()
          : cyrillicToEngMap[char.toLowerCase()];
      } else {
        return char;
      }
    })
    .join('');
}

export function prepareTokenForBracelet(patientName: string): never | string {
  const isTextOnCyrillic = patientName.match(/[а-яА-Я]/gim);

  const latinText = isTextOnCyrillic ? cyrillicToEng(patientName) : patientName;

  const matches = Array.from(latinText.matchAll(/\?oneTimeToken=(?<token>[^&]*)/gim));
  const token = matches[0]?.groups?.token ?? null;

  if (!token) throw new Error(I18nService.t('Common.InvalidQrCode'));

  return token;
}

export const getDeclensionWord = (value: number, words: [string, string, string]): string => {
  value = Math.abs(value) % 100;
  const num = value % 10;

  if (value > 10 && value < 20) {
    return words[2];
  }
  if (num > 1 && num < 5) {
    return words[1];
  }
  if (num === 1) {
    return words[0];
  }

  return words[2];
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const clearHtmlFromTags = (text?: string) => {
  if (!text) return '-';

  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');

  // Находим элемент с id 'header-inspection-card'
  const divToRemove = doc.getElementById('header-inspection-card');

  // Удаляем найденный элемент, если он существует
  if (divToRemove) {
    divToRemove?.parentNode?.removeChild(divToRemove);
  }

  const textWithoutHeader = doc.documentElement.outerHTML;

  const tableSections = textWithoutHeader.split(/(<table(?:\s[^>]*)?>[\s\S]*?<\/table>)/g);
  const cleanedSections = tableSections.map((section) => {
    if (section.startsWith('<table')) {
      return section;
    } else {
      return section.replace(/<[^>]*>|\n/g, '');
    }
  });
  return cleanedSections.join('').replace(/( style="[^"]*)height:[^"]*;/gi, '$1');
};
