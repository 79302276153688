<template>
  <VeeForm
    class="transform-goods-table-filling-form"
    :validation-schema="validationSchema"
    v-slot="{ meta }"
    @submit="onSubmit">
    <div class="transform-goods-table-filling-form__field-group">
      <FormSearchSelectField
        :disabled="fromWarehouseId === null"
        :search-query="{ warehouse_id: fromWarehouseId }"
        field-name="product_id"
        field-object-name="product"
        :fetch-data="misApi.common.product.getCountExistsItemsWithCountByWarehouseId"
        :placeholder="$t('TransferGoods.ProductSearchNameArticle')"
        @select="onProductSelect" />
      <FormNumberField
        :disabled="fromWarehouseId === null"
        field-name="count"
        :placeholder="$t('Nomenclature.Qty')"
        :in-column="false"
        :min="1"
        :max="countMax.value"
        ref="countInput" />
      <MiButton
        type="primary"
        native-type="submit"
        :disabled="!meta.dirty || fromWarehouseId === null"
        >{{ $t('Common.Add') }}
      </MiButton>
    </div>
  </VeeForm>
</template>
<script lang="ts">
export default {
  name: 'TransformGoodsTableFillingForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { Form as VeeForm, SubmissionHandler } from 'vee-validate';
import { reactive, ref } from 'vue';
import { number, object } from 'yup';

import { I18nService } from '~shared/lib';
import { MiButton } from '~shared/ui';
import { MigrationProduct } from '~shared/api/common';
import { misApi } from '~shared/api';

import FormNumberField from '@/components/common/form/ui/FormNumberField/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';

const emit = defineEmits(['table:add']);

type Props = {
  fromWarehouseId: number | null;
};

withDefaults(defineProps<Props>(), {
  fromWarehouseId: null,
});

const countMax = reactive<{ value: number }>({ value: 1 });
const countInput = ref<InstanceType<typeof FormNumberField> | null>(null);

const onSubmit: SubmissionHandler<Record<string, unknown>> = async (values, actions) => {
  actions.resetForm();
  emit('table:add', values);
};

const validationSchema = object({
  product_id: number().nullable().required(I18nService.t('Validation.RequiredField')),
  count: number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(I18nService.t('Validation.RequiredField')),
});

const onProductSelect = (product: MigrationProduct) => {
  countInput.value?.setValue(1);
  countMax.value = product.count ?? 1;
};
</script>

<style lang="scss" src="./index.scss" />
