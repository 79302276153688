import { ElNotification } from 'element-plus';

import { apiService, MetaDto, VaccinationsDto } from '~shared/api';
import { QueryParams } from '~shared/types';
import { findCurrentEnv, getApiErrorMessage, joinQueryParams } from '~shared/lib';

const urlPath = 'b2g/analytics';

export const exportDispensarizationMicrodistrictsDataURL =
  findCurrentEnv('API_URL') + `b2g/exports/dispensaries/microdistricts`;
export const exportDispensarizationCodesDataURL =
  findCurrentEnv('API_URL') + `b2g/exports/dispensaries/codes`;
export const exportDispensarizationPatientsDataURL =
  findCurrentEnv('API_URL') + `b2g/exports/dispensaries/patients`;
export const exportDisabledPeopleDataURL =
  findCurrentEnv('API_URL') + `b2g/exports/disabilities/microdistricts`;
export const exportDisabledPeoplePatientsDataURL =
  findCurrentEnv('API_URL') + `b2g/exports/disabilities/patients`;

export const exportHealthGroupMicrodistrictsDataURL =
  findCurrentEnv('API_URL') + `b2g/exports/groups/microdistricts`;

export const exportHealthGroupCodesDataURL = findCurrentEnv('API_URL') + `b2g/exports/groups/codes`;

export const exportHealthGroupPatientsDataURL =
  findCurrentEnv('API_URL') + `b2g/exports/groups/patients`;

export const exportPregnancyMicrodistrictsDataURL =
  findCurrentEnv('API_URL') + `b2g/exports/pregnancies/microdistricts`;

export const exportPregnancyPatientsDataURL =
  findCurrentEnv('API_URL') + `b2g/exports/pregnancies/patients`;

export const exportVaccinesDataURL = findCurrentEnv('API_URL') + `b2g/exports/vaccines`;

export const exportVaccinesMicrodistrictsDataURL =
  findCurrentEnv('API_URL') + `b2g/exports/vaccines/microdistricts`;

export const exportVaccinesPatientsDataURL =
  findCurrentEnv('API_URL') + `b2g/exports/vaccines/patients`;

export const getAnalyticsMicrodistricts = async <T>(query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{
      data: T;
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPath}/microdistricts`,
        query: query,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getAnalyticsDiagnoses = async <T>(query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{
      data: T;
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPath}/codes`,
        query: query,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getAnalyticsPatients = async <T>(query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{
      data: T;
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPath}/patients`,
        query: query,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getAnalyticVaccinations = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{
      data: VaccinationsDto[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPath}/vaccines`,
        query: query,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
