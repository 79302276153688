<template>
  <MainLayout content-class="v-nomenclature-content" fix-height>
    <MiFilterBox>
      <template #actions>
        <MiButton type="primary" @click="onCreateButtonClick">
          {{ `+ ${$t('Product.CreateProduct')}` }}
        </MiButton>
      </template>
      <ElInput
        v-model="searchProduct.value"
        class="v-nomenclature-content-input"
        :placeholder="$t('Income.SearchGoods')"
        @input="debouncedInput">
        <template #prefix>
          <MiIcon icon="SEARCH" />
        </template>
      </ElInput>
    </MiFilterBox>
    <NomenclatureTable
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :items="items"
      :total="total"
      @modal:open="onEditButtonClick" />
  </MainLayout>
</template>

<script lang="ts">
export default {
  name: 'VNomenclature',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ElInput } from 'element-plus';
import debounce from 'lodash.debounce';
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';

import { MainLayout } from '~widgets/layouts';
import { MiButton, MiFilterBox, MiIcon } from '~shared/ui';
import { useQuery, usePage, usePerPage, compareQueriesThenLoadData } from '~shared/lib';
import { DEFAULT_DEBOUNCE_DELAY } from '~shared/config';
import { ProductDto } from '~shared/api/common';
import { misApi } from '~shared/api';

import ProductCrudModal from '@/components/warehouse/modals/ProductCrudModal/index.vue';
import NomenclatureTable from '@/components/warehouse/tables/NomenclatureTable/index.vue';

const store = useStore();
const loading = ref(false);
const perPage = usePerPage();
const page = usePage();
const searchProduct = useQuery({ field: 'search' });
const debouncedSearchString = ref(searchProduct.value);

const total = computed(() => store.state.nomenclature.total);
const items = computed(() => store.state.nomenclature.data);
const query = computed(() => ({
  per_page: perPage.value,
  page: page.value,
  search: debouncedSearchString.value,
}));

const getProducts = async () => {
  loading.value = true;
  const response = await misApi.common.product.getItems(query.value);
  if (response?.data) {
    const { data, meta } = response.data;
    store.dispatch('nomenclature/setData', {
      items: data,
      total: +meta.total,
      overwriteDataState: true,
    });
  }
  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getProducts,
      resetPage: page.reset,
      fieldsForResetPage: ['search'],
    });
  },
  { deep: true, immediate: true }
);

const onCreateButtonClick = async () => {
  await store.dispatch('modalAndDrawer/openModal', ProductCrudModal);
};

const onEditButtonClick = async (payload: ProductDto) => {
  await store.dispatch('modalAndDrawer/openModal', {
    component: ProductCrudModal,
    payload: {
      formData: payload,
    },
  });
};

const debouncedInput = debounce((value) => {
  debouncedSearchString.value = value;
}, DEFAULT_DEBOUNCE_DELAY);
</script>

<style lang="scss">
.v-nomenclature-content {
  &-input {
    width: 500px;
  }
}
</style>
