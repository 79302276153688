<template>
  <MiFormItem
    :class="[
      'form-select',
      {
        'form-select_in-column': inColumn,
        'form-select_in-line-space-between': !inColumn && !!label && spaceBetween,
      },
    ]"
    :error="errorMessage"
    :label="label"
    :required="required">
    <MiSelect
      ref="miSelectRef"
      class="form-select__select"
      v-model="value"
      :validate-event="false"
      :disabled="disabled"
      :placeholder="placeholder || $t('Common.PleaseSelect')"
      :clearable="clearable"
      :multiple="multiple"
      :multiple-limit="multipleLimit"
      :collapse-tags="collapseTags"
      :collapse-tags-tooltip="collapseTagsTooltip"
      @update:model-value="$emit('update:modelValue', $event)"
      @change="selectHandler">
      <ElOption
        class="form-select__option"
        v-for="item in options"
        :key="item[valueKey]"
        :disabled="disabledValues.includes(item[props.valueKey])"
        :label="item[labelKey]"
        :value="item[valueKey]">
        <slot name="optionTemplate" :item="item" />
      </ElOption>
    </MiSelect>
  </MiFormItem>
</template>

<script lang="ts" setup>
import cloneDeep from 'lodash.clonedeep';
import { useField } from 'vee-validate';
import { ref, watch } from 'vue';
import { ElOption } from 'element-plus';

import { MiSelect, MiSelectProps, MiFormItem } from '~shared/ui';
import { AnyObject } from '~shared/types';

type Props = {
  options: AnyObject[];
  inColumn?: boolean;
  spaceBetween?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  required?: boolean;
  fieldName: string;
  fieldObjectName: string;
  labelKey?: string;
  valueKey?: string;
  label?: string;
  placeholder?: string;
  multiple?: boolean;
  collapseTags?: boolean;
  collapseTagsTooltip?: boolean;
  disabledValues?: (string | number)[];
  multipleLimit?: number;
};

const props = withDefaults(defineProps<Props>(), {
  inColumn: true,
  labelKey: 'title',
  valueKey: 'id',
  label: '',
  placeholder: '',
  disabledValues: () => [],
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: MiSelectProps['modelValue']): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (event: 'select', value: any): void;
}>();

const { value, setValue, errorMessage } = useField<MiSelectProps['modelValue']>(
  () => props.fieldName
);

const { setValue: setObjectValue } = useField<AnyObject | undefined>(() => props.fieldObjectName);

const selectHandler = (eventValue: string | number | boolean) => {
  setValue(eventValue);
  let result: AnyObject | AnyObject[] | undefined;

  if (Array.isArray(eventValue)) {
    result = eventValue.map((item) => {
      return props.options.find((f) => f[props.valueKey] === item);
    });
  } else {
    result = props.options.find((f) => f[props.valueKey] === eventValue);
  }

  const cloneResult = cloneDeep(result);

  if (cloneResult) {
    setObjectValue(cloneResult);
  }
  emit('select', cloneResult);
};

const miSelectRef = ref<InstanceType<typeof MiSelect> | null>(null);

const setFocus = () => {
  miSelectRef.value?.setFocus();
};

watch(
  () => value.value,
  (v) => {
    if (!v) {
      if ((typeof v === 'string' && v.length === 0) || v === null) {
        setValue(undefined, false);
      }
      setObjectValue(undefined);
    }
  },
  { immediate: true }
);

defineExpose({
  setFocus,
});
</script>

<style lang="scss" src="./index.scss" />
