import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const HEALTH_GROUP_MAIN_LIST_ROUTE = {
  name: 'HealthGroupMainList',
  path: ROUTE_PATH.healthGroup.mainList,
  component: () => import('./HealthGroupMainList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director'])],
  meta: {
    title: 'Analytics.HealthGroup',
  },
};

export const HEALTH_GROUP_PATIENT_LIST_ROUTE = {
  name: 'HealthGroupPatientList',
  path: ROUTE_PATH.healthGroup.patientList,
  component: () => import('./HealthGroupPatientList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
};

export const HEALTH_GROUP_CODE_LIST_ROUTE = {
  name: 'HealthGroupCodeList',
  path: ROUTE_PATH.healthGroup.codeList,
  component: () => import('./HealthGroupCodeList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
};
