import axios from 'axios';
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { getFormattedCategory } from '~entities/patient';
import {
  misApi,
  PatientDocumentType,
  PatientRelationshipType,
  PatientRelative,
  SearchPatientRequestPayload,
  PatientShortDto,
  PatientRelatePayload,
} from '~shared/api';
import { I18nService } from '~shared/lib';

export const usePatientRelativesStore = defineStore('patientRelativesStore', () => {
  const data = ref<PatientRelative[]>([]);
  const modalData = ref<(PatientShortDto & { category_title?: string }) | null | undefined>(null);
  const tableLoading = ref(false);
  const visible = ref(false);
  const modalLoading = ref(false);
  const readOnly = ref(false);
  const isFetched = ref(false);
  const disabledRelativeBtn = ref(false);
  const successful = computed(() => !!modalData.value);
  const currentUserId = ref<number>();

  async function fetchPatientRelatives() {
    tableLoading.value = true;
    const response = await misApi.g2g.patients.getRelatives({ userId: currentUserId.value });
    if (response?.data?.data) data.value = response?.data.data;
    tableLoading.value = false;
  }

  async function searchPatient(payload: SearchPatientRequestPayload) {
    modalLoading.value = true;
    disabledRelativeBtn.value = false;
    const response = await misApi.g2g.patients.search(payload);
    if (response?.data) {
      modalData.value = {
        ...response.data,
        category_title: getFormattedCategory(response.data.category),
      };
    }

    isFetched.value = true;
    readOnly.value = true;
    modalLoading.value = false;
  }

  async function addRelative(type: PatientRelatePayload['type']) {
    try {
      modalLoading.value = true;
      const payload = prepareRelativePayload(type);
      disabledRelativeBtn.value = true;
      const response = await misApi.g2g.patients.relate(payload);
      visible.value = false;
      isFetched.value = true;
      disabledRelativeBtn.value = false;
      return response;
    } catch (err) {
      // Это проверка нужно чтобы не отправляли повторные запросы если ошибка bad request
      // Если будут ошибки отличающие от bad request кнопка не будт заблочена
      if (axios.isAxiosError(err) && err?.response?.status !== 400) {
        disabledRelativeBtn.value = false;
      }
    } finally {
      readOnly.value = true;
      modalLoading.value = false;
    }
  }

  function prepareRelativePayload(type: PatientRelatePayload['type']): PatientRelatePayload {
    return {
      type: type,
      related_user_id: modalData?.value?.id,
      user_id: currentUserId.value,
    };
  }

  function setVisible(value: boolean) {
    visible.value = value;
  }

  function resetData() {
    modalData.value = null;
    isFetched.value = false;
    readOnly.value = false;
  }

  function setCurrentUserId(id: number) {
    currentUserId.value = id;
  }

  const documentTypeOptions = computed<{ id: PatientDocumentType; title: string }[]>(() => {
    return [
      { id: 'uz_passport', title: I18nService.t('Contingent.DocumentTypeName.uz_passport') },
      {
        id: 'foreign_passport',
        title: I18nService.t('Contingent.DocumentTypeName.foreign_passport'),
      },
      { id: 'accreditation', title: I18nService.t('Contingent.DocumentTypeName.accreditation') },
      {
        id: 'birth_certificate',
        title: I18nService.t('Contingent.DocumentTypeName.birth_certificate'),
      },
      { id: 'id_card', title: I18nService.t('Contingent.DocumentTypeName.id_card') },
      { id: 'no_document', title: I18nService.t('Contingent.DocumentTypeName.no_document') },
    ];
  });

  const relationshipTypeOptions = computed<{ id: PatientRelationshipType; title: string }[]>(() => {
    return [
      { id: 'spouse', title: I18nService.t('Contingent.RelationShip.spouse') },
      { id: 'parent', title: I18nService.t('Contingent.RelationShip.parent') },
      { id: 'child', title: I18nService.t('Contingent.RelationShip.child') },
    ];
  });

  return {
    data,
    currentUserId,
    tableLoading,
    visible,
    modalLoading,
    successful,
    isFetched,
    readOnly,
    modalData,
    documentTypeOptions,
    relationshipTypeOptions,
    disabledRelativeBtn,
    setVisible,
    resetData,
    fetchPatientRelatives,
    searchPatient,
    addRelative,
    setCurrentUserId,
  };
});
