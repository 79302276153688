import { AppointmentServiceGroupWithCount } from './AppointmentServiceGroupWithCount.model';
import { CRUDModel } from './CRUD.model';

import { apiService } from '~shared/api';
import { joinQueryParams, clearObjectEmptyValues } from '~shared/lib';

/**
 * @class Appointment
 * @extends CRUDModel
 */
export class Appointment extends CRUDModel {
  static modelName = 'appointment';
  static tableName = 'appointments';

  /**
   * @typedef {object} AppointmentConstructorPayload
   * @property {number} id
   * @property {number} patient_id
   * @property {Patient} patient
   * @property {number} user_id synonym for payload.patient_id
   * @property {number} doctor_id
   * @property {Doctor} doctor
   * @property {number} specialty_id
   * @property {Specialty} specialty
   * @property {Array<number>} service_ids
   * @property {Array<Service>} services
   * @property {Array<number>} group_service_ids
   * @property {Array<ServiceGroup>} group_services
   * @property {Array<AppointmentServiceGroupWithCount|object>} group_services_with_count
   * @property {number} treatment_id
   * @property {Treatment} treatment
   * @property {number} inspection_card_id
   * @property {InspectionCard} inspection_card
   * @property {number} invoice_id
   * @property {Invoice|object} invoice
   * @property {number|string} reference_id
   * @property {AppointmentSource|object} reference
   *
   * @property {Array<AppointmentSubject>} appointments
   * @property {number} discount
   * @property {Date|string} start_at - format DD.MM.YY hh:mm
   * @property {Date|string} end_at
   * @property {string} status
   * @property {string} cancel_reason
   * @property {string} cancel_description
   * @property {object} permissions
   * @property {boolean} permissions.editable
   * @property {boolean} permissions.deletable
   * @property {boolean} permissions.deletable
   * @property {boolean} permissions.deletable
   * @property {number} recommendation_id
   * @property {object} recommendation
   * @param {number} recommendation.id
   * @param {Array} recommendation.group_services
   * @param {Array} recommendation.group_service_ids
   */
  /** @param {AppointmentConstructorPayload} [payload] */
  constructor(payload) {
    super(payload);

    this.patient_id = payload?.patient_id ?? payload?.user_id ?? null;
    this.patient = payload?.patient ?? undefined;
    this.doctor_id = payload?.doctor_id ?? null;
    this.doctor = payload?.doctor ?? null;
    this.specialty_id = payload?.specialty_id ?? null;
    this.specialty = payload?.specialty ?? null;
    this.service_ids = payload?.service_ids ?? [];
    this.services = payload?.services ?? [];
    this.group_service_ids = payload?.group_service_ids ?? [];
    this.group_services = payload?.group_services ?? [];
    this.group_services_with_count =
      payload?.group_services_with_count?.map(
        (elem) => new AppointmentServiceGroupWithCount(elem) // нужно для использования _id в качестве ключа
      ) ?? [];
    this.treatment_id = payload?.treatment_id ?? null;
    this.treatment = payload?.treatment ?? null;
    this.inspection_card_id = payload?.inspection_card_id ?? null;
    this.inspection_card = payload?.inspection_card ?? null;
    this.invoice_id = payload?.invoice_id ?? null;
    this.invoice = payload?.invoice ?? {};
    this.reference_id = payload?.reference_id ?? null;
    this.reference = payload?.reference ?? null;

    this.appointments = payload?.appointments ?? []; // only for create
    this.discount = payload?.discount ?? 0;
    this.start_at = payload?.start_at ?? null;
    this.end_at = payload?.end_at ?? null;
    this.status = payload?.status ?? null;
    this.cancel_reason = payload?.cancel_reason ?? null;
    this.cancel_description = payload?.cancel_description ?? null;
    this.permissions = {
      editable: payload?.permissions?.editable ?? false,
      deletable: payload?.permissions?.deletable ?? false,
    };
    this.recommendation_id = payload?.recommendation_id ?? null;
    this.recommendation = {
      id: payload?.recommendation?.id ?? null,
      group_services: payload?.recommendation?.group_services ?? [],
      group_service_ids: payload?.recommendation?.group_service_ids ?? [],
    };
    this.parent_appointment_id = payload?.parent_appointment_id;
    this.medical_case_id = payload?.medical_case_id;
  }

  /**
   * @override
   * @param {Appointment|object} payload
   * @return {Promise<{data: response.data, response: AxiosResponse<*>, modelName?: Object}>}
   */
  static async create(payload) {
    return super.create(payload, { url: `${Appointment.tableName}/create/byGroup` });
  }

  /**
   * @param {number} treatmentId
   * @return {Promise<{data: response.data, response: AxiosResponse<*>, modelName?: Object}>}
   */
  static async createByTreatment(treatmentId) {
    return super.create(
      {},
      {
        url: `${Appointment.tableName}/create/byTreatment/${treatmentId}`,
      }
    );
  }

  static async getStatistic({ startAt, endAt, doctorsId }) {
    const response = await apiService.api.instance.get(
      joinQueryParams({
        url: `/${this.tableName}/month`,
        query: clearObjectEmptyValues({ start_at: startAt, end_at: endAt, doctors_id: doctorsId }),
      })
    );

    return { response, data: response.data };
  }

  /**
   * @param {string,number} doctorId
   * @return {Promise<{data: response.data, response: AxiosResponse<*>}>}
   */
  static async findByDoctor({ doctorId }) {
    const response = await apiService.api.instance.get(`${this.tableName}/${doctorId}/queues`);
    return { response, data: response.data };
  }

  /**
   * @param {string,number} id
   * @param {string} status
   * @return {Promise<{data: response.data, response: AxiosResponse<*>}>}
   */
  static async updateStatus({ id, status }) {
    const response = await apiService.api.instance.put(`${this.tableName}/${id}/${status}`);
    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {number} serviceCaseId
   * @param {number} appointmentId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async attachServiceCase({ serviceCaseId, appointmentId }) {
    const response = await apiService.api.instance.put(`${this.tableName}/${appointmentId}/case`, {
      service_case_id: serviceCaseId,
    });
    return { response, data: response.data };
  }

  /**
   * @param {number} treatmentId
   * @param {number} appointmentId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async attachTreatment({ treatmentId, appointmentId }) {
    const response = await apiService.api.instance.put(
      `${this.tableName}/${appointmentId}/treatment`,
      {
        treatment_id: treatmentId,
      }
    );
    return { response: response, data: response.data };
  }

  /**
   * @param {string} date format - DD.MM.YY hh:mm
   * @return {Date|string}
   */
  static getStartDate(date) {
    return date.split(' ')[0];
  }

  /**
   * @param {string} date format - DD.MM.YY hh:mm
   * @return {Date|string}
   */
  static getStartTime(date) {
    return date.split(' ')[1];
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @param {string|number} [params.doctorId]
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getCountStatistic({ startAt, endAt, doctorId }) {
    const query = { start_at: startAt, end_at: endAt };

    if (doctorId) {
      query.doctor_id = doctorId;
    }

    const response = await apiService.api.instance.get(
      joinQueryParams({
        url: `statistics/${this.tableName}/count`,
        query,
      })
    );

    return { response: response, data: response.data };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @param {string|number} [params.doctorId]
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getOnTimeStatistic({ startAt, endAt, doctorId }) {
    const query = { start_at: startAt, end_at: endAt };

    if (doctorId) {
      query.doctor_id = doctorId;
    }

    const response = await apiService.api.instance.get(
      joinQueryParams({
        url: `statistics/${this.tableName}/onTime`,
        query,
      })
    );

    return { response: response, data: response.data };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getAverageWaitingStatistic({ startAt, endAt }) {
    const response = await apiService.api.instance.get(
      joinQueryParams({
        url: `statistics/${this.tableName}/average/waiting`,
        query: { start_at: startAt, end_at: endAt },
      })
    );

    return { response: response, data: response.data };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @param {string|number} [params.doctorId]
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getSuccessfulStatistic({ startAt, endAt, doctorId }) {
    const query = { start_at: startAt, end_at: endAt };

    if (doctorId) {
      query.doctor_id = doctorId;
    }

    const response = await apiService.api.instance.get(
      joinQueryParams({
        url: `statistics/${this.tableName}/successful`,
        query,
      })
    );

    return { response: response, data: response.data };
  }

  /**
   * @param {Object} payload
   * @param {number} payload.appointment_id
   * @param {number} payload.user_id
   * @param {Array<number>} payload.group_service_ids
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async recommendationCreate(payload) {
    const response = await apiService.api.instance.post(`recommendations/create`, payload);

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {number} id
   * @param {Object} payload
   * @param {Array<number>} payload.group_service_ids
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async recommendationUpdate(id, payload) {
    const response = await apiService.api.instance.put(`recommendations/${id}/update`, payload);

    return {
      response: response,
      data: response.data,
    };
  }

  static enum = {
    statuses: {
      Created: 'created',
      Approved: 'approved',
      Waiting: 'waiting',
      InProgress: 'in_progress',
      Provided: 'provided',
      Canceled: 'canceled',
    },
    // only for front
    inspectionTypes: {
      Full: 'full',
      Treatment: 'treatment',
    },
  };
}
