import { I18nService } from '~shared/lib';
import { ContingentCategory, PatientFullDto, PatientDocumentType } from '~shared/api';
import { EMPTY_VALUE_DASH } from '~shared/config';
import { PatientGenderEnum } from '~shared/api/gen/v2';
import { DiseaseCodeResource, DispensaryResource } from '~shared/api/gen/prescriptions';
import { DispensaryDto } from '~shared/api';

type Dispensary = DispensaryResource & {
  disease_code?: DiseaseCodeResource;
};

export const getFormattedCategory = (value?: ContingentCategory) => {
  switch (typeof value === 'string' ? Number(value) : value) {
    case 1:
      return I18nService.t(`Contingent.Status.one`);
    case 2:
      return I18nService.t(`Contingent.Status.two`);
    case 3:
      return I18nService.t(`Contingent.Status.third`);
    case 4:
      return I18nService.t(`Contingent.Status.fourth`);
    case 5:
      return I18nService.t(`Contingent.Status.fifth`);
    case 6:
      return I18nService.t(`Contingent.Status.nulevka`);
    case 7:
      return I18nService.t(`Contingent.Status.insurance`);
    case null:
      return I18nService.t(`Contingent.Paid`);
    default:
      return EMPTY_VALUE_DASH;
  }
};

export const getDocumentTypeName = (
  patient?: Partial<PatientFullDto> | null
): PatientDocumentType | undefined => {
  switch (true) {
    case !!patient?.passport_number && !!patient.passport_serial:
      return 'uz_passport';
    case !!patient?.foreign_passport_number && !!patient.foreign_passport_serial:
      return 'foreign_passport';
    case !!patient?.accreditation_number:
      return 'accreditation';
    case !!patient?.birth_certificate:
      return 'birth_certificate';
    case !!patient?.id_card:
      return 'id_card';
    default:
      return 'no_document';
  }
};

export const getFormattedDocumentTypeName = (value?: PatientDocumentType) => {
  if (!value) {
    return EMPTY_VALUE_DASH;
  }
  return I18nService.t(`Contingent.DocumentTypeName.${value}`);
};

export const getFormattedGender = (value?: PatientGenderEnum) => {
  if (!value) {
    return EMPTY_VALUE_DASH;
  }
  return I18nService.t(`User.Genders.${value}`);
};

const isDispensaryArray = (arr: unknown[]): arr is Dispensary[] =>
  arr.every((d): d is Dispensary => typeof d === 'object' && d !== null && 'disease_code' in d);

const isDispensaryDtoArray = (arr: unknown[]): arr is DispensaryDto[] =>
  arr.every((d): d is DispensaryDto => typeof d === 'object' && d !== null && 'disease_code' in d);

const isDiseaseCodeResourceArray = (arr: unknown[]): arr is DiseaseCodeResource[] =>
  arr.every((d): d is DiseaseCodeResource => typeof d === 'object' && d !== null && 'code' in d);

export const formattedDispanseries = (
  dispensaries?: Dispensary[] | DiseaseCodeResource[] | DispensaryDto[]
): string | undefined => {
  if (Array.isArray(dispensaries)) {
    if (isDispensaryArray(dispensaries) || isDispensaryDtoArray(dispensaries)) {
      return dispensaries.map((d) => d.disease_code?.code).join(',');
    }

    if (isDiseaseCodeResourceArray(dispensaries)) {
      return dispensaries.map((d) => d.code).join(',');
    }
  }

  return EMPTY_VALUE_DASH;
};

export const formattedDispensariesMoreInfo = (
  dispensaries?: Dispensary[] | DiseaseCodeResource[] | DispensaryDto[]
) => {
  if (Array.isArray(dispensaries)) {
    if (isDispensaryArray(dispensaries) || isDispensaryDtoArray(dispensaries)) {
      return dispensaries
        .map((item) => `<div key=${item.disease_code?.code}>${item.disease_code?.title}</div>`)
        .join('');
    }

    if (isDiseaseCodeResourceArray(dispensaries)) {
      return dispensaries.map((item) => `<div key=${item.code}>${item.title}</div>`).join('');
    }
  }

  return I18nService.t('Common.No');
};
