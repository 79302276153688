import { apiService } from '~shared/api';

import { CRUDModel } from '@/models/CRUD.model';

// TODO: rename to FileModel
export class File extends CRUDModel {
  static modelName = 'file';
  static tableName = 'files';

  /**
   * @param {object} payload
   * @param {number} payload.id
   * @param {string} payload.link
   * @param {string} payload.filename
   * @param {string} payload.name synonym for filename
   * @param {number} payload.size
   */
  constructor(payload) {
    super(payload);

    this.link = payload.link;
    this.filename = payload?.filename ?? payload?.name ?? null;
    this.name = payload?.name ?? payload?.filename ?? null;
    this.size = payload?.size ?? 0;
  }

  /**
   * @override
   * @param {UploadFile|File|object} file
   * @return {Promise<void>}
   */
  static async create(file) {
    const formData = new FormData();
    // TODO: после переименования поставить проверку по instanceof
    formData.append('file', file?.raw || file);

    const response = await apiService.api.instance.post(`${this.tableName}/create`, formData);
    return {
      response: response,
      data: response.data,
    };
  }

  static getExtension(file) {
    return file.filename.match(/.*\.([a-z0-9]+)$/i)[1];
  }

  static isImage(file) {
    const extension = this.getExtension(file);
    return this.imageExtensions.includes(extension);
  }

  static imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg'];
}
