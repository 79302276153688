import { ElNotification } from 'element-plus';
import { isAxiosError } from 'axios';

import { QueryParams } from '~shared/types';
import {
  apiService,
  DrugDto,
  MetaDto,
  PatientRecipeDto,
  CreateRecipeRequestPayload,
  RecipeUpdateRequest,
  CreateRecipeResponseData,
  RecipeIssueRequestData,
  MedicineDto,
  IssueRecipeDto,
} from '~shared/api';
import { getApiErrorMessage, I18nService, joinQueryParams } from '~shared/lib';

const urlPath = 'g2g/recipes';

export const getDrugs = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{ data: DrugDto; meta: MetaDto }>(
      joinQueryParams({
        url: 'g2g/drugs',
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const create = async (payload: CreateRecipeRequestPayload) => {
  try {
    const response = await apiService.api.instance.post<{ data: CreateRecipeResponseData }>(
      `${urlPath}/create`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const update = async (id: number | null, payload: RecipeUpdateRequest) => {
  try {
    const response = await apiService.api.instance.put<{ data: CreateRecipeResponseData }>(
      `${urlPath}/${id}/update`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const cancel = async (id: number) => {
  try {
    const response = await apiService.api.instance.put<{ data: CreateRecipeResponseData }>(
      `${urlPath}/${id}/canceled`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getPatientRecipes = async (patientId: number, query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{ data: PatientRecipeDto[]; meta: MetaDto }>(
      joinQueryParams({
        url: `g2g/patients/${patientId}/recipes`,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getPharmacyRecipes = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{ data: PatientRecipeDto[]; meta: MetaDto }>(
      joinQueryParams({
        url: urlPath,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getById = async (id: number) => {
  try {
    const response = await apiService.api.instance.get<{ data: PatientRecipeDto }>(
      `${urlPath}/${id}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    /* empty */
  }
};

export const getMedicinesById = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{ data: MedicineDto[] }>(
      joinQueryParams({
        url: 'g2g/medicines',
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const issue = async (
  id: number | undefined,
  payload: { issues: IssueRecipeDto[]; image: string }
) => {
  try {
    const response = await apiService.api.instance.put<{ data: RecipeIssueRequestData }>(
      `${urlPath}/${id}/issues`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 404) {
      ElNotification({
        type: 'error',
        title: I18nService.t('MyId.PatientNotFound'),
      });
    } else {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }
};
