import { ElNotification } from 'element-plus';

import { OrganizationResponseData, OrganizationsResponseData } from '../types';

import { getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';

const urlPath = 'g2g/organization';
const urlPathOrganizations = 'g2g/organizations';

export const getOrganizationsByType = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<OrganizationsResponseData>(
      joinQueryParams({
        url: `${urlPath}-types`,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createOrganizationType = async (payload: { title: string }) => {
  try {
    const response = await apiService.api.instance.post<OrganizationResponseData>(
      `${urlPath}-types`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getOrganizationTypeById = async (organizationTypeId: number) => {
  try {
    const response = await apiService.api.instance.get<OrganizationResponseData>(
      `${urlPath}-types/${organizationTypeId}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const updateOrganizationType = async (
  organizationTypeId: number,
  payload: { title: string }
) => {
  try {
    const response = await apiService.api.instance.put<OrganizationsResponseData>(
      `${urlPath}-types/${organizationTypeId}`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const deleteOrganizationType = async (organizationTypeId: number) => {
  try {
    const response = await apiService.api.instance.delete(`${urlPath}-types/${organizationTypeId}`);
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getOrganizations = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<OrganizationsResponseData>(
      joinQueryParams({
        url: urlPathOrganizations,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createOrganization = async (payload: { title: string }) => {
  try {
    const response = await apiService.api.instance.post<OrganizationResponseData>(
      urlPathOrganizations,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getOrganizationById = async (organizationId: number) => {
  try {
    const response = await apiService.api.instance.get<OrganizationResponseData>(
      `${urlPathOrganizations}/${organizationId}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const updateOrganization = async (organizationId: number, payload: { title: string }) => {
  try {
    const response = await apiService.api.instance.put<OrganizationResponseData>(
      `${urlPathOrganizations}/${organizationId}`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const deleteOrganization = async (organizationId: number) => {
  try {
    const response = await apiService.api.instance.delete(
      `${urlPathOrganizations}/${organizationId}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getPositions = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<OrganizationsResponseData>(
      joinQueryParams({
        url: `${urlPath}-positions`,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createOrganizationPosition = async (payload: { title: string }) => {
  try {
    const response = await apiService.api.instance.post<OrganizationResponseData>(
      `${urlPath}-positions`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getOrganizationPositionById = async (organizationPositionId: number) => {
  try {
    const response = await apiService.api.instance.get<OrganizationResponseData>(
      `${urlPath}-positions/${organizationPositionId}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const updateOrganizationPosition = async (
  organizationPositionId: number,
  payload: { title: string }
) => {
  try {
    const response = await apiService.api.instance.put<OrganizationResponseData>(
      `${urlPath}-positions/${organizationPositionId}`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const deleteOrganizationPosition = async (organizationPositionId: number) => {
  try {
    const response = await apiService.api.instance.delete(
      `${urlPath}-positions/${organizationPositionId}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
