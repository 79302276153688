<template>
  <MiCheckbox class="form-checkbox" v-bind="$props" v-model="value">
    <template v-if="$slots.default" #default><slot /></template>
  </MiCheckbox>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { CheckboxProps } from 'element-plus';

import { MiCheckbox } from '~shared/ui';

type Props = {
  fieldName: string;
  modelValue?: boolean;
} & Partial<CheckboxProps>;

const props = withDefaults(defineProps<Props>(), {
  validateEvent: false,
  size: 'large',
});

const { value } = useField<boolean>(() => props.fieldName, undefined, {
  syncVModel: false,
});
</script>
