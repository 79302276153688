import { RouteRecordRaw } from 'vue-router';

import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const EDIT_EPICRISIS_ROUTE: RouteRecordRaw = {
  name: 'EditEpicrisis',
  path: ROUTE_PATH.editEpicrisis.raw,
  component: () => import('./ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'nurse', 'manager']),
  ],
  meta: {
    title: 'Hospitalization.Epicrisis',
  },
};
export const CREATE_EPICRISIS_ROUTE: RouteRecordRaw = {
  name: 'CreateEpicrisis',
  path: ROUTE_PATH.createEpicrisis,
  component: () => import('./ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'nurse', 'manager']),
  ],
  meta: {
    title: 'Hospitalization.Epicrisis',
  },
};
