import { AxiosError, GenericAbortSignal } from 'axios';
import { ElNotification } from 'element-plus';

import {
  CreatePatientRequestPayload,
  CreatePatientResponseData,
  GetAnalyzesResponseData,
  GetAppointmentsResponseData,
  GetChronologyResponseData,
  GetPatientResponseData,
  UpdatePatientGroupPayload,
  PatientRelatePayload,
  PatientRelative,
  PatientRelativeRequest,
  GetPatientStatesResponseData,
  UpdatePatientStatesPayload,
  SearchPatientRequestPayload,
  SearchPatientResponseData,
  GetPatientListResponseData,
  GetInpatientCaresResponseData,
  UpdatePatientRequestPayload,
  PatientFullDto,
  PatientShortDto,
} from '../types';
import { PatientResource } from '../../gen/v2';

import { UserId } from '~shared/api';
import {
  getApiErrorMessage,
  joinQueryParams,
  I18nService,
  useAbortable,
  prepareTokenForBracelet,
} from '~shared/lib';
import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';

const urlPath = 'g2g/patients';

export const search = async (payload: SearchPatientRequestPayload, signal?: GenericAbortSignal) => {
  try {
    const response = await apiService.api.instance.post<SearchPatientResponseData>(
      `${urlPath}/check/document`,
      payload,
      { signal }
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getList = async (query: QueryParams = {}, signal?: GenericAbortSignal) => {
  try {
    const response = await apiService.api.instance.get<GetPatientListResponseData>(
      joinQueryParams({
        url: urlPath,
        query,
      }),
      {
        signal,
      }
    );
    return {
      ...response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError && err.code === 'ERR_CANCELED') return;
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getById = async (id: string | number) => {
  try {
    const response = await apiService.api.instance.get<GetPatientResponseData>(`${urlPath}/${id}`);
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const create = async (payload: CreatePatientRequestPayload) => {
  try {
    const response = await apiService.api.instance.post<CreatePatientResponseData>(
      `${urlPath}/create`,
      payload
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const update = async ({ id, ...payload }: UpdatePatientRequestPayload) => {
  try {
    const response = await apiService.api.instance.put<{ data: PatientFullDto }>(
      `${urlPath}/${id}/update`,
      payload
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getRelatives = async (payload: PatientRelativeRequest) => {
  try {
    if (!payload.userId) {
      throw new Error('User id is not provided');
    }
    return await apiService.api.instance.get<{ data?: PatientRelative[] }>(
      `${urlPath}/${payload.userId}/related`
    );
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const relate = async (payload: PatientRelatePayload) => {
  try {
    if (!payload.related_user_id || !payload.user_id)
      throw new Error('User id or related user id are not provided');
    return await apiService.api.instance.post<{ data?: PatientRelative[] }>(
      `${urlPath}/relate`,
      payload
    );
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
    throw err;
  }
};

export const updateGroup = async (payload: UpdatePatientGroupPayload) => {
  try {
    await apiService.api.instance.put<CreatePatientResponseData[]>(
      `${urlPath}/${payload.userId}/group`,
      payload
    );
    ElNotification({
      type: 'success',
      title: 'Group updated',
    });
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const updateStates = async (payload: UpdatePatientStatesPayload) => {
  try {
    await apiService.api.instance.put<CreatePatientResponseData[]>(
      `b2g/patients/${payload.userId}/states/update`,
      payload
    );
    ElNotification({
      type: 'success',
      title: 'States updated',
    });
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getStates = async (payload: { userId: UserId }) => {
  try {
    const response = await apiService.api.instance.get<GetPatientStatesResponseData>(
      `b2g/patients/${payload.userId}/states`
    );
    return {
      data: response?.data?.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getChronology = async (
  patientId: number,
  query: QueryParams & {
    start_at?: string;
    end_at?: string;
  } = {}
) => {
  try {
    const response = await apiService.api.instance.get<GetChronologyResponseData>(
      joinQueryParams({
        url: `${urlPath}/${patientId}/chronology`,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getAppointments = async (
  patientId: number,
  query: QueryParams & {
    start_at?: string;
    end_at?: string;
  } = {}
) => {
  try {
    const response = await apiService.api.instance.get<GetAppointmentsResponseData>(
      joinQueryParams({
        url: `${urlPath}/${patientId}/appointments`,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getAnalyzes = async (
  patientId: number,
  query: QueryParams & {
    start_at?: string;
    end_at?: string;
  } = {}
) => {
  try {
    const response = await apiService.api.instance.get<GetAnalyzesResponseData>(
      joinQueryParams({
        url: `${urlPath}/${patientId}/analyses`,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getInpatientCares = async (
  patientId: number,
  query: QueryParams & {
    start_at?: string;
    end_at?: string;
  } = {}
) => {
  try {
    const response = await apiService.api.instance.get<GetInpatientCaresResponseData>(
      joinQueryParams({
        url: `${urlPath}/${patientId}/hospitals`,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getByBracelet = async (patientName: string) => {
  try {
    const token = prepareTokenForBracelet(patientName);
    const response = await apiService.api.instance.get<GetPatientResponseData>(
      `patients/getByOneTimeToken?oneTimeToken=${token}`
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(I18nService.t('Common.InvalidQrCode')),
    });
  }
};

export const getByBraceletV2 = async (patientName: string) => {
  try {
    const token = prepareTokenForBracelet(patientName);
    const response = await apiService.api.instance.get<{ data: PatientResource }>(
      `patients/getByOneTimeToken?oneTimeToken=${token}`
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(I18nService.t('Common.InvalidQrCode')),
    });
  }
};

export const getQrCodeLinkByUserId = async (userId: string | number) => {
  try {
    const response = await apiService.api.instance.get<{ link: string }>(`printer/${userId}/link`);
    return {
      data: response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(I18nService.t('Common.InvalidQrCode')),
    });
  }
};

export const archive = async (userId: string | number) => {
  try {
    const response = await apiService.api.instance.put(`${urlPath}/${userId}/archive`);
    return {
      data: response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const unarchive = async (userId: string | number) => {
  try {
    const response = await apiService.api.instance.put(`${urlPath}/${userId}/unarchive`);
    return {
      data: response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const registerDeath = async (
  id: string | number,
  payload: {
    disease_codes: string[];
    died_at: string;
  }
) => {
  try {
    const response = await apiService.api.instance.put<{ data: PatientShortDto }>(
      `${urlPath}/${id}/die`,
      payload
    );

    return {
      data: response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const deregisterDeath = async (id: string | number) => {
  try {
    const response = await apiService.api.instance.put<{ data: PatientShortDto }>(
      `${urlPath}/${id}/die/cancel`
    );

    return {
      data: response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const abortableGetList = useAbortable(getList);
