<template>
  <MiFormItem
    :class="[
      'form-search-select-for-create-appointment',
      {
        'form-search-select_in-column': props.inColumn,
        'form-search-select_in-line-space-between':
          !props.inColumn && !!props.label && props.spaceBetween,
        'form-search-select-custom': customLabel,
      },
    ]"
    :error="errorMessage"
    :label="props.label"
    :required="props.required">
    <template v-if="customLabel" #label>
      <span class="form-search-select_label">{{ props.label }}</span>
      <MiButton
        class="form-search-select_btn"
        type="primary"
        text
        link
        @click="$emit('openModal', $event)">
        {{ customLabel }}
      </MiButton>
    </template>
    <MiSelect
      class="form-search-select__select"
      v-model="value"
      remote
      filterable
      reserve-keyword
      :validate-event="false"
      :remote-method="debouncedGetItems"
      :loading="loading"
      :multiple="multiple"
      :disabled="props.disabled"
      :placeholder="props.placeholder || $t('Common.PleaseInput')"
      :clearable="props.clearable"
      :no-data-text="$t('Common.NoData')"
      :collapse-tags="props.collapseTags"
      :collapse-tags-tooltip="props.collapseTagsTooltip"
      @update:model-value="$emit('update:modelValue', $event)"
      @change="selectHandler"
      :popper-class="`${popperClass} ui-models-autocomplete-search__popper`"
      ref="component">
      <template #prefix>
        <MiIcon icon="SEARCH" class="form-search-select__icon" />
      </template>

      <ElOption
        class="form-search-select__option"
        v-for="item in items"
        :key="item[props.valueKey]"
        :label="typeof props.labelKey === 'function' ? props.labelKey(item) : item[props.labelKey]"
        :value="item[props.valueKey]">
        <slot name="optionTemplate" :item="item" />
      </ElOption>

      <template #empty>
        <div class="form-search-select-empty">
          <div v-if="validateMsg" class="form-search-select-empty__text">
            {{ validateMsg }}
          </div>
          <div v-else class="form-search-select-empty__text">
            {{ loading ? 'Loading' : $t('Common.NoData') }}
          </div>

          <MiButton
            v-if="props.showCreateOption"
            v-show="!loading"
            class="form-search-select-empty__create"
            type="primary"
            size="default"
            @click="createItem">
            <template #icon>
              <MiIcon icon="PLUS" />
            </template>
            {{ $t('Common.Create') }}
          </MiButton>
        </div>
      </template>
    </MiSelect>
  </MiFormItem>
</template>

<script lang="ts" setup>
import { ElSelect, ElOption } from 'element-plus';
import cloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';
import { useField } from 'vee-validate';
import { computed, ref, watch } from 'vue';

import { AnyObject } from '~shared/types';
import { MiIcon, MiSelect, MiButton, MiFormItem } from '~shared/ui';
import { clearObjectEmptyValues, I18nService } from '~shared/lib';

type Props = {
  inColumn?: boolean;
  spaceBetween?: boolean;
  clearable?: boolean;
  collapseTags?: boolean;
  collapseTagsTooltip?: boolean;
  showCreateOption?: boolean;
  disabled?: boolean;
  required?: boolean;
  fieldObjectName: string;
  multiple?: boolean;
  fieldName: string;
  label?: string;
  labelKey?: string | ((item: AnyObject) => string);
  valueKey?: string;
  placeholder?: string;
  methodName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchData: (query?: any) => Promise<any | undefined>;
  customLabel?: string;
  searchQuery?: object;
  popperClass?: string;
  rawFetchResult?: boolean;
};

const SEARCH_DEBOUNCE_DELAY = 1000;

const props = withDefaults(defineProps<Props>(), {
  inColumn: true,
  spaceBetween: false,
  clearable: false,
  collapseTags: false,
  collapseTagsTooltip: false,
  showCreateOption: false,
  disabled: false,
  required: false,
  multiple: false,
  labelKey: 'title',
  valueKey: 'id',
  label: '',
  placeholder: '',
  methodName: 'find',
  customLabel: '',
  query: () => ({}),
});

const emit = defineEmits(['select', 'create', 'update:modelValue', 'openModal']);

const loading = ref(false);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const items = ref<any[]>([]);

const validateMsg = ref<string>();

const { value, setValue, errorMessage } = useField<
  string | string[] | number | number[] | undefined
>(() => props.fieldName);

const { value: objectValue, setValue: setObjectValue } = useField<
  AnyObject | AnyObject[] | undefined
>(() => props.fieldObjectName);

const component = ref<InstanceType<typeof ElSelect> | null>(null);

const createItem = () => {
  if (component.value) {
    const query = component.value.query;
    component.value.query = '';
    return emit('create', query);
  }
};

const queryString = computed(() => component.value?.query);

const getItems = async () => {
  if (!queryString.value || !queryString.value.trim() || queryString.value.trim().length < 3) {
    return;
  }

  loading.value = true;

  const response = await props.fetchData(
    clearObjectEmptyValues({
      ...props.query,
      page: 1,
      per_page: 30,
      search: queryString.value,
    })
  );

  const responseData = props.rawFetchResult ? response?.data?.data : response?.data;

  if (objectValue.value && responseData) {
    const arrayOfvalue = (
      Array.isArray(objectValue.value) ? objectValue.value : [objectValue.value]
    ).filter((f) => !!f?.id);
    const ids = arrayOfvalue.map((item) => item.id);
    const newItems = responseData.filter((f: AnyObject) => {
      return !ids.includes(f.id);
    });
    items.value = [...arrayOfvalue, ...newItems];
  } else {
    items.value = responseData ?? [];
  }

  loading.value = false;
};

const debouncedGetItems = debounce((params) => {
  if (!params) return;
  getItems();
}, SEARCH_DEBOUNCE_DELAY);

const selectHandler = (eventValue: string | number | string[] | number[]) => {
  setValue(eventValue);

  let result: AnyObject | AnyObject[] | undefined;

  if (Array.isArray(eventValue)) {
    result = eventValue.map((item) => {
      return items.value.find((f) => f[props.valueKey] === item);
    });
  } else {
    result = items.value.find((f) => f[props.valueKey] === eventValue);
  }
  const cloneResult = cloneDeep(result);

  if (cloneResult) {
    setObjectValue(cloneResult);
    emit('select', cloneResult);
  }
};

const setValueToSelect = <T extends object>(value: T) => {
  if (
    component.value &&
    value &&
    'id' in value &&
    (typeof value.id === 'string' || typeof value.id === 'number')
  ) {
    setValue(value?.id);
    setObjectValue(value);
  }
};

defineExpose({
  setValueToSelect,
});

watch(
  queryString,
  (v) => {
    if (!v) {
      items.value = [];
    }
    if (v && v.trim().length < 3) {
      items.value = [];
      validateMsg.value = I18nService.t('Validation.SearchLength');
    } else {
      validateMsg.value = undefined;
    }
  },
  { immediate: true }
);

watch(
  () => value.value,
  (v) => {
    if (!v) {
      if ((typeof v === 'string' && v.length === 0) || v === null) {
        setValue(undefined);
      }
      setObjectValue(undefined);
    }
  },
  { immediate: true }
);

watch(
  () => objectValue.value,
  (newValue) => {
    if (newValue) {
      const arrayOfvalue = (Array.isArray(newValue) ? newValue : [newValue]).filter(
        (f) => !!f?.[`${props.valueKey}`]
      );
      const ids = arrayOfvalue.map((item) => item[props.valueKey]);
      const newItems = items.value.filter((f) => {
        return !ids.includes(f[`${props.valueKey}`]);
      });

      items.value = [...arrayOfvalue, ...newItems];
    } else {
      items.value = [];
    }
  },
  { immediate: true, deep: true }
);
</script>

<style lang="scss" src="./index.scss" />
