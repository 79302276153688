<template>
  <MiDialog
    width="1440"
    class="create-g2-gappointment-modal"
    :model-value="modelValue"
    :title="$t(`Title.${appointment?.id ? 'Edit' : 'Create'}`)"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElForm
      id="create-or-edit-appointment"
      class="create-g2-gappointment-modal-form"
      label-position="top"
      v-loading="loading.appointment"
      @submit.prevent="submitHandler">
      <UiDataModalGrid class="create-g2-gappointment-modal-form__grid">
        <template #info>
          <ElCard :header="$t('Contingent.PatientInfo')" shadow="never">
            <PatientsSearchSelectDataBlock
              v-show="!patientPart.show"
              v-model="appointment.patient_id"
              :search-query="patientsOptions.searchQuery"
              :default-item="initialData.patient || patientPart.newPatient || appointment.patient"
              :disabled="!!appointment.id"
              :hide-select="!!appointment.id"
              :required="!patientPart.show"
              :appointment="appointment"
              :show-create-option="isPaidDepartment"
              @create-patient="handleCreatePatient"
              ref="patient">
            </PatientsSearchSelectDataBlock>
          </ElCard>
        </template>

        <template #control>
          <ElCard shadow="never">
            <CreateAppointmentSubjectOrServiceGroupWithCount
              v-model:appointment="appointment"
              :patient="initialData.patient"
              :set-default-my-doctor="setDefaultMyDoctor"
              reset-after-create
              ref="create_or_edit_appointment_subject"
              @subject:create="addSubject" />
          </ElCard>
        </template>

        <template #content>
          <AppointmentSubjectsOrServiceGroupWithCountTable
            class="create-g2-gappointment-modal-form__table-part"
            :items="
              appointment.id ? appointment.group_services_with_count : appointment.appointments
            "
            editable
            deletable
            @item:update="updateSubject"
            @item:remove="removeSubject" />
        </template>

        <template #footer>
          <ElCard class="create-g2-gappointment-modal-form__cost-part" shadow="never">
            <ElFormItem v-if="isNotDoctor" :label="$t('Common.Discount') + ' (%)'">
              <ElInput v-model="appointment.discount" type="number" min="0" max="100" />
            </ElFormItem>
            <ElFormItem :label="$t('Common.Total')">
              {{ discountedCost }}
            </ElFormItem>
          </ElCard>
        </template>
      </UiDataModalGrid>
    </ElForm>

    <!--  Actions  -->
    <template #footer>
      <ElFormItem>
        <div class="create-g2-gappointment-modal-form__actions">
          <MiButton
            type="primary"
            native-type="submit"
            form="create-or-edit-appointment"
            :loading="loading.form">
            {{ $t(`Common.${appointment?.id ? 'Edit' : 'Create'}`) }}
          </MiButton>
        </div>
      </ElFormItem>
    </template>
  </MiDialog>
</template>

<script>
import axios from 'axios';
import { markRaw } from 'vue';
import { ElFormItem, ElForm, ElCard, ElInput } from 'element-plus';

// eslint-disable-next-line import/no-internal-modules
import UiDataModalGrid from './UiDataModalGrid/index.vue';
// eslint-disable-next-line import/no-internal-modules
import AppointmentSubjectsOrServiceGroupWithCountTable from './AppointmentSubjectsOrServiceGroupWithcountTable/index.vue';
// eslint-disable-next-line import/no-internal-modules
import CreateAppointmentSubjectOrServiceGroupWithCount from './CreateAppointmentSubjectOrServiceGroupWithCount/index.vue';
// eslint-disable-next-line import/no-internal-modules
import PatientsSearchSelectDataBlock from './PatientsSearchSelectDataBlock/index.vue';
// eslint-disable-next-line import/no-internal-modules
import CreateOrEditOrPayInvoiceModal from './CreateOrEditOrPayInvoiceModal/index.vue';
// eslint-disable-next-line import/no-internal-modules
import { Appointment } from './models/Appointment.model';
// eslint-disable-next-line import/no-internal-modules
import { Doctor } from './models/Doctor.model';
// eslint-disable-next-line import/no-internal-modules
import { Patient } from './models/Patient.model';
// eslint-disable-next-line import/no-internal-modules
import { Service } from './models/Service.model';
// eslint-disable-next-line import/no-internal-modules
import { ServiceGroup } from './models/ServiceGroup.model';
import { User } from './models/User.model';

import { useSessionStore } from '~entities/session';
import { MiButton, MiDialog } from '~shared/ui';
import { misApi } from '~shared/api';
import { ROUTE_PATH } from '~shared/config';
import { formatPrice } from '~shared/lib';

//TODO: Данный компонент лютое легаси которое необходимо переписать в будущем
export default {
  name: 'CreateOrEditAppointmentModal',
  components: {
    MiButton,
    PatientsSearchSelectDataBlock,
    CreateAppointmentSubjectOrServiceGroupWithCount,
    AppointmentSubjectsOrServiceGroupWithCountTable,
    MiDialog,
    UiDataModalGrid,
    ElFormItem,
    ElForm,
    ElCard,
    ElInput,
  },
  emits: ['update:modelValue', 'created-successfully', 'search-patient'],
  props: {
    modelValue: Boolean,
    id: [Number, String],
    disableDefaultAction: Boolean, // отключаем дефолтное поведение после создания//
    setDefaultMyDoctor: {
      // для автоматического выбора врача, если мы с аккаунта врача
      type: Boolean,
      default: true,
    },
    initialData: {
      type: [Appointment, Object],
      default: () => ({}),
    },
  },
  data() {
    return {
      /** @type Appointment */
      appointment: new Appointment(),
      loading: {
        appointment: false,
        form: false,
      },

      patientPart: {
        show: false,
        nameOrPhone: null,
      },
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },

    currentDate() {
      return this.user.role === 'manager'
        ? new Date().toLocaleString('ru', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
          })
        : undefined;
    },

    isNotDoctor() {
      return this.user?.role !== User.enum.roles.Doctor;
    },

    isPaidDepartment() {
      return this.user?.is_paid_department;
    },

    patientsOptions() {
      return {
        searchQuery: {},
      };
    },

    cost() {
      return this.appointment.id
        ? this.appointment.group_services_with_count.reduce(
            (acc, item) => acc + item.count * item.group_service.price,
            0
          )
        : this.appointment.appointments.reduce((acc, elem) => {
            const servicesCost = elem.group_services_with_count.reduce(
              (acc, groupServiceWithCount) =>
                acc + groupServiceWithCount.group_service.price * groupServiceWithCount.count,
              0
            );

            return acc + servicesCost;
          }, 0);
    },

    discountedCost() {
      return formatPrice({
        price:
          this.appointment.discount > 0
            ? this.cost - this.cost * (this.appointment.discount / 100)
            : this.cost,
      });
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.appointment = new Appointment({
          ...this.initialData,
          patient_id: this.initialData.patient?.id || null,
          start_at: this.initialData.start_at ?? this.currentDate,
        });
      },
      immediate: true,
    },

    id: {
      handler(value) {
        if (value) this.getAppointment();
      },
      immediate: true,
    },
  },

  methods: {
    async submitHandler() {
      if (!this.validate() || this.loading.form) return;
      this.loading.form = true;

      try {
        const { appointments, invoice } = await this.createAppointment();

        this.$notify({
          type: 'success',
          title: this.$i18n.t('Notifications.SuccessCreated'),
        });
        this.$emit('created-successfully', {
          data: { appointments, invoice },
        });
        this.$emit('update:modelValue', false);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.form = false;
    },

    async createAppointment() {
      const response = await misApi.g2g.appointments.create(this.appointment);

      if (!response) {
        return;
      }

      if (!this.disableDefaultAction) {
        setTimeout(() => {
          this.$router.push(ROUTE_PATH.appointments.activeList.withQuery());
          // this.openInvoiceModalAfterUpdateIfNeeded(response.data.invoice);
        });
      }
      return { appointments: response.data.appointments, invoice: response.data.invoice };
    },

    openInvoiceModalAfterUpdateIfNeeded(invoice) {
      if (this.user?.role === User.enum.roles.Manager) {
        setTimeout(() => {
          this.$store.dispatch('modalAndDrawer/openModal', {
            component: CreateOrEditOrPayInvoiceModal,
            payload: {
              id: invoice.id,
            },
          });
        });
      }
    },

    /**
     * Доролнительная проверка
     * @return {boolean} если успешно true
     */
    validate() {
      if (this.appointment.id) {
        if (!this.appointment.doctor_id) {
          this.$notify({ type: 'error', title: this.$t('Appointments.SelectDoctor') });
          return false;
        }
        if (!this.appointment.group_service_ids?.length) {
          this.$notify({ type: 'error', title: this.$t('Appointments.SelectServices') });
          return false;
        }

        return true;
      }

      if (!this.appointment.appointments.length) {
        this.$notify({
          type: 'error',
          title: this.$t('NotAppointmentsError'),
        });
        return false;
      }

      return true;
    },

    /** @param {AppointmentSubject|AppointmentServiceGroupWithCount|object} subject */
    addSubject(subject) {
      const field = this.isAppointmentSubject(subject)
        ? 'appointments'
        : 'group_services_with_count';
      this.appointment[field].push(subject);

      if (!this.isAppointmentSubject(subject)) {
        this.appointment.group_service_ids.push(subject.group_service.id);
        this.appointment.group_services.push(subject.group_service);
      }
    },

    /** @param {AppointmentSubject|AppointmentServiceGroupWithCount|object} subject */
    updateSubject(subject) {
      const field = this.isAppointmentSubject(subject)
        ? 'appointments'
        : 'group_services_with_count';

      const index = this.appointment[field].findIndex((elem) => elem._id === subject._id);
      this.appointment[field][index] = subject;
    },

    /** @param {AppointmentSubject|AppointmentServiceGroupWithCount|object} subject */
    removeSubject(subject) {
      const field = this.isAppointmentSubject(subject)
        ? 'appointments'
        : 'group_services_with_count';
      this.appointment[field] = this.appointment[field].filter((elem) => elem._id !== subject._id);

      if (!this.isAppointmentSubject(subject)) {
        this.appointment.group_service_ids = this.appointment.group_service_ids.filter(
          (elem) => elem !== subject.group_service.id
        );
        this.appointment.group_services = this.appointment.group_services.filter(
          (elem) => elem.id !== subject.group_service.id
        );
      }
    },

    insertPatient(action) {
      this.appointment.patient = action.data.patient;
      this.appointment.patient_id = action.data.patient.id;
    },

    /**
     * @param {AppointmentSubject|AppointmentServiceGroupWithCount} subject
     * @return {boolean}
     */
    isAppointmentSubject(subject) {
      return Object.hasOwn(subject, 'group_services_with_count');
    },

    handleCreatePatient() {
      this.$emit('search-patient');
    },
  },

  // other
  setup: () => ({
    Patient: markRaw(Patient),
    Doctor: markRaw(Doctor),
    Service: markRaw(Service),
    ServiceGroup: markRaw(ServiceGroup),
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
