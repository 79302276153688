import { RouteRecordRaw } from 'vue-router';

import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const STATISTIC_DASHBOARD_ROUTE: RouteRecordRaw = {
  name: 'StatisticDashboard',
  path: ROUTE_PATH.statistic.dashboard,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director'])],
  meta: {
    title: 'Common.Dashboard',
  },
};
