<template>
  <div class="mi-table-wrapper">
    <ElScrollbar class="mi-table-wrapper__scrollbar">
      <ElTable
        :class="['mi-table-wrapper__table', tableClass]"
        :data="data"
        :max-height="maxHeight"
        :empty-text="$t('Common.NoData')"
        :default-sort="defaultSort"
        v-bind="$attrs"
        v-loading="loading"
        ref="elTable">
        <template v-if="$slots.empty" #empty>
          <slot name="empty"></slot>
        </template>
        <slot></slot>
      </ElTable>
    </ElScrollbar>

    <div class="mi-table-wrapper__footer" v-if="showTableFooter">
      <slot name="append"></slot>
      <ElPagination
        v-show="!hidePagination"
        class="mi-table-wrapper__pagination"
        :page-count="pageCount"
        :total="total"
        :current-page="page"
        :page-size="perPage"
        :page-sizes="PAGE_SIZES"
        background
        hide-on-single-page
        :layout="paginationLayout"
        @update:current-page="$emit('update:page', $event)"
        @update:page-size="$emit('update:perPage', $event)" />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'MiTable',
};
</script>

<script setup lang="ts">
import { Sort, ElTable, ElPagination, ElScrollbar } from 'element-plus';
import { computed, watch, ref, useSlots } from 'vue';

import { PAGE_SIZES } from '~shared/config';

export type MiTableSort = Sort;

type MiTableProps = {
  loading: boolean;
  hidePagination?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  page: number;
  perPage: number;
  total: number;
  tableClass?: string;
  maxHeight?: number;
  defaultSort?: MiTableSort;
  paginationLayout?: string;
};

const props = withDefaults(defineProps<MiTableProps>(), {
  paginationLayout: 'prev, pager, next, sizes',
});

const $slots = useSlots();

const elTable = ref<InstanceType<typeof ElTable> | null>(null);

const pageCount = computed(() => {
  return Math.ceil(props.total / props.perPage);
});

const showTableFooter = computed(() => {
  return $slots.append || props.total > props.perPage;
});

watch(
  () => props.loading,
  () => {
    if (elTable.value) {
      elTable.value.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
);
</script>

<style lang="scss" src="./index.scss"></style>
