<template>
  <MiTable
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @row-click="goToWarehouse"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <MiTableColumn prop="title" :label="$t('Warehouse.Warehouse')" />
    <MiTableColumn prop="address" :label="$t('Warehouse.Address')" />
    <MiTableColumn prop="description" :label="$t('Nomenclature.Notes')" />
    <MiTableColumn :label="$t('Nomenclature.Actions')" width="150px">
      <template #default="{ row }">
        <MiButton size="small" type="primary" plain @click.stop="$emit('modal:open', row)"
          >{{ $t('Nomenclature.Edit') }}
        </MiButton>
      </template>
    </MiTableColumn>
    <MiTableColumn width="150px">
      <MiButton type="primary" text>
        <template #icon>
          <MiIcon icon="EYE" />
        </template>
        {{ $t('Common.View') }}
      </MiButton>
    </MiTableColumn>
  </MiTable>
</template>
<script lang="ts">
import { PropType } from 'vue';
import { useRouter } from 'vue-router';

import { ROUTE_PATH } from '~shared/config';
import { joinQueryParams } from '~shared/lib';
import { MiButton, MiTableColumn, MiTable, MiIcon } from '~shared/ui';
import { WarehouseDto } from '~shared/api/common';

export default {
  name: 'WarehousesTable',
  components: {
    MiButton,
    MiTableColumn,
    MiTable,
    MiIcon,
  },
  emits: ['update:page', 'update:perPage', 'modal:open'],
  props: {
    loading: { type: Boolean, required: true },
    items: { type: Array as PropType<WarehouseDto[]>, required: true },
    page: { type: Number, required: true },
    perPage: { type: Number, required: true },
    total: { type: Number, required: true },
  },
  setup() {
    const router = useRouter();
    const goToWarehouse = (warehouse: WarehouseDto) => {
      router.push(
        joinQueryParams({
          url: ROUTE_PATH.warehouse.goodsStock,
          query: {
            warehouse_name: warehouse.title,
            warehouse_id: warehouse.id,
          },
        })
      );
    };
    return {
      goToWarehouse,
    };
  },
};
</script>

<style lang="scss" scoped></style>
