import { ElNotification } from 'element-plus';

import { apiService } from '~shared/api';
import {
  GetDisabilitiesResponse,
  CreateDisabilityPayload,
  DisabilityDto,
  UpdateDisabilityPayload,
} from '~shared/api';
import { getApiErrorMessage } from '~shared/lib';

const urlPath = 'g2g/disabilities';

export const getByUserId = async (id?: number | string) => {
  try {
    if (!id) {
      throw new Error('Id is not provided');
    }

    const response = await apiService.api.instance.get<GetDisabilitiesResponse>(`${urlPath}/${id}`);

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const create = async (payload: CreateDisabilityPayload) => {
  try {
    const response = await apiService.api.instance.post<UpdateDisabilityPayload>(
      `${urlPath}/create`,
      payload
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const update = async (payload: UpdateDisabilityPayload, disabilityId?: number) => {
  try {
    if (!disabilityId) {
      throw new Error('disabilityId is not provided');
    }

    const response = await apiService.api.instance.put<DisabilityDto>(
      `${urlPath}/${disabilityId}/update`,
      payload
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
