import { ElNotification } from 'element-plus';

import { getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { QueryParams } from '~shared/types';
import {
  apiService,
  DefaultDirectoryItemDto,
  GetDefaultDirectoryListResponseData,
} from '~shared/api';

const urlPath = 'g2g/organizations';

export const getOrgTypes = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<GetDefaultDirectoryListResponseData>(
      joinQueryParams({
        url: `${urlPath}/types`,
        query,
      })
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getOrgListByTypeId = async (
  query: QueryParams & {
    organization_type_id: DefaultDirectoryItemDto['id'];
  }
) => {
  try {
    const response = await apiService.api.instance.get<GetDefaultDirectoryListResponseData>(
      joinQueryParams({
        url: urlPath,
        query,
      })
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getPositionsByOrgId = async (
  query: QueryParams & {
    organization_id: DefaultDirectoryItemDto['id'];
  }
) => {
  try {
    const response = await apiService.api.instance.get<GetDefaultDirectoryListResponseData>(
      joinQueryParams({
        url: `${urlPath}/positions`,
        query,
      })
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
