import axios from 'axios';
import { ElDialog, ElForm, ElFormItem, ElInput } from 'element-plus';

import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import { MiSelectSearch, MiButton } from '~shared/ui';
import { misApi } from '~shared/api';

import { Analysis } from '@/models/laboratory/Analysis.model';

export default {
  name: 'CreateOrEditReferencesValueModal',
  emits: ['update:modelValue', 'action'],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    MiButton,
    MiSelectSearch,
  },
  props: {
    modelValue: Boolean,
    data: [Object],
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    formOptions() {
      return {
        start_value: {
          required: !this.referencesValue.norm_text,
        },
        end_value: {
          required: !this.referencesValue.norm_text,
        },
        norm_text: {
          required: !this.referencesValue.start_value && !this.referencesValue.end_value,
        },
        analysis: {
          required: true,
          disabled: !!this.data?.analysis_id,
        },
        references_group: {
          required: true,
          disabled: !!this.data?.references_group_id,
        },
      };
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.referencesValue = this.data || {
          start_value: null,
          end_value: null,
          norm_text: null,
          references_group_id: null,
          references_group: null,
          analysis_id: null,
          analysis: null,
          created_at: null,
        };
      },
      immediate: true,
    },
  },

  methods: {
    async submitHandler() {
      if (this.loading) return;
      this.loading = true;

      try {
        const referencesValue = this.referencesValue.id ? await this.edit() : await this.create();

        this.$notify({
          type: 'success',
          title: this.$t(`Notifications.Success${this.referencesValue.id ? 'Updated' : 'Created'}`),
        });
        this.$emit(
          'action',
          new GlobalModalInstance({
            name: this.referencesValue.id ? 'edited' : 'created',
            data: { referencesValue },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading = false;
    },

    async create() {
      const response = await misApi.common.references.createReferencesValue(this.referencesValue);
      return response.data.data;
    },
    async edit() {
      const response = await misApi.common.references.updateReferencesValueItem(
        this.referencesValue.id,
        this.referencesValue
      );
      return response.data.data;
    },
  },

  setup: () => ({ Analysis, misApi }),
};
