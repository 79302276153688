import { ROUTE_PATH } from '~shared/config';
import { onlyUserHasRoleMiddleware, onlyUserIsAuthMiddleware } from '~shared/lib';

export const PHARMACY_ROUTE = {
  name: 'Pharmacy',
  path: ROUTE_PATH.pharmacy,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['pharmacist'])],
  meta: {
    title: 'Recipes.Pharmacy',
  },
};
