import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const DISPENSARIZATION_MAIN_LIST_ROUTE = {
  name: 'DispensarizationMainList',
  path: ROUTE_PATH.dispensarization.mainList,
  component: () => import('./DispensarizationMainList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director'])],
  meta: {
    title: 'Analytics.Dispensarization',
  },
};

export const DISPENSARIZATION_PATIENT_LIST_ROUTE = {
  name: 'DispensarizationPatientList',
  path: ROUTE_PATH.dispensarization.patientList,
  component: () => import('./DispensarizationPatientList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
};

export const DISPENSARIZATION_CODE_LIST_ROUTE = {
  name: 'DispensarizationCodeList',
  path: ROUTE_PATH.dispensarization.codeList,
  component: () => import('./DispensarizationCodeList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
};

export const DISPENSARIZATION_DEREGISTERED_PATIENT_LIST_ROUTE = {
  name: 'DispensarizationDeregisteredPatientList',
  path: ROUTE_PATH.dispensarization.deregisteredPatientList,
  component: () => import('./DispensarizationDeregisteredPatientList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
};

export const DISPENSARIZATION_NEW_PATIENT_LIST_ROUTE = {
  name: 'DispensarizationNewPatientList',
  path: ROUTE_PATH.dispensarization.newPatientList,
  component: () => import('./DispensarizationNewPatientList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
};
