<template>
  <ElAvatar
    class="mi-avatar"
    :shape="shape"
    :size="size"
    fit="fill"
    :src="src"
    :alt="alt"
    @error="handleError">
    <img :src="userNeutralImg" :alt="alt" />
  </ElAvatar>
</template>

<script setup lang="ts">
import { ElAvatar, AvatarInstance } from 'element-plus';
import { computed, ref } from 'vue';

import userNeutralImg from '~shared/ui/svg/user-neutral.svg';

const props = withDefaults(
  defineProps<{
    size?: AvatarInstance['size'];
    shape?: AvatarInstance['shape'];
    src?: AvatarInstance['src'];
    alt?: string;
  }>(),
  {
    alt: 'Avatar',
    size: 'default',
    shape: 'circle',
  }
);

const loadImgError = ref(false);
const src = computed(() => {
  return props.src && !loadImgError.value ? props.src : userNeutralImg;
});

const handleError = () => {
  loadImgError.value = true;
};
</script>

<style lang="scss" src="./index.scss" />
