import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const DOCUMENT_ROUTE = {
  name: 'DocumentPage',
  path: ROUTE_PATH.documents,
  component: () => import('./ui/Document'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['doctor', 'nurse', 'director']),
  ],
  meta: {
    title: 'Patients.DocumentCreation',
  },
  props: true,
};
