<template>
  <ElContainer v-bind="$attrs" class="main-layout">
    <ElAside
      :class="[
        'main-layout__menu main-layout-menu',
        {
          'main-layout-menu--expanded': !mainNavBarCollapsed,
          'main-layout-menu--collapsed': mainNavBarCollapsed,
        },
      ]">
      <div class="main-layout-menu__logo-box">
        <div class="main-layout-menu__img-box">
          <MainLogo @click="handleForwardToRootPage" />
        </div>

        <MiButton
          :class="[
            'main-layout-menu__collapse-btn',
            {
              'main-layout-menu__collapse-btn--expanded': !mainNavBarCollapsed,
              'main-layout-menu__collapse-btn--collapsed': mainNavBarCollapsed,
            },
          ]"
          @click="toggleMainNavBarCollapsed"
          circle
          size="small">
          <ElIcon>
            <MiIcon icon="CHEVRON_RIGHT" />
          </ElIcon>
        </MiButton>
      </div>

      <div class="main-layout-menu__menu-box">
        <NavbarMenu />
      </div>

      <div class="main-layout-menu__footer-logo-box">
        <img
          :src="mainNavBarCollapsed ? logoUzinfocomCollapsedSvgSrc : logoUzinfocomSvgSrc"
          alt="footer-logo" />
      </div>
    </ElAside>

    <ElContainer class="main-layout__main">
      <ElHeader class="main-layout__header main-layout-header">
        <div class="main-layout-header__inner">
          <div class="left-content">
            <PatientsSearch v-if="withPatientSearch" class="main-layout-header__search-input" />
          </div>

          <div v-if="$slots['header-actions']" class="main-layout-header__actions">
            <slot name="header-actions"></slot>
          </div>

          <SupportButton />

          <CloudStudyButton />

          <LangSelect />

          <ElDivider direction="vertical" />

          <UserMenu />
        </div>
      </ElHeader>

      <ElMain
        :class="[
          'main-layout__content',
          {
            'main-layout__content--with-footer': Boolean($slots.footer),
          },
        ]"
        v-loading="loading">
        <slot></slot>
      </ElMain>

      <div class="main-layout__footer" v-if="Boolean($slots.footer)">
        <div class="footer-inner">
          <slot name="footer" />
        </div>
      </div>
    </ElContainer>
  </ElContainer>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { ElContainer, ElAside, ElMain, ElDivider, ElIcon, ElHeader } from 'element-plus';

import { NavbarMenu } from '../NavbarMenu';
import { UserMenu } from '../UserMenu';
import { PatientsSearch } from '../PatientsSearch';
import { CloudStudyButton } from '../CloudStudyButton';
import { SupportButton } from '../SupportButton';

import { LangSelect } from '~features/LangSelect';
import { MainLogo } from '~entities/logo';
import { useUserConfigStore } from '~entities/session';
import { MiButton, MiIcon, logoUzinfocomSvgSrc, logoUzinfocomCollapsedSvgSrc } from '~shared/ui';
import { ROUTE_PATH } from '~shared/config';

withDefaults(
  defineProps<{
    fixHeight?: boolean;
    contentClass?: string;
    mainClass?: string;
    loading?: boolean;
    withPatientSearch?: boolean;
  }>(),
  {
    withPatientSearch: true,
  }
);

const router = useRouter();

const { mainNavBarCollapsed } = storeToRefs(useUserConfigStore());
const { toggleMainNavBarCollapsed } = useUserConfigStore();

const handleForwardToRootPage = () => {
  router.push(ROUTE_PATH.root);
};
</script>

<style lang="scss" src="./index.scss" />
