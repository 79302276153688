<template>
  <MiButton class="cloud-study-button" :loading="loading" @click="handleBtnClick">
    <template #icon>
      <MiIcon icon="PLAY" />
    </template>
    {{ $t('Common.Training') }}
  </MiButton>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { misApi } from '~shared/api';
import { MiButton, MiIcon } from '~shared/ui';

const loading = ref(false);

const handleBtnClick = async () => {
  loading.value = true;
  const response = await misApi.gen.v2.cloudStudy.getCloudStudyUrl();

  if (response) window.open(response.data.url, '_blank');
  loading.value = false;
};
</script>

<style scoped>
.cloud-study-button {
  height: 35px;
  border-radius: 16px;
}
</style>
