import { ROUTE_PATH } from '~shared/config';
import { onlyUserHasRoleMiddleware, onlyUserIsAuthMiddleware } from '~shared/lib';

export const ANALYSIS_LIST_ROUTE = {
  name: 'AnalysisList',
  path: ROUTE_PATH.analysisList,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['laboratory'])],
  meta: {
    title: 'Laboratory.Analysis.Analyzes',
  },
};
