<template>
  <MainLayout>
    <MiFilterBox class="v-orders">
      <div class="v-orders__filters">
        <MiSearchInput
          v-model="searchString"
          :placeholder="$t('Patients.PatientSearchPlaceholder')"
          @input="searchWithDebounce"
          clearable />

        <MainContingentCheckbox v-if="!isB2gInterface" v-model="isMainContingent.value" />

        <MiSelectSearch
          :model-value="laboratoryDepartment?.id"
          v-model:model-object-value="laboratoryDepartment"
          :placeholder="$t('InpatientCare.Department')"
          clearable
          :abortable-fetch-data="misApi.g2g.directory.laboratoryDepartments.abortableGetList" />

        <SelectOrderStatus v-model="status.value" clearable />

        <MiDatePicker
          class="v-orders__date-picker"
          v-model="datePeriod.value"
          type="daterange"
          unlink-panels
          :format="DATE_FORMAT"
          :value-format="DATE_FORMAT"
          :start-placeholder="$t('DateAndTime.StartDate')"
          :end-placeholder="$t('DateAndTime.EndDate')" />

        <ScanBiomaterialBarCode only-icon @successful-scan="biomaterialSuccessScanHandler" />
      </div>

      <template #actions>
        <MiButton type="primary" @click="isAppointmentModalVisible = true">
          {{ $t('Appointments.CreateAppointment') }}
        </MiButton>
      </template>
    </MiFilterBox>

    <OrdersTable
      :items="data"
      :loading="loading"
      v-model:per-page="perPage.value"
      v-model:page="page.value"
      :total="total"
      @biomaterial:update="getOrders"
      :show-paid-status="isShowPaidStatus"
      :current-period="currentPeriod" />

    <CreateAppointmentModal
      v-model="isAppointmentModalVisible"
      disable-default-action
      set-default-my-doctor
      @created-successfully="getDataByInterval" />
  </MainLayout>
</template>

<script>
import noop from 'lodash.noop';
import debounce from 'lodash.debounce';
import { mapState, mapActions } from 'vuex';
import { storeToRefs } from 'pinia';

import { MainLayout } from '~widgets/layouts';
import { CreateAppointmentModal } from '~features/patients';
import { MainContingentCheckbox } from '~features/contingent';
import { useAppConfigStore } from '~entities/session';
import { MiButton, MiDatePicker, MiFilterBox, MiSearchInput, MiSelectSearch } from '~shared/ui';
import {
  usePage,
  usePerPage,
  useQuery,
  useUser,
  useGetDataByInterval,
  compareQueriesThenLoadData,
  useLocalStorage,
  amplitudeService,
  useDatePeriod,
} from '~shared/lib';
import { DATE_FORMAT, DEFAULT_DEBOUNCE_DELAY } from '~shared/config';
import { misApi } from '~shared/api';

import OrdersTable from '@/components/laboratory/OrdersTable/index.vue';
import SelectOrderStatus from '@/components/laboratory/SelectOrderStatus/index.vue';
import ScanBiomaterialBarCode from '@/components/scanner/ScanBiomaterialBarCode/index.vue';
import { Order } from '@/models/laboratory/Order.model';
import { LABORATORY_ORDER_ROUTE } from '@/router/laboratory.routes';

export default {
  name: 'VOrders',
  components: {
    MiButton,
    MiDatePicker,
    MiSearchInput,
    MainContingentCheckbox,
    ScanBiomaterialBarCode,
    OrdersTable,
    SelectOrderStatus,
    MainLayout,
    MiFilterBox,
    MiSelectSearch,
    CreateAppointmentModal,
  },
  data() {
    return {
      isAppointmentModalVisible: false,
      searchString: this.patientsSearch.value,
    };
  },
  setup: () => ({
    perPage: usePerPage(),
    page: usePage(),
    datePeriod: useDatePeriod([]),
    status: useQuery({
      field: 'status',
    }),
    isMainContingent: useQuery({
      field: 'main_contingent',
      valueIsBoolean: true,
    }),
    patientsSearch: useQuery({
      field: 'search',
    }),
    patient: useUser(),
    laboratoryDepartment: useLocalStorage('laboratory_department'),
    getDataByInterval: noop,
    searchWithDebounce: noop,
    DATE_FORMAT,
    misApi,
  }),
  computed: {
    ...mapState({
      data: (state) => state.orders.data,
      total: (state) => state.orders.total,
      loading: (state) => state.orders.loading,
    }),

    queryWatchers() {
      return {
        per_page: this.perPage.value,
        page: this.page.value,
        start_at: this.datePeriod.value?.[0],
        end_at: this.datePeriod.value?.[1],
        status: this.status.value,
        user_id: this.patient.value.id,
        department_id: this.laboratoryDepartment?.id,
        main_contingent: this.isMainContingent.value,
        search: this.patientsSearch.value,
      };
    },
    isB2gInterface() {
      const { featureFlags } = storeToRefs(useAppConfigStore());
      return featureFlags.value?.['user.interface'] === 'b2g';
    },
    currentPeriod() {
      return this.$route.query.date_period;
    },
    isShowPaidStatus() {
      const { featureFlags } = storeToRefs(useAppConfigStore());
      const isB2gPlusInterface =
        featureFlags.value?.['user.interface'] === 'b2g' &&
        featureFlags.value?.['clinic.cashRegister'];
      const isG2gInterface = featureFlags.value?.['user.interface'] === 'g2g';

      return isB2gPlusInterface || isG2gInterface;
    },
  },
  watch: {
    'queryWatchers': {
      handler(value, oldValue) {
        compareQueriesThenLoadData({
          query: value,
          oldQuery: oldValue,
          getData: this.getDataByInterval,
          resetPage: this.page.reset,
          fieldsForResetPage: ['status', 'start_at', 'patient_id'],
        });
      },
      deep: true,
    },
    'datePeriod.value'(val, old) {
      if (JSON.stringify(val) !== JSON.stringify(old)) {
        amplitudeService.logEvent('laboratory_orders.change_date_rage');
      }
    },
    'status.value'(val, old) {
      if (val !== old) {
        amplitudeService.logEvent('laboratory_orders.change_status');
      }
    },
    'patient.value.id'(val, old) {
      if (val !== old) {
        amplitudeService.logEvent('laboratory_orders.change_patient');
      }
    },
  },

  methods: {
    ...mapActions({
      setLoading: 'orders/setLoading',
      setData: 'orders/setData',
    }),

    sendAmplitudeScanBarcodeLaboratory(orderId, patientId, biomaterialIds) {
      amplitudeService.logEvent('scan_barcode_laboratory', {
        order_id: orderId,
        patient_id: patientId,
        biomaterial_id: biomaterialIds,
      });
    },

    async getOrders() {
      this.setLoading(true);

      const { data } = await Order.find({
        _url: 'g2g/orders',
        ...this.queryWatchers,
      });
      this.setData({
        items: data.data,
        total: data.meta.total,
        overwriteDataState: true,
      });

      this.setLoading(false);
    },

    biomaterialSuccessScanHandler(order) {
      this.sendAmplitudeScanBarcodeLaboratory(
        order?.id,
        order.user?.id,
        order.biomaterials.map((item) => item.id)
      );

      this.$router.push({
        name: LABORATORY_ORDER_ROUTE.name,
        params: {
          id: order.id,
        },
      });
    },
  },

  created() {
    this.searchWithDebounce = debounce((value) => {
      this.patientsSearch.value = value;
    }, DEFAULT_DEBOUNCE_DELAY);
  },

  mounted() {
    this.getDataByInterval = useGetDataByInterval(this.getOrders);
    this.getDataByInterval();
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
