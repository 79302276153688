import { AnyObject, QueryParams } from '~shared/types';
import { MetaDto, apiService } from '~shared/api';
import { joinQueryParams, clearObjectEmptyValues } from '~shared/lib';

export class CRUDModel {
  static modelName = 'model';
  static tableName = 'models';

  id: number | null = null;

  constructor(payload?: { id?: number | null }) {
    this.id = payload?.id ?? null;
  }

  static async find<T = AnyObject[]>({
    _url,
    per_page,
    page,
    order_by,
    order_direction,
    query_field,
    query_type,
    query_operator,
    search,
    ...args
  }: QueryParams & { _url?: string }) {
    const urlWithQuery = joinQueryParams({
      url: _url || this.tableName,
      query: clearObjectEmptyValues({
        per_page,
        page,
        order_by,
        order_direction,
        query_field,
        query_type,
        query_operator,
        search,
        ...args,
      }),
    });

    const response = await apiService.api.instance.get<{ data: T; meta: MetaDto }>(urlWithQuery);
    return {
      response,
      data: response.data,
    };
  }

  static async findOneById<T = AnyObject>(id: number) {
    const response = await apiService.api.instance.get<{ data: T }>(`${this.tableName}/${id}`);

    return {
      response: response,
      data: response.data,
      [this.modelName]: response.data.data,
    };
  }

  static async create<T = AnyObject>(payload: object, options: { url?: string } = {}) {
    const response = await apiService.api.instance.post<{ data: T }>(
      `${options.url || this.tableName + '/create'}`,
      payload
    );
    return {
      response,
      data: response.data,
      [this.modelName]: response.data.data,
    };
  }

  static async update<T = AnyObject>({
    id,
    payload,
    options = {},
  }: {
    id: number;
    payload: AnyObject;
    options?: { url?: string };
  }) {
    const response = await apiService.api.instance.put<{ data: T }>(
      options.url || `${this.tableName}/${id}/update`,
      payload
    );
    return {
      response: response,
      data: response.data,
      [this.modelName]: response.data.data,
    };
  }

  static async delete<T = AnyObject>({ id }: { id: number }) {
    const response = await apiService.api.instance.delete<{ data: T }>(
      `${this.tableName}/${id}/delete`
    );
    return {
      response: response,
      data: response.data,
    };
  }
}
