import { onMounted, ref } from 'vue';
import { defineStore } from 'pinia';

import { LocaleIdKey } from '~shared/types';
import { I18nService, authStorageService } from '~shared/lib';
import { apiService } from '~shared/api';

export const useLocaleStore = defineStore('localeStore', () => {
  const currentLang = ref<LocaleIdKey>('uz');

  function setLocale(payload: LocaleIdKey) {
    currentLang.value = payload;
    authStorageService.setLocale(payload);
    apiService.setDefaultLocale(payload);
    I18nService.setLocale(payload);
  }

  onMounted(() => {
    currentLang.value = authStorageService.getLocale() ?? 'uz';
  });

  return {
    currentLang,
    setLocale,
  };
});
