import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const B2G_PATIENT_PROFILE_ROUTE = {
  name: 'B2gPatientProfile',
  path: ROUTE_PATH.patients.b2gProfiles.rawPath,
  component: () => import('./ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'manager', 'nurse']),
  ],
  meta: {
    title: 'Patients.PatientProfile',
  },
  props: true,
};
