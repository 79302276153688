import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const PREGNANCY_MAIN_LIST_ROUTE = {
  name: 'PregnancyMainList',
  path: ROUTE_PATH.pregnancy.mainList,
  component: () => import('./PregnancyMainList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director'])],
  meta: {
    title: 'Analytics.Pregnants',
  },
};

export const PREGNANCY_MICRODISTRIC_LIST_ROUTE = {
  name: 'PregnancyMicrodistricList',
  path: ROUTE_PATH.pregnancy.microdistrics,
  component: () => import('~pages/analytics/pregnancy/PregnancyMicrodistricList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Analytics.Pregnants',
  },
};

export const PREGNANCY_GROUP_LIST_ROUTE = {
  name: 'PregnancyGroupList',
  path: ROUTE_PATH.pregnancy.groups,
  component: () => import('~pages/analytics/pregnancy/PregnancyGroupList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Analytics.Pregnants',
  },
};
