import { ElNotification } from 'element-plus';

import { GetChamberDirectoryListResponseData } from '../../types';

import { apiService } from '~shared/api';
import { getApiErrorMessage, joinQueryParams, useAbortable } from '~shared/lib';
import { QueryParams } from '~shared/types';

const urlPath = 'chambers';

export const getList = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<GetChamberDirectoryListResponseData>(
      joinQueryParams({
        url: urlPath,
        query,
      })
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const abortableGetList = useAbortable(getList);
