import { AxiosError, GenericAbortSignal } from 'axios';
import { ElNotification } from 'element-plus';

import { apiService, GetParentClinicsListResponseData, WorkingTimeResource } from '~shared/api';
import { getApiErrorMessage, joinQueryParams, useAbortable } from '~shared/lib';
import { QueryParams } from '~shared/types';

const urlPath = 'clinics';

export const getClinicWorkingTime = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{ data: WorkingTimeResource }>(
      joinQueryParams({
        url: `${urlPath}/working-time`,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getList = async (query: QueryParams = {}, signal?: GenericAbortSignal) => {
  try {
    const response = await apiService.api.instance.get<GetParentClinicsListResponseData>(
      joinQueryParams({
        url: `g2g/${urlPath}/parent`,
        query,
      }),
      {
        signal,
      }
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    if (err instanceof AxiosError && err.code === 'ERR_CANCELED') return;
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const abortableGetList = useAbortable(getList);
