import { ElNotification } from 'element-plus';

import { GetDefaultDirectoryListResponseData } from '../../types';

import { getApiErrorMessage, joinQueryParams, useAbortable } from '~shared/lib';
import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';

const urlPath = 'g2g/microdistricts';

export const getList = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<GetDefaultDirectoryListResponseData>(
      joinQueryParams({
        url: urlPath,
        query,
      })
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getListV2 = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<GetDefaultDirectoryListResponseData>(
      joinQueryParams({
        url: urlPath,
        query,
      })
    );
    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getListB2g = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get(
      joinQueryParams({
        url: 'b2g/microdistricts',
        query,
      })
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const abortableGetList = useAbortable(getList);
