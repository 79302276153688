import axios from 'axios';
import { ElNotification } from 'element-plus';

import { CRUDModel } from './CRUD.model';

import { apiService, misApi } from '~shared/api';
import { QueryParams } from '~shared/types';

type EmployeeRole =
  | 'manager'
  | 'doctor'
  | 'director'
  | 'laboratory'
  | 'warehouse'
  | 'accountant'
  | 'nurse';

type DoctorSpecialtyDto = {
  id: number;
  title: string;
};

type EmployeeDto = {
  id: number;
  role: EmployeeRole;
  name: string;
  is_working: boolean;
  phone: string;
  short_working_time: string;
  salary: number;
  address: string;
  appointment_commission: string;
  birthdate: string;
  doctor_recommendation_commission: string;
  doctor?: {
    id: number;
    avatar?: { id: number; link: string } | null;
    specialties: DoctorSpecialtyDto[];
  } | null;
  working_time: {
    monday_start: string | null;
    monday_end: string | null;
    tuesday_start: string | null;
    tuesday_end: string | null;
    wednesday_start: string | null;
    wednesday_end: string | null;
    saturday_start: string | null;
    saturday_end: string | null;
    thursday_start: string | null;
    thursday_end: string | null;
    friday_start: string | null;
    friday_end: string | null;
    sunday_start: string | null;
    sunday_end: string | null;
  };
  date_last_salary: string;
  amount_last_salary: number;
  specialty_ids: number[];
};

export type EmployeeFormData = Omit<
  EmployeeDto,
  'role' | 'id' | 'salary' | 'amount_last_salary' | 'doctor'
> & {
  id?: EmployeeDto['id'] | null;
  role?: EmployeeDto['role'] | null;
  salary?: EmployeeDto['salary'] | null;
  amount_last_salary?: EmployeeDto['amount_last_salary'] | null;
  doctor?: (EmployeeDto['doctor'] & { specialty_ids?: number[] }) | null;
  specialty_ids?: number[];
};

export class User extends CRUDModel {
  static modelName = 'user';
  static tableName = 'users';
  start_time = '08:00';
  end_time = '21:00';

  role: EmployeeFormData['role'];
  name: EmployeeFormData['name'];
  is_working: EmployeeFormData['is_working'];
  phone: EmployeeFormData['phone'];
  short_working_time: EmployeeFormData['short_working_time'];
  salary: EmployeeFormData['salary'];
  address: EmployeeFormData['address'];
  appointment_commission: EmployeeFormData['appointment_commission'];
  birthdate: EmployeeFormData['birthdate'];
  doctor_recommendation_commission: EmployeeFormData['doctor_recommendation_commission'];
  doctor?: EmployeeFormData['doctor'];
  working_time: EmployeeFormData['working_time'];
  date_last_salary: EmployeeFormData['date_last_salary'];
  amount_last_salary: EmployeeFormData['amount_last_salary'];
  specialty_ids: EmployeeFormData['specialty_ids'];

  constructor(payload?: EmployeeFormData) {
    super(payload);

    this.role = payload?.role ?? null;
    this.name = payload?.name ?? '';
    this.is_working = payload?.is_working ?? true;
    this.phone = payload?.phone ?? '';
    this.short_working_time = payload?.short_working_time ?? '';
    this.salary = payload?.salary ?? null;
    this.address = payload?.address ?? '';
    this.appointment_commission = payload?.appointment_commission ?? '';
    this.birthdate = payload?.birthdate ?? '';
    this.doctor_recommendation_commission = payload?.doctor_recommendation_commission ?? '';
    this.doctor = payload?.doctor
      ? {
          ...payload.doctor,
          specialty_ids: payload.doctor.specialties.map((item) => item.id),
        }
      : null;
    this.specialty_ids = payload?.doctor?.specialties.map((item) => item.id) ?? [];
    this.working_time = payload?.working_time ?? {
      monday_start: this.start_time,
      monday_end: this.end_time,
      tuesday_start: this.start_time,
      tuesday_end: this.end_time,
      wednesday_start: this.start_time,
      wednesday_end: this.end_time,
      saturday_start: this.start_time,
      saturday_end: this.end_time,
      thursday_start: this.start_time,
      thursday_end: this.end_time,
      friday_start: this.start_time,
      friday_end: this.end_time,
      sunday_start: this.start_time,
      sunday_end: this.end_time,
    };
    this.date_last_salary = payload?.date_last_salary ?? '';
    this.amount_last_salary = payload?.amount_last_salary ?? null;
  }

  static async getDevices(deviceid: string, query: QueryParams) {
    return super.find({
      _url: `${this.tableName}/${deviceid}${misApi.b2b.device.urlPath}`,
      ...query,
    });
  }

  static async getKey(userId: EmployeeDto['id']) {
    try {
      const response = await apiService.api.instance.post(`${this.tableName}/${userId}/key`);

      return {
        response: response,
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async createEmployee(payload: EmployeeFormData) {
    try {
      const response = await apiService.api.instance.post<{ data: EmployeeDto }>(
        `${this.tableName}/create`,
        payload
      );
      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async updateEmployee(payload: EmployeeFormData) {
    try {
      const response = await apiService.api.instance.put<{ data: EmployeeDto }>(
        `${this.tableName}/${payload.id}/update`,
        payload
      );
      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static enum = {
    genders: {
      MAN: 'man',
      Woman: 'woman',
    },
    roles: {
      Admin: 'admin',
      Director: 'director',
      Patient: 'patient',
      Manager: 'manager',
      Doctor: 'doctor',
      Laboratory: 'laboratory',
      Warehouse: 'warehouse',
      Nurse: 'nurse',
      Accountant: 'accountant',
      FamilyDoctor: 'family-doctor',
    },
  };
}
