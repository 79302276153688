import { ref } from 'vue';
import { defineStore } from 'pinia';

import { FeatureFlagsResponse } from '~shared/api/gen/v2';
import { useLocalStorage } from '~shared/lib';
import { misApi } from '~shared/api';

export const useAppConfigStore = defineStore('appConfigStore', () => {
  const appConfigFromStorage = useLocalStorage<{ feature_flags: FeatureFlagsResponse | null }>(
    'app_config'
  );

  const featureFlags = ref<FeatureFlagsResponse | null>(
    appConfigFromStorage.value?.feature_flags ?? null
  );

  function setFeatureFlags(payload: FeatureFlagsResponse | null) {
    featureFlags.value = payload;
  }

  async function getFeatureFlags() {
    const response = await misApi.gen.v2.features.getCurrentFeatures();

    if (response?.data.data) {
      setFeatureFlags(response.data.data);
      appConfigFromStorage.value = {
        feature_flags: response.data.data,
      };
    } else if (!response?.data && appConfigFromStorage.value?.feature_flags) {
      setFeatureFlags(appConfigFromStorage.value.feature_flags);
    } else if (!response?.data && !appConfigFromStorage.value?.feature_flags) {
      appConfigFromStorage.value = {
        feature_flags: null,
      };
    }
  }

  return {
    featureFlags,
    getFeatureFlags,
  };
});
