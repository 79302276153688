const getDaysInMonth = (dateStr: Date) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  return new Date(year, month, 0).getDate();
};

/**
 * @example 2022-09-30T00:00:00.000Z  ->  2022-08-30T00:00:00.000Z || 2022-10-30T00:00:00.000Z
 * @param {string} date ISO format
 * @param {object} [options]
 * @param {"next"|"prev"} options.type = "next"
 * @return {string}
 */
export const prevOrNextMonthByISOString = (date: Date, options = { type: 'next' }) => {
  const _date = new Date(date);
  const newMonthDate = new Date(date);
  newMonthDate.setMonth(options.type === 'next' ? _date.getMonth() + 1 : _date.getMonth() - 1, 1);

  const oldDay = _date.getDate();
  const daysInNewMonth = getDaysInMonth(newMonthDate);
  newMonthDate.setDate(oldDay > daysInNewMonth ? daysInNewMonth : oldDay);

  return newMonthDate;
};
