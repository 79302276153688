import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const DASHBOARD_ROUTE = {
  name: 'REGISTRY_DASHBOARD',
  path: '/dashboard',
  component: 'VDashboard',
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Common.Dashboard',
  },
};

export const routes = [DASHBOARD_ROUTE];
