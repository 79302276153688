import { NavigationGuardNext, NavigationGuardWithThis, RouteLocationNormalized } from 'vue-router';

import { ACCESS_TOKEN_KEY, ACCESS_TOKEN_PURPOSE, ROUTE_PATH, USER_KEY } from '~shared/config';
import { UserRoleEnum } from '~shared/api';
import { authStorageService, DeviceService, localStorageUtils } from '~shared/lib';
import { AccessTokenPurpose } from '~shared/lib/authStorage.service';

export const onlyUserIsAuthMiddleware: NavigationGuardWithThis<undefined> = function (
  _to,
  _from,
  next
) {
  const user = localStorage.getItem(USER_KEY);

  const token = localStorage.getItem(ACCESS_TOKEN_KEY);

  const tokenPurpose = localStorageUtils.getParsedItem(
    ACCESS_TOKEN_PURPOSE
  ) as AccessTokenPurpose | null;

  if (user) {
    return next();
  } else if (token && tokenPurpose === 'roleSelection') {
    return next(ROUTE_PATH.auth.roleSelection);
  }

  next(ROUTE_PATH.auth.login);
};

export const onlyUserNotAuthMiddleware: NavigationGuardWithThis<undefined> = function (
  _to,
  _from,
  next
) {
  const user = localStorage.getItem(USER_KEY);

  const token = localStorage.getItem(ACCESS_TOKEN_KEY);

  const tokenPurpose = localStorageUtils.getParsedItem(
    ACCESS_TOKEN_PURPOSE
  ) as AccessTokenPurpose | null;

  if (user) {
    return next(ROUTE_PATH.root);
  } else if (token && tokenPurpose === 'roleSelection') {
    return next(ROUTE_PATH.auth.roleSelection);
  }

  next();
};

export const onlyUserHasRoleMiddleware = (
  roles: UserRoleEnum[]
): NavigationGuardWithThis<undefined> => {
  return (_to, _from, next) => {
    const userAsString = localStorage.getItem(USER_KEY);
    const currentUser = userAsString ? JSON.parse(userAsString) : null;
    const hasAccessRole = currentUser?.role ? roles.includes(currentUser.role) : false;

    if (hasAccessRole) {
      return next();
    }

    return next(ROUTE_PATH.noAccess);
  };
};

export const getRedirectTokenMiddleware = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.query.accessToken && to.query.refreshToken && to.query.deviceId) {
    authStorageService.setAccessToken(to.query.accessToken as string);
    authStorageService.setRefreshToken(to.query.refreshToken as string);
    DeviceService.setDeviceId(to.query.deviceId as string);
    next();
  } else {
    next(ROUTE_PATH.auth.login);
  }
};

export const onlyUserFromMobileAuthMiddleware: NavigationGuardWithThis<undefined> = function (
  _to,
  _from,
  next
) {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);

  const tokenPurpose = localStorageUtils.getParsedItem(
    ACCESS_TOKEN_PURPOSE
  ) as AccessTokenPurpose | null;

  if (token && tokenPurpose === 'roleSelection') {
    return next();
  }

  next(ROUTE_PATH.auth.login);
};
