import { ElNotification } from 'element-plus';

import { apiService } from '~shared/api';
import { QueryParams } from '~shared/types';
import { getApiErrorMessage, joinQueryParams } from '~shared/lib';

const urlPath = 'b2g/statistics';
const urlPathG2g = 'g2g/statistics';

type OrganizationsStatistics = {
  organization_types_count?: number;
  organizations_count?: number;
  organization_positions_count?: number;
};

type OrganizationsCountResponseData = {
  data: OrganizationsStatistics;
};

type StatisticsCommonData = {
  total_count: number;
  count: number;
};

type DisabilityGroupEnum = 'first' | 'second' | 'third';

type StatisticsCommonResponseData = {
  data: StatisticsCommonData;
};

export const getDispensaryCount = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/dispensary/count`,
        query: query,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getDispensaryRegistration = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/dispensary/registration`,
        query: query,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getDispensaryDeregistration = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/dispensary/deregistration`,
        query: query,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getDisabilitiesByGroup = async (group: DisabilityGroupEnum, query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/disabilities/${group}`,
        query: query,
      })
    );

    return { response, data: response.data.data };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getDisabilitiesChange = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/disabilities/group/change`,
        query: query,
      })
    );

    return { response, data: response.data.data };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getOrganizationsStatistics = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<OrganizationsCountResponseData>(
      joinQueryParams({
        url: `${urlPathG2g}/organizations`,
        query: query,
      })
    );

    return { response, data: response.data.data };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getVaccinated = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/vaccines/exists`,
        query,
      })
    );

    return { ...response.data };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getNotVaccinated = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/vaccines/doesnt_exists`,
        query,
      })
    );

    return { ...response.data };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getGroupCount = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/group/count`,
        query: query,
      })
    );

    return { response, data: response.data.data };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getGroupAppointment = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/group/appointment`,
        query: query,
      })
    );

    return { response, data: response.data.data };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getGroupIncome = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/group/income`,
        query: query,
      })
    );

    return { response, data: response.data.data };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getGroupOutcome = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/group/outcome`,
        query: query,
      })
    );

    return { response, data: response.data.data };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getPregnancyCount = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/pregnancy/count`,
        query,
      })
    );

    return { ...response.data };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getPregnancySuccessCount = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/pregnancy/success`,
        query,
      })
    );

    return { ...response.data };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getAbortsCount = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/pregnancy/aborts`,
        query,
      })
    );

    return { ...response.data };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getMisbirthsCount = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance<StatisticsCommonResponseData>(
      joinQueryParams({
        url: `${urlPath}/pregnancy/misbirths`,
        query,
      })
    );

    return { ...response.data };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
