import { ROUTE_PATH } from '~shared/config';
import { onlyUserHasRoleMiddleware, onlyUserIsAuthMiddleware } from '~shared/lib';

export const RECIPE_LIST_ROUTE = {
  name: 'RecipeList',
  path: ROUTE_PATH.recipes,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['doctor'])],
  meta: {
    title: 'Recipes.Recipes',
  },
};
