<template>
  <ElDialog
    class="create-or-pay-invoice-modal"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)">
    <template #header>
      <div v-if="!!invoice.id" class="create-or-pay-invoice-modal-header">
        <div>{{ $t('CashRegister.Invoice') }}:</div>
        <div>#{{ invoice?.id }}</div>
        <InvoiceStatusTag
          class="create-or-pay-invoice-modal-header__status"
          :status="invoice.status" />
      </div>

      <div v-if="!invoice.id" class="create-or-pay-invoice-modal__header">
        {{ $t('Invoices.Create') }}
      </div>
    </template>

    <ElForm
      id="create-or-pay-invoice"
      class="create-or-pay-invoice-modal__content create-or-pay-invoice-modal-content"
      @submit.prevent="submitHandler">
      <UiDataModalGrid>
        <template #info>
          <ElCard
            v-loading="loading.invoice"
            shadow="never"
            :style="{ gridRow: invoice.id && invoice.transactions_ids.length ? '1/3' : 'auto' }">
            <template #header> {{ $t('Common.Info') }}</template>

            <PatientsSearchSelectDataBlock
              v-model="invoice.user.id"
              :disabled="!!invoice.id"
              :hide-select="!!invoice.id"
              :default-item="invoice.user"
              required
              ref="patient"
              @select="invoice.user = $event">
              <template #content-append>
                <!--  Notes  -->
                <ElFormItem
                  class="create-or-pay-invoice-modal__notes"
                  :label="$t('Contingent.Description')">
                  <ElInput
                    v-model="invoice.description"
                    type="textarea"
                    :rows="3"
                    :disabled="!!invoice.id" />
                </ElFormItem>

                <!--  Doctors  -->
                <ElFormItem
                  v-if="invoice.appointments_ids.length"
                  :class="[
                    'create-or-pay-invoice-modal__doctors',
                    {
                      'create-or-pay-invoice-modal__doctors_multi':
                        invoice.appointments_ids.length > 1,
                    },
                  ]"
                  :label="$t('Common.Doctor')">
                  <template v-if="invoice.appointments_ids.length === 1">
                    {{ invoice.appointments[0].doctor.name }}
                  </template>

                  <ul v-if="invoice.appointments_ids.length > 1">
                    <li v-for="appointment in invoice.appointments" :key="appointment.id">
                      {{ appointment.doctor.name }}
                    </li>
                  </ul>
                </ElFormItem>

                <!--  ReferenceModel  -->
                <ElFormItem>
                  <SelectReference
                    v-model="invoice.reference_id"
                    :default-item="invoice.reference"
                    :disabled="!!invoice.id"
                    show-create-option
                    @select="invoice.reference = $event" />
                </ElFormItem>

                <!--  Created at  -->
                <ElFormItem v-if="!!invoice.id" :label="$t('DateAndTime.CreatedAt')">
                  {{ invoice.created_at }}
                </ElFormItem>
              </template>
            </PatientsSearchSelectDataBlock>
          </ElCard>
        </template>

        <template #control>
          <ElCard shadow="never" v-loading="loading.invoice">
            <CreateInvoicePaymentSubject
              :invoice="invoice"
              :disabled="!editable"
              @subject:create="attachPaymentSubject" />
          </ElCard>
        </template>

        <template #content>
          <ElCard shadow="never" v-loading="loading.invoice">
            <PaymentSubjectsTable
              class="create-or-pay-invoice-modal__services"
              height="200px"
              :invoice="invoice"
              :items="invoice.payment_subjects"
              :editable="editable"
              :deletable="editable"
              @item:update="updatePaymentSubject"
              @item:remove="deletePaymentSubject" />

            <ElFormItem
              class="create-or-pay-invoice-modal__discount-price-part"
              :label="$t('Common.Discount') + ' (%)'">
              <ElInput
                v-model="invoice.discount"
                type="number"
                placeholder="0%"
                :disabled="!editable"
                min="0"
                max="100" />
            </ElFormItem>

            <ElFormItem
              class="create-or-pay-invoice-modal__discount-price-part"
              :label="$t('Common.Total')">
              {{ totalPrice }}
            </ElFormItem>
          </ElCard>
        </template>

        <template #footer v-if="!!invoice.id">
          <TransactionsTable
            class="create-or-pay-invoice-modal__transactions-table"
            height="200px"
            :items="invoice.transactions"
            :loading="loading.invoice"
            :page="1"
            :per-page="invoice.transactions_ids.length || 1"
            :total="invoice.transactions_ids.length" />
        </template>
      </UiDataModalGrid>
    </ElForm>

    <template #footer>
      <div class="create-or-pay-invoice-modal-actions">
        <MiButton v-if="!!invoice.id" text :loading="loading.print" @click="print">
          <template #icon>
            <MiIcon icon="PRINTER" />
          </template>
          {{ $t('Common.Print') }}
        </MiButton>
        <MiButton
          v-show="!!invoice.user.id"
          type="primary"
          plain
          :loading="loading.printBracelet"
          @click="printBracelet">
          <template #icon>
            <MiIcon icon="PRINTER" />
          </template>
          {{ $t('Patients.PrintBracelet') }}
        </MiButton>

        <MiButton v-if="isShowRefundButton" type="danger" plain @click="refund">
          {{ $t('Common.Refund') }}
        </MiButton>
        <MiButton v-if="isShowPayButton" type="primary" @click="pay">
          {{ $t('Common.Pay') }}
        </MiButton>

        <MiButton
          v-show="editable"
          :type="invoice.id ? 'warning' : 'primary'"
          native-type="submit"
          :loading="loading.form"
          form="create-or-pay-invoice">
          {{ invoice.id ? $t('Common.Save') : $t('Common.Create') }}
        </MiButton>
      </div>
    </template>

    <InvoicePayOrRefundModal
      v-if="!!invoice.id"
      v-model="payModalIsOpen"
      :invoice="invoice"
      :type="payType"
      append-to-body
      @action="invoicePayModalActionHandler" />
    <InvoicePrinterCheck :invoice="invoice" :qr-payload="check.qrPayload" ref="invoiceCheck" />
  </ElDialog>
</template>

<script>
import axios from 'axios';
import { ElDialog, ElForm, ElInput, ElFormItem, ElCard } from 'element-plus';

// eslint-disable-next-line import/no-internal-modules
import InvoicePrinterCheck from './InvoicePrinterCheck/index.vue';
// eslint-disable-next-line import/no-internal-modules
import InvoicePayOrRefundModal from './InvoicePayOrRefundModal/index.vue';
// eslint-disable-next-line import/no-internal-modules
import PaymentSubjectsTable from './PaymentSubjectsTable/index.vue';
// eslint-disable-next-line import/no-internal-modules
import CreateInvoicePaymentSubject from './CreateInvoicePaymentSubject/index.vue';
// eslint-disable-next-line import/no-internal-modules
import UiDataModalGrid from '../UiDataModalGrid/index.vue';
// eslint-disable-next-line import/no-internal-modules
import SelectReference from '../SelectReference/index.vue';
// eslint-disable-next-line import/no-internal-modules
import PatientsSearchSelectDataBlock from '../PatientsSearchSelectDataBlock/index.vue';
// eslint-disable-next-line import/no-internal-modules
import { Invoice } from '../models/Invoice.model';
// eslint-disable-next-line import/no-internal-modules
import { Patient } from '../models/Patient.model';
// eslint-disable-next-line import/no-internal-modules
import { ServiceGroup } from '../models/ServiceGroup.model';
// eslint-disable-next-line import/no-internal-modules
import { Transaction } from '../models/Transaction.model';

import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import { InvoiceStatusTag } from '~entities/invoice';
import { printerService, amplitudeService, formatPrice } from '~shared/lib';
import { MiButton, MiIcon } from '~shared/ui';

import TransactionsTable from '@/components/transactions/TransactionsTable/index.vue';

export default {
  name: 'CreateOrEditOrPayInvoiceModal',
  components: {
    MiButton,
    CreateInvoicePaymentSubject,
    PaymentSubjectsTable,
    InvoicePayOrRefundModal,
    InvoiceStatusTag,
    PatientsSearchSelectDataBlock,
    TransactionsTable,
    InvoicePrinterCheck,
    SelectReference,
    UiDataModalGrid,
    MiIcon,
    ElDialog,
    ElForm,
    ElInput,
    ElFormItem,
    ElCard,
  },
  emits: ['update:modelValue', 'action'],
  props: {
    modelValue: Boolean,
    id: [Number, String],
  },
  data() {
    return {
      /** @type {Invoice} invoice */
      invoice: new Invoice(),
      loading: {
        invoice: false,
        form: false,
        print: false,
        printBracelet: false,
      },
      payModalIsOpen: false,
      payType: Transaction.enum.types.PayIn,

      check: {
        qrPayload: null,
      },
    };
  },
  computed: {
    totalPrice() {
      let sum = this.invoice.id
        ? this.invoice.discounted_amount
        : this.invoice.payment_subjects.reduce(
            (acc, elem) => acc + elem.subject.price * elem.count,
            0
          );
      sum =
        !this.invoice.id && this.invoice.discount > 0
          ? sum - sum * (this.invoice.discount / 100)
          : sum;

      return `${formatPrice({ price: sum })} ${this.$t('Common.Sum')}`;
    },

    editable() {
      return this.id ? this.invoice.status === Invoice.enum.statuses.NotPaid : true;
    },
    isShowPayButton() {
      return (
        !!this.invoice.id &&
        [Invoice.enum.statuses.NotPaid, Invoice.enum.statuses.PartiallyPaid].includes(
          this.invoice.status
        )
      );
    },
    isShowRefundButton() {
      return (
        !!this.invoice.id &&
        [
          Invoice.enum.statuses.Paid,
          Invoice.enum.statuses.PartiallyPaid,
          Invoice.enum.statuses.Overpay,
        ].includes(this.invoice.status)
      );
    },
  },

  watch: {
    modelValue: {
      handler(val) {
        this.invoice = new Invoice({});
        this.sendAmplitudeOpenModal(val);
      },
      immediate: true,
    },

    id: {
      handler(value) {
        if (value) this.getInvoiceById();
      },
      immediate: true,
    },
  },

  methods: {
    sendAmplitudeOpenModal(val) {
      if (val && this.id) {
        amplitudeService.logEvent('open_invoice_modal');
      } else if (val) {
        amplitudeService.logEvent('invoice_modal.open');
      }
    },

    async getInvoiceById() {
      this.loading.invoice = true;
      const { invoice } = await Invoice.findOneById(this.id);
      this.invoice = new Invoice(invoice);
      this.loading.invoice = false;
    },

    async submitHandler() {
      if (this.loading.form || !this.validate()) return;
      this.loading.form = true;

      try {
        const invoice = this.invoice.id ? await this.update() : await this.create();

        this.$notify({
          type: 'success',
          title: this.$t(`Notifications.Success${this.invoice.id ? 'Updated' : 'Created'}`),
        });
        this.$emit(
          'action',
          new GlobalModalInstance({
            name: this.invoice.id ? 'updated' : 'created',
            data: { invoice },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.form = false;
    },

    sendAmplitudeCreateInvoice() {
      amplitudeService.logEvent('invoice_create');
    },

    async create() {
      const { invoice } = await Invoice.create(this.invoice);
      this.sendAmplitudeCreateInvoice();
      return invoice;
    },

    sendAmplitudeUpdateInvoice() {
      amplitudeService.logEvent('invoice_modal.save');
    },

    async update() {
      const { invoice } = await Invoice.update({ id: this.invoice.id, payload: this.invoice });
      this.sendAmplitudeUpdateInvoice();
      return invoice;
    },

    /**
     * Доролнительная проверка
     * @return {boolean} если успешно true
     */
    validate() {
      if (!this.invoice.payment_subjects.length) {
        this.$notify({
          type: 'error',
          title: this.$t('Appointments.SelectServices'),
        });
        return false;
      }

      return true;
    },

    /** @param {InvoicePaymentSubject|object} subject */
    attachPaymentSubject(subject) {
      // В текущей реализации используется только ServiceGroup
      this.invoice.subjects.unshift({ group_service_id: subject.subject.id, count: subject.count });
      this.invoice.payment_subjects.unshift(subject);
    },

    /**
     * @param {InvoicePaymentSubject|object} paymentSubject
     * @param {InvoicePaymentSubject|object} oldPaymentSubject
     * */
    // TODO: при избавлении от subjects переделать
    updatePaymentSubject({ paymentSubject, oldPaymentSubject }) {
      // В текущей реализации используется только ServiceGroup
      const subjectIndex = this.invoice.subjects.findIndex(
        (subject) =>
          subject.group_service_id === oldPaymentSubject.subject.id &&
          subject.count === oldPaymentSubject.count
      );
      if (subjectIndex >= 0) this.invoice.subjects[subjectIndex].count = paymentSubject.count;

      const paymentSubjectIndex = this.invoice.payment_subjects.findIndex((subject) =>
        subject.id && paymentSubject.id
          ? subject.id === paymentSubject.id
          : subject._id === paymentSubject._id
      );
      this.invoice.payment_subjects[paymentSubjectIndex] = paymentSubject;
    },

    /** @param {InvoicePaymentSubject|object} paymentSubject */
    deletePaymentSubject(paymentSubject) {
      // В текущей реализации используется только ServiceGroup
      const subjectIndex = this.invoice.subjects.findIndex(
        (subject) =>
          subject.group_service_id === paymentSubject.subject.id &&
          subject.count === paymentSubject.count
      );

      this.invoice.subjects = this.invoice.subjects.filter((elem, index) => index !== subjectIndex);
      this.invoice.payment_subjects = this.invoice.payment_subjects.filter((elem) =>
        elem.id && paymentSubject.id
          ? elem.id !== paymentSubject.id
          : elem._id !== paymentSubject._id
      );
    },

    sendAmplitudePay() {
      amplitudeService.logEvent('invoice_modal.open_create_transaction_modal', {
        appointment_ids: this.invoice?.appointments_ids,
        doctor_ids: this.invoice?.appointments.map((appointment) => appointment.doctor_id),
        patient_id: this.invoice?.user?.id,
      });
    },

    pay() {
      this.payType = Transaction.enum.types.PayIn;
      this.payModalIsOpen = true;
      this.sendAmplitudePay();
    },

    sendAmplitudeRefund() {
      amplitudeService.logEvent('invoice_modal.open_refund_modal');
    },

    refund() {
      this.payType = Transaction.enum.types.PayOut;
      this.payModalIsOpen = true;
      this.sendAmplitudeRefund();
    },

    async invoicePayModalActionHandler(action) {
      this.$emit('action', action);
      this.payModalIsOpen = false;
    },

    async print() {
      if (this.loading.print) return;
      this.loading.print = true;

      try {
        const { data } = await Patient.getQrCodeLinkByUserId(this.invoice.user_id);
        this.check.qrPayload = data.link;
        this.$refs.invoiceCheck.print();
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.print = false;
    },

    sendAmplitudePrintBracelet() {
      amplitudeService.logEvent('invoice_modal.print_bracelet');
    },

    async printBracelet() {
      if (this.loading.printBracelet) return;
      this.loading.printBracelet = true;

      await printerService.printBraceletByPatientId(this.invoice.user_id);

      this.sendAmplitudePrintBracelet();
      this.loading.printBracelet = false;
    },
  },

  setup: () => ({
    ServiceGroup,
    Invoice,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
