<template>
  <div
    :class="[
      'form-field-group-wrapper',
      {
        'form-field-group-wrapper--with-shadow': boxShadow,
        'form-field-group-wrapper--no-padding': noPadding,
        'form-field-group-wrapper--with-margin-top': withMarginTop,
      },
    ]">
    <div class="form-field-group-wrapper__title" v-if="title">
      <div class="form-field-group-wrapper__title-text">
        <slot name="title">
          {{ title }}
        </slot>
      </div>

      <div class="form-field-group-wrapper__title-append">
        <slot name="title-append" />
      </div>
      <slot name="actions" />
    </div>
    <div
      :class="[
        'form-field-group-wrapper__content',
        {
          'form-field-group-wrapper__content_grid-3-col': props.gridColumns === 'three-columns',
          'form-field-group-wrapper__content_grid-4-col': props.gridColumns === 'four-columns',
          'form-field-group-wrapper__content_grid-5-col': props.gridColumns === 'five-columns',
        },
      ]">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    title?: string | null;
    boxShadow?: boolean;
    noPadding?: boolean;
    gridColumns?: 'three-columns' | 'four-columns' | 'five-columns' | null;
    withMarginTop?: boolean;
  }>(),
  {
    title: null,
    gridColumns: null,
  }
);
</script>

<style lang="scss" src="./index.scss" />
