import { object, string } from 'yup';

import { I18nService } from '~shared/lib';

export const patientRelativesValidationSchema = object().shape({
  type: string().required(I18nService.t('Validation.RequiredField')),
  document_type_id: string(),
  pinfl: string().when(['document_type_id'], (fields, schema) => {
    if (fields[0] === 'pinfl') {
      return schema
        .required(I18nService.t('Validation.RequiredField'))
        .nullable()
        .test('pinfl', I18nService.tc('Validation.Length', { length: 14 }), (value) => {
          if (value && value.length > 0) {
            return value.length === 14;
          }
          return true;
        });
    }
    return schema;
  }),
  id_serial: string().when(['document_type_id'], (fields, schema) => {
    if (fields[0] === 'uz_passport') {
      return schema
        .required(I18nService.t('Validation.RequiredField'))
        .length(2, I18nService.tc('Validation.Length', { length: 2 }));
    }
    return schema;
  }),
  id_number: string().when(['document_type_id'], (fields, schema) => {
    if (fields[0] === 'uz_passport') {
      return schema
        .required(I18nService.t('Validation.RequiredField'))
        .length(7, I18nService.tc('Validation.Length', { length: 7 }));
    }
    return schema;
  }),
  birthdate: string().when(['document_type_id'], (fields, schema) => {
    if (fields[0] === 'uz_passport' || fields[0] === 'birth_certificate') {
      return schema.required(I18nService.t('Validation.RequiredField'));
    }
    return schema;
  }),
  birth_certificate: string().when(['document_type_id'], (fields, schema) => {
    if (fields[0] === 'birth_certificate') {
      return schema.required(I18nService.t('Validation.RequiredField'));
    }
    return schema;
  }),
});
