import { ElNotification } from 'element-plus';

import { ScheduleSlotResource } from '../types';

import { getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { apiService } from '~shared/api';
import { QueryParams } from '~shared/types';

export const getList = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{ data: ScheduleSlotResource[] }>(
      joinQueryParams({
        url: 'b2g/schedule/slots',
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
