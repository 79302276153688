<template>
  <div class="patient-search-form">
    <MiCard :header="$t('Common.Patient')" v-if="examinationPlan">
      <div class="patient-info-wrapper">
        <MiInfoField bold :label="$t('Common.PatientFullName')" space-between>
          {{ patient ? joinFullname(patient) : EMPTY_VALUE_DASH }}
        </MiInfoField>
        <MiInfoField bold :label="$t('Common.Photo')" space-between>
          <TakePhotoButton
            v-if="patient?.id"
            :patient-id="patient.id"
            :has-photo="!!patient.myid_box_updated_at"
            @patient-update-successful="updatePatientMyidBoxUpdatedAt" />

          <template v-else>
            {{ EMPTY_VALUE_DASH }}
          </template>
        </MiInfoField>
        <MiInfoField bold :label="$t('Contingent.Birthdate')" space-between>
          {{ patient?.birthdate ?? EMPTY_VALUE_DASH }}
        </MiInfoField>
        <MiInfoField bold label="MED ID" space-between>
          {{ patient?.number_med_card ?? EMPTY_VALUE_DASH }}
        </MiInfoField>
        <MiInfoField bold :label="$t('Contingent.PINFL')" space-between>
          {{ patient?.pinfl ?? EMPTY_VALUE_DASH }}
        </MiInfoField>
      </div>
    </MiCard>

    <MiTabs v-else stretch v-model="currentTabId">
      <ElTabPane label="MyID" name="my-id">
        <div class="patient-search-form__mi-id-tab-content">
          <div class="patient-info-wrapper">
            <MiInfoField :label="$t('Common.PatientFullName')" space-between>
              {{ patient ? joinFullname(patient) : EMPTY_VALUE_DASH }}
            </MiInfoField>
            <MiInfoField :label="$t('Contingent.Birthdate')" space-between>
              {{
                patient?.birthdate ? convertDateToAppFormat(patient?.birthdate) : EMPTY_VALUE_DASH
              }}
            </MiInfoField>
            <MiInfoField label="MED ID" space-between>
              {{ patient?.number_med_card ?? EMPTY_VALUE_DASH }}
            </MiInfoField>
            <MiInfoField :label="$t('Contingent.PINFL')" space-between>
              {{ patient?.pinfl ?? EMPTY_VALUE_DASH }}
            </MiInfoField>
            <MiInfoField v-if="isB2gInterface" :label="$t('Contingent.AttachedTo')" space-between>
              <AttachPatientToPolyclinicButton
                v-if="patient?.active_attached_clinic"
                link
                :disabled="!patient?.id"
                :patient-id="patient?.id"
                @update-successful="handleUpdateSuccessful">
                {{ patient?.active_attached_clinic?.title }}
              </AttachPatientToPolyclinicButton>

              <AttachPatientToPolyclinicButton
                v-else
                link
                :disabled="!patient?.id"
                :patient-id="patient?.id"
                @update-successful="handleUpdateSuccessful">
                {{ $t('Contingent.AttachToPolyclinic') }}
              </AttachPatientToPolyclinicButton>
            </MiInfoField>
            <MiInfoField v-if="isB2gInterface" :label="$t('Directories.Area')" space-between>
              {{ patient?.active_attached_microdistrict?.title ?? EMPTY_VALUE_DASH }}
            </MiInfoField>
            <MiInfoField v-if="isB2gInterface" :label="$t('Doctors.Doctor')" space-between>
              {{ patient?.active_attached_doctor?.name ?? EMPTY_VALUE_DASH }}
            </MiInfoField>
          </div>

          <SearchByPhotoButton
            @patient-search-successful="setPatient"
            @try-document-search="handleTryDocumentsSearch" />
        </div>
      </ElTabPane>
      <ElTabPane :label="$t('Common.Document')" name="docs-search">
        <FormSelect
          :options="documentOptions"
          field-name="documentTypeId"
          field-object-name="documentType"
          :label="$t('Patients.DocumentType')"
          @select="handleDocumentTypeSelect" />

        <div v-show="values.documentTypeId === 'uz_passport'" class="passport-fields">
          <FormTextField
            ref="passportSerialFieldRef"
            field-name="searchParams.id_serial"
            :label="$t('Contingent.PassportSeries')"
            data-maska="@@"
            placeholder="AZ"
            :formatter="(val: string) => val.toUpperCase()"
            required
            @key-down:enter="setPassportNumberFieldFocus" />
          <FormTextField
            ref="passportNumberFieldRef"
            field-name="searchParams.id_number"
            :label="$t('Contingent.PassportNumbers')"
            data-maska="#######"
            @key-down:enter="setDocsSearchBirthdateFieldFocus"
            required />
        </div>

        <div v-show="values.documentTypeId === 'birth_certificate'" class="passport-fields">
          <FormTextField
            ref="birthCertificateSerialFieldRef"
            field-name="searchParams.birth_certificate_serial"
            :label="$t('Patients.PassportCode')"
            data-maska="S"
            :formatter="(val: string) => val.toUpperCase()"
            required
            @key-down:enter="setBirthCertificateNumberFieldFocus" />
          <FormTextField
            ref="birthCertificateNumberFieldRef"
            field-name="searchParams.birth_certificate_number"
            :label="$t('Common.Number')"
            data-maska="D"
            @key-down:enter="setDocsSearchBirthdateFieldFocus"
            required />
        </div>

        <FormTextField
          ref="pinflFieldRef"
          v-show="values.documentTypeId === 'pinfl'"
          field-name="searchParams.pinfl"
          :label="$t('Contingent.PINFL')"
          mask-name="int-14"
          @key-down:enter="setDocsSearchBirthdateFieldFocus"
          required />

        <FormTextField
          ref="numberMedCardFieldRef"
          v-show="values.documentTypeId === 'medcard_number'"
          field-name="searchParams.medcard_number"
          :label="$t('Contingent.MedCardNumber')"
          @key-down:enter="setDocsSearchBirthdateFieldFocus"
          required />

        <FormTextField
          ref="docsSearchBirthdateFieldRef"
          v-if="values.documentTypeId !== 'pinfl'"
          field-name="searchParams.birthdate"
          :label="$t('Contingent.Birthdate')"
          data-maska="##.##.####"
          required />

        <div class="actions-box">
          <ScanPatientBracelet @successful-scan="setPatient" />

          <MiButton
            :disabled="submitDisabled"
            type="primary"
            :loading="isSubmitting"
            @click="handleSubmitForm">
            <template #icon>
              <MiIcon icon="SEARCH" />
            </template>
            {{ $t('Common.Find') }}
          </MiButton>

          <MiButton @click="clearForm" type="danger"> {{ $t('Common.Clear') }} </MiButton>
        </div>

        <div class="search-result" v-if="searchFailed">
          {{ $t(searchFailedErrorMessage) }}
        </div>

        <ElDivider />

        <div class="patient-info-wrapper">
          <MiInfoField :label="$t('Common.PatientFullName')" space-between>
            {{ patient ? joinFullname(patient) : EMPTY_VALUE_DASH }}
          </MiInfoField>
          <MiInfoField :label="$t('Common.Photo')" space-between>
            <TakePhotoButton
              v-if="patient?.id"
              :patient-id="patient.id"
              :has-photo="!!patient.myid_box_updated_at"
              @patient-update-successful="updatePatientMyidBoxUpdatedAt" />

            <template v-else>
              {{ EMPTY_VALUE_DASH }}
            </template>
          </MiInfoField>
          <MiInfoField :label="$t('Contingent.Birthdate')" space-between>
            {{ patient?.birthdate ? convertDateToAppFormat(patient?.birthdate) : EMPTY_VALUE_DASH }}
          </MiInfoField>
          <MiInfoField label="MED ID" space-between>
            {{ patient?.number_med_card ?? EMPTY_VALUE_DASH }}
          </MiInfoField>
          <MiInfoField :label="$t('Contingent.PINFL')" space-between>
            {{ patient?.pinfl ?? EMPTY_VALUE_DASH }}
          </MiInfoField>
          <MiInfoField v-if="isB2gInterface" :label="$t('Contingent.AttachedTo')" space-between>
            <AttachPatientToPolyclinicButton
              v-if="patient?.active_attached_clinic"
              link
              :disabled="!patient?.id"
              :patient-id="patient?.id"
              @update-successful="handleUpdateSuccessful">
              {{ patient?.active_attached_clinic?.title }}
            </AttachPatientToPolyclinicButton>

            <AttachPatientToPolyclinicButton
              v-else
              link
              :disabled="!patient?.id"
              :patient-id="patient?.id"
              @update-successful="handleUpdateSuccessful">
              {{ $t('Contingent.AttachToPolyclinic') }}
            </AttachPatientToPolyclinicButton>
          </MiInfoField>
          <MiInfoField v-if="isB2gInterface" :label="$t('Directories.Area')" space-between>
            {{ patient?.active_attached_microdistrict?.title ?? EMPTY_VALUE_DASH }}
          </MiInfoField>
          <MiInfoField v-if="isB2gInterface" :label="$t('Doctors.Doctor')" space-between>
            {{ patient?.active_attached_doctor?.name ?? EMPTY_VALUE_DASH }}
          </MiInfoField>
        </div>
      </ElTabPane>
      <ElTabPane :label="$t('Common.FullName')" name="without-docs">
        <FormSearchSelectForCreateAppointment
          clearable
          :label="$t('Patients.PatientSearch')"
          :placeholder="$t('Patients.PatientSearchPlaceholder')"
          field-name="patientFoundByName_id"
          field-object-name="patientFoundByName"
          :label-key="setFullName"
          raw-fetch-result
          popper-class="patient-search-form__search-select-popper"
          :fetch-data="misApi.gen.v2.patients.searchPatients">
          <template #optionTemplate="{ item }">
            <div class="patient-search-form__search-select-item">
              <div class="patient-search-form__top-row">
                <span class="patient-search-form__name">{{ setFullName(item) }}</span>
                <span class="patient-search-form__med-card">{{ item.medcard_number }}</span>
              </div>
              <div class="patient-search-form__bottom-row">
                {{ convertDateToAppFormat(item.birthdate) }}
              </div>
            </div>
          </template>
        </FormSearchSelectForCreateAppointment>

        <div class="patient-info-wrapper">
          <MiInfoField :label="$t('Common.PatientFullName')" space-between>
            {{ patient ? joinFullname(patient) : EMPTY_VALUE_DASH }}
          </MiInfoField>
          <MiInfoField :label="$t('Common.Photo')" space-between>
            <TakePhotoButton
              v-if="patient?.id"
              :patient-id="patient.id"
              :has-photo="!!patient.myid_box_updated_at"
              @patient-update-successful="updatePatientMyidBoxUpdatedAt" />

            <template v-else>
              {{ EMPTY_VALUE_DASH }}
            </template>
          </MiInfoField>
          <MiInfoField :label="$t('Contingent.Birthdate')" space-between>
            {{ patient?.birthdate ? convertDateToAppFormat(patient?.birthdate) : EMPTY_VALUE_DASH }}
          </MiInfoField>
          <MiInfoField label="MED ID" space-between>
            {{ patient?.number_med_card ?? EMPTY_VALUE_DASH }}
          </MiInfoField>
          <MiInfoField :label="$t('Contingent.PINFL')" space-between>
            {{ patient?.pinfl ?? EMPTY_VALUE_DASH }}
          </MiInfoField>
          <MiInfoField v-if="isB2gInterface" :label="$t('Contingent.AttachedTo')" space-between>
            <AttachPatientToPolyclinicButton
              v-if="patient?.active_attached_clinic"
              link
              :disabled="!patient?.id"
              :patient-id="patient?.id"
              @update-successful="handleUpdateSuccessful">
              {{ patient?.active_attached_clinic?.title }}
            </AttachPatientToPolyclinicButton>

            <AttachPatientToPolyclinicButton
              v-else
              link
              :disabled="!patient?.id"
              :patient-id="patient?.id"
              @update-successful="handleUpdateSuccessful">
              {{ $t('Contingent.AttachToPolyclinic') }}
            </AttachPatientToPolyclinicButton>
          </MiInfoField>
          <MiInfoField v-if="isB2gInterface" :label="$t('Directories.Area')" space-between>
            {{ patient?.active_attached_microdistrict?.title ?? EMPTY_VALUE_DASH }}
          </MiInfoField>
          <MiInfoField v-if="isB2gInterface" :label="$t('Doctors.Doctor')" space-between>
            {{ patient?.active_attached_doctor?.name ?? EMPTY_VALUE_DASH }}
          </MiInfoField>
        </div>
      </ElTabPane>
    </MiTabs>
  </div>
</template>

<script setup lang="ts">
import { ElDivider, ElTabPane } from 'element-plus';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useForm } from 'vee-validate';

import { ScanPatientBracelet } from './ScanPatientBracelet';
import { TakePhotoButton } from './TakePhotoButton';
import { SearchByPhotoButton } from './SearchByPhotoButton';
import { getPatientSearchValidationSchema, DocumentTypeIdEnum, TabNameEnum } from '../../model';

import {
  AttachPatientToPolyclinicButton,
  CreatePatientsClinicAttachmentsResource,
} from '~features/patients';
import { useSessionStore, useAppConfigStore } from '~entities/session';
import { EMPTY_VALUE_DASH } from '~shared/config';
import { FormSearchSelectForCreateAppointment, FormSelect, FormTextField } from '~shared/ui/form';
import { MiButton, MiInfoField, MiTabs, MiIcon, MiCard } from '~shared/ui';
import { AnyObject } from '~shared/types';
import {
  PatientSearchResource,
  CreateAppointmentRequestBody,
  PatientSearchResourceNew,
  PatientResource,
  FindPatientByDocumentsRequest,
} from '~shared/api/gen/v2';
import { misApi } from '~shared/api';
import {
  I18nService,
  convertDateToAppFormat,
  joinFullname,
  amplitudeService,
  getBirthdateInDateFormat,
} from '~shared/lib';

const props = defineProps<{
  modalVisible: boolean | undefined;
  patient: Partial<PatientSearchResource> | undefined;
  examinationPlan?: boolean;
}>();

const emit = defineEmits<{
  'update:patient': [v: Partial<PatientSearchResource> | undefined];
  'select': [];
}>();

const { userRole } = storeToRefs(useSessionStore());
const { featureFlags } = storeToRefs(useAppConfigStore());

const isB2gInterface = computed(() => featureFlags.value?.['user.interface'] === 'b2g');
const searchFailed = ref(false);

const passportSerialFieldRef = ref<InstanceType<typeof FormTextField> | null>(null);
const passportNumberFieldRef = ref<InstanceType<typeof FormTextField> | null>(null);
const birthCertificateNumberFieldRef = ref<InstanceType<typeof FormTextField> | null>(null);
const pinflFieldRef = ref<InstanceType<typeof FormTextField> | null>(null);
const numberMedCardFieldRef = ref<InstanceType<typeof FormTextField> | null>(null);
const docsSearchBirthdateFieldRef = ref<InstanceType<typeof FormTextField> | null>(null);

const surnameFieldRef = ref<InstanceType<typeof FormTextField> | null>(null);

const patient = computed({
  get() {
    return props.patient;
  },
  set(v) {
    emit('update:patient', v);
  },
});

const isMyIDIntegration = computed(() => {
  return featureFlags.value?.['clinic.myIDIntegration'] && userRole.value === 'manager';
});

const documentOptions = computed<{ id: DocumentTypeIdEnum; title: string }[]>(() => [
  { id: 'pinfl', title: I18nService.t('Contingent.PINFL') },
  { id: 'uz_passport', title: I18nService.t('Contingent.DocumentTypeName.uz_passport') },
  { id: 'medcard_number', title: 'MED ID' },
  { id: 'birth_certificate', title: I18nService.t('Contingent.DocumentTypeName.metrics') },
]);

const { values, handleSubmit, isSubmitting, meta, errors, resetForm, validate } = useForm<{
  tabId: TabNameEnum;
  documentTypeId: DocumentTypeIdEnum;
  searchParams: FindPatientByDocumentsRequest;
  patientFoundByName_id: number;
  patientFoundByName: PatientSearchResource &
    PatientSearchResourceNew & {
      id: CreateAppointmentRequestBody['user_id'];
    };
}>({
  initialValues: {
    tabId: 'docs-search',
    documentTypeId: 'pinfl',
  },
  validationSchema: getPatientSearchValidationSchema(isMyIDIntegration.value),
});

const clearForm = () => {
  searchFailed.value = false;
  patient.value = undefined;

  resetForm();
};

const currentTabId = computed({
  get() {
    return values.tabId;
  },
  set(value) {
    searchFailed.value = false;
    patient.value = undefined;

    resetForm({
      values: {
        tabId: value,
        documentTypeId: 'pinfl',
      },
    });

    switch (value) {
      case 'docs-search':
        setPinflFieldFocus();
        break;
      case 'without-docs':
        setSurnameFieldFocus();
        sendAmplitudeSetTabWithoutDocs();
    }
  },
});

const searchFailedErrorMessage = computed(() => {
  return isMyIDIntegration.value
    ? 'Patients.PatientNotFoundWarningWithPhoto'
    : 'Patients.PatientNotFoundWarning';
});

const submitDisabled = computed(
  () => Object.keys(errors.value).length > 0 || !meta.value.dirty || isSubmitting.value
);

const handleTryDocumentsSearch = () => {
  currentTabId.value = 'docs-search';
};

const setPatient = (payload: PatientResource) => {
  patient.value = payload;
};

const updatePatientMyidBoxUpdatedAt = (payload: PatientResource) => {
  if (!patient.value) return;
  patient.value.myid_box_updated_at = payload.myid_box_updated_at;
};

const setPassportNumberFieldFocus = () => {
  passportNumberFieldRef.value?.setFocus();
};
const setPinflFieldFocus = () => {
  pinflFieldRef.value?.setFocus();
};
const setDocsSearchBirthdateFieldFocus = () => {
  docsSearchBirthdateFieldRef.value?.setFocus();
};
const setSurnameFieldFocus = () => {
  surnameFieldRef.value?.setFocus();
};

const setBirthCertificateNumberFieldFocus = () => {
  birthCertificateNumberFieldRef.value?.setFocus();
};

const sendAmplitudeChangeDocument = (to?: DocumentTypeIdEnum) => {
  amplitudeService.logEvent('create_appointment_modal.change_document_patient_search', {
    from: values.documentTypeId,
    to: to ?? '',
  });
};

const handleDocumentTypeSelect = (value: { id: DocumentTypeIdEnum; title: string } | undefined) => {
  sendAmplitudeChangeDocument(value?.id);
  resetForm({
    values: {
      tabId: 'docs-search',
      documentTypeId: value?.id,
      searchParams: {
        birthdate: values.searchParams.birthdate,
      },
    },
  });
  patient.value = undefined;
};

const sendAmplitudeSetTabWithoutDocs = () => {
  amplitudeService.logEvent('create_appointment_modal.search_without_documents');
};

const sendAmplitudeSearchPatient = () => {
  amplitudeService.logEvent('create_appointment_modal.search_patient_by_documents', {
    success: (!searchFailed.value).toString(),
  });
};

const setFullName = (item: AnyObject) => {
  return joinFullname({
    name: item.name,
    surname: item.surname,
    patronymic: item.patronymic,
  });
};

const setPatientInfo = (
  val: PatientSearchResource &
    PatientSearchResourceNew & {
      id: CreateAppointmentRequestBody['user_id'];
    }
) => {
  patient.value = {
    id: val?.id,
    surname: val?.surname,
    name: val?.name,
    patronymic: val?.patronymic,
    birthdate: val?.birthdate,
    gender: val?.gender,
    active_attached_clinic: val?.active_attached_clinic,
    active_attached_microdistrict: val?.active_attached_microdistrict,
    active_attached_doctor: val?.active_attached_doctor,
    number_med_card: val?.medcard_number || val?.number_med_card,
    pinfl: val?.pinfl,
  };
};

const handleSubmitForm = handleSubmit(
  async (values) => {
    const payload = values.searchParams;

    if (values.searchParams.birthdate || patient.value?.birthdate) {
      payload.birthdate = getBirthdateInDateFormat(
        values.searchParams.birthdate || patient.value?.birthdate
      );
    }

    try {
      const response = await misApi.gen.v2.patients.findPatientByDocuments(payload);
      patient.value = response?.data.data;
      searchFailed.value = !response?.data;
    } catch (err) {
      searchFailed.value = true;
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      sendAmplitudeSearchPatient();
    }
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);

const handleUpdateSuccessful = async (response: CreatePatientsClinicAttachmentsResource) => {
  patient.value!.active_attached_clinic = response.data.clinic;
  patient.value!.active_attached_microdistrict = response?.data.microdistrict;
  patient.value!.active_attached_doctor = response?.data.doctor;
};

watch(
  () => props.modalVisible,
  (v) => {
    if (!v) {
      currentTabId.value = 'docs-search';
      resetForm({
        values: {
          tabId: 'docs-search',
          documentTypeId: 'pinfl',
        },
      });
    }
  }
);

watch(
  () => values.patientFoundByName,
  (v) => {
    if (values.tabId === 'without-docs') {
      setPatientInfo(v);
    }
  },
  { deep: true }
);

defineExpose({
  validate,
});
</script>

<style lang="scss" src="./index.scss" />
