import { ElNotification } from 'element-plus';

import {
  FillCapacityPayload,
  GetPatronageStatesResponseData,
  PatronagePlanningDto,
  PatronageShortDto,
  PatronageUpdatePayload,
  PatronageUpdateResponse,
  PatronageFullDto,
} from '../types';

import { apiService, MetaDto } from '~shared/api';
import { getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { QueryParams } from '~shared/types';

const urlPath = 'b2g/patronages';

export const getStates = async () => {
  try {
    const response = await apiService.api.instance.get<GetPatronageStatesResponseData>(
      `${urlPath}/states`
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getList = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{
      data: PatronageShortDto[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: urlPath,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getPatronagePlanning = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{
      data: PatronagePlanningDto;
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPath}/planning`,
        query: query,
      })
    );

    return {
      response: response,
      data: response.data.data,
      meta: response.data.meta,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const fillCapacity = async (query: QueryParams, payload: FillCapacityPayload) => {
  try {
    const response = await apiService.api.instance.post<{
      data: PatronagePlanningDto;
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPath}/planning/select`,
        query: query,
      }),
      payload
    );

    return {
      response: response,
      data: response.data.data,
      meta: response.data.meta,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const itemUpdate = async (id: PatronageFullDto['id'], payload: PatronageUpdatePayload) => {
  try {
    const response = await apiService.api.instance.post<{ data: PatronageUpdateResponse }>(
      `${urlPath}/${id}/update`,
      payload
    );

    return {
      response: response,
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getById = async (id: number | string) => {
  try {
    const response = await apiService.api.instance.get<{ data: PatronageFullDto }>(
      `${urlPath}/${id}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
