<template>
  <MainLayout content-class="v-outcome-content" fix-height>
    <MiFilterBox>
      <ElDatePicker
        class="v-doctors-content-header__date"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <MiSelectSearch
        :model-value="expense.value.id"
        v-model:model-object-value="expense.value"
        :fetch-data="misApi.common.expenses.getExpenseTypes"
        :placeholder="$t('Outcome.FlowType')"
        raw-fetch-result
        clearable />

      <UiSelect
        :options="statusOptions"
        v-model="statusSelect.value"
        :placeholder="$t('Common.Status')" />

      <ElInput
        class="v-hospital-header__department"
        v-model="recipient.value"
        :placeholder="$t('Outcome.Recipient')"
        clearable />

      <template #actions>
        <MiButton type="primary" @click="onCreateButtonClick">
          {{ `+ ${$t('Outcome.CreateOutcome')}` }}
        </MiButton>
      </template>
    </MiFilterBox>

    <AccountingOutcomesTable
      :loading="loading"
      :items="items"
      :total="total"
      @approve="onApproveBtnClick"
      @cancel="onCancelBtnClick"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </MainLayout>
</template>

<script lang="ts">
export default {
  name: 'VAccountingOutcome',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ElDatePicker, ElInput } from 'element-plus';
import { ref, computed, watch, reactive } from 'vue';
import { useStore } from 'vuex';

import { MainLayout } from '~widgets/layouts';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { misApi } from '~shared/api';
import { MiFilterBox, MiSelectSearch, MiButton } from '~shared/ui';
import {
  useDatePeriod,
  usePerPage,
  usePage,
  useModel,
  useQuery,
  getMonthPeriod,
} from '~shared/lib';
import { DATE_FORMAT } from '~shared/config';
import { I18nService, compareQueriesThenLoadData } from '~shared/lib';
import { ExpenseStatus } from '~shared/api/common';

import ServiceOutcomeModal from '@/components/accounting/modals/ServiceOutcomeModal/index.vue';
import AccountingOutcomesTable from '@/components/accounting/tables/AccountingOutcomesTable/index.vue';
import UiSelect from '@/components/ui/UiSelect/index.vue';

const statusOptions = computed<{ label: string; value: ExpenseStatus }[]>(() => [
  {
    label: I18nService.t('Income.Statuses.accepted'),
    value: 'accepted',
  },
  { label: I18nService.t('Income.Statuses.canceled'), value: 'canceled' },
  { label: I18nService.t('Income.Statuses.created'), value: 'created' },
]);

const statusSelect = reactive<{
  value: string;
}>({ value: '' });

const store = useStore();
const loading = ref(false);
const page = usePage();
const perPage = usePerPage();
const recipient = useQuery({ field: 'recipient' });

const date = useDatePeriod(getMonthPeriod());

const expense = useModel({
  fieldNames: {
    id: 'expense_id',
    name: 'expense_name',
  },
});

const total = computed(() => store.state.accountingOutcomesTable.total);
const items = computed(() => store.state.accountingOutcomesTable.data);
const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  expense_type_id: expense.value.id,
  recipient: recipient.value,
  status: statusSelect.value,
}));

const getExpenses = async () => {
  loading.value = true;
  const response = await misApi.common.expenses.getItems(query.value);

  if (response?.data) {
    const { data, meta } = response.data;

    store.dispatch('accountingOutcomesTable/setData', {
      items: data,
      total: meta.total,
      overwriteDataState: true,
    });
  }

  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getExpenses,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);

const onCreateButtonClick = async () => {
  const action = await store.dispatch('modalAndDrawer/openModal', ServiceOutcomeModal);

  if (!(action instanceof GlobalModalClose)) {
    getExpenses();
  }
};

const onApproveBtnClick = async (id: number) => {
  await store.dispatch('accountingOutcomesTable/setItemLoading', {
    id: id,
    loading: { accept: true },
  });
  const response = await misApi.common.expenses.acceptItem(id);
  if (response?.data?.data) {
    store.dispatch('accountingOutcomesTable/editItem', response.data.data);
  }
  await store.dispatch('accountingOutcomesTable/setItemLoading', {
    id: id,
    loading: { accept: false },
  });
};

const onCancelBtnClick = async (id: number) => {
  await store.dispatch('accountingOutcomesTable/setItemLoading', {
    id: id,
    loading: { cancel: true },
  });
  const response = await misApi.common.expenses.cancelItem(id);
  if (response?.data?.data) {
    store.dispatch('accountingOutcomesTable/editItem', response.data.data);
  }
  await store.dispatch('accountingOutcomesTable/setItemLoading', {
    id: id,
    loading: { cancel: false },
  });
};
</script>

<style lang="scss"></style>
