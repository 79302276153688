<template>
  <div class="service-products-table-filling-form">
    <div class="service-products-table-filling-form__field-group">
      <FormSearchSelectField
        field-name="product_id"
        field-object-name="product"
        :fetch-data="misApi.common.product.getItems"
        :placeholder="$t('Income.SearchGoods')"
        :label="$t('Services.ExpendableMaterials')"
        show-create-option
        @create="$emit('product:create', $event)" />
      <!-- <FormTextField
        field-name="product.price"
        :placeholder="$t('Common.Price')"
        :in-column="false"
        mask="##########" /> -->
      <FormNumberField
        field-name="count"
        :placeholder="$t('Analytics.Quantity')"
        :in-column="false" />
      <div />
    </div>
    <MiButton
      class="service-products-table-filling-form__add-button"
      type="primary"
      :disabled="submitDisabled"
      @click="addProduct"
      >{{ `+ ${$t('Common.Add')}` }}
    </MiButton>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ServiceProductsTableFillingForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useForm } from 'vee-validate';
import { object, number } from 'yup';
import { computed } from 'vue';

import { I18nService } from '~shared/lib';
import { MiButton } from '~shared/ui';
import { misApi } from '~shared/api';

import FormNumberField from '@/components/common/form/ui/FormNumberField/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';
// import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';

const emit = defineEmits(['table:add', 'product:create']);
const MAX_AMOUNT = 9999999;

const validationSchema = object({
  product_id: number().nullable().required(I18nService.t('Validation.RequiredField')),
  count: number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .min(1, I18nService.t('Validation.GreaterZero'))
    .required(I18nService.t('Validation.RequiredField'))
    .test(
      'count',
      `${I18nService.t('Validation.MaxValue')} ${MAX_AMOUNT}`,
      (value) => value <= MAX_AMOUNT
    ),
  // product: object({
  //   price: string().nullable().required(I18nService.t('Validation.RequiredField')),
  // }),
});

const { errors, isSubmitting, handleSubmit, meta, resetForm } = useForm({
  validationSchema,
});

const submitDisabled = computed(
  () => Object.keys(errors.value).length > 0 || isSubmitting.value || !meta.value.dirty
);

const addProduct = handleSubmit(
  (values) => {
    emit('table:add', values);
    resetForm();
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);
</script>

<style lang="scss" src="./index.scss" />
