import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const PATIENT_LIST_ROUTE = {
  name: 'PatientList',
  path: ROUTE_PATH.patients.list,
  component: () => import('./ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'manager', 'nurse']),
  ],
  meta: {
    title: 'Common.Patients',
  },
};
