<template>
  <MainLayout content-class="v-suppliers-content" fix-height>
    <MiFilterBox>
      <template #actions>
        <MiButton type="primary" @click="onCreateButtonClick"
          >+ {{ $t('Supplier.AddSupplier') }}
        </MiButton>
      </template>
    </MiFilterBox>
    <SuppliersTable
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :items="items"
      :total="total"
      @modal:open="onEditButtonClick" />
  </MainLayout>
</template>

<script lang="ts">
export default {
  name: 'VSuppliers',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { MainLayout } from '~widgets/layouts';
import { MiButton, MiFilterBox } from '~shared/ui';
import { usePage, usePerPage, compareQueriesThenLoadData } from '~shared/lib';
import { misApi } from '~shared/api';
import { SupplierDto } from '~shared/api/common';

import SupplierCrudModal from '@/components/warehouse/modals/SupplierCrudModal/index.vue';
import SuppliersTable from '@/components/warehouse/tables/SuppliersTable/index.vue';

const store = useStore();
const loading = ref(false);
const perPage = usePerPage();
const page = usePage();

const total = computed(() => store.state.suppliers.total);
const items = computed(() => store.state.suppliers.data);
const query = computed(() => ({
  per_page: perPage.value,
  page: page.value,
}));

const getSuppliers = async () => {
  loading.value = true;
  const response = await misApi.common.supplier.getItems(query.value);
  if (response?.data) {
    const { data, meta } = response.data;
    store.dispatch('suppliers/setData', {
      items: data,
      total: +meta.total,
      overwriteDataState: true,
    });
  }
  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getSuppliers,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);

const onCreateButtonClick = async () => {
  await store.dispatch('modalAndDrawer/openModal', SupplierCrudModal);
};

const onEditButtonClick = async (payload: SupplierDto) => {
  await store.dispatch('modalAndDrawer/openModal', {
    component: SupplierCrudModal,
    payload: {
      formData: payload,
    },
  });
};
</script>

<style lang="scss"></style>
