<template>
  <MiButton type="primary" plain :disabled="disabled" @click="startScan">
    <template #icon> <MiIcon icon="SCAN" /> </template>
    <div v-if="!onlyIcon">{{ text }}</div>
    <MiScanInputModal
      :model-value="modalIsOpen"
      :text="isLoading ? $t('Common.Loading') : null"
      ref="scanModal"
      @successful-scan="scanHandler"
      @update:model-value="endScan" />
  </MiButton>
</template>

<script>
import axios from 'axios';

import { MiButton, MiIcon, MiScanInputModal } from '~shared/ui';

import { Order } from '@/models/laboratory/Order.model';

export default {
  name: 'ScanBiomaterialBarCode',
  components: { MiScanInputModal, MiButton, MiIcon },
  emits: ['successful-scan'],
  props: {
    disabled: Boolean,
    onlyIcon: Boolean,
  },
  data() {
    return {
      isScanning: false,
      isLoading: false,
      modalIsOpen: false,
    };
  },
  computed: {
    text() {
      if (this.isLoading) return this.$t('Common.Loading');
      if (this.isScanning) return this.$t('Common.Scanning');

      return this.$t('Common.ScanBarCode');
    },
  },

  methods: {
    startScan() {
      if (this.isScanning) return;
      this.isScanning = true;
      this.modalIsOpen = true;
    },
    endScan() {
      this.isScanning = false;
      this.isLoading = false;
      this.modalIsOpen = false;
    },

    async scanHandler(data) {
      if (this.isLoading) return;
      this.isLoading = true;

      try {
        const order = await Order.getByBiomaterialBarCodePayload(data);
        this.$emit('successful-scan', order);
        this.endScan();
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
        this.$refs.scanModal.reset();
      }

      this.isLoading = false;
    },
  },
};
</script>
