<template>
  <ol class="printer-document-content-block__list">
    <li v-for="item in block.value" :key="item">{{ item }}</li>
  </ol>
</template>

<script>
// eslint-disable-next-line import/no-internal-modules
import { PrinterDocumentContentBlock } from '../../../models/PrinterDocumentContentBlock.model';

export default {
  name: 'PrinterDocumentContentBlockList',
  props: {
    block: [PrinterDocumentContentBlock, Object],
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
