import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const TREATMENT_COURSE_ROUTE = {
  name: 'TreatmentCourse',
  path: ROUTE_PATH.treatmentCourse.rawPath,
  component: () => import('./ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['doctor', 'director', 'nurse']),
  ],
  meta: {
    title: 'Common.CourseOfTreatment',
  },
  props: true,
};
