import { defineStore } from 'pinia';
import { ref } from 'vue';

import { misApi, PatientGroupType, PatronageState } from '~shared/api';
import { I18nService } from '~shared/lib';

export const usePatientGroupStore = defineStore('patientGroupStore', () => {
  const patientGroupType = ref<PatientGroupType>();
  const patientStates = ref<PatronageState['id'][]>();
  const currentUserId = ref<number | null>(null);

  const patientGroupTypeOptions: { id: PatientGroupType; title: string }[] = [
    { id: 'first_group', title: I18nService.t('Contingent.PatientGroups.first_group') },
    { id: 'second_group', title: I18nService.t('Contingent.PatientGroups.second_group') },
    { id: 'third_group', title: I18nService.t('Contingent.PatientGroups.third_group') },
    { id: 'fourth_group', title: I18nService.t('Contingent.PatientGroups.fourth_group') },
  ];

  const patientStateOptions = ref<PatronageState[]>([]);

  const handleChangePatientGroup = async (selectedValue: PatientGroupType) => {
    await misApi.g2g.patients.updateGroup({ userId: currentUserId.value, group: selectedValue });
  };

  const handleChangePatientState = async (selectedValue: number[]) => {
    await misApi.g2g.patients.updateStates({
      userId: currentUserId.value,
      user_state_ids: selectedValue,
    });
  };

  const setCurrentUserId = (id: number) => {
    currentUserId.value = id;
  };

  const getPatronagesStateList = async () => {
    const response = await misApi.g2g.patronages.getStates();
    patientStateOptions.value = response?.data ?? [];
    return response?.data;
  };

  const getPatientStates = async () => {
    const response = await misApi.g2g.patients.getStates({ userId: currentUserId.value });
    patientStates.value = response?.data?.map((item) => item.id);
  };

  const setPatientGroupType = (value: PatientGroupType | null) => {
    if (value) patientGroupType.value = value;
  };

  return {
    currentUserId,
    patientGroupType,
    patientStates,
    patientGroupTypeOptions,
    patientStateOptions,
    handleChangePatientGroup,
    setCurrentUserId,
    getPatronagesStateList,
    handleChangePatientState,
    getPatientStates,
    setPatientGroupType,
  };
});
