import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const ANALYTIC_DIRECTIONS_ROUTE = {
  name: 'AnalyticDirections',
  path: ROUTE_PATH.directions,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['doctor', 'director'])],
  meta: {
    title: 'Analytics.Directions',
  },
};
