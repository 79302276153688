import { ElNotification } from 'element-plus';

import { ExpenseDto, ExpenseRequest, ExpenseTypeDto, ExpenseTypeRequest } from '../types';

import { apiService, MetaDto } from '~shared/api';
import { joinQueryParams, getApiErrorMessage } from '~shared/lib';
import { QueryParams } from '~shared/types';

const urlPrefix = 'expenses';

export const getItems = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{ data: ExpenseDto[]; meta: MetaDto }>(
      joinQueryParams({
        url: urlPrefix,
        query,
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getItemById = async (id: number) => {
  try {
    const response = await apiService.api.instance.get<{ data: ExpenseDto }>(`${urlPrefix}/${id}`);

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createItem = async (payload: ExpenseRequest) => {
  try {
    const response = await apiService.api.instance.post<{ data: ExpenseDto }>(
      `${urlPrefix}/create`,
      payload
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const acceptItem = async (id: number) => {
  try {
    const response = await apiService.api.instance.put<{ data: ExpenseDto }>(
      `${urlPrefix}/${id}/accepted`
    );
    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const cancelItem = async (id: number) => {
  try {
    const response = await apiService.api.instance.put<{ data: ExpenseDto }>(
      `${urlPrefix}/${id}/canceled`
    );
    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getExpenseTypes = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{ data: ExpenseTypeDto[] }>(
      joinQueryParams({
        url: `${urlPrefix}/types`,
        query,
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createExpenseType = async (payload: ExpenseTypeRequest) => {
  try {
    const response = await apiService.api.instance.post<{ data: ExpenseTypeDto }>(
      `${urlPrefix}/types/create`,
      payload
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
