import { ElNotification } from 'element-plus';

import { SupplierDto } from '../types';

import { apiService, MetaDto } from '~shared/api';
import { joinQueryParams, getApiErrorMessage, clearObjectEmptyValues } from '~shared/lib';
import { QueryParams } from '~shared/types';

const urlPrefix = 'suppliers';

export const getItems = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{ data: SupplierDto[]; meta: MetaDto }>(
      joinQueryParams({
        url: urlPrefix,
        query: clearObjectEmptyValues(query),
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getItemById = async (id: number) => {
  try {
    const response = await apiService.api.instance.get<{ data: SupplierDto[] }>(
      `${urlPrefix}/${id}`
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createItem = async (payload: SupplierDto) => {
  try {
    const response = await apiService.api.instance.post<{ data: SupplierDto[] }>(
      `${urlPrefix}/create`,
      payload
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const updateItem = async (id: number, payload: SupplierDto) => {
  try {
    const response = await apiService.api.instance.put<{ data: SupplierDto[] }>(
      `${urlPrefix}/${id}/update`,
      payload
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const deleteItem = async (id: number) => {
  try {
    const response = await apiService.api.instance.delete<{ data: SupplierDto[] }>(
      `${urlPrefix}/${id}/delete`
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
