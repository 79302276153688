import { PatientGroupType, DisabilityGroup, Gender } from '~shared/api';
import { CVDScreeningRiskGroupEnum, EndocrineScreeningRiskGroupEnum } from '~shared/api/gen/v2';
import { I18nService } from '~shared/lib';

export const SEARCH_DEBOUNCE_DELAY = 1000;

export type AgeRangeEnum = 'null-1' | '1-6' | '6-15' | '15-40' | '40-50' | '50-66' | '65-null';

export const ageRangeOptions: { value: AgeRangeEnum; label: string }[] = [
  { value: 'null-1', label: I18nService.t('Patients.AgeOptions.0-12_months') },
  { value: '1-6', label: I18nService.t('Patients.AgeOptions.1-5_years') },
  { value: '6-15', label: I18nService.t('Patients.AgeOptions.6-14_years') },
  { value: '15-40', label: I18nService.t('Patients.AgeOptions.15-39_years') },
  { value: '40-50', label: I18nService.t('Patients.AgeOptions.40-49_years') },
  { value: '50-66', label: I18nService.t('Patients.AgeOptions.50-65_years') },
  { value: '65-null', label: I18nService.t('Patients.AgeOptions.65+_years') },
];

export const patientGroupOptions: { value: PatientGroupType | 'undefined'; label: string }[] = [
  { value: 'undefined', label: I18nService.t('Common.NotSet') },
  { value: 'first_group', label: I18nService.t('Contingent.PatientGroups.first_group') },
  { value: 'second_group', label: I18nService.t('Contingent.PatientGroups.second_group') },
  { value: 'third_group', label: I18nService.t('Contingent.PatientGroups.third_group') },
  { value: 'fourth_group', label: I18nService.t('Contingent.PatientGroups.fourth_group') },
];

export const disabilityOptions: { value: DisabilityGroup | 'undefined'; label: string }[] = [
  { value: 'undefined', label: I18nService.t('Common.NotSet') },
  { value: 'first_group', label: I18nService.t('Patients.Degrees.first_group') },
  { value: 'second_group', label: I18nService.t('Patients.Degrees.second_group') },
  { value: 'third_group', label: I18nService.t('Patients.Degrees.third_group') },
];

export const genderOptions: { value: Gender | 'undefined'; label: string }[] = [
  { value: 'undefined', label: I18nService.t('Common.NotSet') },
  { value: 'man', label: I18nService.t('Common.Genders.man') },
  { value: 'woman', label: I18nService.t('Common.Genders.woman') },
];

export const pregnancyOptions: { value: number; label: string }[] = [
  { value: 1, label: I18nService.t('Patients.Pregnants') },
  { value: 0, label: I18nService.t('Patients.NonPregnant') },
];

export const cvdRiskGroupsOptions: { value: CVDScreeningRiskGroupEnum; label: string }[] = [
  {
    value: 'low',
    label: I18nService.t('Analytics.CVD5'),
  },
  {
    value: 'moderate',
    label: I18nService.t('Analytics.5CVD10'),
  },
  {
    value: 'medium',
    label: I18nService.t('Analytics.10CVD20'),
  },
  {
    value: 'high',
    label: I18nService.t('Analytics.20CVD30'),
  },
  {
    value: 'very_high',
    label: I18nService.t('Analytics.30CVD'),
  },
];

export const endocrineRiskGroupsOptions: {
  value: EndocrineScreeningRiskGroupEnum;
  label: string;
}[] = [
  {
    value: 'low',
    label: I18nService.t('Analytics.LowRisk'),
  },
  {
    value: 'medium',
    label: I18nService.t('Analytics.ThereIsRisk'),
  },
  {
    value: 'high',
    label: I18nService.t('Analytics.IncreasedRisk'),
  },
  {
    value: 'very_high',
    label: I18nService.t('Analytics.HighRisk'),
  },
];
