<template>
  <MainLayout content-class="v-income-content" fix-height>
    <MiFilterBox>
      <ElDatePicker
        class="v-doctors-content-header__date"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <MiSelectSearch
        class="v-income-content__products"
        v-model="productIds.value"
        v-model:model-object-value="products"
        :fetch-data="misApi.common.product.getItems"
        :placeholder="$t('Income.SearchGoods')"
        raw-fetch-result
        multiple
        clearable />

      <MiSelectSearch
        :model-value="supplier.value.id"
        v-model:model-object-value="supplier.value"
        :fetch-data="misApi.common.supplier.getItems"
        :placeholder="$t('Income.Supplier')"
        raw-fetch-result
        clearable />

      <template #actions>
        <MiButton type="primary" @click="onCreateButtonClick">
          {{ `+ ${$t('Income.CreateIncome')}` }}
        </MiButton>
      </template>
    </MiFilterBox>
    <IncomesTable
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :items="items"
      :total="total"
      @modal:open="onAcceptButtonClick"
      @modal:cancel="onCanceledButtonClick" />
  </MainLayout>
</template>

<script lang="ts">
export default {
  name: 'VIncome',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import axios from 'axios';
import { ElNotification, ElDatePicker } from 'element-plus';
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';

import { MainLayout } from '~widgets/layouts';
import { MiButton, MiFilterBox, MiSelectSearch } from '~shared/ui';
import {
  useQuery,
  useDatePeriod,
  usePage,
  usePerPage,
  useModel,
  compareQueriesThenLoadData,
  getMonthPeriod,
} from '~shared/lib';
import { DATE_FORMAT } from '~shared/config';
import { I18nService } from '~shared/lib';
import { IncomeDto, ProductDto } from '~shared/api/common';
import { misApi } from '~shared/api';

import IncomeCrudModal from '@/components/warehouse/modals/IncomeCrudModal/index.vue';
import IncomesTable from '@/components/warehouse/tables/IncomesTable/index.vue';

const loading = ref(false);
const store = useStore(); // TODO типизировать store
const perPage = usePerPage(); // TODO типизировать usePerPage
const page = usePage(); // TODO типизировать usePage

const supplier = useModel({
  fieldNames: {
    id: 'supplier_id',
    name: 'supplier_name',
  },
});

const productIds = useQuery({ field: 'product_ids', valueIsNumberArray: true });
const date = useDatePeriod(getMonthPeriod()); // TODO типизировать useDatePeriod

const products = ref<ProductDto[]>([]);
const total = computed(() => store.state.incomes.total);
const items = computed(() => store.state.incomes.data);
const query = computed(() => ({
  per_page: perPage.value,
  page: page.value,
  start_at: date.value[0],
  end_at: date.value[1],
  // status: status.value,
  supplier_id: supplier.value.id,
  product_ids: productIds.value,
}));

const getIncomes = async () => {
  loading.value = true;
  try {
    const response = await misApi.common.income.getItems(query.value);
    if (response?.data) {
      const { data, meta } = response.data;
      store.dispatch('incomes/setData', {
        items: data,
        total: +meta.total,
        overwriteDataState: true,
      });
    }
  } catch (err) {
    ElNotification({
      type: 'error',
      title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
    });
  } finally {
    loading.value = false;
  }
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getIncomes,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);

const onCreateButtonClick = async () => {
  await store.dispatch('modalAndDrawer/openModal', IncomeCrudModal);
};

const onAcceptButtonClick = async (payload: IncomeDto) => {
  await store.dispatch('incomes/setItemLoading', {
    id: payload.id,
    loading: { accept: true },
  });
  const response = await misApi.common.income.getItemById(payload.id);
  store.dispatch('modalAndDrawer/openModal', {
    component: IncomeCrudModal,
    payload: {
      formData: response?.data.data,
    },
  });
  await store.dispatch('incomes/setItemLoading', { id: payload.id, loading: undefined });
};

const onCanceledButtonClick = async (payload: Omit<IncomeDto, 'id'> & { id: number }) => {
  await store.dispatch('incomes/setItemLoading', {
    id: payload.id,
    loading: { cancel: true },
  });
  const response = await misApi.common.income.canceledItem(payload.id);
  await store.dispatch('incomes/editItem', response?.data.data);
  await store.dispatch('incomes/setItemLoading', { id: payload.id, loading: undefined });
};
</script>

<style lang="scss">
.v-income-content {
  .layout-content-header-main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
