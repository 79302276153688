<template>
  <div class="lang-select">
    <ElDropdown @command="setLocale">
      <div class="lang-select-dropdown">
        <MiIcon class="lang-select__icon" v-if="currentLocale?.icon" :icon="currentLocale.icon" />
        <span class="lang-select__text">{{ currentLocale?.label }}</span>
      </div>

      <template #dropdown>
        <ElDropdownMenu>
          <ElDropdownItem :command="locale" v-for="locale of locales" :key="locale.value">
            <div class="lang-select-dropdown">
              <MiIcon class="lang-select__icon" v-if="locale.icon" :icon="locale.icon" />
              <span class="lang-select__text">{{ locale.label }}</span>
            </div>
          </ElDropdownItem>
        </ElDropdownMenu>
      </template>
    </ElDropdown>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { ElDropdown, ElDropdownMenu, ElDropdownItem } from 'element-plus';
import { useRouter } from 'vue-router';

import { useLocaleStore } from '../model/store';

import { MiIcon, ICON_NAME } from '~shared/ui';
import { LocaleIdKey, LocaleLabelKey } from '~shared/types';

type LocaleItem = {
  icon: keyof typeof ICON_NAME;
  value: LocaleIdKey;
  label: LocaleLabelKey;
};

const localeStore = useLocaleStore();

const router = useRouter();

const locales = computed((): LocaleItem[] => [
  {
    icon: 'UZBEKISTAN',
    value: 'uz',
    label: `О'zbekcha`,
  },
  {
    icon: 'RUSSIAN',
    value: 'ru',
    label: 'Русский',
  },
  {
    icon: 'ENGLISH',
    value: 'en',
    label: 'English',
  },
]);

const currentLocale = computed(() => {
  const currentLang = localeStore.currentLang;
  return locales.value.find((ell) => ell.value == currentLang);
});

const setLocale = (locale: LocaleItem) => {
  if (localeStore.currentLang !== locale.value) {
    router.go(0);
  }
  localeStore.setLocale(locale.value);
};
</script>

<style scoped>
.el-dropdown-menu {
  padding: 2px !important;
}
</style>
<style lang="scss" src="./index.scss" />
