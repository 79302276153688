<template>
  <MainLayout fix-height>
    <EventCalendar
      class="v-dashboard-content__calendar"
      v-model:type="type.value"
      v-model:date="date.value"
      :loading="loading.calendar"
      :month-data="dataForMonth"
      :day-data="dataForDay"
      @click:event="goToAppointment"
      @click:day="dayClickHandler">
      <template #actions>
        <MiSelectSearch
          class="v-dashboard-content__doctor-select"
          v-if="!userIsDoctor"
          :model-value="doctor.value.id"
          v-model:model-object-value="doctor.value"
          :fetch-data="misApi.common.doctors.getList"
          :placeholder="$t('Appointments.SelectDoctor')"
          label-key="name"
          raw-fetch-result
          clearable />
      </template>
    </EventCalendar>
  </MainLayout>
</template>

<script>
import groupBy from 'lodash.groupby';
import noop from 'lodash.noop';

import { getDaysInMonth, resetDaysInISOString, dateAppFormatToISOString } from './lib';

import { APPOINTMENT_ROUTE } from '~pages/appointments';
import { APPOINTMENTS_ACTIVE_ROUTE } from '~pages/appointments';
import { MainLayout } from '~widgets/layouts';
import { useSessionStore } from '~entities/session';
import { MiSelectSearch } from '~shared/ui';
import {
  useQuery,
  useGetDataByInterval,
  useUser,
  compareQueriesThenLoadData,
  ISOStringToDateAppFormat,
  I18nService,
} from '~shared/lib';
import { misApi } from '~shared/api';

import { EventCalendarEvent } from '@/components/EventCalendar/Event/EventCalendarEvent.model';
import { EVENT_CALENDAR_TYPES } from '@/components/EventCalendar/index.enum';
import EventCalendar from '@/components/EventCalendar/index.vue';
import { Appointment } from '@/models/appointment/Appointment.model';

export default {
  name: 'VDashboard',
  components: { EventCalendar, MainLayout, MiSelectSearch },
  setup: () => ({
    getDataByInterval: noop,

    type: useQuery({
      field: 'calendar_type',
      defaultValue: EVENT_CALENDAR_TYPES.MONTH,
      routerPush: true,
    }),
    date: useQuery({
      field: 'calendar_date',
      defaultValue: new Date().toISOString(),
      formatter: (val) => new Date(val).toISOString(),
    }),

    doctor: useUser({
      fieldNames: {
        id: 'doctor_id',
        name: 'doctor_name',
      },
    }),

    misApi,
  }),
  data() {
    return {
      loading: {
        calendar: false,
      },
      dataForMonth: {},
      dataForDay: [],
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },

    userIsDoctor() {
      return this.user?.role === 'doctor';
    },

    daysInMonth() {
      return getDaysInMonth(this.startAt);
    },
    startAt() {
      const forMonthType = resetDaysInISOString(this.date.value);

      return this.type.value === EVENT_CALENDAR_TYPES.MONTH ? forMonthType : this.date.value;
    },
    endAt() {
      const forMonthType = this.startAt.replace(
        /(\d\d\d\d)-(\d\d)-(\d\d)T/,
        (str, year, month) => `${year}-${month}-${this.daysInMonth}T`
      );

      return this.type.value === EVENT_CALENDAR_TYPES.MONTH ? forMonthType : this.date.value;
    },

    doctorFromRoute() {
      return this.doctor.value.id && this.doctor.value.name ? { ...this.doctor.value } : null;
    },

    watchers() {
      return {
        type: this.type.value,
        date: this.date.value,
        doctorId: this.doctor.value.id,
      };
    },
  },

  watch: {
    watchers: {
      handler(value, oldValue) {
        compareQueriesThenLoadData({
          query: value,
          oldQuery: oldValue,
          getData: this.getDataByInterval,
        });
      },
      deep: true,
    },
  },

  methods: {
    getDataByCalendarType() {
      this.type.value === EVENT_CALENDAR_TYPES.MONTH
        ? this.getDataForMonthType()
        : this.getDateForDayType();
    },

    // использую I18nService - почему-то при переходе на другую страницу watcher всё равно отрабатывает до демонтирования компонента
    async getDataForMonthType() {
      this.loading.calendar = true;
      const { data } = await Appointment.getStatistic({
        startAt: ISOStringToDateAppFormat(this.startAt, { withTime: false, fullYear: false }),
        endAt: ISOStringToDateAppFormat(this.endAt, { withTime: false, fullYear: false }),
        doctorsId: this.userIsDoctor ? this.user.doctor_id : this.doctor.value.id || null,
      });

      this.dataForMonth = {};
      Object.keys(data.data).forEach((date) => {
        this.dataForMonth[dateAppFormatToISOString(date).split('T')[0]] = [
          { title: I18nService.tc('Appointments.AppointmentsTc', data.data[date].count) },
        ];
      });
      this.loading.calendar = false;
    },

    async getDateForDayType() {
      this.loading.calendar = true;
      /** @type { {data: Array<Appointment>} } */
      const { data } = await Appointment.find({
        page: 1,
        per_page: 999,
        query_field: 'doctor_id',
        query_type: 'IN',
        search: this.userIsDoctor ? this.user.doctor_id : this.doctor.value.id || null,
        start_at: ISOStringToDateAppFormat(this.startAt, { withTime: false, fullYear: false }),
        end_at: ISOStringToDateAppFormat(this.endAt, { withTime: false, fullYear: false }),
        not_canceled: true,
      });
      const excludedLiveQueue = data.data.filter((appointment) => appointment.start_at);

      const groups = groupBy(excludedLiveQueue, 'doctor_id');
      this.dataForDay = Object.keys(groups).map((doctorId) => ({
        column: {
          id: doctorId,
          title: groups[doctorId][0].doctor.name,
        },
        data: groups[doctorId].map(
          (appointment) =>
            new EventCalendarEvent({
              title: this.$t('Appointments.Appointment'),
              description: appointment.patient?.name,
              startAt: appointment.start_at,
              endAt: appointment.end_at,
              payload: appointment,
            })
        ),
      }));
      this.loading.calendar = false;
    },

    goToAppointment(event) {
      this.$router.push({
        name: APPOINTMENT_ROUTE.name,
        params: {
          id: event.payload.id,
        },
      });
    },

    dayClickHandler(date) {
      this.$router.push({
        name: APPOINTMENTS_ACTIVE_ROUTE.name,
        query: {
          doctor_id: this.doctor.value.id,
          doctor_name: this.doctor.value.name,
          start_at: ISOStringToDateAppFormat(date),
          end_at: ISOStringToDateAppFormat(date),
        },
      });
    },
  },

  mounted() {
    this.getDataByInterval = useGetDataByInterval(this.getDataByCalendarType);
    this.getDataByInterval();
  },
};
</script>

<style lang="scss" src="./index.scss" />
