<template>
  <ElConfigProvider size="large" :locale="lang">
    <RouterView />
    <GlobalModalAndDrawer />
  </ElConfigProvider>

  <MiSvgSprite />
</template>

<script lang="ts" setup>
import cloneDeep from 'lodash.clonedeep';
import { onBeforeMount, computed, watch } from 'vue';
import { ElConfigProvider } from 'element-plus';
import { Language } from 'element-plus/lib/locale';
import { RouterView } from 'vue-router';
import { useRouter } from 'vue-router';
import ru from 'element-plus/lib/locale/lang/ru';
import en from 'element-plus/lib/locale/lang/en';
import uz from 'element-plus/lib/locale/lang/uz-uz';
import { Locale } from 'vue-i18n';
import { storeToRefs } from 'pinia';

import { setFeaturesFlags } from './providers';

import { GlobalModalAndDrawer } from '~widgets/GlobalModalAndDrawer';
import { useLocaleStore } from '~features/LangSelect';
import { useSessionStore, useAppConfigStore } from '~entities/session';
import { misApi, apiService } from '~shared/api';
import { API_LOGOUT_EMIT } from '~shared/config';
import { MiSvgSprite } from '~shared/ui';
import {
  I18nService,
  EmitterService,
  DeviceService,
  globalRouter,
  authStorageService,
  amplitudeService,
  loadHelpDeskEddyChatWidget,
  removeOldHelpDeskEddyChatWidget,
} from '~shared/lib';

globalRouter.router = useRouter();
const appConfigStore = useAppConfigStore();
const localeStore = useLocaleStore();

const { logout, checkAndSetUserAndTokenFromClientStorage, setUser } = useSessionStore();
const { user } = storeToRefs(useSessionStore());
const { currentLang } = storeToRefs(localeStore);

const correctUzbLocalElPlus = cloneDeep(uz);
correctUzbLocalElPlus.el.select.placeholder = 'Tanlang';

const langByLocale: Record<Locale, Language> = {
  ru,
  en,
  uz: correctUzbLocalElPlus,
};

const lang = computed(() => {
  const locale = I18nService.getLocale();
  return langByLocale[locale];
});

const addGlobalEventListeners = () => {
  EmitterService.on(API_LOGOUT_EMIT, logout);
};

const getMe = async () => {
  const accessToken = authStorageService.getAccessToken();
  const accessTokenPurpose = authStorageService.getAccessTokenPurpose();

  if (!accessToken || accessTokenPurpose === 'roleSelection') return;

  const response = await misApi.g2g.users.getUser();

  if (response?.data) {
    setUser(response.data);
  }
};

const getFeatureFlags = async () => {
  const accessToken = authStorageService.getAccessToken();
  const accessTokenPurpose = authStorageService.getAccessTokenPurpose();

  if (!accessToken || accessTokenPurpose === 'roleSelection') return;

  appConfigStore.getFeatureFlags();
};

watch(
  () => appConfigStore.featureFlags,
  (v) => {
    if (v) {
      setFeaturesFlags(v);
    }
  },
  { deep: true }
);

onBeforeMount(() => {
  // Проверяем наличие deviceId в localStorage, записываем в headers, генерируем новый если отсутствует, сохраняем в localStorage
  DeviceService.checkAndSetDeviceId();
  apiService.setDefaultDeviceId(DeviceService.getDeviceId() as string);

  I18nService.setLocaleFromStorage();
  checkAndSetUserAndTokenFromClientStorage();

  // TODO: добавить проверку на существование сохранённого пользователя
  getMe();
  getFeatureFlags();

  addGlobalEventListeners();
  amplitudeService.init(authStorageService.getUser()?.id);
});

watch(
  () => user.value,
  (newValue, oldValue) => {
    if (newValue && !oldValue) {
      // TODO: заменить setTimeout
      setTimeout(() => {
        loadHelpDeskEddyChatWidget(newValue, currentLang.value);
      }, 1000);
    } else if (newValue === null) {
      removeOldHelpDeskEddyChatWidget();
    }
  },
  { immediate: true }
);
</script>

<style src="./index.scss" lang="scss" />
