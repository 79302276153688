import { ROUTE_PATH } from '~shared/config';
import { onlyUserHasRoleMiddleware, onlyUserIsAuthMiddleware } from '~shared/lib';

export const REIMBURSMENT_RECIPE_LIST_ROUTE = {
  name: 'ReimbursmentRecipeList',
  path: ROUTE_PATH.reimbursement.recipeList,
  component: () => import('./ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['prescription-reviewer', 'director']),
  ],
  meta: {
    title: 'Recipes.Recipes',
  },
};
