import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { ContactInfoDto, CreateContactInfoPayload, misApi } from '~shared/api';
import { AnyObject } from '~shared/types';

export const usePatientContactInfoStore = defineStore('patientContactInfoStore', () => {
  const data = ref<ContactInfoDto[]>([]);
  const tableLoading = ref(false);
  const visible = ref(false);
  const modalLoading = ref(false);
  const currentContactInfo = ref<ContactInfoDto | AnyObject>({});
  const currentUserId = ref<number>();

  const editMode = computed(() => !!currentContactInfo.value.id);

  const fetchContactInfo = async () => {
    if (!currentUserId.value) {
      return;
    }

    tableLoading.value = true;
    const response = await misApi.g2g.contactInformations.getList({ userId: currentUserId.value });

    if (response?.data.data) {
      data.value = response?.data.data;
    }

    tableLoading.value = false;
  };

  const createContactInfo = async (payload: CreateContactInfoPayload) => {
    if (!currentUserId.value) {
      return;
    }

    modalLoading.value = true;
    const response = await misApi.g2g.contactInformations.create({
      ...payload,
      userId: currentUserId.value,
    });
    visible.value = false;
    modalLoading.value = false;
    return response;
  };

  const updateContactInfo = async (payload: ContactInfoDto) => {
    modalLoading.value = true;
    const response = await misApi.g2g.contactInformations.update(payload);
    visible.value = false;
    modalLoading.value = false;
    return response;
  };

  const removeContactInfo = async (payload: { id: number }) => {
    modalLoading.value = true;
    const response = await misApi.g2g.contactInformations.remove(payload);
    visible.value = false;
    modalLoading.value = false;
    return response;
  };

  const setCurrentContactInfo = (values: ContactInfoDto) => {
    currentContactInfo.value = values;
    visible.value = true;
  };

  const resetCurrentContactInfo = () => {
    currentContactInfo.value = {};
  };

  const setCurrentUserId = (id: number) => {
    currentUserId.value = id;
  };

  return {
    data,
    tableLoading,
    visible,
    modalLoading,
    currentContactInfo,
    editMode,
    fetchContactInfo,
    createContactInfo,
    updateContactInfo,
    removeContactInfo,
    setCurrentContactInfo,
    resetCurrentContactInfo,
    setCurrentUserId,
  };
});
