import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const VACCINATION_MAIN_LIST_ROUTE = {
  name: 'VaccinationMainList',
  path: ROUTE_PATH.vaccination.mainList,
  component: () => import('./VaccinationMainList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Analytics.Vaccination',
  },
};

export const VACCINATION_MICRODISTRICT_LIST_ROUTE = {
  name: 'VaccinationMicrodistrictList',
  path: ROUTE_PATH.vaccination.microdistrictList,
  component: () => import('./VaccinationMicrodistricList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director'])],
  meta: {
    title: 'Analytics.Vaccination',
  },
};

export const VACCINATION_GROUP_LIST_ROUTE = {
  name: 'VaccinationGroupList',
  path: ROUTE_PATH.vaccination.groupList,
  component: () => import('./VaccinationGroupList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Analytics.Vaccination',
  },
};
