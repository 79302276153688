<template>
  <ElDialog
    class="service-outcome-modal"
    :model-value="modelValue"
    width="586px"
    :title="$t('Outcome.Add')"
    v-bind="$attrs"
    @update:model-value="handleUpdateModelValue">
    <template v-if="loadingOutcomeById">
      <div v-loading="true" />
    </template>
    <template v-else>
      <VeeForm as="ElForm">
        <OutcomeForm
          v-if="formData"
          :form-data="formData"
          @close-modal="handleUpdateModelValue"
          @action="handleAction" />
      </VeeForm>
    </template>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'ServiceOutcomeModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ref, watchEffect } from 'vue';
import { ElDialog } from 'element-plus';
import { Form as VeeForm } from 'vee-validate';

import { ExpenseFormData } from './model';

import { misApi } from '~shared/api';

import OutcomeForm from '@/components/accounting/modals/ServiceOutcomeModal/OutcomeForm/index.vue';

const props = withDefaults(defineProps<{ modelValue: boolean; id?: number | null }>(), {
  modelValue: false,
  id: null,
});
const emit = defineEmits(['update:modelValue', 'action']);

const loadingOutcomeById = ref(false);

const formData = ref<ExpenseFormData | null>(null);

const handleUpdateModelValue = (e: boolean) => emit('update:modelValue', e);

const handleAction = (e: unknown) => emit('action', e);

const getOutcomeById = async (id: number) => {
  loadingOutcomeById.value = true;

  const response = await misApi.common.expenses.getItemById(id);
  formData.value = response?.data?.data ?? null;
};

const resetFormData = () => {
  formData.value = {
    accepted_at: '',
    expense_date: '',
    recipient: '',
    expense_type: null,
    amount: null,
    status: 'created',
    auto_accept: false,
    expense_type_id: null,
  };
};

watchEffect(() => {
  if (props.id !== null) {
    getOutcomeById(props.id);
  } else {
    resetFormData();
  }
});
</script>
