import { ROUTE_PATH } from '~shared/config';
import { onlyUserNotAuthMiddleware } from '~shared/lib';

const AUTH_ROUTE_NAME = 'AuthLogin';

export const LOGIN_ROUTE = {
  name: AUTH_ROUTE_NAME,
  path: ROUTE_PATH.auth.login,
  component: () => import('./ui'),
  beforeEnter: [onlyUserNotAuthMiddleware],
  meta: {
    title: 'Common.Authorization',
  },
};
