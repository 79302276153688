<template>
  <div
    :class="[
      'printer-document-content-block',
      {
        'printer-document-content-block_title': isTitle,
        'printer-document-content-block_inline': block.inline,
        'printer-document-content-block_with-divider': block.withDivider,
      },
      block.class,
    ]"
    :style="block.style ?? {}">
    <span v-if="isShowLabel" class="printer-document-content-block__label">
      {{ block.label ? block.label + ':' : '' }}
    </span>
    <slot name="value">
      <PrinterDocumentContentInlineValue v-if="isInlineValue" :block="block" />
      <PrinterDocumentContentBlockList v-if="isListValue" :block="block" />
      <PrinterDocumentContentBlockTable v-if="isTableValue" :block="block" />
      <PrinterDocumentContentBlockEditor v-if="isEditor" :block="block" />
      <PrinterDocumentContentTinymce v-if="isTinymce" :block="block" />
    </slot>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-internal-modules
import PrinterDocumentContentBlockEditor from './PrinterDocumentContentBlockEditor/index.vue';
// eslint-disable-next-line import/no-internal-modules
import PrinterDocumentContentBlockList from './PrinterDocumentContentBlockList/index.vue';
// eslint-disable-next-line import/no-internal-modules
import PrinterDocumentContentBlockTable from './PrinterDocumentContentBlockTable/index.vue';
// eslint-disable-next-line import/no-internal-modules
import PrinterDocumentContentInlineValue from './PrinterDocumentContentInlineValue/index.vue';
// eslint-disable-next-line import/no-internal-modules
import PrinterDocumentContentTinymce from './PrinterDocumentContentTinymce/index.vue';

import { PrinterDocumentContentBlock as ContentBlock } from '@/models/client/PrinterDocumentContentBlock.model';

export default {
  name: 'PrinterDocumentContentBlock',
  components: {
    PrinterDocumentContentInlineValue,
    PrinterDocumentContentBlockList,
    PrinterDocumentContentBlockTable,
    PrinterDocumentContentBlockEditor,
    PrinterDocumentContentTinymce,
  },
  props: {
    block: [ContentBlock, Object],
  },
  setup: () => ({
    ContentBlock,
  }),
  computed: {
    type() {
      return this.block.type ?? ContentBlock.enum.types.InlineValue;
    },
    isTitle() {
      return this.type === ContentBlock.enum.types.Title;
    },
    isTableValue() {
      return this.type === ContentBlock.enum.types.Table;
    },
    isListValue() {
      return this.type === ContentBlock.enum.types.List;
    },
    isInlineValue() {
      return this.type === ContentBlock.enum.types.InlineValue;
    },
    isEditor() {
      return this.type === ContentBlock.enum.types.Editor;
    },
    isTinymce() {
      return this.type === ContentBlock.enum.types.Tinymce;
    },
    isShowLabel() {
      return !this.isTableValue && !this.isEditor && !this.isTinymce;
    },
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
