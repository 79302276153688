import { ElNotification } from 'element-plus';

import { apiService } from '~shared/api';
import { AnyObject } from '~shared/types';
import { getApiErrorMessage } from '~shared/lib';

const urlPrefix = 'studys';

export async function updateStudiesReferencesGroupsByOrderId(orderId: number) {
  try {
    const response = await apiService.api.instance.put(`${urlPrefix}/${orderId}/references`);

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
}

export async function create(payload: AnyObject) {
  try {
    const response = await apiService.api.instance.post(`${urlPrefix}/create`, payload);
    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
}

export async function update({ id, payload }: { id: number; payload: AnyObject }) {
  try {
    const response = await apiService.api.instance.put(`${urlPrefix}/${id}/update`, payload);
    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
}

export async function remove(id: number) {
  try {
    const response = await apiService.api.instance.delete(`${urlPrefix}/${id}/delete`);
    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
}
