<template>
  <ElDialog
    :title="$t('Laboratory.ReferencesGroup')"
    class="create-or-edit-references-group-modal"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElForm
      id="create-or-edit-references-group-modal-form"
      class="create-or-edit-references-group-modal__content"
      label-position="top"
      @submit.prevent="submitHandler">
      <ElFormItem :label="$t('Common.NamingInRu')">
        <ElInput v-model="referencesGroup.title_ru" required />
      </ElFormItem>
      <ElFormItem :label="$t('Common.NamingInUz')">
        <ElInput v-model="referencesGroup.title_uz" required />
      </ElFormItem>

      <MiRadioGroup
        class="create-or-edit-references-group-modal__content-gender-select"
        v-model="referencesGroup.gender">
        <ElRadioButton label="man">{{ $t('User.Genders.man') }}</ElRadioButton>
        <ElRadioButton label="woman">{{ $t('User.Genders.woman') }}</ElRadioButton>
      </MiRadioGroup>

      <ElFormItem :label="$t('User.StartAge')">
        <ElInput v-model="referencesGroup.start_age" type="number" min="0" max="150" required />
      </ElFormItem>
      <ElFormItem :label="$t('User.EndAge')">
        <ElInput v-model="referencesGroup.end_age" type="number" min="0" max="150" required />
      </ElFormItem>
    </ElForm>

    <template #footer>
      <MiButton
        type="primary"
        native-type="submit"
        form="create-or-edit-references-group-modal-form"
        :loading="loading">
        {{ referencesGroup.id ? $t('Common.Save') : $t('Common.Create') }}
      </MiButton>
    </template>
  </ElDialog>
</template>

<script>
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { ElDialog, ElForm, ElFormItem, ElInput, ElRadioButton } from 'element-plus';

import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import { MiRadioGroup, MiButton } from '~shared/ui';
import { misApi } from '~shared/api';

export default {
  name: 'CreateOrEditReferencesGroupsModal',
  components: { MiButton, ElDialog, ElForm, ElFormItem, ElInput, MiRadioGroup, ElRadioButton },
  emits: ['update:modelValue', 'action'],
  props: {
    modelValue: Boolean,
    data: [Object],
  },
  data() {
    return {
      loading: false,
      referencesGroup: null,
    };
  },
  watch: {
    modelValue: {
      handler() {
        this.referencesGroup = this.data
          ? cloneDeep(this.data)
          : {
              title: null,
              title_ru: null,
              title_uz: null,
              gender: 'man',
              start_age: null,
              end_age: null,
            };
      },
      immediate: true,
    },
  },

  methods: {
    async submitHandler() {
      if (this.loading) return;
      this.loading = true;

      try {
        const referencesGroup = this.referencesGroup.id ? await this.update() : await this.create();

        this.$notify({
          type: 'success',
          title: this.$t(`Notifications.Success${this.referencesGroup.id ? 'Updated' : 'Created'}`),
        });
        this.$emit(
          'action',
          new GlobalModalInstance({
            name: 'created',
            data: { referencesGroup },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading = false;
    },

    async create() {
      const response = await misApi.common.references.createReferencesGroup(this.referencesGroup);
      return response.data.data;
    },
    async update() {
      const response = await misApi.common.references.updateReferencesGroupItem(
        this.referencesGroup.id,
        this.referencesGroup
      );
      return response.data.data;
    },
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
