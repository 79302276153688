<template>
  <teleport to="body">
    <div
      v-bind="$attrs"
      class="print-wrapper"
      v-show="isShow"
      ref="refPrintBody"
      :class="{
        'print-wrapper__color-black': colorBlack,
      }">
      <table class="print-wrapper__table">
        <tbody class="print-wrapper__table-body">
          <!------------Header------------------->
          <tr v-if="!hideHeader">
            <td>
              <div class="print-wrapper__header">
                <slot name="header">
                  <MiPrintHeader :is-g2-g-interface="isG2GInterface" :session-user="sessionUser" />
                </slot>
                <slot v-if="showPatientInfo" name="userInfo">
                  <div class="print-wrapper__user-info-header">
                    <h2>
                      Bemor: <span>{{ patientData?.name }}</span>
                    </h2>
                    <h2>
                      Tibbiy karta raqami :
                      <span>{{ patientData?.number_med_card }}</span>
                    </h2>
                  </div>
                  <div class="print-wrapper__user-info">
                    <table>
                      <tr :key="patientInfo.title" v-for="patientInfo in patientDataTableLeft">
                        <td class="print-wrapper__user-info-title">{{ patientInfo.title }}</td>
                        <td>{{ patientInfo.value }}</td>
                      </tr>
                    </table>
                    <table>
                      <tr :key="patientInfo.title" v-for="patientInfo in patientDataTableRight">
                        <td class="print-wrapper__user-info-title">{{ patientInfo.title }}</td>
                        <td>{{ patientInfo.value }}</td>
                      </tr>
                    </table>
                    <slot name="userInfoBody" />
                  </div>
                </slot>
              </div>
            </td>
          </tr>
          <!------------Body------------------->
          <tr>
            <td>
              <div class="print-wrapper__body">
                <slot name="default" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, shallowRef } from 'vue';

import { MiPrintHeader } from './MiPrintHeader';

import { I18nService } from '~shared/lib';
import { PatientFullDto, UserFullDto } from '~shared/api';

const props = withDefaults(
  defineProps<{
    showPatientInfo?: boolean;
    hideHeader?: boolean;
    colorBlack?: boolean;
    patientData?: PatientFullDto;
    sessionUser?: UserFullDto | null;
    isG2GInterface?: boolean; // TODO поменять название пропса
  }>(),
  {
    showPatientInfo: false,
    colorBlack: false,
    hideHeader: false,
  }
);

const isShow = shallowRef(false);

const refPrintBody = ref();

const print = () => {
  isShow.value = true;

  // если будут какие-то вычисления, наподобии генерации qr кода
  return new Promise((resolve): void => {
    setTimeout(async () => {
      await window.print();
      isShow.value = false;

      resolve('');
    }, 1000);
  });
};
const showPrint = () => {
  isShow.value = true;
};

const getHtmlOfContent = (): Promise<string> => {
  // Чтобы браузер успел подгрузить html
  return new Promise((resolve): void => {
    setTimeout(async () => {
      isShow.value = true;
      await nextTick();
      resolve(refPrintBody.value);
      // isShow.value = false;
    }, 1000);
  });
};

const patientDataTableLeft = computed(() => {
  if (!props.patientData) return [];
  return [
    {
      title: 'Bemor jinsi',
      value: I18nService.t(`Common.Genders.${props.patientData.gender}`),
    },
    { title: "Tug'ilgan kuni", value: props.patientData.birthdate },
    { title: 'Yashash manzili', value: props.patientData.place_registration },
  ];
});

const patientDataTableRight = computed(() => {
  if (!props.patientData) return [];
  return [
    { title: 'Telefon raqami', value: props.patientData.phone },
    { title: 'Ish joyi', value: props.patientData.working_place },
  ];
});

defineExpose({ print, getHtmlOfContent, showPrint });
</script>

<style lang="scss" src="./index.scss" />
