import { RouteRecordRaw, RouteComponent } from 'vue-router';

import { AnyObject } from '~shared/types';

export const insertRouteParams = ({ path, params }: { path: string; params: AnyObject }) => {
  return path.replace(/:[a-zA-Z]*/gim, (str) => {
    const paramKey = str.split(':')[1]!;
    return params[paramKey];
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createChildrenRoutesByChildrenMap = (childrenMap: any) => {
  if (!childrenMap) {
    return [];
  }

  return Object.keys(childrenMap).map((key) => childrenMap[key]);
};

export const insertComponentsInRoutes = (
  routes: Array<RouteRecordRaw & { childrenMap: Record<string, RouteRecordRaw> }>,
  components: Record<string, RouteComponent>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  return routes.map((route) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const component = components[route.component as any];

    return {
      ...route,
      component,
      children: insertComponentsInRoutes(
        createChildrenRoutesByChildrenMap(route.childrenMap),
        components
      ),
    };
  });
};
