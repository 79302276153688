import { object, string, number, boolean } from 'yup';

import { I18nService } from '~shared/lib';

export type TabNameEnum = 'docs-search' | 'without-docs' | 'my-id';
export type DocumentTypeIdEnum = 'pinfl' | 'uz_passport' | 'medcard_number' | 'birth_certificate';

export const getPatientSearchValidationSchema = (_isMyIDIntegration?: boolean) => {
  return object().shape({
    tabId: string(),
    documentTypeId: string(),
    searchParams: object()
      .nullable()
      .when(['documentTypeId', 'tabId'], (fields, schema) => {
        const documentTypeId = fields[0] as DocumentTypeIdEnum;
        const tabId = fields[1] as TabNameEnum;

        if (tabId !== 'docs-search') return schema;

        switch (documentTypeId) {
          case 'pinfl':
            return schema.shape({
              pinfl: string()
                .required(I18nService.t('Validation.RequiredField'))
                .length(14, I18nService.tc('Validation.Length', { length: 14 })),
            });
          case 'medcard_number':
            return schema.shape({
              medcard_number: string().required(I18nService.t('Validation.RequiredField')),
              birthdate: string().required(I18nService.t('Validation.RequiredField')),
            });
          case 'uz_passport':
            return schema.shape({
              id_serial: string()
                .required(I18nService.t('Validation.RequiredField'))
                .length(2, I18nService.tc('Validation.Length', { length: 2 })),
              id_number: string()
                .required(I18nService.t('Validation.RequiredField'))
                .length(7, I18nService.tc('Validation.Length', { length: 7 })),
              birthdate: string().required(I18nService.t('Validation.RequiredField')),
            });
          case 'birth_certificate':
            return schema.shape({
              birth_certificate_serial: string()
                .required(I18nService.t('Validation.RequiredField'))
                .matches(/^[a-zA-Z-]+$/, {
                  message: I18nService.t('Validation.CheckThatTheDataEnteredIsCorrect'),
                }),
              birth_certificate_number: string().required(
                I18nService.t('Validation.RequiredField')
              ),
              birthdate: string().required(I18nService.t('Validation.RequiredField')),
            });
          default:
            return schema;
        }
      }),
    patientFoundByName: object()
      .nullable()
      .when(['tabId'], (fields, schema) => {
        const tabId = fields[0] as TabNameEnum;

        if (tabId !== 'without-docs') return schema;

        return schema.shape({
          surname: string().required(I18nService.t('Validation.RequiredField')),
          name: string().required(I18nService.t('Validation.RequiredField')),
          gender: string().required(I18nService.t('Validation.RequiredField')),
          birthdate: string().required(I18nService.t('Validation.RequiredField')),
        });
      }),
  });
};

export const createAppointmentValidationSchema = object().shape({
  date: string().required(I18nService.t('Validation.RequiredField')),
  payloadData: object()
    .nullable()
    .shape({
      start_at: string().required(I18nService.t('Validation.RequiredField')),
      doctor_id: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(I18nService.t('Validation.RequiredField')),
      user_id: number().transform((value) => (isNaN(value) ? undefined : value)),
    }),
  isSlotAvailable: boolean().isTrue(),
});
