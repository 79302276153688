export const NEW_ENTITY_ID = 'new-entity';
export const DEFAULT_GET_DATA_INTERVAL = 60000;
export const DRAWER_DEFAULT_DIRECTION = 'rtl';
export const DRAWER_DEFAULT_SIZE = '480px';
export const DEFAULT_PRICE_DELIMITER = ' ';
export const VOTP_INPUT_LENGTH = 6;
export const BIOMATERIAL_BAR_CODE_HEIGHT = 19;
export const DEFAULT_MAP_ZOOM = 11;
export const DEFAULT_MAP_CENTER: number[] = [41.28, 69.15];
export const URL_TO_MOBILE_APP: string = 'https://landing.dmed.uz/download/';
export const EMPTY_VALUE = '...';
export const DEFAULT_DEBOUNCE_DELAY = 500;
export const EMPTY_VALUE_DASH = '-';
export const CODE_FROM_MOBILE_INPUT_LENGTH = 5;

export const ANCOR_ID = {
  CURRENT_CHECKUP: 'current-checkup',
  ANALYSIS_TABLE: 'analysis-table',
  ICD_REGISTRATION_TABLE: 'icd-registration-table',
  DIAGNOSES: 'diagnoses',
  SICK_LEAVES: 'sick-leaves',
  EPICRISES: 'epicrises',
  TREATMENT_COURSES: 'treatment-courses',
  HOSPITALIZATIONS: 'hospitalizations',
  TREATMENT_DIARY: 'treatment-diary',
  FORM_003: 'form-003',
} as const;
