import { Path, Locale } from 'vue-i18n';

import { LOCALES, DEFAULT_LOCALE } from '~shared/config';
import { I18nPlugin, authStorageService } from '~shared/lib';
import { apiService } from '~shared/api';

export class I18nService {
  static locales = Object.keys(LOCALES).map((key) => key);

  static t(payload: Path) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return I18nPlugin.global.t(payload);
  }

  static tc(payload: Path, options: Record<string, unknown>) {
    return I18nPlugin.global.tc(payload, options);
  }

  static getLocale(): Locale {
    return I18nPlugin.global.locale;
  }

  static setLocale(locale: Locale) {
    if (!this.checkHasLocale(locale)) {
      // eslint-disable-next-line no-console
      console.error(`${locale} - incorrect locale.\nSupported locales - ${this.locales.join(',')}`);
      locale = DEFAULT_LOCALE;
    }

    I18nPlugin.global.locale = locale;
    apiService.setDefaultLocale(locale);
    authStorageService.setLocale(locale);
  }

  static setLocaleFromStorage() {
    this.setLocale(authStorageService.getLocale() || DEFAULT_LOCALE);
  }

  static checkHasLocale(locale: Locale) {
    return this.locales.includes(locale);
  }
}
