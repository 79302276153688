<template>
  <MiPrint hide-header color-black ref="refMiPrint">
    <div class="appointment-talon-print">
      <img class="appointment-talon-print__top" alt="no" :src="talonPretierSvgSrc" />
      <div class="appointment-talon-print__header">
        <div class="appointment-talon-print__medcard-num">
          {{ appointment.user?.number_med_card }}
        </div>
        <div class="appointment-talon-print__header-sub-title">
          {{ $t('Common.YourNumberInQueue') }}
        </div>
      </div>
      <div class="appointment-talon-print__body">
        <div class="appointment-talon-print__patient-info">
          <div class="appointment-talon-print__patient-info-title">
            <span>{{ $t('Common.Patient') }}</span>
          </div>
          <AppointmentTalonPrintField
            :title="$t('Common.Patient')"
            :text="joinFullname(appointment.user)" />
          <AppointmentTalonPrintField title="MED ID" :text="appointment.user?.number_med_card!" />
        </div>
        <div class="appointment-talon-print__appointment-info">
          <div class="appointment-talon-print__appointment-info-title">
            <span> {{ $t('Appointments.Appointment') }}</span>
          </div>
          <AppointmentTalonPrintField
            :title="$t('Appointments.Appointment')"
            :text="formattedTime" />
          <AppointmentTalonPrintField
            :title="$t('Common.Doctor')"
            :text="appointment.doctor?.name" />
          <AppointmentTalonPrintField
            :title="$t('Common.Cabinet')"
            :text="appointment.doctor?.cabinet!" />
        </div>
      </div>
      <div class="appointment-talon-print__footer">
        <p class="appointment-talon-print__logo-wrapper">
          <img
            class="appointment-talon-print__logo appointment-talon-print__logo--dmed"
            alt="Logo"
            :src="logoDmedV2SvgSrc" />
          <img
            class="appointment-talon-print__logo appointment-talon-print__logo--uzinfocom"
            alt="Logo"
            :src="logoUzinfocomSvgSrc" />
        </p>
        <div class="appointment-talon-print__qr-wrapper">
          <MiQrCode :payload="{ data: qrPayload, color: '#000000', errorCorrectionLevel: 'L' }" />
          <p class="appointment-talon-print__qr-download-text">{{ $t('Common.DownloadApp') }}</p>
        </div>
      </div>
      <img class="appointment-talon-print__bottom" alt="no" :src="talonPretierSvgSrc" />
    </div>
  </MiPrint>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';

import { AppointmentDataProps } from '../AppointmentTalonModal';

import { AppointmentTalonPrintField } from '~features/patients/CreateAppointmentModal/ui/AppointmentTalonPrintField';
import { misApi } from '~shared/api';
import { URL_TO_MOBILE_APP } from '~shared/config';
import {
  MiPrint,
  MiQrCode,
  logoDmedV2SvgSrc,
  logoUzinfocomSvgSrc,
  talonPretierSvgSrc,
} from '~shared/ui';
import { joinFullname } from '~shared/lib';

type Props = {
  appointment: AppointmentDataProps & {
    time: string;
  };
};
const props = defineProps<Props>();

const emit = defineEmits<{
  printed: [];
}>();

const refMiPrint = ref();

const qrPayload = ref('');

const formattedTime = computed(() => {
  return `${props.appointment.start_at} ${props.appointment.time}`;
});

const getOneTimeToken = async () => {
  if (!props.appointment.user.id) return;

  const response = await misApi.g2g.patients.getQrCodeLinkByUserId(props.appointment.user.id);
  if (response?.data) {
    //TODO: костыль, пока на бэке не пофиксили протокол в link
    const token = response.data.link.split('=')[1];
    qrPayload.value = new URL(`${URL_TO_MOBILE_APP}?oneTimeToken=${token}`).toString();
  }
};

const print = async () => {
  await getOneTimeToken();
  await refMiPrint.value.print();
  emit('printed');
};

defineExpose({ print });
</script>

<style lang="scss" src="./index.scss"></style>
