import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const CHECKUP_TEMPLATE_EDITOR_ROUTE = {
  name: 'CheckupTemplateEditor',
  path: ROUTE_PATH.templates.checkupEditor.rawPath,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['doctor', 'nurse'])],
  meta: {
    title: 'Templates.CheckupTemplateEditor',
  },
};
