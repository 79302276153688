<template>
  <ElDialog
    width="480px"
    class="warehouse-crud-modal"
    :title="isNewWarehouse ? $t('Warehouse.AddWarehouse') : $t('Warehouse.EditWarehouse')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <VeeForm
      class="warehouse-crud-form"
      :validation-schema="validationSchema"
      :initial-values="initialValues"
      v-slot="{ meta }"
      @submit="onSubmit">
      <FieldGroupWrapper>
        <FormTextField
          :label="$t('Warehouse.Warehouse')"
          field-name="title"
          required
          maxlength="255" />
        <FormTextField
          :label="$t('Warehouse.Address')"
          field-name="address"
          required
          maxlength="255" />
        <FormTextField
          :label="$t('Nomenclature.Notes')"
          field-name="description"
          type="textarea"
          :rows="4"
          maxlength="255" />
      </FieldGroupWrapper>
      <FormActionsWrapper>
        <MiButton
          v-if="isNewWarehouse"
          type="primary"
          native-type="submit"
          :disabled="!meta.dirty"
          :loading="loading.save"
          >{{ `+ ${$t('Common.Add')}` }}
        </MiButton>
        <MiButton
          v-if="!isNewWarehouse"
          type="primary"
          native-type="submit"
          :disabled="!meta.dirty"
          :loading="loading.save"
          >{{ $t('Common.Save') }}
        </MiButton>
        <ElPopconfirm
          v-if="!isNewWarehouse"
          :title="$t('Ui.PopConfirm.DefaultTitle')"
          @confirm="onDeleteButtonClick">
          <template #reference>
            <MiButton type="primary" plain :loading="loading.delete">
              {{ $t('Common.Delete') }}
            </MiButton>
          </template>
        </ElPopconfirm>
      </FormActionsWrapper>
    </VeeForm>
  </ElDialog>
</template>

<script lang="ts">
import { ElDialog, ElPopconfirm } from 'element-plus';
import { SubmissionHandler, Form as VeeForm } from 'vee-validate';
import { PropType, reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { object, string } from 'yup';

import { I18nService } from '~shared/lib';
import { MiButton } from '~shared/ui';
import { misApi } from '~shared/api';
import { WarehouseDto } from '~shared/api/common';

import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';

export default {
  name: 'WarehouseCrudModal',
  components: {
    MiButton,
    VeeForm,
    FormTextField,
    FieldGroupWrapper,
    FormActionsWrapper,
    ElDialog,
    ElPopconfirm,
  },
  emits: ['update:modelValue', 'action'],
  props: {
    formData: {
      type: Object as PropType<WarehouseDto>,
      default: () => ({ title: '', phone: '', contact_person: '', description: '' }),
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    // eslint-disable-next-line vue/no-setup-props-destructure
    const initialValues = ref({ ...props.formData });
    const isNewWarehouse = computed(() => !initialValues.value.id);

    const loading = reactive({
      save: false,
      delete: false,
    });

    const validationSchema = object({
      title: string().required(I18nService.t('Validation.RequiredField')),
      address: string().required(I18nService.t('Validation.RequiredField')),
      description: string().nullable(),
    });

    const closeModal = () => {
      emit('update:modelValue');
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit: SubmissionHandler<any> = async (values) => {
      loading.save = true;
      if (values.id) {
        const response = await misApi.common.warehouse.updateItem(values.id, values);
        await store.dispatch('warehouses/editItem', response?.data.data);
      } else {
        const response = await misApi.common.warehouse.createItem(values);
        await store.dispatch('warehouses/createItem', response?.data.data);
      }
      closeModal();
      loading.save = false;
    };

    const onDeleteButtonClick = async () => {
      loading.delete = true;
      if (typeof initialValues.value.id === 'number') {
        await misApi.common.warehouse.deleteItem(initialValues.value.id);
        await store.dispatch('warehouses/deleteItem', initialValues.value);
      }
      closeModal();
      loading.delete = false;
    };

    return {
      isNewWarehouse,
      initialValues,
      loading,
      validationSchema,
      onSubmit,
      onDeleteButtonClick,
    };
  },
};
</script>

<style lang="scss" src="./index.scss" />
