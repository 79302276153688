<template>
  <ElForm class="create-appointment-subject" @submit.prevent="createSubject">
    <div
      class="create-appointment-subject__recommendations"
      v-if="filteredRecommendations.length > 0">
      <div class="create-appointment-subject__recommendations-text">
        {{ $t('Recommendations.HasRecommendation') }}
      </div>
      <div class="create-appointment-subject__recommendations-count">
        {{ filteredRecommendations.length }}
      </div>
      <MiButton
        class="create-appointment-subject__recommendations-appoint-button"
        type="primary"
        :disabled="isAppointButtonDisabled"
        @click="addRecomendationToSelect"
        >{{ $t('Recommendations.RecordByRecommendation') }}</MiButton
      >
    </div>

    <div class="create-appointment-subject__part create-appointment-subject-part">
      <!--  GroupService  -->
      <UiModelsAutocompleteSearch
        class="create-appointment-subject__services"
        :model-value="groupServiceIds"
        :models="groupServices"
        label="title"
        :placeholder="$t('Appointments.SelectServices')"
        :model-for-use="ServiceGroup"
        :search-query="groupServicesOptions.searchQuery"
        :required="groupServicesOptions.required"
        :multiple="groupServicesOptions.multiple"
        collapse-tags
        collapse-tags-tooltip
        :disabled="!currentPatientId"
        @select="selectGroupServices">
        <template #optionItem="{ item }"> {{ generateLabel(item) }}</template>
      </UiModelsAutocompleteSearch>

      <!--  Doctor  -->
      <UiModelsAutocompleteSearch
        class="create-appointment-subject__doctor"
        popper-class="create-appointment-subject__doctor-popper"
        :model-value="doctor?.id"
        clearable
        :model-for-use="Doctor"
        :default-item="appointment?.doctor ?? user?.doctor"
        :search-query="doctorOptions.searchQuery"
        :placeholder="$t('Appointments.SelectDoctor')"
        :required="doctorOptions.required"
        @select="doctor = $event">
        <template #optionItem="{ item }">
          <div class="create-appointment-subject__doctor-item">
            <div class="create-appointment-subject__doctor-item-wrapper">
              <span>{{ item.name }}</span>
              <span class="create-appointment-subject__doctor-item-cabinet">{{
                item.cabinet ?? '-'
              }}</span>
            </div>
            <p class="create-appointment-subject__doctor-specialties">
              <span :key="spec.id" v-for="(spec, idx) in item.specialties">
                {{ idx === 0 ? '' : ',' }} {{ spec.title }}
              </span>
            </p>
          </div>
        </template>
      </UiModelsAutocompleteSearch>
    </div>

    <div class="create-appointment-subject__part create-appointment-subject-part">
      <!--  Select Date type  -->
      <ElRadioGroup class="create-appointment-subject__date-type" v-model="isLiveQueue">
        <ElRadio :label="true"> {{ $t('Appointments.LiveQueue') }}</ElRadio>
        <ElRadio :label="false"> {{ $t('Appointments.RecordOnTime') }}</ElRadio>
      </ElRadioGroup>

      <!--  Date  -->
      <ScheduleSlotsSelect
        v-show="true"
        :label="$t('DateAndTime.SelectDate')"
        v-model:start-at="startAt"
        v-model:end-at="endAt"
        :default-start-at="appointment.start_at"
        :default-end-at="appointment.end_at"
        :search-query="slotsOptions.searchQuery"
        :dependencies="slotsOptions.dependencies"
        :disabled="slotsOptions.disabled" />

      <MiButton class="create-appointment-subject__submit" type="primary" native-type="submit">
        {{ $t('Common.Add') }}
      </MiButton>
    </div>
  </ElForm>
</template>

<script>
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { ElForm, ElRadioGroup, ElRadio } from 'element-plus';

// eslint-disable-next-line import/no-internal-modules
import UiModelsAutocompleteSearch from '../UiModelsAutocompleteSearch/index.vue';
// eslint-disable-next-line import/no-internal-modules
import ScheduleSlotsSelect from './ScheduleSlotsSelect/index.vue';
// eslint-disable-next-line import/no-internal-modules
import { Appointment } from '../models/Appointment.model';
// eslint-disable-next-line import/no-internal-modules
import { Doctor } from '../models/Doctor.model';
// eslint-disable-next-line import/no-internal-modules
import { Patient } from '../models/Patient.model';
// eslint-disable-next-line import/no-internal-modules
import { ServiceGroup } from '../models/ServiceGroup.model';
// eslint-disable-next-line import/no-internal-modules
import { AppointmentServiceGroupWithCount } from '../models/AppointmentServiceGroupWithCount.model';
// eslint-disable-next-line import/no-internal-modules
import { AppointmentSubject } from '../models/AppointmentSubject.model';

import { useSessionStore } from '~entities/session';
import { formatPrice } from '~shared/lib';
import { MiButton } from '~shared/ui';

export default {
  name: 'CreateAppointmentSubjectOrServiceGroupWithCount',
  emits: ['subject:create', 'update:appointment'],
  components: {
    MiButton,
    ScheduleSlotsSelect,
    UiModelsAutocompleteSearch,
    ElForm,
    ElRadioGroup,
    ElRadio,
  },
  props: {
    appointment: [Appointment, Object], // use for edit
    patient: [Patient, Object],
    setDefaultMyDoctor: Boolean,
    resetAfterCreate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      /** @type {AppointmentSubject|AppointmentServiceGroupWithCount} subject  */
      appointmentItem: this.appointment.id
        ? new AppointmentServiceGroupWithCount()
        : new AppointmentSubject(),
      isLiveQueue: true,
      appointedRecommendations: [],
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },

    currentPatientId() {
      return this.appointment.patient_id;
    },

    tableItemIds() {
      return this.appointment.id
        ? this.appointment.group_services_with_count.reduce(
            (acc, curr) => [...acc, ...curr.group_service_ids],
            []
          )
        : this.appointment.appointments.reduce(
            (acc, curr) => [...acc, ...curr.group_service_ids],
            []
          );
    },

    filteredRecommendations() {
      return this.appointedRecommendations
        .map((item) => ({
          group_services: item.group_services.filter((f) => !this.tableItemIds.includes(f.id)),
          group_service_ids: item.group_service_ids.filter((f) => !this.tableItemIds.includes(f)),
        }))
        .filter((f) => f.group_services.length > 0);
    },

    isAppointButtonDisabled() {
      const firstRecommendationIds = this.filteredRecommendations[0].group_service_ids;
      return firstRecommendationIds.every((item) => this.groupServiceIds.includes(item));
    },

    // Если их нету, значит идёт редактирование
    isAppointmentSubjectsFlow() {
      return !this.appointment.id;
    },

    doctor: {
      get() {
        return this.isAppointmentSubjectsFlow
          ? this.appointmentItem.doctor
          : this.appointment.doctor;
      },
      set(value) {
        if (this.isAppointmentSubjectsFlow) {
          this.appointmentItem.doctor_id = value?.id || null;
          this.appointmentItem.doctor = value;
        } else {
          this.$emit('update:appointment', {
            ...this.appointment,
            doctor_id: value?.id || null,
            doctor: value,
          });
        }
      },
    },
    doctorOptions() {
      return {
        required: false,
        searchQuery: {
          group_service_ids: this.isAppointmentSubjectsFlow
            ? this.appointmentItem.group_service_ids
            : this.appointment.group_service_ids,
        },
      };
    },

    /** @return {number|Array<number>} */
    groupServiceIds() {
      return this.isAppointmentSubjectsFlow
        ? this.appointmentItem.group_service_ids
        : this.appointmentItem.group_service_id;
    },
    groupServices() {
      return this.isAppointmentSubjectsFlow
        ? this.appointmentItem.group_services
        : [this.appointmentItem.group_service];
    },
    groupServicesOptions() {
      return {
        multiple: !!this.isAppointmentSubjectsFlow,
        required: true,
        searchQuery: {
          query_field: null,
          query_type: null,
          query_operator: null,
          some_services: true,
          doctor_id: this.doctor?.id,
          user_id: this.currentPatientId,
        },
      };
    },

    startAt: {
      get() {
        return this.isAppointmentSubjectsFlow
          ? this.appointmentItem.start_at
          : this.appointment.start_at;
      },
      set(value) {
        if (this.isAppointmentSubjectsFlow) {
          this.appointmentItem.start_at = value;
        } else {
          this.$emit('update:appointment', { ...this.appointment, start_at: value });
        }
      },
    },
    endAt: {
      get() {
        return this.isAppointmentSubjectsFlow
          ? this.appointmentItem.end_at
          : this.appointment.end_at;
      },
      set(value) {
        if (this.isAppointmentSubjectsFlow) {
          this.appointmentItem.end_at = value;
        } else {
          this.$emit('update:appointment', { ...this.appointment, end_at: value });
        }
      },
    },
    slotsOptions() {
      return {
        disabled: this.isAppointmentSubjectsFlow ? !this.groupServiceIds.length : false,
        dependencies: [
          this.groupServiceIds instanceof Array ? this.groupServiceIds : [this.groupServiceIds],
          this.doctor?.id,
        ],
        searchQuery: {
          group_service_ids: this.groupServiceIds,
          doctor_id: this.doctor?.id,
          appointment_id: this.appointment?.id,
        },
      };
    },

    isCurrentUserDoctorOrLaboratory() {
      return this.user.role === 'doctor' || this.user.role === 'laboratory';
    },
  },

  watch: {
    'appointment.patient_id': {
      handler(value) {
        if (value) {
          // this.getPatientRecommendations(value);
        }
      },
      immediate: true,
    },

    'isAppointmentSubjectsFlow': {
      handler(value) {
        this.appointmentItem = value
          ? new AppointmentSubject(this.appointment)
          : new AppointmentServiceGroupWithCount();
      },
      immediate: true,
    },

    'appointment.start_at': {
      handler(value) {
        if (value) {
          this.isLiveQueue = false;
        }
      },
      immediate: true,
    },

    'user.doctor_id': {
      handler(value) {
        if (
          value &&
          this.setDefaultMyDoctor &&
          this.isAppointmentSubjectsFlow &&
          this.isCurrentUserDoctorOrLaboratory
        ) {
          this.appointmentItem = new AppointmentSubject({
            ...this.appointmentItem,

            doctor_id: value,
            doctor: this.user.doctor,
          });
        }
      },
      immediate: true,
    },

    'isLiveQueue': {
      handler() {
        this.startAt = null;
        this.endAt = null;
      },
    },
  },

  methods: {
    createSubject() {
      this.$emit('subject:create', cloneDeep(this.appointmentItem));
      if (this.resetAfterCreate) this.reset();
    },

    async getPatientRecommendations(id) {
      try {
        const { data } = await Patient.getRecommendations(id);
        this.appointedRecommendations = data.data;
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }
    },

    addRecomendationToSelect() {
      const recommendation = this.filteredRecommendations[0];
      this.selectGroupServices(recommendation.group_services);
    },

    reset() {
      if (this.isAppointmentSubjectsFlow) {
        this.appointmentItem = new AppointmentSubject({
          doctor_id:
            this.setDefaultMyDoctor && this.isCurrentUserDoctorOrLaboratory
              ? this.user.doctor_id
              : null,
          doctor:
            this.setDefaultMyDoctor && this.isCurrentUserDoctorOrLaboratory
              ? this.user.doctor
              : null,
        });
        this.isLiveQueue = true;
      }

      if (!this.isAppointmentSubjectsFlow) {
        this.appointmentItem = new AppointmentServiceGroupWithCount();
      }
    },

    /** @param {ServiceGroup|Array<ServiceGroup>} items */
    selectGroupServices(items) {
      if (this.isAppointmentSubjectsFlow) {
        this.appointmentItem.group_service_ids = items.map((item) => item.id);
        this.appointmentItem.group_services = items;
        this.appointmentItem.group_services_with_count = items.map(
          (item) =>
            new AppointmentServiceGroupWithCount({
              uuid: item.id,
              group_service_id: item.id,
              group_service: item,
              count: 1,
            })
        );
      }

      if (!this.isAppointmentSubjectsFlow) {
        this.appointmentItem.group_service_id = items.id;
        this.appointmentItem.group_service = items;
      }
    },

    /** @param {ServiceGroup} item */
    generateLabel(item) {
      return `${item.title} - ${formatPrice({ price: item.price })} ${this.$t('Common.Sum')}`;
    },
  },

  setup: () => ({
    ServiceGroup,
    Doctor,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
