import { computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';

import { AgeRangeEnum } from '../config';

import { useUserConfigStore } from '~entities/session';
import { DisabilityGroup, Gender, PatientGroupType } from '~shared/api';
import {
  CVDScreeningRiskGroupEnum,
  EndocrineScreeningRiskGroupEnum,
  GetPatientListParams,
} from '~shared/api/gen/v2';
import { clearObjectEmptyValues } from '~shared/lib';

export type PatientsTableFilterValues = {
  search?: string;
  ageRanges?: AgeRangeEnum[];
  microdistrictIds?: number[];
  dispensaryCodes?: string[];
  groups?: (PatientGroupType | 'undefined')[];
  disabilityGroups?: (DisabilityGroup | 'undefined')[];
  genders?: (Gender | 'undefined')[];
  vaccineIds?: number[];
  pregnancy?: number;
  cvdRiskGroups?: CVDScreeningRiskGroupEnum[];
  endocrineRiskGroups?: EndocrineScreeningRiskGroupEnum[];
};

export const usePatientsTableFiltersStore = defineStore('patientsTableFiltersStore', () => {
  const userConfigStore = useUserConfigStore();
  const { userConfig } = storeToRefs(userConfigStore);

  const filterValues = computed<PatientsTableFilterValues>(() => ({
    ...userConfig.value.tables?.patients?.filters,
  }));
  const queryParams = computed<GetPatientListParams>(() => ({
    ...userConfig.value.tables?.patients?.queryParams,
  }));
  const filtersHidden = computed<boolean>(() => !!userConfig.value.tables?.patients?.filtersHidden);

  function toggleFiltersHidden() {
    userConfigStore.toggleTableFiltersHidden('patients');
  }

  function setFilters({
    ageRanges,
    cvdRiskGroups,
    disabilityGroups,
    dispensaryCodes,
    endocrineRiskGroups,
    genders,
    groups,
    microdistrictIds,
    pregnancy,
    search,
    vaccineIds,
  }: PatientsTableFilterValues) {
    userConfigStore.setTableFilters(
      'patients',
      clearObjectEmptyValues({
        ageRanges,
        cvdRiskGroups,
        disabilityGroups,
        dispensaryCodes,
        endocrineRiskGroups,
        genders,
        groups,
        microdistrictIds,
        pregnancy,
        search,
        vaccineIds,
      })
    );
  }

  function updateFilters(payload: PatientsTableFilterValues) {
    userConfigStore.updateTableFilters('patients', payload);
  }

  function setQueryParams(payload: PatientsTableFilterValues) {
    const ageRangesValue = payload.ageRanges?.map((str) => {
      const [start, end] = str.split('-');
      return {
        start_years: start === 'null' ? null : Number(start),
        end_years: end === 'null' ? null : Number(end),
      };
    });
    const query: GetPatientListParams = {
      age_ranges: ageRangesValue,
      search: payload.search,
      microdistrict_ids: payload.microdistrictIds,
      dispensaries: payload.dispensaryCodes,
      group: payload.groups,
      disabilities: payload.disabilityGroups,
      gender: payload.genders,
      vaccines: payload.vaccineIds,
      pregnancy: payload.pregnancy ? Boolean(payload.pregnancy) : undefined,
      cvd_risk_groups: payload.cvdRiskGroups,
      endocrine_risk_groups: payload.endocrineRiskGroups,
    };
    userConfigStore.setTableQueryParams('patients', clearObjectEmptyValues(query));
  }

  return {
    filterValues,
    queryParams,
    filtersHidden,
    toggleFiltersHidden,
    setFilters,
    updateFilters,
    setQueryParams,
  };
});
