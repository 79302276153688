import { defineStore } from 'pinia';

import { misApi } from '~shared/api';
import { usePage, usePerPage } from '~shared/lib';
import {
  DisabilityResponse,
  DiseaseCode,
  DispensaryResource,
  GetPatientListParams,
  MicroDistrictResource,
  PatientResource,
} from '~shared/api/gen/v2';

export const usePatientsStore = defineStore('patients', {
  state: () => ({
    data: [] as (PatientResource & {
      dispensaries?: (DispensaryResource & {
        disease_code?: DiseaseCode;
      })[];
      disabilities?: DisabilityResponse[];
      microdistrict?: MicroDistrictResource;
    })[],
    loading: false,
    total: 0,
    page: usePage() as { reset: () => Promise<void>; value: number },
    perPage: usePerPage() as { reset: () => Promise<void>; value: number },
  }),
  actions: {
    async getPatients(query: GetPatientListParams = {}) {
      this.loading = true;
      const response = await misApi.gen.v2.patients.getPatientList(query);
      if (response?.data.data) {
        this.data = response.data.data;
        this.total = response.data.meta?.total ?? 0;
      }
      this.loading = false;
    },
  },
});
