import * as amplitude from '@amplitude/analytics-browser';

import { UserRoleEnum } from '~shared/api';
import { findCurrentEnv } from '~shared/lib';

export const init = (currentUser?: number) => {
  amplitude.init(findCurrentEnv('AMPLITUDE_API_KEY'), String(currentUser), {
    minIdLength: 1,
    defaultTracking: false,
  });
};

export const setUser = (
  userId: number,
  clinicId?: number | string,
  userName?: string,
  userRole?: UserRoleEnum
) => {
  const identifyEvent = new amplitude.Identify();
  amplitude.setUserId(String(userId));
  identifyEvent.set('role', userRole ?? '');
  identifyEvent.set('name', userName ?? '');

  if (clinicId) {
    identifyEvent.set('clinic_id', clinicId);
    amplitude.identify(identifyEvent);
  }
};

export const removeUser = () => {
  amplitude.reset();
};

export const logEvent = (event: string, eventProperties?: Record<string, string | number>) => {
  amplitude.track(event, eventProperties);
};

export { logPageVisit } from './logPageVisit';
