import { ElNotification } from 'element-plus';

import { IncomeDto, IncomePayloadDto, IncomeWithProductsDto } from '../types';

import { clearObjectEmptyValues, getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { apiService, MetaDto } from '~shared/api';
import { QueryParams } from '~shared/types';

const urlPrefix = 'incomes';

export const getItems = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{ data: IncomeDto[]; meta: MetaDto }>(
      joinQueryParams({
        url: urlPrefix,
        query: clearObjectEmptyValues(query),
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getItemById = async (id: number) => {
  try {
    const response = await apiService.api.instance.get<{ data: IncomeWithProductsDto }>(
      `${urlPrefix}/${id}`
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createItem = async (payload: IncomePayloadDto) => {
  try {
    const response = await apiService.api.instance.post<{ data: IncomeWithProductsDto }>(
      `${urlPrefix}/create`,
      payload
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const updateItem = async (id: number, payload: IncomePayloadDto) => {
  try {
    const response = await apiService.api.instance.put<{ data: IncomeWithProductsDto }>(
      `${urlPrefix}/${id}/update`,
      payload
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const acceptedItem = async (id: number, payload: IncomePayloadDto) => {
  try {
    const response = await apiService.api.instance.put<{ data: IncomeWithProductsDto }>(
      `${urlPrefix}/${id}/accepted`,
      payload
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const canceledItem = async (id: number) => {
  try {
    const response = await apiService.api.instance.put<{ data: IncomeWithProductsDto }>(
      `${urlPrefix}/${id}/canceled`
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
