import { ROUTE_PATH } from '~shared/config';
import { onlyUserHasRoleMiddleware, onlyUserIsAuthMiddleware } from '~shared/lib';

export const CONTINGENT_MAIN_LIST_ROUTE = {
  name: 'ContingentMainList',
  path: ROUTE_PATH.contingent.active,
  component: () => import('./ContingentMainList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'manager', 'nurse']),
  ],
  meta: {
    title: 'Contingent.Contingent',
  },
};

export const CONTINGENT_ARCHIVED_LIST_ROUTE = {
  name: 'ContingentArchived',
  path: ROUTE_PATH.contingent.archived,
  component: () => import('./ContingentArchivedList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'manager', 'nurse']),
  ],
  meta: {
    title: 'Contingent.Contingent',
  },
};
