import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const PATIENT_MEDICAL_CARD_ROUTE = {
  name: 'PatientMedicalCard',
  path: ROUTE_PATH.patients.medicalCard.rawPath,
  component: () => import('./ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['doctor', 'nurse', 'director']),
  ],
  meta: {
    title: 'MedicalCard.MedicalCard',
  },
};
