import { ref } from 'vue';
import { defineStore } from 'pinia';
import * as Sentry from '@sentry/vue';

import {
  UserFullDto,
  LoginUserRequestData,
  UserCredentialsData,
  UserRoleEnum,
  misApi,
} from '~shared/api';
import { authStorageService, amplitudeService, clinicStorageService } from '~shared/lib';
import { LoginAsRoleRequestBody } from '~shared/api/gen/v2';

type AppointmentDataForCurrentUser = {
  isActiveAppointment: boolean;
  appointmentId: number | null;
};

export const useSessionStore = defineStore('sessionStore', () => {
  const user = ref<UserFullDto | null>(null);
  const userCredentials = ref<UserCredentialsData | null>(null);
  const isAuthorized = ref(false);
  const userRole = ref<UserRoleEnum | null>(null);
  const appointmentDataForCurrentUser = ref<AppointmentDataForCurrentUser>({
    isActiveAppointment: false,
    appointmentId: null,
  });

  function setUserRole(payload: UserRoleEnum) {
    userRole.value = payload;
  }
  function setLoginUser(payload: UserFullDto) {
    setUser(payload);
    isAuthorized.value = true;
    userCredentials.value = null;

    if (user.value) {
      setUserRole(user.value.role);
      amplitudeService.setUser(
        user.value.id,
        (clinicStorageService.getClinic()?.id && user.value.clinic_id) || user.value.clinic?.id,
        user.value.name,
        user.value.role
      );
    }
  }

  function resetUser() {
    user.value = null;
    isAuthorized.value = false;
    removeSentryUser();
    amplitudeService.removeUser();
  }

  function setUserCredentials(payload: UserCredentialsData) {
    userCredentials.value = payload;
  }

  function setActiveAppointmentForCurrentUser(payload: AppointmentDataForCurrentUser) {
    appointmentDataForCurrentUser.value = {
      ...payload,
      isActiveAppointment: true,
    };
  }

  function closeActiveAppointmentForCurrentUser() {
    appointmentDataForCurrentUser.value = {
      isActiveAppointment: false,
      appointmentId: null,
    };
  }

  async function login(payload: LoginUserRequestData | UserCredentialsData | null) {
    if (!payload) return;
    const response = await misApi.g2g.session.login(payload);

    if (response) {
      authStorageService.setAccessToken(response.access_token);
      authStorageService.setRefreshToken(response.refresh_token);
      authStorageService.setUser(response.user);
      setLoginUser(response.user);
    }
  }

  async function loginAsRole(payload: LoginAsRoleRequestBody) {
    const response = await misApi.gen.raw.v2.auth.loginAsRole(payload);

    authStorageService.setAccessToken(response.data.access_token);
    authStorageService.setRefreshToken(response.data.refresh_token);
    // TODO типизировать, когда бэк типизирует user
    authStorageService.setUser(response.data.user as UserFullDto);
    setLoginUser(response.data.user as UserFullDto);
  }

  async function checkAndSetUserAndTokenFromClientStorage() {
    const accessToken = authStorageService.getAccessToken();
    const refreshToken = authStorageService.getRefreshToken();

    if (!accessToken || !refreshToken) return;

    const userFromLocalStorage = authStorageService.getUser();
    if (userFromLocalStorage) {
      setLoginUser(userFromLocalStorage);
    }
    isAuthorized.value = true;
  }

  async function logout() {
    resetUser();
    authStorageService.removeTokens();
    authStorageService.removeAccessTokenPurpose();
    authStorageService.removeUser();
  }

  function setUser(payload: UserFullDto) {
    user.value = payload;
    setSentryUser(user.value);
  }

  function setSentryUser({ role, clinic, email }: UserFullDto) {
    Sentry.setTags({ role, clinicId: clinic?.id, clinicTitle: clinic?.title });
    Sentry.setUser({
      email,
    });
  }
  function removeSentryUser() {
    Sentry.setUser(null);
    Sentry.setTags({ role: null, clinicId: null, clinicTitle: null });
  }

  return {
    user,
    isAuthorized,
    userRole,
    userCredentials,
    appointmentDataForCurrentUser,
    login,
    loginAsRole,
    logout,
    checkAndSetUserAndTokenFromClientStorage,
    setUser,
    setUserCredentials,
    setActiveAppointmentForCurrentUser,
    closeActiveAppointmentForCurrentUser,
  };
});
