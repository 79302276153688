import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const LABORATORY_ORDERS_ROUTE = {
  name: 'LABORATORY_ORDERS',
  path: '/laboratory/orders',
  component: 'VOrders',
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['manager', 'laboratory', 'doctor', 'director']),
  ],
  meta: {
    title: 'Common.Orders',
  },
};

export const LABORATORY_ORDER_ROUTE = {
  name: 'LABORATORY_ORDER',
  path: '/laboratory/orders/:id',
  component: 'VOrder',
  beforeEnter: [onlyUserIsAuthMiddleware],
  props: true,
  meta: {
    title: 'Laboratory.Order.Order',
  },
};

export const LABORATORY_ANALYSIS_REFERENCES_VALUES_ROUTE = {
  name: 'LABORATORY_ANALYSIS_REFERENCES_VALUES',
  path: '/laboratory/analyzes/:analysisId/references-values',
  component: 'VReferencesValues',
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['laboratory'])],
  props: true,
  meta: {
    title: 'Laboratory.ReferencesValues',
  },
};

export const LABORATORY_REFERENCES_GROUPS_ROUTE = {
  name: 'LABORATORY_REFERENCES_GROUPS',
  path: '/laboratory/references-groups',
  component: 'VReferencesGroups',
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['laboratory'])],
  meta: {
    title: 'Laboratory.ReferencesGroups',
  },
};

export const LABORATORY_REFERENCES_GROUPS_VALUES_ROUTE = {
  name: 'LABORATORY_REFERENCES_VALUES',
  path: '/laboratory/references-groups/:referencesGroupId/references-values',
  component: 'VReferencesValues',
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['laboratory'])],
  props: true,
  meta: {
    title: 'Laboratory.ReferencesValues',
  },
};

export const routes = [
  LABORATORY_ORDERS_ROUTE,
  LABORATORY_ORDER_ROUTE,
  LABORATORY_ANALYSIS_REFERENCES_VALUES_ROUTE,
  LABORATORY_REFERENCES_GROUPS_ROUTE,
  LABORATORY_REFERENCES_GROUPS_VALUES_ROUTE,
];
