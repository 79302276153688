import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware } from '~shared/lib';

export const ICON_LIST_ROUTE = {
  name: 'IconList',
  path: ROUTE_PATH.iconList,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Icon list',
  },
};
