<template>
  <MiTable
    :data="dataWithPayload"
    :page="page"
    :per-page="perPage"
    :total="total"
    :loading="loading"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @row-click="rowClickHandler">
    <MiTableColumn prop="title" :label="$t('Common.Naming')" />
    <MiTableColumn prop="_gender" :label="$t('User.Gender')" />
    <MiTableColumn prop="_agePeriod" :label="$t('User.Age')" />

    <MiTableColumn :label="$t('Common.Actions')" width="180px">
      <template #default="{ row }">
        <MiButton type="primary" plain @click.stop="edit(row)">
          {{ $t('Common.Edit') }}
        </MiButton>
      </template>
    </MiTableColumn>
  </MiTable>
</template>

<script>
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { MiTableColumn, MiButton, MiTable } from '~shared/ui';

import CreateOrEditReferencesGroupModal from '@/components/laboratory/referencesGroups/CreateOrEditReferencesGroupModal/index.vue';
import { LABORATORY_REFERENCES_GROUPS_VALUES_ROUTE } from '@/router/laboratory.routes';

export default {
  name: 'ReferencesGroupsTable',
  emits: ['update:page', 'update:perPage', 'item:update'],
  components: { MiTableColumn, MiButton, MiTable },
  props: {
    /** @type {Array<ReferencesGroupDto>} */
    data: Array,
    total: Number,
    loading: Boolean,
    page: Number,
    perPage: Number,
  },
  computed: {
    dataWithPayload() {
      return this.data.map((referencesGroup) => ({
        ...referencesGroup,
        _gender: this.$t(`User.Genders.${referencesGroup.gender}`),
        _agePeriod: `${referencesGroup.start_age} - ${referencesGroup.end_age}`,
      }));
    },
  },

  methods: {
    async edit(referencesGroup) {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditReferencesGroupModal,
        payload: {
          data: referencesGroup,
        },
      });

      if (!(action instanceof GlobalModalClose))
        this.$emit('item:update', action.data.referencesGroup);
    },

    rowClickHandler(referencesGroup) {
      this.$router.push({
        name: LABORATORY_REFERENCES_GROUPS_VALUES_ROUTE.name,
        params: {
          referencesGroupId: referencesGroup.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
