import { ElNotification } from 'element-plus';
import { AxiosError, GenericAbortSignal } from 'axios';

import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';
import { getApiErrorMessage, joinQueryParams, useAbortable } from '~shared/lib';
import { SpecialityDto } from '~shared/api/b2b';

const urlPath = '/specialties';

export const getList = async (query: QueryParams = {}, signal?: GenericAbortSignal) => {
  try {
    const response = await apiService.api.instance.get<{ data: SpecialityDto[] }>(
      joinQueryParams({
        url: urlPath,
        query,
      }),
      {
        signal,
      }
    );

    return {
      data: response.data?.data,
    };
  } catch (err) {
    if (err instanceof AxiosError && err.code === 'ERR_CANCELED') return;
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getListV2 = async (query: QueryParams = {}, signal?: GenericAbortSignal) => {
  try {
    const response = await apiService.api.instance.get<{ data: SpecialityDto[] }>(
      joinQueryParams({
        url: urlPath,
        query,
      }),
      {
        signal,
      }
    );

    return response;
  } catch (err) {
    if (err instanceof AxiosError && err.code === 'ERR_CANCELED') return;
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const abortableGetList = useAbortable(getList);
