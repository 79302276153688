import { computed, ref, watch } from 'vue';

import { localStorageUtils } from '..';

import { localStorageKeys } from '~shared/config';

const keys = Object.values(localStorageKeys);

type KeyEnum = (typeof keys)[number];

export const useLocalStorage = <T = unknown>(key: KeyEnum) => {
  const localStorageValue = computed(
    (): T | undefined => <T | null>localStorageUtils.getParsedItem(key) ?? undefined
  );

  const value = ref(localStorageValue.value);

  watch(
    () => value.value,
    (newValue) => {
      if (newValue) {
        localStorageUtils.setItemSafely(key, newValue);
      } else {
        localStorage.removeItem(key);
      }
    },
    { deep: true }
  );

  return value;
};
