import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { getFormattedCategory } from '../lib';

import { misApi, PatientShortDto, SearchPatientRequestPayload } from '~shared/api';

export const usePatientSearchStore = defineStore('patientSearch', () => {
  const searchResult = ref<(PatientShortDto & { category_title?: string }) | null | undefined>(
    null
  );

  const loading = ref(false);
  const visible = ref(false);
  const readOnly = ref(false);
  const isFetched = ref(false);
  const successful = computed(() => !!searchResult.value && !!searchResult.value.id);

  function setVisible(value: boolean) {
    visible.value = value;
  }

  function resetData() {
    searchResult.value = null;
    isFetched.value = false;
    readOnly.value = false;
  }

  async function searchPatient(payload: SearchPatientRequestPayload) {
    loading.value = true;
    const response = await misApi.g2g.patients.search(payload);

    if (response?.data) {
      searchResult.value = {
        ...response.data,
        category_title: getFormattedCategory(response.data.category),
      };
    }

    isFetched.value = true;
    readOnly.value = true;
    loading.value = false;
  }

  return {
    searchResult,
    loading,
    visible,
    isFetched,
    readOnly,
    successful,
    setVisible,
    resetData,
    searchPatient,
  };
});
