import { ElNotification } from 'element-plus';

import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';
import { getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { SlotResource } from '~shared/api/common';

const urlPath = 'schedule';

export async function getSlots(query: QueryParams) {
  try {
    const response = await apiService.api.instance.get<{
      data: SlotResource[];
    }>(
      joinQueryParams({
        url: `${urlPath}/slots/byGroup`,
        query,
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
}
