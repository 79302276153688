import { ElNotification } from 'element-plus';

import {
  ContactInfoDto,
  GetContactInfoListPayload,
  GetContactInfoListResponseData,
  CreateContactInfoPayload,
} from '../types';

import { getApiErrorMessage } from '~shared/lib';
import { apiService } from '~shared/api';

const urlPath = 'g2g/contact_informations';

export const getList = async ({ userId }: GetContactInfoListPayload) => {
  try {
    return await apiService.api.instance.get<GetContactInfoListResponseData>(
      `${urlPath}/${userId}`
    );
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
export const create = async (payload: CreateContactInfoPayload) => {
  try {
    return await apiService.api.instance.post<{ data: ContactInfoDto }>(
      `${urlPath}/${payload.userId}/create`,
      payload
    );
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const update = async (payload: ContactInfoDto) => {
  try {
    return await apiService.api.instance.put<{ data: ContactInfoDto }>(
      `${urlPath}/${payload.id}/update`,
      payload
    );
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const remove = async (payload: { id: number }) => {
  try {
    return await apiService.api.instance.delete(`${urlPath}/${payload.id}/delete`);
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
