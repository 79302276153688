import { ElNotification } from 'element-plus';

import {
  GetCardByAppointmentIdResponseData,
  GetCardByIdResponseData,
  UpdateCardPayload,
  UpdateCardResponseData,
} from '../types';

import { getApiErrorMessage } from '~shared/lib';
import { apiService } from '~shared/api';

const urlPath = 'g2g/cards';

export const getByAppointmentId = async (appointmentId?: number | string) => {
  try {
    if (!appointmentId) {
      throw new Error('getByAppointmentId ERROR: appointmentId is not provided');
    }

    const response = await apiService.api.instance.get<GetCardByAppointmentIdResponseData>(
      `g2g/cards/${appointmentId}/get`
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getById = async (id?: number | string) => {
  try {
    if (!id) {
      throw new Error('Id is not provided');
    }

    const response = await apiService.api.instance.get<GetCardByIdResponseData>(`${urlPath}/${id}`);

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const update = async ({ id, ...rest }: UpdateCardPayload) => {
  try {
    if (!id) {
      throw new Error('Id is not provided');
    }

    const response = await apiService.api.instance.put<UpdateCardResponseData>(
      `${urlPath}/${id}/update`,
      rest
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
