import { ElNotification } from 'element-plus';

import { StatisticCommonResourse } from '../types';

import { apiService } from '~shared/api';
import { getApiErrorMessage, joinQueryParams } from '~shared/lib';

type CommonParams = {
  startAt?: string;
  endAt?: string;
  doctorId?: string | number;
};

type ParamsWithRequiredDoctorId = {
  startAt?: string;
  endAt?: string;
  doctorId: string | number;
};

export const getAppointmentsCount = async (query: CommonParams) => {
  try {
    const queryParams = { start_at: query.startAt, end_at: query.endAt, doctor_id: query.doctorId };

    const response = await apiService.api.instance.get<StatisticCommonResourse>(
      joinQueryParams({
        url: `statistics/appointments/count`,
        query: queryParams,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getCompletedAppointments = async (query: CommonParams) => {
  try {
    const queryParams = { start_at: query.startAt, end_at: query.endAt, doctor_id: query.doctorId };

    const response = await apiService.api.instance.get<StatisticCommonResourse>(
      joinQueryParams({
        url: 'statistics/appointments/successful',
        query: queryParams,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getProvidedServices = async (query: ParamsWithRequiredDoctorId) => {
  try {
    const queryParams = { start_at: query.startAt, end_at: query.endAt };

    const response = await apiService.api.instance.get<StatisticCommonResourse>(
      joinQueryParams({
        url: `statistics/doctors/${query.doctorId}/services/count`,
        query: queryParams,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getServicesPrice = async (query: ParamsWithRequiredDoctorId) => {
  try {
    const queryParams = { start_at: query.startAt, end_at: query.endAt };

    const response = await apiService.api.instance.get<StatisticCommonResourse>(
      joinQueryParams({
        url: `statistics/doctors/${query.doctorId}/services/price`,
        query: queryParams,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getAppointmentsOnTime = async (query: CommonParams) => {
  try {
    const queryParams = { start_at: query.startAt, end_at: query.endAt, doctor_id: query.doctorId };

    const response = await apiService.api.instance.get<StatisticCommonResourse>(
      joinQueryParams({
        url: `statistics/appointments/onTime`,
        query: queryParams,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getPatientAverageWaitingByDoctor = async (query: ParamsWithRequiredDoctorId) => {
  try {
    const queryParams = { start_at: query.startAt, end_at: query.endAt };

    const response = await apiService.api.instance.get<{
      time_waiting: number;
      time_appointment: number;
    }>(
      joinQueryParams({
        url: `statistics/doctors/${query.doctorId}/appointments/average/downtime`,
        query: queryParams,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getPatientAverageWaiting = async (query: CommonParams) => {
  try {
    const queryParams = { start_at: query.startAt, end_at: query.endAt };

    const response = await apiService.api.instance.get<{
      time_waiting: number;
      time_appointment: number;
    }>(
      joinQueryParams({
        url: `statistics/appointments/average/waiting`,
        query: queryParams,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getInvoicesIncome = async (query: CommonParams) => {
  try {
    const queryParams = { start_at: query.startAt, end_at: query.endAt };

    const response = await apiService.api.instance.get<StatisticCommonResourse>(
      joinQueryParams({
        url: `statistics/invoices/income`,
        query: queryParams,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getPatientsCount = async (query: CommonParams) => {
  try {
    const queryParams = { start_at: query.startAt, end_at: query.endAt };

    const response = await apiService.api.instance.get<StatisticCommonResourse>(
      joinQueryParams({
        url: `statistics/patients/count`,
        query: queryParams,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getPatientSeparationStatistic = async () => {
  try {
    const response = await apiService.api.instance.get<{
      count: number;
      man: number;
      woman: number;
    }>(`statistics/patients/total`);

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getRepeatPatients = async (query: CommonParams) => {
  try {
    const queryParams = { start_at: query.startAt, end_at: query.endAt, doctor_id: query.doctorId };

    const response = await apiService.api.instance.get<StatisticCommonResourse>(
      joinQueryParams({
        url: `statistics/patients/returned`,
        query: queryParams,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getWeeklyWorkloadByPeriod = async (query: Omit<CommonParams, 'doctorId'>) => {
  try {
    const queryParams = { start_at: query.startAt, end_at: query.endAt };

    const response = await apiService.api.instance.get<number[]>(
      joinQueryParams({
        url: `statistics/clinic/workload/weekly`,
        query: queryParams,
      })
    );

    return response.data;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getDailyWorkloadByPeriod = async (query: Omit<CommonParams, 'doctorId'>) => {
  try {
    const queryParams = { start_at: query.startAt, end_at: query.endAt };

    const response = await apiService.api.instance.get<Record<string, number>>(
      joinQueryParams({
        url: `statistics/clinic/workload/daily`,
        query: queryParams,
      })
    );

    return response.data;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
