<template>
  <div>
    <div class="printer-document-content-block__editor-label" v-if="block.label">
      {{ block.label }}
    </div>
    <EditorCard
      class="printer-document-content-block__editor-card"
      :data="block.basicData"
      readonly />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import { PrinterDocumentContentBlock } from '@/models/client/PrinterDocumentContentBlock.model';

export default {
  name: 'PrinterDocumentContentBlockEditor',
  components: {
    EditorCard: defineAsyncComponent(() => import('@/components/EditorCard/index.vue')),
  },
  props: {
    block: [PrinterDocumentContentBlock, Object],
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
