import { ROUTE_PATH } from '~shared/config';
import { onlyUserHasRoleMiddleware, onlyUserIsAuthMiddleware } from '~shared/lib';

export const INPATIENT_CARE_LIST_ROUTE = {
  name: 'InpatientCareList',
  path: ROUTE_PATH.inpatientCare.list,
  component: () => import('./ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'InpatientCare.InpatientCare',
  },
};
