<template>
  <MainLayout content-class="v-order" :loading="loading.order">
    <template v-if="order.id">
      <div>
        <PatientCardRow
          class="v-order__patient-card-row"
          :patient="order?.user"
          :items="patientCardItems" />

        <MiFilterBox :title="$t('Laboratory.Results')">
          <template #actions>
            <MiButton
              v-show="actionsVisible.rePickReferencesGroup"
              type="primary"
              plain
              :loading="loading.rePickReferencesGroup"
              @click="rePickReferencesGroup">
              {{ $t('Laboratory.RePickReferencesGroup') }}
            </MiButton>

            <MiButton
              v-show="actionsVisible.addBiomaterial"
              type="primary"
              :loading="loading.biomaterial"
              @click="addBiomaterial">
              {{ $t('Laboratory.Biomaterial.AddBiomaterial') }}
            </MiButton>
          </template>
        </MiFilterBox>

        <OrderBiomaterialDataActionsCard
          v-for="biomaterial in order.biomaterials"
          :key="biomaterial.id"
          :biomaterial="biomaterial"
          :studys="biomaterial.studys"
          v-model:order="order"
          :editable="editable"
          v-loading="loading.study && loadingStudyBiomaterialId === biomaterial.id"
          @update:studys="localUpdateStudiesByBiomaterialId($event, biomaterial.id)"
          @study:create="localCreateStudyByBiomaterialId($event, biomaterial.id)"
          @study:update="localUpdateStudyByBiomaterialId($event, biomaterial.id)"
          @study:update:api="updateStudyByBiomaterialId($event, biomaterial.id)"
          @study:delete="localDeleteStudyByBiomaterialId($event, biomaterial.id)"
          @biomaterial:delete="localDeleteBiomaterial(biomaterial.id)"
          @biomaterial:update="localUpdateBiomaterial($event, biomaterial.id)"
          @editor:change="saveEditorData($event, biomaterial.id)"
          @editor:update="updateEditorData($event, biomaterial.id)" />

        <div class="v-order__actions v-order-actions">
          <MiButton text @click="print">
            <template #icon> <MiIcon icon="PRINTER" /> </template>
            {{ $t('Common.Print') }}
          </MiButton>
        </div>

        <OrderPrinterDocument :order="order" ref="orderPrinterDocument" />
      </div>
    </template>
  </MainLayout>
</template>

<script src="./index.js"></script>

<style lang="scss" src="./index.scss" />
