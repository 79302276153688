import { ElNotification } from 'element-plus';

import {
  CreateInpatientCarePayload,
  GetInpatientCareListResponseData,
  InpatientCareFullDto,
  InpatientCareStatus,
  InvoiceDto,
  UpdateInpatientCarePayload,
  UpdateDocumentPayload,
} from '../types';

import { getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';

const urlPath = 'g2g/hospitals';

export const getList = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<GetInpatientCareListResponseData>(
      joinQueryParams({
        url: urlPath,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getById = async (id: number | string) => {
  try {
    const response = await apiService.api.instance.get<{ data: InpatientCareFullDto }>(
      `${urlPath}/${id}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const updateStatus = async (id: number | string, status: InpatientCareStatus) => {
  try {
    const response = await apiService.api.instance.put<{ data: InpatientCareFullDto }>(
      `${urlPath}/${id}/${status}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const create = async (payload: CreateInpatientCarePayload) => {
  try {
    const response = await apiService.api.instance.post<{ data: InpatientCareFullDto }>(
      `${urlPath}/create`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const update = async (id: number | string, payload: UpdateInpatientCarePayload) => {
  try {
    const response = await apiService.api.instance.put<{ data: InpatientCareFullDto }>(
      `${urlPath}/${id}/update`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createInvoice = async (id: number | string) => {
  try {
    const response = await apiService.api.instance.post<{ data: InvoiceDto }>(
      `${urlPath}/${id}/invoice`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createTreatmentDiary = async (id: number | string) => {
  try {
    const response = await apiService.api.instance.post<{ data: InvoiceDto }>(
      `${urlPath}/${id}/treatment-diary`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getDocumentList = async (id: string | number) => {
  try {
    const response = await apiService.api.instance.get(`${urlPath}/${id}/documents`);
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const updateDocument = async (id: string | number, payload: UpdateDocumentPayload) => {
  try {
    const response = await apiService.api.instance.put(`${urlPath}/${id}/documents`, payload);
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
