import { onlyUserIsAuthMiddleware } from '~shared/lib';
import { ROUTE_PATH } from '~shared/config';

export const CHECKUP_TEMPLATE_HEADER_ROUTE = {
  name: 'CheckupHeaderTemplate',
  path: `${ROUTE_PATH.templates.checkupHeaderTemplate.rawPath}/:id?`,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Templates.CreatingCheckupHeader',
  },
};
