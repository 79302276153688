import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const HOSPITALIZATION_REFERRAL_ROUTE = {
  name: 'HospitalizationReferral',
  path: ROUTE_PATH.hospitalization.referral,
  component: () => import('./HospitalizationReferral/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'manager']),
  ],
  meta: {
    title: 'Hospitalization.Hospitalization',
  },
};

export const HOSPITALIZATION_REFERRAL_MEDICAL_CASE_ROUTE = {
  name: 'HospitalizationReferralMedicalCase',
  path: ROUTE_PATH.hospitalization.referralMedicalCase,
  component: () => import('./HospitalizationReferralMedicalCase/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'manager']),
  ],
  meta: {
    title: 'Hospitalization.Hospitalization',
  },
};

export const EDIT_HOSPITALIZATION_REFERRAL_ROUTE = {
  name: 'EditHospitalizationReferral',
  path: ROUTE_PATH.hospitalization.editReferral.rawPath,
  component: () => import('./HospitalizationReferral/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'manager']),
  ],
  meta: {
    title: 'Hospitalization.Hospitalization',
  },
};

export const VIEWING_HOSPITALIZATION_REFERRAL_ROUTE = {
  name: 'ViewingHospitalizationReferral',
  path: ROUTE_PATH.hospitalization.viewingReferral.rawPath,
  component: () => import('./ViewingHospitalizationReferral/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'manager']),
  ],
  meta: {
    title: 'Hospitalization.ViewHospitalizationReferral',
  },
};

export const HOSPITALIZATION_LIST_ROUTE = {
  name: 'HospitalizationList',
  path: ROUTE_PATH.hospitalization.list,
  component: () => import('./HospitalizationList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'manager']),
  ],
  meta: {
    title: 'Hospitalization.Hospitalization',
  },
};
