export const USER_KEY = 'user';
export const DEVICE_ID_KEY = 'device_id';
export const LOCALE_KEY = 'locale';
export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const LABORATORY_DEPARTMENT = 'laboratory_department';
export const APP_CONFIG = 'app_config';
export const CLINIC_KEY = 'clinic';
export const USER_CONFIG = 'user_config';
export const RECIPE_SIGN_DATE_LAST_ATTEMPT = 'RECIPE_SIGN_DATE_LAST_ATTEMPT';
export const RECIPE_SIGN_COUNT_ATTEMPTS = 'RECIPE_SIGN_COUNT_ATTEMPTS';
export const ACCESS_TOKEN_PURPOSE = 'access_token_purpose';
