import { ElNotification } from 'element-plus';

import { ReferencesGroupDto, ReferencesValueDto } from '../types';

import { apiService, MetaDto } from '~shared/api';
import { joinQueryParams, getApiErrorMessage } from '~shared/lib';
import { QueryParams } from '~shared/types';

const urlPrefix = 'references';

export const getReferencesGroupItems = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{
      data: ReferencesGroupDto[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPrefix}/groups`,
        query,
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getReferencesGroupById = async (id: number) => {
  try {
    const response = await apiService.api.instance.get<{ data: ReferencesGroupDto }>(
      `${urlPrefix}/groups/${id}`
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createReferencesGroup = async (payload: ReferencesGroupDto) => {
  try {
    const response = await apiService.api.instance.post<{ data: ReferencesGroupDto }>(
      `${urlPrefix}/groups/create`,
      payload
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const updateReferencesGroupItem = async (id: string, payload: ReferencesGroupDto) => {
  try {
    const response = await apiService.api.instance.put<{ data: ReferencesGroupDto }>(
      `${urlPrefix}/groups/${id}/update`,
      payload
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getReferencesValueItems = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{
      data: ReferencesValueDto[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPrefix}/values`,
        query,
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createReferencesValue = async (payload: ReferencesValueDto) => {
  try {
    const response = await apiService.api.instance.post<{ data: ReferencesValueDto }>(
      `${urlPrefix}/values/create`,
      payload
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const updateReferencesValueItem = async (id: string, payload: ReferencesValueDto) => {
  try {
    const response = await apiService.api.instance.put<{ data: ReferencesValueDto }>(
      `${urlPrefix}/values/${id}/update`,
      payload
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const deleteReferencesValueItem = async (id: number) => {
  try {
    await apiService.api.instance.delete(`${urlPrefix}/values/${id}/delete`);
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
