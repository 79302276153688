import { ROUTE_PATH } from '~shared/config';
import { onlyUserHasRoleMiddleware, onlyUserIsAuthMiddleware } from '~shared/lib';

export const SETTINGS_LIST_ROUTE = {
  name: 'SettingsList',
  path: ROUTE_PATH.settings.main,
  component: () => import('./SettingsList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'manager'])],
  meta: {
    title: 'Common.Settings',
  },
};
export const SETTINGS_ORGANIZATIONS_TYPE_ROUTE = {
  name: 'SettingsOrganizationsType',
  path: ROUTE_PATH.settings.organizationsTypes,
  component: () => import('./SettingsOrganizationsType/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'manager'])],
  meta: {
    title: 'Common.Settings',
  },
};

export const SETTINGS_ORGANIZATIONS_ROUTE = {
  name: 'SettingsOrganizations',
  path: ROUTE_PATH.settings.organizations,
  component: () => import('./SettingsOrganizations/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'manager'])],
  meta: {
    title: 'Common.Settings',
  },
};

export const SETTINGS_POSITIONS_ROUTE = {
  name: 'SettingsPositions',
  path: ROUTE_PATH.settings.positions,
  component: () => import('./SettingsPositions/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'manager'])],
  meta: {
    title: 'Common.Settings',
  },
};
