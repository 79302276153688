<template>
  <VeeForm
    class="income-product-table-filling-form"
    :validation-schema="validationSchema"
    v-slot="{ meta }"
    @submit="onSubmit">
    <div class="income-product-table-filling-form__field-group">
      <FormSearchSelectField
        field-name="product_id"
        field-object-name="product"
        :fetch-data="misApi.common.product.getItems"
        :placeholder="$t('Income.SearchGoods')"
        show-create-option
        @create="$emit('product:create', $event)" />
      <FormTextField v-show="false" field-name="product.title" disabled />
      <FormTextField
        field-name="product.article"
        :placeholder="$t('Nomenclature.VendorCode')"
        disabled
        :in-column="false" />
      <FormTextField
        field-name="price"
        :placeholder="$t('Income.Price')"
        :in-column="false"
        required
        mask-name="float-12.2" />
      <FormNumberField
        field-name="planed_count"
        :placeholder="$t('Nomenclature.Qty')"
        :in-column="false"
        :min="1" />
      <div />
    </div>
    <MiButton type="primary" native-type="submit" :disabled="!meta.dirty"
      >{{ `+ ${$t('Common.Add')}` }}
    </MiButton>
  </VeeForm>
</template>

<script lang="ts">
export default {
  name: 'IncomeProductsTableFillingForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { SubmissionHandler, Form as VeeForm } from 'vee-validate';
import { object, number, string } from 'yup';

import { I18nService } from '~shared/lib';
import { MiButton } from '~shared/ui';
import { misApi } from '~shared/api';

import FormNumberField from '@/components/common/form/ui/FormNumberField/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';

const MAX_AMOUNT = 2147683648;

const emit = defineEmits(['table:add', 'product:create']);

const validationSchema = object({
  product_id: number().nullable().required(I18nService.t('Validation.RequiredField')),
  planed_count: number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(I18nService.t('Validation.RequiredField')),
  price: string()
    .nullable()

    .required(I18nService.t('Validation.RequiredField'))
    .test(
      'price',
      `${I18nService.t('Validation.MaxAmount')} ${MAX_AMOUNT}`,
      (value) => value.length > 0 && Number(value) <= MAX_AMOUNT
    ),
});

const onSubmit: SubmissionHandler<Record<string, unknown>> = async (values, actions) => {
  actions.resetForm();
  emit('table:add', values);
};
</script>

<style lang="scss" src="./index.scss" />
