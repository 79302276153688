<template>
  <MainLayout :roles="['admin']">
    <MiFilterBox>
      <ElPageHeader :title="$t('Common.Back')" :content="title" @back="backHandler"> </ElPageHeader>

      <template #actions>
        <MiButton type="primary" @click="create">
          {{ $t('Common.Create') }}
        </MiButton>
      </template>
    </MiFilterBox>

    <ReferencesValuesTable
      :data="items"
      :total="total"
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      @item:update="editItem"
      @item:delete="deleteItem" />
  </MainLayout>
</template>

<script src="./index.js"></script>
<style lang="scss" src="./index.scss" />
