import { ElNotification } from 'element-plus';

import { getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { QueryParams } from '~shared/types';
import { apiService, GetAnalysisListResponseData } from '~shared/api';

const urlPath = 'g2g/analysis/by-category';

export const getList = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<GetAnalysisListResponseData>(
      joinQueryParams({
        url: urlPath,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
