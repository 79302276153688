export * from './api';
export * from './app';
export * from './date';
export * from './i18n';
export * from './localStorage';
export * from './router';
export * from './query';
export * from './emits';
export * as localStorageKeys from './localStorage';
import * as tableNames from './tableNames';

export const tableNameValues = Object.values(tableNames);
