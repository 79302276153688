import { object, string, array } from 'yup';

import { I18nService } from '~shared/lib';

export const validationSchema = object().shape({
  dispensaries: array().of(
    object().shape({
      disease_code: string().when(['deleted'], (fields, schema) => {
        if (fields[0] !== true) {
          return schema
            .required(I18nService.t('Validation.RequiredField'))
            .test(
              'diseaseCodeAlreadyExists',
              I18nService.t('Validation.ThisValueIsAlreadyInTheList'),
              (value, context) => {
                const dispensaries =
                  (context.from?.[1]?.value.dispensaries as
                    | Array<{
                        disease_code: string;
                        deleted?: boolean;
                        registration_date?: string;
                        deregistration_date?: string;
                      }>
                    | undefined) ?? [];

                const filteredDispensaries = dispensaries.filter(
                  (f) => f.deleted !== true && f.disease_code === value
                );

                const dublicates: number[] = [];

                filteredDispensaries.forEach((currentItem, idx, currentArray) => {
                  const slicedCurrentArray = [
                    ...currentArray.slice(0, idx),
                    ...currentArray.slice(idx + 1),
                  ];

                  if (
                    slicedCurrentArray.some(
                      (item) =>
                        (item.registration_date === currentItem.registration_date &&
                          item.deregistration_date === currentItem.deregistration_date) ||
                        (!currentItem.deregistration_date && !item.deregistration_date) ||
                        ((!currentItem.deregistration_date || !item.deregistration_date) &&
                          item.registration_date === currentItem.registration_date)
                    )
                  ) {
                    dublicates.push(idx);
                  }
                });

                const isValueAlreadyExists = dublicates.length > 0;

                if (isValueAlreadyExists) {
                  return false;
                }

                return true;
              }
            );
        }
        return schema;
      }),
      registration_date: string().when(['deleted'], (fields, schema) => {
        if (fields[0] !== true) {
          return schema.required(I18nService.t('Validation.RequiredField'));
        }
        return schema;
      }),
    })
  ),
  pregnancies: array().of(
    object().shape({
      first_day_menstruation: string().when(['deleted'], (fields, schema) => {
        if (fields[0] !== true) {
          return schema.required(I18nService.t('Validation.RequiredField'));
        }
        return schema;
      }),
      planned_date_birth: string().when(['deleted'], (fields, schema) => {
        if (fields[0] !== true) {
          return schema.required(I18nService.t('Validation.RequiredField'));
        }
        return schema;
      }),
      count_previous_births: string().when(['deleted'], (fields, schema) => {
        if (fields[0] !== true) {
          return schema.required(I18nService.t('Validation.RequiredField'));
        }
        return schema;
      }),
    })
  ),
  disabilities: array().of(
    object().shape({
      group: string().when(['deleted'], (fields, schema) => {
        if (fields[0] !== true) {
          return schema.required(I18nService.t('Validation.RequiredField'));
        }
        return schema;
      }),
      disease_code: string().when(['deleted'], (fields, schema) => {
        if (fields[0] !== true) {
          return schema.required(I18nService.t('Validation.RequiredField'));
        }
        return schema;
      }),
      registration_date: string().when(['deleted'], (fields, schema) => {
        if (fields[0] !== true) {
          return schema.required(I18nService.t('Validation.RequiredField'));
        }
        return schema;
      }),
    })
  ),
  vaccines: array().of(
    object().shape({
      vaccine_id: string().when(['deleted'], (fields, schema) => {
        if (fields[0] !== true) {
          return schema.required(I18nService.t('Validation.RequiredField'));
        }
        return schema;
      }),
      vaccine_date: string().when(['deleted'], (fields, schema) => {
        if (fields[0] !== true) {
          return schema.required(I18nService.t('Validation.RequiredField'));
        }
        return schema;
      }),
    })
  ),
  beneficiary: array().of(
    object().shape({
      category: string().when(['deleted'], (fields, schema) => {
        if (fields[0] !== true) {
          return schema.required(I18nService.t('Validation.RequiredField'));
        }
        return schema;
      }),
    })
  ),
});
