<template>
  <MiButton v-bind="$props" @click.stop="isModalVisible = true">
    <template v-if="$slots.default">
      <span style="text-wrap: wrap">
        <slot />
      </span>
    </template>
    <template v-else>
      {{ $t('Contingent.Attach') }}
    </template>
  </MiButton>

  <AttachPatientToPolyclinicModal
    v-model="isModalVisible"
    :patient-id="patientId"
    :doctor="doctor"
    :microdistrict="microdistrict"
    @update-successful="$emit('update-successful', $event)" />
</template>

<script lang="ts" setup>
import { shallowRef } from 'vue';

import { AttachPatientToPolyclinicModal } from '../AttachPatientToPolyclinicModal';

import { CreatePatientsClinicAttachmentsResource } from '~features/patients';
import { MiButton, MiButtonProps } from '~shared/ui';
import { DoctorResource, MicroDistrictResource } from '~shared/api/gen/v2';

type Props = {
  patientId?: string | number | null;
  doctor?: DoctorResource;
  microdistrict?: MicroDistrictResource;
} & MiButtonProps;

withDefaults(defineProps<Props>(), {
  size: 'small',
  type: 'primary',
});

defineEmits<{
  'update-successful': [e: CreatePatientsClinicAttachmentsResource];
}>();

const isModalVisible = shallowRef(false);
</script>
