<template>
  <ElTooltip
    :disabled="props.disabled"
    effect="light"
    :content="props.description"
    :placement="props.location">
    <slot v-if="$slots.default" />
    <MiIcon icon="TOOLTIP" />
  </ElTooltip>
</template>

<script lang="ts" setup>
import { Placement, ElTooltip } from 'element-plus';

import { MiIcon } from '~shared/ui';

type Props = {
  description: string;
  location?: Placement;
  disabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  description: '',
  location: 'top-start',
});
</script>

<style lang="scss" scoped></style>
