import { object, number } from 'yup';

import { I18nService } from '~shared/lib';
import {
  ClinicAttachmentResource,
  ClinicTitleResponse,
  DoctorNameResource,
  MicroDistrictResource,
} from '~shared/api/gen/v2';

export const validationSchema = object().shape({
  doctor_id: number().required(() => I18nService.t('Validation.RequiredField')),
  microdistrict_id: number().required(() => I18nService.t('Validation.RequiredField')),
});

export type CreatePatientsClinicAttachmentsResource = {
  data: ClinicAttachmentResource & {
    clinic: ClinicTitleResponse;
    microdistrict: MicroDistrictResource;
    doctor: DoctorNameResource;
  };
};
