import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const ATTACHED_POPULATION_ROUTE = {
  name: 'AttachedPopulation',
  path: ROUTE_PATH.attachedPopulation,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Analytics.AttachedPopulation',
  },
};
