import { ElNotification } from 'element-plus';
import { AxiosError, GenericAbortSignal } from 'axios';

import {
  CreateEmployeeRequestBody,
  DeviceDto,
  EmployeeDoctorWithStatisticsResourse,
  EmployeeFullResource,
  EmployeeShortResource,
  EmployeeDoctorFullResource,
} from '../types';

import { getApiErrorMessage, joinQueryParams, useAbortable } from '~shared/lib';
import { MetaDto, apiService } from '~shared/api';
import { QueryParams } from '~shared/types';

const urlPath = '/users';

export const getList = async (query: QueryParams = {}, signal?: GenericAbortSignal) => {
  try {
    const response = await apiService.api.instance.get<{
      data: EmployeeShortResource[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: urlPath,
        query,
      }),
      {
        signal,
      }
    );

    return response;
  } catch (err) {
    if (err instanceof AxiosError && err.code === 'ERR_CANCELED') return;
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getEmployeeDoctorWithStatisticsList = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{
      data: EmployeeDoctorWithStatisticsResourse[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: 'statistics/doctors',
        query,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const abortableGetList = useAbortable(getList);

export const getKey = async (userId: EmployeeShortResource['id']) => {
  try {
    const response = await apiService.api.instance.post<{ key_activating: number }>(
      `${urlPath}/${userId}/key`
    );

    return {
      data: response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getById = async (id: number | string) => {
  try {
    const response = await apiService.api.instance.get<{ data: EmployeeFullResource }>(
      `${urlPath}/${id}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getEmployeeDoctorById = async (id: number | string) => {
  try {
    const response = await apiService.api.instance.get<{ data: EmployeeDoctorFullResource }>(
      `doctors/${id}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getDevices = async (userId: string | number, query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{
      data: DeviceDto[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPath}/${userId}/devices`,
        query,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const create = async (payload: CreateEmployeeRequestBody) => {
  try {
    const response = await apiService.api.instance.post<{ data: EmployeeFullResource }>(
      `${urlPath}/create`,
      payload
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const update = async (payload: CreateEmployeeRequestBody) => {
  try {
    const response = await apiService.api.instance.put<{ data: EmployeeFullResource }>(
      `${urlPath}/${payload.id}/update`,
      payload
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
