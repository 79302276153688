<template>
  <MiCheckbox
    class="main-contingent-checkbox"
    v-bind="props"
    v-model="value"
    @change="$emit('change', $event)">
    <template #default>
      <div class="main-contingent-checkbox__content">
        <span>{{ $t('Contingent.MainContingent') }}</span>
        <MiIcon icon="MAIN_CONTINGENT" />
      </div>
    </template>
  </MiCheckbox>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { CheckboxValueType } from 'element-plus';

import { MiCheckbox, MiCheckboxProps, MiIcon } from '~shared/ui';

type Props = Partial<MiCheckboxProps>;

const props = withDefaults(defineProps<Props>(), {
  validateEvent: false,
});

const emit = defineEmits<{
  'update:modelValue': [e: MiCheckboxProps['modelValue'] | undefined];
  'change': [e: CheckboxValueType | undefined];
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val: MiCheckboxProps['modelValue'] | undefined) {
    emit('update:modelValue', val);
  },
});
</script>

<style lang="scss">
.main-contingent-checkbox {
  margin-right: 0;

  &__content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
</style>
