import { createApp } from 'vue';
import { ElLoading, ElInfiniteScroll, ElNotification } from 'element-plus';
import dayjs from 'dayjs';

import 'dayjs/locale/ru'; // Date and time localization

import App from './App.vue';
import { router, storeVuex, storePinia, createSentryIns } from './providers';

import { I18nPlugin } from '~shared/lib';

import './index.scss';

dayjs.locale('ru');

const app = createApp(App)
  .use(router)
  // TODO: переходное решение, в будущем выпилить
  .use(storeVuex)
  .use(storePinia)
  .use(I18nPlugin)
  .use(ElLoading)
  .use(ElInfiniteScroll)
  .use(ElNotification);

// init global ui components
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UiComponents = import.meta.glob<true, string, any>('../components/ui/**/*.vue', {
  eager: true,
});
for (const key in UiComponents) {
  const component = UiComponents[key].default;
  app.component(component.name, component);
}

// init sentry
createSentryIns(app);

app.mount('#app');
