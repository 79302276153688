<template>
  <MiSelect v-bind="$props" v-model="value">
    <ElOption
      v-for="option in options"
      :key="typeof option.value === 'boolean' ? Number(option.value) : option.value"
      :label="option.label"
      :value="option.value">
      <slot v-if="$slots.option" :item="option" name="option" />
    </ElOption>
  </MiSelect>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { ElOption } from 'element-plus';

import { MiSelect, MiSelectProps } from '~shared/ui';

export type MiSelectWithOptionProps = {
  options: { label: string; value: string | number | boolean; disabled?: boolean }[];
  noEmptyStringValue?: boolean;
} & Partial<MiSelectProps>;

const props = withDefaults(defineProps<MiSelectWithOptionProps>(), {
  reserveKeyword: true,
  teleported: true,
  persistent: true,
  validationEvent: true,
  noEmptyStringValue: true,
});

const emit = defineEmits<{
  'update:modelValue': [e: MiSelectWithOptionProps['modelValue']];
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val: MiSelectWithOptionProps['modelValue']) {
    emit('update:modelValue', val);
  },
});

watch(
  () => value.value,
  (v) => {
    if (v === '' && props.noEmptyStringValue) {
      value.value = undefined;
    }
  }
);

defineSlots<{
  option(props: { item: MiSelectWithOptionProps['options'][number] }): never;
}>();
</script>

<style lang="scss" src="./index.scss" />
