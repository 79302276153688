import { ElNotification } from 'element-plus';

import { AnswerCreateDto, FeedbackFullDto } from '../types';

import { QueryParams } from '~shared/types';
import { MetaDto, apiService } from '~shared/api';
import { getApiErrorMessage, joinQueryParams } from '~shared/lib';

const urlPath = 'b2g/feedbacks';

export const getItems = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{
      data: FeedbackFullDto[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPath}`,
        query,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createAnswer = async (id: number, payload: AnswerCreateDto) => {
  try {
    const response = await apiService.api.instance.post<{ data: FeedbackFullDto }>(
      `${urlPath}/${id}/response`,
      payload
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
