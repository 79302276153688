<template>
  <MiSelect
    ref="refMiSearchSelect"
    class="mi-search-select__select"
    popper-class="mi-search-select__popper"
    v-model="value"
    filterable
    :remote-show-suffix="!notCallWithEmptyQuery"
    remote
    reserve-keyword
    :validate-event="false"
    :remote-method="debouncedGetItems"
    :loading="loading"
    :clearable="clearable"
    :multiple="multiple"
    :collapse-tags="collapseTags"
    :collapse-tags-tooltip="collapseTagsTooltip"
    :placeholder="placeholder || $t('Common.PleaseInput')"
    :no-data-text="$t('Common.NoData')"
    :disabled="disabled"
    @change="selectHandler"
    @visible-change="handleSelectVisibilityChange">
    <template #prefix>
      <MiIcon icon="SEARCH" class="mi-search-select__icon" />
    </template>

    <ElOption
      class="mi-search-select__option"
      v-for="item in options"
      :key="item[valueKey]"
      :disabled="item.disabled"
      :label="item[labelKey]"
      :value="item[valueKey]">
      <slot name="option" :item="item" />
    </ElOption>

    <template #empty>
      <div class="mi-search-select-empty">
        <div class="mi-search-select-empty__text">
          {{ loading ? 'Loading' : $t('Common.NoData') }}
        </div>

        <MiButton
          v-if="showCreateOption"
          v-show="!loading"
          class="form-search-select-empty__create"
          type="primary"
          size="default"
          @click="createItem">
          <template #icon>
            <MiIcon icon="PLUS" />
          </template>
          {{ $t('Common.Create') }}
        </MiButton>
      </div>
    </template>
  </MiSelect>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { cloneDeep, debounce } from 'lodash';
import { ElOption } from 'element-plus';

import { AnyObject, QueryParams } from '~shared/types';
import { MiIcon, MiSelect, MiButton } from '~shared/ui';
import { MetaDto } from '~shared/api';
import { DEFAULT_DEBOUNCE_DELAY } from '~shared/config';

export type MiSelectSearchProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchData?: (query?: any) => Promise<any | undefined>;
  extraOptions?: AnyObject[];
  modelValue: string | string[] | number | number[] | undefined;
  modelObjectValue: AnyObject | AnyObject[] | undefined;
  showCreateOption?: boolean;
  labelKey?: string;
  valueKey?: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query?: any;
  clearable?: boolean;
  multiple?: boolean;
  collapseTags?: boolean;
  collapseTagsTooltip?: boolean;
  disabled?: boolean;
  rawFetchResult?: boolean;
  notCallWithEmptyQuery?: boolean;

  abortableFetchData?: () => [
    (payload?: QueryParams) => Promise<{ data: AnyObject[]; meta?: MetaDto } | undefined>,
    () => void,
  ];
};

const props = withDefaults(defineProps<MiSelectSearchProps>(), {
  showCreateOption: false,
  extraOptions: () => [],
  labelKey: 'title',
  valueKey: 'id',
  placeholder: '',
  query: () => ({}),
});

const emit = defineEmits<{
  'update:modelValue': [e: MiSelectSearchProps['modelValue']];
  'update:modelObjectValue': [e: MiSelectSearchProps['modelObjectValue']];
  'select': [e: MiSelectSearchProps['modelObjectValue']];
  'createOption': [e: string];
}>();

defineSlots<{
  option(props: { item: AnyObject }): never;
}>();

const loading = ref(false);

const options = ref<AnyObject[]>([]);

const refMiSearchSelect = ref<InstanceType<typeof MiSelect> | null>(null);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const objectValue = computed({
  get() {
    return props.modelObjectValue;
  },
  set(val) {
    emit('update:modelObjectValue', val);
  },
});

const searchQuery = computed(() => refMiSearchSelect.value?.query);

let currentAbortController: (() => void) | null = null;

const createItem = () => {
  if (refMiSearchSelect.value) {
    refMiSearchSelect.value.query = '';
    return emit('createOption', searchQuery.value as string);
  }
};
const getItems = async () => {
  if (props.notCallWithEmptyQuery && (!searchQuery.value || !searchQuery.value.trim())) return;

  loading.value = true;

  let response;

  if (props.abortableFetchData) {
    if (typeof currentAbortController === 'function') {
      currentAbortController();
    }
    const [fetchData, cancel] = props.abortableFetchData();

    currentAbortController = cancel;
    response = await fetchData({
      ...props.query,
      page: 1,
      per_page: 30,
      search: searchQuery.value,
    });
  } else if (props.fetchData) {
    response = await props.fetchData({
      ...props.query,
      page: 1,
      per_page: 30,
      search: searchQuery.value || null,
    });
  }

  const responseData = props.rawFetchResult ? response?.data?.data : response?.data;

  if (objectValue.value && responseData) {
    const arrayOfValue = (
      Array.isArray(objectValue.value) ? objectValue.value : [objectValue.value]
    ).filter((f) => !!f[props.valueKey]);

    const ids = arrayOfValue.map((item) => item[props.valueKey]);
    const extraOptionIds = props.extraOptions.map((item) => item[props.valueKey]);

    const newOptions = responseData.filter((f: AnyObject) => {
      return !ids.includes(f[props.valueKey]);
    });

    const filteredArrayOfValue = arrayOfValue.filter((f) => {
      return !extraOptionIds.includes(f[props.valueKey]);
    });

    options.value = [...props.extraOptions, ...filteredArrayOfValue, ...newOptions];
  } else {
    options.value = responseData
      ? [...props.extraOptions, ...responseData]
      : [...props.extraOptions];
  }

  loading.value = false;
};

const handleSelectVisibilityChange = (isOpen: boolean) => {
  if (props.notCallWithEmptyQuery) {
    if (!isOpen) {
      options.value = [];
    }
    return;
  }

  if (isOpen) {
    getItems();
  }
};

const selectHandler = (eventValue: string | number | string[] | number[]) => {
  let result: AnyObject | AnyObject[] | undefined;

  if (Array.isArray(eventValue)) {
    result = eventValue.map((item) => {
      return options.value.find((f) => f[props.valueKey] === item);
    });
  } else {
    result = options.value.find((f) => f[props.valueKey] === eventValue);
  }

  objectValue.value = cloneDeep(result);
  emit('select', cloneDeep(result));
};

const debouncedGetItems = debounce(getItems, DEFAULT_DEBOUNCE_DELAY);

watch(
  () => value.value,
  (v) => {
    if (!v) {
      objectValue.value = undefined;
    }
  }
);

watch(
  () => objectValue.value,
  (newValue) => {
    if (newValue) {
      const arrayOfValue = (Array.isArray(newValue) ? newValue : [newValue]).filter(
        (f) => !!f[props.valueKey]
      );

      const ids = arrayOfValue.map((item) => item[props.valueKey]);
      const extraOptionIds = props.extraOptions.map((item) => item[props.valueKey]);

      const newOptions = options.value.filter((f) => {
        return !extraOptionIds.includes(f[props.valueKey]) && !ids.includes(f[props.valueKey]);
      });

      const filteredArrayOfValue = arrayOfValue.filter((f) => {
        return !extraOptionIds.includes(f[props.valueKey]);
      });

      options.value = [...props.extraOptions, ...filteredArrayOfValue, ...newOptions];
    } else {
      options.value = [...props.extraOptions];
    }
  },
  { immediate: true, deep: true }
);
</script>

<style lang="scss" src="./index.scss" />
