<template>
  <div
    :class="[
      'mi-card-wrapper',
      {
        'mi-card-wrapper--with-shadow': boxShadow,
        'mi-card-wrapper--with-table': withTable,
      },
    ]">
    <h2 v-if="outTitle" class="mi-card-out-title">{{ outTitle }}</h2>
    <ElCard
      :class="[
        'mi-card',
        {
          'mi-card--with-footer': $slots.footer,
          'mi-card--content-hidden': contentHidden,
        },
      ]"
      v-bind="$props"
      ref="elCard"
      shadow="never">
      <template v-if="$slots.header" #header>
        <slot name="header" />
      </template>
      <slot></slot>
    </ElCard>

    <template v-if="$slots.footer">
      <div class="mi-card-footer">
        <slot name="footer" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ElCard, CardProps } from 'element-plus';
import { ref } from 'vue';

type Props = {
  boxShadow?: boolean;
  outTitle?: string;
  contentHidden?: boolean;
  withTable?: boolean;
} & Partial<CardProps>;

withDefaults(defineProps<Props>(), {
  boxShadow: true,
  withTable: false,
});

const elCard = ref<InstanceType<typeof ElCard> | null>(null);
</script>

<style lang="scss" src="./index.scss" />
