import { ROUTE_PATH } from '~shared/config';
import { onlyUserHasRoleMiddleware, onlyUserIsAuthMiddleware } from '~shared/lib';

export const SURVEY_MAIN_LIST_ROUTE = {
  name: 'SurveyMainList',
  path: ROUTE_PATH.survey.mainList,
  component: () => import('./SurveyMainList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'minister']),
  ],
  meta: {
    title: 'Analytics.Survey',
  },
};

export const SURVEY_PATIENT_ROUTE = {
  name: 'SurveyPatient',
  path: ROUTE_PATH.survey.patient.rawPath,
  component: () => import('./SurveyPatient/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'minister']),
  ],
  meta: {
    title: 'Analytics.Survey',
  },
};

export const SURVEY_CODE_LIST_ROUTE = {
  name: 'SurveyCodeList',
  path: ROUTE_PATH.survey.codeList.rawPath,
  component: () => import('./SurveyCodeList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'minister']),
  ],
  meta: {
    title: 'Analytics.Survey',
  },
};
