<template>
  <div class="patients-search">
    <ElAutocomplete
      class="patients-search-autocomplete"
      popper-class="patients-search-autocomplete-popper"
      v-model="searchString"
      :placeholder="$t('Patients.PatientSearchPlaceholder')"
      :debounce="SEARCH_DEBOUNCE_DELAY"
      :fetch-suggestions="handleSearchStringChange"
      @select="handleSelect"
      clearable>
      <template #prefix>
        <MiIcon icon="SEARCH" />
      </template>
      <template #default="{ item }: { item: SearchItem }">
        <div
          v-if="!('id' in item) && !('validateMsg' in item)"
          class="autocomplete-popper-not-found">
          <span class="autocomplete-popper-not-found__text">{{ $t('Patients.NoPatient') }}</span>
        </div>

        <div v-if="'validateMsg' in item" class="autocomplete-popper-not-found">
          <span class="autocomplete-popper-not-found__text">{{ item.validateMsg }}</span>
        </div>

        <div v-if="'id' in item" class="autocomplete-popper-item">
          <div class="autocomplete-popper-item__top-row">
            <span class="autocomplete-popper-item__name">{{ joinFullname(item) }}</span>
            <span class="autocomplete-popper-item__med-card">{{ item.medcard_number }}</span>
          </div>
          <div class="autocomplete-popper-item__bottom-row">
            {{ convertDateToAppFormat(item.birthdate) }}
          </div>
        </div>
      </template>
    </ElAutocomplete>

    <ScanPatientBracelet @successful-scan="handleGoToPatientByBracelet" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  ElAutocomplete,
  AutocompleteFetchSuggestions,
  AutocompleteFetchSuggestionsCallback,
} from 'element-plus';
import { storeToRefs } from 'pinia';

import { ScanPatientBracelet } from '~features/ScanPatientBracelet';
import { useAppConfigStore, useSessionStore } from '~entities/session';
import { MiIcon } from '~shared/ui';
import { amplitudeService, joinFullname, convertDateToAppFormat, I18nService } from '~shared/lib';
import { PatientFullDto, misApi, UserRoleEnum } from '~shared/api';
import { ROUTE_PATH } from '~shared/config';
import { PatientSearchResourceNew } from '~shared/api/gen/v2';
import { AnyObject } from '~shared/types';

type SearchItem = PatientSearchResourceNew | { validateMsg: string } | object;

const SEARCH_DEBOUNCE_DELAY = 1000;

const router = useRouter();

const sessionStore = useSessionStore();
const { featureFlags } = storeToRefs(useAppConfigStore());

const currentInterface = computed(() => featureFlags.value?.['user.interface']);

const medicalRoles: UserRoleEnum[] = ['director', 'doctor', 'nurse'];
const searchString = ref('');
const prevQueryString = ref('');
const searchResult = ref<Partial<PatientSearchResourceNew>[]>([]);

// ElAutocomplete @select принимает колбэк ((item: Record<string, any>) => any) | undefined,
// фактический тип для "item" не дает использовать
const handleSelect = (item: AnyObject) => {
  if (!sessionStore.userRole || !item.id) return;

  switch (true) {
    case medicalRoles.includes(sessionStore.userRole):
      router.push(ROUTE_PATH.patients.medicalCard.byId(item.id));
      break;
    case currentInterface.value === 'b2g':
      router.push(ROUTE_PATH.patients.b2gProfiles.byId(item.id));
      break;
    case currentInterface.value === 'g2g':
      router.push(ROUTE_PATH.patients.g2gProfiles.byId(item.id));
  }
};

const handleGoToPatientByBracelet = (patient: PatientFullDto) => {
  if (!sessionStore.userRole) return;

  amplitudeService.logEvent('scan_patient_qr', {
    patient_id: patient.id,
  });

  switch (true) {
    case medicalRoles.includes(sessionStore.userRole):
      router.push(ROUTE_PATH.patients.medicalCard.byId(patient.id));
      break;
    case currentInterface.value === 'b2g':
      router.push(ROUTE_PATH.patients.b2gProfiles.byId(patient.id));
      break;
    case currentInterface.value === 'g2g':
      router.push(ROUTE_PATH.patients.g2gProfiles.byId(patient.id));
  }
};

const handleSearchStringChange: AutocompleteFetchSuggestions = (
  queryString: string,
  cb: AutocompleteFetchSuggestionsCallback
) => {
  if (!queryString) {
    cb([]);
  } else if (queryString.trim().length < 3) {
    cb([{ validateMsg: I18nService.t('Validation.SearchLength') }]);
  } else if (queryString && prevQueryString.value === queryString) {
    cb(searchResult.value);
  } else if (queryString && prevQueryString.value !== queryString) {
    prevQueryString.value = queryString;

    misApi.gen.v2.patients
      .searchPatients({
        search: queryString,
      })
      .then(async (response) => {
        const responseData = (await response)?.data.data ?? [];
        // [{}] для того чтобы отобразить "Пациент не найден" когда бэк возвращает пустой массив
        const result = responseData.length ? responseData : [{}];

        searchResult.value = result;
        cb(result);
      });
  }
};
</script>

<style lang="scss" src="./index.scss" />
