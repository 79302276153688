import { number, object, string } from 'yup';

import { I18nService } from '~shared/lib';

export const validationSchema = object().shape({
  recipient: string().required(I18nService.t('Validation.RequiredField')),
  amount: number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .test('no-leading-zero', `${I18nService.t('Validation.GreaterZero')}`, (value, context) => {
      return context.originalValue && !context.originalValue.startsWith('0');
    })
    .required(I18nService.t('Validation.RequiredField')),
  expense_date: string().required(I18nService.t('Validation.RequiredField')),
  expense_type_id: number()
    .transform((value) => (isNaN(value) ? null : value))
    .required(I18nService.t('Validation.RequiredField')),
});
