<template>
  <MainLayout content-class="v-outcome-content" fix-height>
    <MiFilterBox>
      <ElDatePicker
        class="v-doctors-content-header__date"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <MiSelectSearch
        :model-value="warehouse.value.id"
        v-model:model-object-value="warehouse.value"
        :fetch-data="misApi.common.warehouse.getItems"
        :placeholder="$t('Income.Warehouse')"
        raw-fetch-result
        clearable />

      <template #actions>
        <MiButton type="primary" @click="onCreateButtonClick">
          {{ `+ ${$t('Outcome.CreateOutcome')}` }}
        </MiButton>
      </template>
    </MiFilterBox>
    <OutcomesTable
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :items="items"
      :total="total" />
  </MainLayout>
</template>

<script lang="ts">
export default {
  name: 'VOutcome',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ElDatePicker } from 'element-plus';
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';

import { MainLayout } from '~widgets/layouts';
import { MiButton, MiFilterBox, MiSelectSearch } from '~shared/ui';
import {
  useDatePeriod,
  usePage,
  usePerPage,
  useModel,
  compareQueriesThenLoadData,
  getMonthPeriod,
} from '~shared/lib';
import { DATE_FORMAT } from '~shared/config';
import { misApi } from '~shared/api';

import OutcomeCrudModal from '@/components/warehouse/modals/OutcomeCrudModal/index.vue';
import OutcomesTable from '@/components/warehouse/tables/OutcomesTable/index.vue';

const loading = ref(false);
const store = useStore();
const page = usePage();
const perPage = usePerPage();
const warehouse = useModel({
  fieldNames: {
    id: 'warehouse_id',
    name: 'warehouse_name',
  },
});

const date = useDatePeriod(getMonthPeriod());

const total = computed(() => store.state.outcomes.total);
const items = computed(() => store.state.outcomes.data);
const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  // type: outcome.value,
  warehouse_id: warehouse.value.id,
}));

const getOutcomes = async () => {
  loading.value = true;
  const response = await misApi.common.outcomes.getItems(query.value);
  if (response?.data) {
    const { data, meta } = response.data;
    store.dispatch('outcomes/setData', {
      items: data,
      total: +meta.total,
      overwriteDataState: true,
    });
  }
  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getOutcomes,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);

const onCreateButtonClick = async () => {
  await store.dispatch('modalAndDrawer/openModal', OutcomeCrudModal);
  await getOutcomes();
};
</script>

<style lang="scss"></style>
