<template>
  <ElSelect class="mi-select" v-bind="$props" ref="elSelectRef" v-model="value">
    <template v-if="$slots.prefix" #prefix>
      <slot name="prefix" />
    </template>
    <template v-if="$slots.empty" #empty>
      <slot name="empty" />
    </template>
    <slot />
  </ElSelect>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { ElSelect, ISelectProps } from 'element-plus';

import { AnyObject } from '~shared/types';

export type MiSelectProps = Partial<Omit<ISelectProps, 'modelValue'>> & {
  modelValue:
    | string
    | number
    | boolean
    | Array<string | number | boolean | AnyObject>
    | AnyObject
    | undefined;
};

const props = withDefaults(defineProps<MiSelectProps>(), {
  reserveKeyword: true,
  teleported: true,
  persistent: true,
  validationEvent: true,
});

const emit = defineEmits<{
  'update:modelValue': [e: MiSelectProps['modelValue']];
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const elSelectRef = ref<InstanceType<typeof ElSelect> | null>(null);

const query = computed(() => elSelectRef.value?.query);

const setFocus = () => {
  elSelectRef.value?.focus();
};

defineExpose({
  setFocus,
  query,
});
</script>

<style lang="scss" src="./index.scss" />
