<template>
  <table :class="['printer-document-content-block__table', block.class]">
    <caption v-if="block.label">
      {{
        block.label
      }}
    </caption>
    <thead>
      <tr>
        <td :width="block.headers.width" v-for="header in block.headers" :key="header.prop">
          {{ header.label }}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in block.value" :key="item.id || index">
        <td :width="header.width" v-for="header in block.headers" :key="header.prop">
          <PrinterDocumentContentBlockFile v-if="item[header.prop]?.id" :file="item[header.prop]" />
          <span v-else>{{ item[header.prop] }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
// eslint-disable-next-line import/no-internal-modules
import PrinterDocumentContentBlockFile from '../PrinterDocumentContentBlockFile/index.vue';

import { PrinterDocumentContentBlock } from '@/models/client/PrinterDocumentContentBlock.model';

export default {
  name: 'PrinterDocumentContentBlockTable',
  components: { PrinterDocumentContentBlockFile },
  props: {
    block: [PrinterDocumentContentBlock, Object],
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
