import { ElNotification } from 'element-plus';

import { getApiErrorMessage } from '~shared/lib';
import { UserFullDto, apiService } from '~shared/api';

const urlPath = '/users';

export const getUser = async () => {
  try {
    const response = await apiService.api.instance.get<{ data: UserFullDto }>(`${urlPath}/me`);

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
