import { ElNotification } from 'element-plus';

import { DeviceStatus, DeviceDto, DeviceCheckResponseData } from '~shared/api/b2b';
import { apiService } from '~shared/api';
import { getApiErrorMessage } from '~shared/lib';

export const urlPath = '/devices';
export const updateStatus = async (deviceId: string, status: DeviceStatus) => {
  try {
    const response = await apiService.api.instance.post<{ data: DeviceDto }>(
      `${urlPath}/${deviceId}/${status}`
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const checkResult = async () => {
  try {
    const response = await apiService.api.instance.get<{ data: DeviceCheckResponseData }>(
      `${urlPath}/check`
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
