import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

//TODO: проверить нужно ли 'doctor' в onlyUserHasRoleMiddleware, чтобы не сломать удалять пока не буду
export const DEPOSIT_LIST_ROUTE = {
  name: 'DepositList',
  path: ROUTE_PATH.cashRegister.depositList,
  component: () => import('./ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'accountant', 'doctor']),
  ],
  meta: {
    title: 'CashRegister.Deposit',
  },
};
