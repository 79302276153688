import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const ANALYTIC_BY_APPOINTMENTS_ROUTE = {
  name: 'AnalyticByAppointments',
  path: ROUTE_PATH.analyticByAppointments,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Appointments.Appointments',
  },
};
