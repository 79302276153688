<template>
  <div class="layout-user-dropdown">
    <div class="user-info">
      <div class="user-info__name">{{ formatFullnameToShort(user?.name) }}</div>
    </div>
    <ElDropdown trigger="click">
      <div class="user-avatar-box">
        <MiAvatar class="user-avatar" :src="user?.doctor?.avatar?.link" size="small" />
      </div>
      <template #dropdown>
        <ElDropdownMenu>
          <ElDropdownItem v-if="user?.email">
            <template #default>
              <MiInfoField label="Email">
                <div class="user-info__email">{{ user?.email }}</div>
              </MiInfoField>
            </template>
          </ElDropdownItem>
          <ElDropdownItem :divided="Boolean(user?.email)" @click="handleLogout">
            <template #default>
              <div class="user-dropdown-item">
                <MiIcon icon="LOGOUT" />
                <span>{{ $t('Common.Logout') }}</span>
              </div>
            </template>
          </ElDropdownItem>
        </ElDropdownMenu>
      </template>
    </ElDropdown>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { ElDropdown, ElDropdownMenu, ElDropdownItem } from 'element-plus';

import { useSessionStore } from '~entities/session';
import { formatFullnameToShort } from '~shared/lib';
import { MiAvatar, MiIcon, MiInfoField } from '~shared/ui';
import { ROUTE_PATH } from '~shared/config';

const router = useRouter();

const sessionStore = useSessionStore();
const { user } = storeToRefs(sessionStore);

const handleLogout = async () => {
  await sessionStore.logout();
  await router.push(ROUTE_PATH.auth.login);
};
</script>

<style lang="scss" scoped>
.layout-user-dropdown {
  display: flex;
  gap: 12px;
  align-items: center;

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: end;

    &__name {
      font-weight: 400;
    }
  }

  .user-avatar-box {
    padding: 2px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
  }

  .user-avatar-box:focus,
  .user-avatar-box:hover {
    border-color: var(--primary-color);
    background-color: var(--primary-color-light-9);
  }
}

.user-dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
}
.user-info__email {
  text-wrap: wrap;
  line-height: 24px;
  max-width: 260px;
  color: var(--text-secondary-color);
  font-size: var(--text-font-size-small);
}
</style>
