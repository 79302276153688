import { RouteRecordRaw } from 'vue-router';

import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const APPOINTMENT_ROUTE: RouteRecordRaw = {
  name: 'Appointment',
  path: ROUTE_PATH.appointments.appointment.rawPath,
  component: () => import('./Appointment/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Contingent.PatientRecord',
  },
};

export const MEDICAL_CASE_ROUTE: RouteRecordRaw = {
  name: 'MedicalCase',
  path: ROUTE_PATH.appointments.medicalCase.rawPath,
  component: () => import('./MedicalCase/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Appointments.MedicalCase',
  },
};

export const UNSCHEDULED_MEDICAL_CASE_ROUTE: RouteRecordRaw = {
  name: 'UnscheduledMedicalCase',
  path: ROUTE_PATH.appointments.unscheduledMedicalCase.rawPath,
  component: () => import('./UnscheduledMedicalCase/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Appointments.MedicalCase',
  },
};

export const APPOINTMENTS_ACTIVE_ROUTE: RouteRecordRaw = {
  name: 'ActiveAppointmentList',
  path: ROUTE_PATH.appointments.activeList,
  component: () => import('./ActiveAppointmentList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Contingent.PatientsRecords',
  },
};

export const APPOINTMENTS_PROVIDED_ROUTE: RouteRecordRaw = {
  name: 'AppointmentsProvided',
  path: ROUTE_PATH.appointments.providedList,
  component: () => import('./ProvidedAppointmentList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Contingent.PatientsRecords',
  },
};

export const APPOINTMENTS_CANCELED_ROUTE: RouteRecordRaw = {
  name: 'AppointmentsCanceled',
  path: ROUTE_PATH.appointments.canceledList,
  component: () => import('./CanceledAppointmentList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Contingent.PatientsRecords',
  },
};

export const APPOINTMENT_SCHEDULE_ROUTE: RouteRecordRaw = {
  name: 'AppointmentSchedule',
  path: ROUTE_PATH.appointmentSchedule,
  component: () => import('./AppointmentSchedule/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['manager', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Appointments.ReceptionCalendar',
  },
};

export const APPOINTMENT_CREATE_ROUTE: RouteRecordRaw = {
  name: 'AppointmentCreate',
  path: ROUTE_PATH.appointments.create,
  component: () => import('./CreateAppointment/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Appointments.ReceptionCalendar',
  },
};

export const EXAMINATION_PLAN_APPOINTMENT_ROUTE: RouteRecordRaw = {
  name: 'ExaminationPlanAppointment',
  path: ROUTE_PATH.appointments.examinationPlan.rawPath,
  component: () => import('./ExaminationPlanAppointment/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Contingent.PatientRecord',
  },
};

export const WRITE_OUT_RECIPE: RouteRecordRaw = {
  name: 'WriteOutRecipe',
  path: ROUTE_PATH.appointments.writeOutRecipe.rawPath,
  component: () => import('./WriteOutRecipe/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Contingent.PatientRecord',
  },
};
