<template>
  <MiTag v-if="status" size="small" :type="typeByStatus[status]" round>
    {{ $t(`Invoices.Statuses.${status}`) }}
  </MiTag>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { TagProps } from 'element-plus';

import { InvoiceStatusEnum } from '~shared/api/gen/v2';
import { MiTag } from '~shared/ui';

defineProps<{
  status?: InvoiceStatusEnum;
}>();

const typeByStatus = computed<Record<InvoiceStatusEnum, TagProps['type']>>(() => {
  return {
    partially_paid: 'warning',
    paid: 'success',
    not_paid: 'danger',
    canceled: 'info',
    refund: '',
    overpay: '',
  };
});
</script>
