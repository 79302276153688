import * as g2g from './g2g/methods';
import * as b2b from './b2b/methods';
import * as common from './common/methods';
import { apiService, apiServicePrescriptions, apiServiceV2 } from './base';
import { getApiWithErrorHandler } from './gen';

export * from './g2g/types';
export * from './types';

const misApiV2Gen = getApiWithErrorHandler(apiServiceV2.api);

const misApiPrescriptions = getApiWithErrorHandler(apiServicePrescriptions.api);

const misApi = {
  g2g,
  b2b,
  common,
  gen: {
    v2: { ...misApiV2Gen },
    raw: {
      v2: apiServiceV2.api,
      prescriptions: apiServicePrescriptions.api,
    },
    prescriptions: { ...misApiPrescriptions },
  },
};

export { misApi, apiService };
