/**
 * @example 2022-09-30T00:00:00.000Z  ->  2022-08-30T00:00:00.000Z || 2022-10-30T00:00:00.000Z
 * @param {string} date ISO format
 * @param {object} [options]
 * @param {"next"|"prev"} options.type = "next"
 * @return {string}
 */
export const prevOrNextDayByISOString = (date: Date, options = { type: 'next' }) => {
  const _date = new Date(date);
  _date.setDate(options.type === 'next' ? _date.getDate() + 1 : _date.getDate() - 1);

  return _date.toISOString();
};
