<template>
  <div
    :class="[
      'form-actions-wrapper',
      {
        'form-actions-wrapper_no-margin': props.noMargin,
        'form-actions-wrapper_align-left': props.align === 'left',
        'form-actions-wrapper_align-right': props.align === 'right',
        'form-actions-wrapper_align-center': props.align === 'center',
        'form-actions-wrapper__wrap': props.wrap,
      },
    ]">
    <slot />
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{ align?: 'left' | 'right' | 'center'; noMargin?: boolean; wrap?: boolean }>(),
  {
    align: 'center',
  }
);
</script>

<style lang="scss" src="./index.scss"></style>
