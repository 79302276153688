<template>
  <div>
    <MiButton class="take-photo-button" v-bind="$props" @click.stop="isModalVisible = true" link>
      <template v-if="$slots.default">
        <slot />
      </template>
      <span v-if="!$slots.default">{{
        hasPhoto ? $t('Common.Update') : $t('MyId.TakePhoto')
      }}</span>
    </MiButton>

    <TakePhotoModal
      v-model="isModalVisible"
      :patient-id="patientId"
      @patient-update-successful="$emit('patient-update-successful', $event)" />
  </div>
</template>

<script setup lang="ts">
import { shallowRef } from 'vue';

import { TakePhotoModal } from './TakePhotoModal';

import { MiButton, MiButtonProps } from '~shared/ui';
import { PatientResource } from '~shared/api/gen/v2';

type Props = {
  patientId: number;
  hasPhoto: boolean;
} & MiButtonProps;

withDefaults(defineProps<Props>(), {
  size: 'small',
  type: 'primary',
  plain: false,
});

defineEmits<{
  (event: 'patient-update-successful', patient: PatientResource): void;
}>();

const isModalVisible = shallowRef(false);
</script>
