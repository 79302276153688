import { ElNotification } from 'element-plus';

import { apiService } from '~shared/api';
import { getApiErrorMessage } from '~shared/lib';

const urlPath = 'printer';

export const printBracelet = async (patientId: number | string) => {
  try {
    const response = await apiService.api.instance.post<{ printer_address: string; task: string }>(
      `${urlPath}/printBracelet`,
      {
        patient_id: patientId,
      }
    );
    return {
      data: response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
