<template>
  <ElFormItem
    :class="[
      'ui-models-select-with-validation',
      {
        'ui-models-select-with-validation_in-column': props.inColumn,
        'ui-models-select-with-validation_in-line-space-between':
          !props.inColumn && !!props.label && props.spaceBetween,
        'ui-models-select-with-validation-custom': customLabel,
      },
    ]"
    :error="errorMessage"
    :label="props.label"
    :required="props.required">
    <template v-if="customLabel" #label>
      <span class="ui-models-select-with-validation_label">{{ props.label }}</span>
      <MiButton
        class="ui-models-select-with-validation_btn"
        type="primary"
        text
        link
        @click="$emit('openModal', $event)">
        {{ customLabel }}
      </MiButton>
    </template>
    <ElSelect
      class="ui-models-select-with-validation__select"
      v-model="value"
      remote
      filterable
      reserve-keyword
      :validate-event="false"
      :remote-method="debouncedGetItems"
      :loading="loading"
      :multiple="multiple"
      :disabled="props.disabled"
      :placeholder="props.placeholder || $t('Common.PleaseInput')"
      :clearable="props.clearable"
      :no-data-text="$t('Common.NoData')"
      :collapse-tags="props.collapseTags"
      :collapse-tags-tooltip="props.collapseTagsTooltip"
      @update:model-value="$emit('update:modelValue', $event)"
      @visible-change="getItems"
      @change="selectHandler"
      popper-class="ui-models-select-with-validation__popper"
      ref="component">
      <template #prefix>
        <MiIcon class="ui-models-select-with-validation__icon" icon="SEARCH" />
      </template>

      <ElOption
        class="ui-models-select-with-validation__option"
        v-for="item in items"
        :key="item[props.valueKey]"
        :label="item[props.labelKey]"
        :value="item[props.valueKey]" />

      <template #empty>
        <div class="ui-models-select-with-validation-empty">
          <div class="ui-models-select-with-validation-empty__text">
            {{ loading ? 'Loading' : $t('Common.NoData') }}
          </div>

          <MiButton
            v-if="props.showCreateOption"
            v-show="!loading"
            class="ui-models-select-with-validation-empty__create"
            type="primary"
            size="default"
            @click="createItem">
            <template #icon>
              <MiIcon icon="PLUS" />
            </template>
            {{ $t('Common.Create') }}
          </MiButton>
        </div>
      </template>
    </ElSelect>
  </ElFormItem>
</template>

<script lang="ts">
export default {
  name: 'FormSearchSelectField',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ElSelect, ElFormItem, ElOption } from 'element-plus';
import cloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';
import { useField } from 'vee-validate';
import { ref, withDefaults, watch } from 'vue';

import { MiIcon } from '~shared/ui';
import { DEFAULT_DEBOUNCE_DELAY } from '~shared/config';
import { MiButton } from '~shared/ui';

import { AnyObject } from '@/types/common';

type Props = {
  inColumn?: boolean;
  spaceBetween?: boolean;
  clearable?: boolean;
  collapseTags?: boolean;
  collapseTagsTooltip?: boolean;
  showCreateOption?: boolean;
  disabled?: boolean;
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchData: (query?: any) => Promise<any | undefined>;
  fieldObjectName: string;
  multiple?: boolean;
  fieldName: string;
  label?: string;
  labelKey?: string;
  valueKey?: string;
  placeholder?: string;
  methodName?: string;
  customLabel?: string;
  searchQuery?: object;
};

const props = withDefaults(defineProps<Props>(), {
  inColumn: true,
  spaceBetween: false,
  clearable: false,
  collapseTags: false,
  collapseTagsTooltip: false,
  showCreateOption: false,
  disabled: false,
  required: false,
  multiple: false,
  labelKey: 'title',
  valueKey: 'id',
  label: '',
  placeholder: '',
  methodName: 'find',
  customLabel: '',
});

const emit = defineEmits(['select', 'create', 'update:modelValue', 'openModal']);

const loading = ref(false);
const items = ref<AnyObject[]>([]);

// eslint-disable-next-line vue/no-setup-props-destructure
const { value, setValue, errorMessage } = useField<
  string | string[] | number | number[] | undefined
>(props.fieldName);

// eslint-disable-next-line vue/no-setup-props-destructure
const { value: objectValue, setValue: setObjectValue } = useField<
  AnyObject | AnyObject[] | undefined
>(props.fieldObjectName);

const component = ref<InstanceType<typeof ElSelect> | null>(null);

const createItem = () => {
  if (component.value) {
    const query = component.value.query;
    component.value.query = '';
    return emit('create', query);
  }
};

const getItems = async (query: string) => {
  loading.value = true;

  const queryParams = {
    page: 1,
    per_page: 30,
    query_type: 'ILIKE',
    query_field: [props.labelKey],
    query_operator: 'OR',
    search: typeof query === 'string' ? query : '',

    ...(props.searchQuery || {}),
  };

  const response = await props.fetchData(queryParams);

  if (objectValue.value) {
    const arrayOfvalue = (
      Array.isArray(objectValue.value) ? objectValue.value : [objectValue.value]
    ).filter((f) => !!f.id);
    const ids = arrayOfvalue.map((item) => item.id);
    const newItems = response?.data.data.filter((f: AnyObject) => {
      return !ids.includes(f.id);
    });
    items.value = [...arrayOfvalue, ...newItems];
  } else {
    items.value = response.data.data;
  }

  loading.value = false;
};

const debouncedGetItems = debounce(getItems, DEFAULT_DEBOUNCE_DELAY);

const selectHandler = (eventValue: string | number | string[] | number[]) => {
  setValue(eventValue);

  let result: AnyObject | AnyObject[] | undefined;

  if (Array.isArray(eventValue)) {
    result = eventValue.map((item) => {
      return items.value.find((f) => f[props.valueKey] === item);
    });
  } else {
    result = items.value.find((f) => f[props.valueKey] === eventValue);
  }
  const cloneResult = cloneDeep(result);

  if (cloneResult) {
    setObjectValue(cloneResult);
    emit('select', cloneResult);
  }
};

watch(
  () => objectValue.value,
  (newValue) => {
    if (newValue) {
      const arrayOfvalue = (Array.isArray(newValue) ? newValue : [newValue]).filter((f) => !!f?.id);
      const ids = arrayOfvalue.map((item) => item.id);
      const newItems = items.value.filter((f) => {
        return !ids.includes(f.id);
      });

      items.value = [...arrayOfvalue, ...newItems];
    }
  },
  { immediate: true, deep: true }
);

defineExpose({
  setValue,
  setObjectValue,
});
</script>

<style lang="scss" src="./index.scss" />
