import * as Sentry from '@sentry/vue';
import { httpClientIntegration } from '@sentry/integrations';
import { Vue } from '@sentry/vue/types/types';

import { router } from './router';

import { findCurrentEnv } from '~shared/lib';

export const createSentryIns = (app: Vue) => {
  return Sentry.init({
    app,
    dsn: findCurrentEnv('SENTRY_DSN'),
    environment: findCurrentEnv('SENTRY_ENVIRONMENT'),
    release: findCurrentEnv('SENTRY_RELEASE'),
    trackComponents: true,
    tracePropagationTargets: ['localhost', findCurrentEnv('API_URL'), /^\//],
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.replayIntegration({
        maskAllText: findCurrentEnv('SENTRY_MASK_ALL_TEXT') === 'true',
        blockAllMedia: findCurrentEnv('SENTRY_BLOCK_ALL_MEDIA') === 'true',
        maskAllInputs: findCurrentEnv('SENTRY_MASK_ALL_INPUTS') === 'true',
        networkDetailAllowUrls: [findCurrentEnv('API_URL')],
        maxReplayDuration: isNaN(Number(findCurrentEnv('SENTRY_MAX_REPLAY_DURATION')))
          ? 300000
          : Number(findCurrentEnv('SENTRY_MAX_REPLAY_DURATION')),
      }),
      httpClientIntegration({ failedRequestStatusCodes: [[400, 599]] }),
    ],
    tracesSampleRate: Number(findCurrentEnv('SENTRY_TRACES_SAMPLE_RATE')),
    // Session Replay
    replaysSessionSampleRate: Number(findCurrentEnv('SENTRY_REPLAYS_SESSION_SAMPLE_RATE')),
    replaysOnErrorSampleRate: Number(findCurrentEnv('SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE')),
  });
};

export const setFeaturesFlags = (payload = {}) => {
  Sentry.setContext('Features flags', payload);
};
