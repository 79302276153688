<template>
  <div class="slot-radio-group-box">
    <div class="title">
      {{ $t('Appointments.DoctorSchedule') }}
    </div>
    <ElDivider />

    <FormRadioGroup
      :class="['radio-group', { 'radio-group--empty': !slots.length }]"
      field-name="payloadData.start_at"
      :label="$t('Appointments.PatientRegistrationSlot')"
      @change="emit('selectSlot')"
      required>
      <ElEmpty class="empty-stub" v-if="!slots.length" />
      <ElRadioButton
        v-for="slot in slots"
        :key="slot.start_at"
        :label="slot.start_at"
        :disabled="!slot.available">
        {{ slot.start_at.split(' ')[1] }}
      </ElRadioButton>
    </FormRadioGroup>
  </div>
</template>

<script lang="ts" setup>
import { ElRadioButton, ElDivider, ElEmpty } from 'element-plus';
import { useField } from 'vee-validate';
import { ref, watch } from 'vue';

import { ScheduleSlotResource, misApi } from '~shared/api';
import { FormRadioGroup } from '~shared/ui/form';

const props = defineProps<{
  parentModalVisible: boolean;
}>();
const emit = defineEmits<{
  selectSlot: [];
}>();
const slots = ref<ScheduleSlotResource[]>([]);

const { handleChange, value: startDate } = useField<string | undefined>('payloadData.start_at');
const { value: doctorId } = useField<number | undefined>('payloadData.doctor_id');
const { value: date } = useField<string | undefined>('date');
const { handleChange: handleSlotState } = useField<boolean>('isSlotAvailable');

const getSlots = async () => {
  if (doctorId?.value && date?.value) {
    const response = await misApi.g2g.scheduleSlots.getList({
      doctor_id: doctorId?.value,
      from: `${date.value} 00:00`,
      to: `${date.value} 23:59`,
    });

    slots.value = response?.data ?? [];
    setSlotState(startDate.value);
  } else {
    slots.value = [];
    handleChange(undefined, false);
  }
};

watch(
  () => ({
    doctorId: doctorId.value,
    date: date.value,
    parentModalVisible: props.parentModalVisible,
  }),
  async (value, oldValue) => {
    if (value.parentModalVisible && value !== oldValue) {
      await getSlots();
    }
  },
  { deep: true, immediate: true }
);

const setSlotState = (date?: string) => {
  if (!slots.value?.length) return;

  const currentSlot = slots.value.find((item) => item.start_at === date);
  handleSlotState(currentSlot?.available, true);
};

watch(startDate, (val) => {
  setSlotState(val);
});

defineExpose({
  getSlots: getSlots,
});
</script>

<style lang="scss" src="./index.scss" />
