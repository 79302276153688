import { ref } from 'vue';

import { UserFullDto } from '../api/g2g/types/index';

import { I18nService } from '~shared/lib/i18n';

const currentUser = ref<UserFullDto | null>();
const currentLang = ref<string | undefined | null>('');

export function loadHelpDeskEddyChatWidget(
  user: UserFullDto | null,
  lang: string | undefined | null
) {
  removeOldHelpDeskEddyChatWidget();

  currentUser.value = user;
  currentLang.value = lang;

  if (!currentUser.value || !currentLang.value) return;

  const script = document.createElement('script');
  script.src = '//cdn5.helpdeskeddy.com//js/modules/chat_widget/hde.js';
  script.setAttribute('id', 'hde-chat-widget');
  script.setAttribute('data-host', 'dmed.helpdeskeddy.com');
  script.setAttribute('data-position', 'bottom-left');
  script.setAttribute('data-lang', currentLang.value);
  script.setAttribute('data-user-name', currentUser.value?.name);
  script.setAttribute('data-user-email', currentUser.value?.email);
  script.setAttribute(
    'data-comment',
    `ФИО пользователя: ${currentUser.value?.name}, 
    Роль: ${I18nService.t(`User.Roles.${currentUser.value?.role}`)}, 
    Название клиники: ${currentUser.value?.clinic?.title}, 
    ID клиники: ${currentUser.value?.clinic_id}`
  );
  script.defer = true;
  document.body.appendChild(script);
}

export function removeOldHelpDeskEddyChatWidget() {
  const hdeChatWidget = document.getElementById('hde-chat-widget');
  const hdeContainer = document.getElementById('hde-container');
  if (hdeChatWidget && hdeContainer) {
    hdeChatWidget.remove();
    hdeContainer.remove();
  }
}
