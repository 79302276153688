<template>
  <MiDialog
    v-model="isModalVisible"
    class="appointment-talon-modal"
    :title="`${$t('Appointments.Ticket')} № ${appointment?.id}`"
    width="730px">
    <div class="appointment-talon-modal__body">
      <MiCard class="appointment-talon-modal__patient-info" :header="$t('Common.Patient')">
        <MiInfoField :label="$t('Common.PatientFullName')" horizontal>
          {{ appointment.user ? joinFullname(appointment.user) : '-' }}
        </MiInfoField>
        <MiInfoField label="Med ID" horizontal>
          {{ appointment.user?.number_med_card ?? '-' }}
        </MiInfoField>
      </MiCard>
      <MiCard header="Запись № 1">
        <MiInfoField :label="$t('Common.Doctor')" horizontal>
          {{ formattedSpecialties }}
        </MiInfoField>
        <MiInfoField :label="$t('Common.FullNameDoctor')" horizontal>
          {{ appointment.doctor?.name ?? '-' }}
        </MiInfoField>
        <MiInfoField :label="$t('Common.Cabinet')" horizontal>
          {{ appointment.doctor?.cabinet ?? '-' }}
        </MiInfoField>
        <MiInfoField :label="$t('DateAndTime.Date')" horizontal>
          {{ formattedDate }}
        </MiInfoField>
        <MiInfoField :label="$t('Appointments.ReceiptTime')" horizontal>
          {{ formattedTime }}
        </MiInfoField>
      </MiCard>
    </div>
    <template #footer>
      <MiButton type="danger" plain @click="handleCancelAppointment" :disabled="loading">{{
        $t('Appointments.CancelReception')
      }}</MiButton>
      <MiButton type="primary" @click="handlePrint" :disabled="loading">{{
        $t('Common.Print')
      }}</MiButton>
    </template>
  </MiDialog>
  <AppointmentTalonPrint
    @printed="$emit('printed')"
    ref="refTalonPrint"
    :appointment="preparedAppointment" />
</template>
<script lang="ts" setup>
import { computed, shallowRef, ref } from 'vue';
import { useRouter } from 'vue-router';

import { AppointmentTalonPrint } from '../AppointmentTalonPrint';

import { MiButton, MiCard, MiDialog, MiInfoField } from '~shared/ui';
import { misApi } from '~shared/api';
import { ROUTE_PATH } from '~shared/config';
import {
  AppointmentResource,
  DoctorResource,
  PatientResource,
  SpecialtyResource,
} from '~shared/api/gen/v2';
import { convertDateToAppFormat, joinFullname } from '~shared/lib';

export type AppointmentDataProps = AppointmentResource & {
  doctor: DoctorResource & {
    specialties: SpecialtyResource[];
  };
  user: PatientResource;
};
const props = defineProps<{
  modelValue: boolean;
  appointment: AppointmentDataProps;
}>();

const emits = defineEmits<{
  'update:modelValue': [event: boolean];
  'fetchTable': [];
  'printed': [];
}>();

const router = useRouter();
const loading = shallowRef(false);

const refTalonPrint = ref();

const isModalVisible = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
  },
});

const formattedDate = computed(() => {
  // TODO костыль
  if (props.appointment?.start_at?.length > 14) {
    return (
      convertDateToAppFormat(props.appointment?.start_at, { withTime: true, shortYear: true })
        ?.substring(0, 9)
        .split('-')
        .reverse()
        .join('.') ?? '-'
    );
  } else {
    return props.appointment?.start_at?.substring(0, 9).split('-').reverse().join('.') ?? '-';
  }
});

const formattedTime = computed(() => {
  // TODO костыль
  if (props.appointment?.start_at?.length > 14) {
    return (
      convertDateToAppFormat(props.appointment?.start_at, {
        withTime: true,
        shortYear: true,
      })?.substring(9, 16) ?? '-'
    );
  } else {
    return props.appointment?.start_at?.substring(9, 16) ?? '-';
  }
});

const preparedAppointment = computed((): AppointmentDataProps & { time: string } => {
  return {
    ...props.appointment,
    start_at: formattedDate.value,
    time: formattedTime.value,
  };
});

const formattedSpecialties = computed(() => {
  return props.appointment?.doctor?.specialties.map((item) => item.title).join(',') ?? '-';
});

const handleCancelAppointment = async () => {
  if (props.appointment?.id) {
    loading.value = true;
    const response = await misApi.g2g.appointments.updateStatus(props.appointment.id, 'provided');

    if (response?.data) {
      emits('fetchTable');
      isModalVisible.value = false;
      await router.push(ROUTE_PATH.appointments.activeList);
    }

    loading.value = false;
  }
};

const handlePrint = async () => {
  loading.value = true;
  await refTalonPrint.value.print();
  isModalVisible.value = false;
  loading.value = false;
};
</script>

<style lang="scss" src="./index.scss"></style>
