import { ROUTE_PATH } from '~shared/config';
import { onlyUserNotAuthMiddleware } from '~shared/lib';

export const WAITING_CONFIRM_ROUTE = {
  name: 'WaitingConfirm',
  path: ROUTE_PATH.auth.waitingConfirm,
  component: () => import('./ui'),
  beforeEnter: [onlyUserNotAuthMiddleware],
  meta: {
    title: 'Common.WaitingConfirm',
  },
};
