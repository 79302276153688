<template>
  <MiTable
    :loading="loading"
    :data="itemsWithPayload"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @row-click="goToOrder">
    <MiTableColumn prop="_id" label="ID" />
    <MiTableColumn
      v-if="isB2gInterface"
      prop="user"
      :label="$t('Common.Patient')"
      min-width="200px">
      <template #default="{ row }">
        <UiUserAvatarInfo v-if="row?.user" :user="row.user" />
      </template>
    </MiTableColumn>
    <MiTableColumn v-else prop="user" :label="$t('Common.Patient')" min-width="200px">
      <template #default="{ row }">
        <PatientName :name="row.user.name" :main-contingent="row.user.main_contingent" />
      </template>
    </MiTableColumn>
    <MiTableColumn
      prop="_biomaterialString"
      :label="$t('Laboratory.Biomaterial.Biomaterial')"
      min-width="240px" />

    <MiTableColumn prop="status" :label="$t('Common.Status')" width="210px">
      <template #default="{ row }">
        <OrderStatusTag :status="row.status" />
      </template>
    </MiTableColumn>
    <MiTableColumn
      v-if="showPaidStatus"
      prop="invoice.status"
      :label="$t('Common.Payment')"
      min-width="140px">
      <template #default="{ row }">
        <InvoiceStatusTag :status="row.invoice?.status" />
      </template>
    </MiTableColumn>

    <MiTableColumn prop="_createdDate" :label="$t('DateAndTime.CreatedAt')" width="120px" />

    <MiTableColumn
      prop="actions"
      :label="$t('Common.Actions')"
      :width="isUserManager ? '200px' : '400px'">
      <template #default="{ row }">
        <MiButton type="primary" link>
          <template #icon>
            <MiIcon icon="EYE" />
          </template>
          {{ $t('Common.Open') }}
        </MiButton>
        <MiButton
          v-if="!isUserManager"
          :disabled="!checkOrderCreatedOrAssembling(row)"
          @click.stop="sampleBiomaterial(row)"
          type="primary">
          {{ $t('Laboratory.SamplingBiomaterial') }}
        </MiButton>
      </template>
    </MiTableColumn>
  </MiTable>
</template>

<script>
import { storeToRefs } from 'pinia';

import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { InvoiceStatusTag } from '~entities/invoice';
import { PatientName } from '~entities/patient';
import { useSessionStore } from '~entities/session';
import { MiButton, MiTableColumn, MiIcon, MiTable } from '~shared/ui';
import { excludeTime } from '~shared/lib';

import { LABORATORY_ORDER_ROUTE } from '@/router/laboratory.routes';
import { Biomaterial } from '@/models/laboratory/Biomaterial.model';
import { Order } from '@/models/laboratory/Order.model';
import OrderStatusTag from '@/components/laboratory/OrderStatusTag/index.vue';
import SampleBiomaterialModal from '@/components/laboratory/SampleBiomaterialModal/index.vue';

export default {
  name: 'OrdersTable',
  components: {
    MiTableColumn,
    PatientName,
    MiButton,
    InvoiceStatusTag,
    OrderStatusTag,
    MiIcon,
    MiTable,
  },
  props: {
    /** @type {Array<Invoice|object>} items */
    items: Array,
    loading: Boolean,
    page: Number,
    perPage: Number,
    total: Number,
    currentPeriod: Object,
    showPaidStatus: Boolean,
  },
  computed: {
    itemsWithPayload() {
      return this.items.map((elem) => ({
        ...elem,

        _id: `#${elem.id}`,
        _biomaterialString: elem.biomaterials
          .map((biomaterial) => biomaterial.biomaterial_type_translate)
          .join(', '),
        _createdDate: excludeTime(elem.created_at),
      }));
    },

    isUserManager() {
      const { userRole } = storeToRefs(useSessionStore());
      return userRole.value === 'manager';
    },
  },

  methods: {
    goToOrder(order) {
      this.$router.push({
        name: LABORATORY_ORDER_ROUTE.name,
        params: {
          id: order.id,
        },
        query: {
          date_period: this.currentPeriod,
        },
      });
    },

    checkOrderCreatedOrAssembling(row) {
      return (
        row.status === Order.enum.statuses.Created || row.status === Order.enum.statuses.Assemble
      );
    },

    getBiomaterialStatus(row) {
      if (row.biomaterials[0].sampling_at && row.biomaterials[0].confirmed_at) {
        return Biomaterial.enum.statuses.Confirmed;
      }

      if (row.biomaterials[0].sampling_at && !row.biomaterials[0].confirmed_at) {
        return Biomaterial.enum.statuses.NotConfirmed;
      }

      if (!row.biomaterials[0].sampling_at) {
        return Biomaterial.enum.statuses.NotAssembled;
      }
    },

    async sampleBiomaterial(row) {
      const biomaterialStatus = this.getBiomaterialStatus(row);

      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: SampleBiomaterialModal,
        payload: {
          biomaterial: row.biomaterials[0],
          patient: row.user,
          status: biomaterialStatus,
        },
      });
      if (!(action instanceof GlobalModalClose))
        this.$emit('biomaterial:update', action.data.biomaterial);
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
