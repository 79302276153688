<template>
  <MiDialog :title="$t('Contingent.AttachToPolyclinic')" v-model="isModalVisible">
    <FormFieldGroupWrapper>
      <FormSearchSelect
        field-name="microdistrict_id"
        field-object-name="microdistrict"
        :fetch-data="misApi.g2g.directory.microdistricts.getList"
        :label="$t('Directories.Area')"
        clearable
        required />
      <FormSearchSelect
        :label="$t('Common.FullNameDoctor')"
        field-name="doctor_id"
        label-key="name"
        field-object-name="doctor"
        raw-fetch-result
        :query="{ microdistrict_id: values.microdistrict_id, is_active: true }"
        :fetch-data="misApi.gen.v2.doctors.listDoctorsForSelect"
        :disabled="!values.microdistrict_id"
        required
        clearable />
    </FormFieldGroupWrapper>
    <FormActionsWrapper align="right">
      <MiButton
        type="primary"
        @click="handleSubmit"
        :disabled="submitDisabled"
        :loading="attachmentLoading"
        >{{ $t('Contingent.Attach') }}</MiButton
      >
    </FormActionsWrapper>
  </MiDialog>
</template>

<script lang="ts" setup>
import { computed, watch, ref } from 'vue';
import { useForm } from 'vee-validate';

import { CreatePatientsClinicAttachmentsResource, validationSchema } from '../../model';

import { MiDialog, MiButton } from '~shared/ui';
import { FormSearchSelect, FormFieldGroupWrapper, FormActionsWrapper } from '~shared/ui/form';
import { misApi } from '~shared/api';
import { DoctorResource, MicroDistrictResource } from '~shared/api/gen/v2';

type FormData = {
  doctor: DoctorResource;
  doctor_id: DoctorResource['id'];
  microdistrict: MicroDistrictResource;
  microdistrict_id: MicroDistrictResource['id'];
};

const props = defineProps<{
  modelValue: boolean;
  patientId: string | number | null | undefined;
  doctor?: DoctorResource;
  microdistrict?: MicroDistrictResource;
}>();

const emit = defineEmits<{
  'update:modelValue': [e: boolean];
  'update-successful': [e: CreatePatientsClinicAttachmentsResource];
}>();

const {
  isSubmitting,
  handleSubmit: submit,
  resetForm,
  values,
  meta,
  setValues,
} = useForm<FormData>({
  validationSchema,
});

const attachmentLoading = ref(false);

const isModalVisible = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const submitDisabled = computed(() => {
  return !meta.value.valid || isSubmitting.value;
});

const handleSubmit = submit(
  async (value) => {
    if (!props.patientId) return;

    attachmentLoading.value = true;

    const response = await misApi.gen.v2.patients.createPatientsClinicAttachments(
      Number(props.patientId),
      {
        microdistrict_id: value.microdistrict_id,
        doctor_id: value.doctor_id,
      }
    );

    if (response?.data) {
      const data: CreatePatientsClinicAttachmentsResource = response.data;
      emit('update-successful', data);
      isModalVisible.value = false;
    }
    attachmentLoading.value = false;
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);

watch(isModalVisible, (val) => {
  if (!val) {
    resetForm();
  } else if (props.doctor && props.microdistrict) {
    setValues({
      microdistrict: props.microdistrict,
      microdistrict_id: props.microdistrict.id,
      doctor: props.doctor,
      doctor_id: props.doctor.id,
    });
  }
});
</script>
