<template>
  <MiFormItem
    :class="[
      'form-radio-group',
      {
        'form-radio-group_in-column': props.inColumn,
        'form-radio-group--option-in-column': props.optionInColumn,
      },
    ]"
    :error="errorMessage"
    :label="props.label"
    :required="required">
    <MiRadioGroup v-model="value" :validate-event="false">
      <slot></slot>
    </MiRadioGroup>
  </MiFormItem>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';

import { MiRadioGroup, MiFormItem } from '~shared/ui';

type Props = {
  fieldName: string;
  label?: string;
  required?: boolean;
  inColumn?: boolean;
  optionInColumn?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  label: '',
  inColumn: true,
  optionInColumn: false,
});

// eslint-disable-next-line vue/no-setup-props-destructure
const { value, errorMessage } = useField<boolean | undefined>(props.fieldName);
</script>

<style lang="scss" src="./index.scss" />
