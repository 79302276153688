import { ElNotification } from 'element-plus';

import { getApiErrorMessage } from '~shared/lib';
import { apiService, CreateVaccinePayload, GetVaccineResponseData } from '~shared/api';

const urlPath = 'g2g/patients';

export const getList = async (userId: number | string) => {
  try {
    return await apiService.api.instance.get<GetVaccineResponseData>(
      `${urlPath}/${userId}/vaccines`
    );
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const create = async (userId: string, payload: CreateVaccinePayload) => {
  try {
    const response = await apiService.api.instance.post<GetVaccineResponseData>(
      `${urlPath}/${userId}/vaccines/create`,
      payload
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const remove = async (userVaccineId: number) => {
  try {
    const response = await apiService.api.instance.delete(`${urlPath}/vaccines/${userVaccineId}`);

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const update = async (userVaccineId: number, payload: CreateVaccinePayload) => {
  try {
    const response = await apiService.api.instance.put<GetVaccineResponseData>(
      `${urlPath}/vaccines/${userVaccineId}`,
      payload
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
