<template>
  <ElDialog
    class="sample-biomaterial-modal"
    :title="$t('Laboratory.SamplingBiomaterial')"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElCard shadow="never">
      <ElFormItem
        class="sample-biomaterial-modal__biomaterial"
        :label="$t('Laboratory.Biomaterial.Biomaterial')">
        <span class="sample-biomaterial-modal__biomaterial-name">
          {{ biomaterial.biomaterial_type_translate || biomaterial.biomaterial_type }}
        </span>
      </ElFormItem>

      <ElFormItem :label="$t('Common.Code')">
        <svg class="sample-biomaterial-modal__bar-code" ref="barCode"></svg>
      </ElFormItem>
    </ElCard>

    <template #footer>
      <MiButton type="primary" @click="print"> {{ $t('Common.Print') }}</MiButton>

      <MiButton
        v-if="status === Biomaterial.enum.statuses.NotAssembled"
        type="primary"
        plain
        :loading="loading"
        @click="completeSampling">
        {{ $t('Laboratory.CompleteSampling') }}
      </MiButton>
    </template>

    <BiomaterialBarCodePrinterDocument
      :biomaterial="biomaterial"
      :patient="patient"
      ref="printerDocument" />
  </ElDialog>
</template>

<script>
import axios from 'axios';
import JsBarcode from 'jsbarcode';
import { defineAsyncComponent } from 'vue';
import { ElFormItem, ElCard, ElDialog } from 'element-plus';

import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import { amplitudeService } from '~shared/lib';
import { MiButton } from '~shared/ui';

import { Biomaterial } from '@/models/laboratory/Biomaterial.model';

export default {
  name: 'SampleBiomaterialModal',
  components: {
    MiButton,
    ElFormItem,
    ElCard,
    ElDialog,
    BiomaterialBarCodePrinterDocument: defineAsyncComponent(
      () => import('@/components/laboratory/BiomaterialBarCodePrinterDocument/index.vue')
    ),
  },
  emits: ['update:modelValue', 'action'],
  props: {
    modelValue: Boolean,
    biomaterial: [Biomaterial, Object],
    patient: [Object],
    status: String, // Study status
  },
  data() {
    return {
      loading: false,
    };
  },

  watch: {
    biomaterial: {
      handler(value) {
        setTimeout(() =>
          JsBarcode(this.$refs.barCode, value.id, {
            height: '26px',
            displayValue: false,
          })
        );
      },
      immediate: true,
    },
  },

  methods: {
    async print() {
      this.sendAmplitudePrintBarcode(this.biomaterial.id, this.$route.params.id, this.patient.id);
      this.$refs.printerDocument?.print();
    },

    async completeSampling() {
      this.loading = true;

      try {
        const { data } = await Biomaterial.updateStatusByBiomaterialId({
          id: this.biomaterial.id,
          status: Biomaterial.enum.statuses.Assembled,
        });

        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessUpdated') });
        this.$emit(
          'action',
          new GlobalModalInstance({
            name: 'updated',
            data: { biomaterial: data.data },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading = false;
    },

    sendAmplitudePrintBarcode(biomaterialId, orderId, patientId) {
      amplitudeService.logEvent('laboratory_order.print_barcode', {
        biomaterial_id: biomaterialId,
        order_id: orderId,
        patient_id: patientId,
      });
    },
  },

  setup: () => ({ Biomaterial }),
};
</script>

<style lang="scss" src="./index.scss" />
