<template>
  <ElDatePicker class="mi-date-picker" v-bind="$props" v-model="value" ref="datePickerRef">
    <template v-if="$slots.default" #default>
      <slot name="default" />
    </template>

    <template v-if="$slots['range-separator']" #range-separator>
      <slot name="range-separator" />
    </template>
  </ElDatePicker>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { ElDatePicker, TimePickerDefaultProps, DatePickType, ModelValueType } from 'element-plus';

import { FULL_DATE_FORMAT, TIME_FORMAT } from '~shared/config/date';

export type MiDatePickerProps = {
  type?: DatePickType;
} & Partial<TimePickerDefaultProps>;

const props = withDefaults(defineProps<MiDatePickerProps>(), {
  type: 'date',
  teleported: true,
  validateEvent: true,
  editable: true,
  clearable: true,
  dateFormat: FULL_DATE_FORMAT,
  timeFormat: TIME_FORMAT,
});
const emit = defineEmits<{
  'update:modelValue': [e: ModelValueType | undefined];
}>();

const datePickerRef = ref<(typeof ElDatePicker & { handleOpen: () => void }) | null>(null);

const handleOpen = ref<(() => void) | null>(null);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val: ModelValueType | undefined) {
    emit('update:modelValue', val);
  },
});

onMounted(() => {
  if (!datePickerRef.value) return;

  handleOpen.value = datePickerRef.value.handleOpen;
});

defineExpose({
  handleOpen,
});
</script>

<style lang="scss" src="./index.scss" />
