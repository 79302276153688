import { AxiosResponse, GenericAbortSignal } from 'axios';
import cloneDeep from 'lodash.clonedeep';

import { RequestParams } from '~shared/api/gen/v2';
import { AnyObject } from '~shared/types';

export const useAbortable = <T extends AnyObject, D = unknown>(
  apiFunction: (payload?: T, signal?: GenericAbortSignal) => Promise<D>
) => {
  return (): [(payload?: T) => Promise<D>, () => void] => {
    const abortController = new AbortController();
    const abortableSearch = async (payload?: T) => {
      return await apiFunction(payload, abortController.signal);
    };

    return [
      abortableSearch,
      () => {
        try {
          abortController.abort('Canceled');
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      },
    ];
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApiMethodWithAbortableForGen<T extends any[], R extends AxiosResponse> = (
  ...args: [...args: T, extraArg?: RequestParams]
) => Promise<R>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAbortableForGen = <T extends any[], R extends AxiosResponse>(
  fn: ApiMethodWithAbortableForGen<T, R>
): [ApiMethodWithAbortableForGen<T, R>, () => void] => {
  const abortController = new AbortController();

  const abortableApiMethod = async (
    ...args: [...args: T, extraArg?: RequestParams]
  ): Promise<R> => {
    const clonedParams: [...args: T, extraArg?: RequestParams] = cloneDeep(args);

    if (clonedParams.length === 0) {
      clonedParams[0] = { signal: abortController.signal };
    } else {
      const params = clonedParams[clonedParams.length - 1] || null;
      clonedParams[clonedParams.length - 1] = { ...(params ?? {}), signal: abortController.signal };
    }
    return fn(...clonedParams);
  };
  return [
    abortableApiMethod,
    () => {
      try {
        abortController.abort('Canceled');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },
  ];
};
