import { AxiosError, GenericAbortSignal } from 'axios';
import { ElNotification } from 'element-plus';

import { QueryParams } from '~shared/types';
import { apiService, MetaDto, DoctorShort } from '~shared/api';
import { getApiErrorMessage, joinQueryParams } from '~shared/lib';

const urlPath = 'g2g/doctors';

export const getList = async (query: QueryParams = {}, signal?: GenericAbortSignal) => {
  try {
    const response = await apiService.api.instance.get<{ data: DoctorShort[]; meta: MetaDto }>(
      joinQueryParams({
        url: urlPath,
        query,
      }),
      {
        signal,
      }
    );
    return {
      ...response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError && err.code === 'ERR_CANCELED') return;
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
