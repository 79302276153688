<template>
  <MiTable
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <MiTableColumn prop="title" :label="$t('Supplier.NameSupplier')" />
    <MiTableColumn prop="contact_person" :label="$t('Supplier.ContactPerson')" />
    <MiTableColumn prop="phone" :label="$t('Supplier.PhoneNumber')" />
    <MiTableColumn prop="description" :label="$t('Nomenclature.Notes')" />
    <MiTableColumn :label="$t('Nomenclature.Actions')" width="150px">
      <template #default="{ row }">
        <MiButton size="small" type="primary" plain @click.stop="$emit('modal:open', row)"
          >{{ $t('Nomenclature.Edit') }}
        </MiButton>
      </template>
    </MiTableColumn>
  </MiTable>
</template>
<script lang="ts">
import { PropType } from 'vue';

import { MiButton, MiTableColumn, MiTable } from '~shared/ui';
import { SupplierDto } from '~shared/api/common';

export default {
  name: 'SuppliersTable',
  components: {
    MiButton,
    MiTableColumn,
    MiTable,
  },
  emits: ['update:page', 'update:perPage', 'modal:open'],
  props: {
    loading: { type: Boolean, required: true },
    items: { type: Array as PropType<SupplierDto[]>, required: true },
    page: { type: Number, required: true },
    perPage: { type: Number, required: true },
    total: { type: Number, required: true },
  },
};
</script>

<style lang="scss" scoped></style>
