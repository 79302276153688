import { onlyUserHasRoleMiddleware, onlyUserIsAuthMiddleware } from '~shared/lib';
import { ROUTE_PATH } from '~shared/config';

export const REPORTS_ON_FORMS_ROUTE = {
  name: 'ReportsOnForms',
  path: ROUTE_PATH.reportsOnForms,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['doctor'])],
  meta: {
    title: 'Common.Documents',
  },
};
