import { onlyUserIsAuthMiddleware } from '~shared/lib';
import { ROUTE_PATH } from '~shared/config';

export const CHECKUP_TEMPLATE_HEADER_TABLE_ROUTE = {
  name: 'CheckupHeaderTemplateTable',
  path: ROUTE_PATH.templates.checkupHeaderTemplateTable,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Templates.CheckupHeaders',
  },
};
