import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useInpatientCareStore = defineStore('inpatientCareStore', () => {
  const isEditable = ref(false);

  function setIsEditable(payload: boolean) {
    isEditable.value = payload;
  }

  return {
    isEditable,
    setIsEditable,
  };
});
