import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const ACCOUNTING_PATH_PREFIX = '/accounting';

export const ACCOUNTING_ROUTE_OUTCOME = {
  name: 'ACCOUNTING_ROUTE_OUTCOME',
  path: `${ACCOUNTING_PATH_PREFIX}/outcomes`,
  component: 'VAccountingOutcome',
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Outcome.Expenses',
  },
};

export const ACCOUNTING_ROUTE_SERVICES = {
  name: 'ACCOUNTING_ROUTE_SERVICES',
  path: `${ACCOUNTING_PATH_PREFIX}/services`,
  component: 'VServices',
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'accountant']),
  ],
  meta: {
    title: 'Common.Services',
  },
};

export const routes = [ACCOUNTING_ROUTE_OUTCOME, ACCOUNTING_ROUTE_SERVICES];
