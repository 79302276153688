<template>
  <MiTable
    :data="itemsWithPayload"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    :default-sort="defaultSort"
    @row-click="editService"
    @sort-change="onSortChange"
    @update:page="$emit('update:page', $event)"
    @update:perPage="$emit('update:perPage', $event)">
    <MiTableColumn prop="id" label="№" width="100px" />
    <MiTableColumn prop="title" :label="$t('Common.Service')" />
    <MiTableColumn
      prop="sold_count"
      :label="$t('Analytics.Quantity')"
      width="200px"
      sortable="custom">
      <template #default="{ row }">
        <div class="services-table__column">
          {{ row.sold_count ? row.sold_count : 0 }}
          <div :class="['services-table__column-value', chooseClassCount(row)]">
            <MiIcon
              class="services-table__column-value-icon"
              :icon="row.sold_count_delta >= 0 ? 'INCREASE_PERCENTAGE' : 'DECREASE_PERCENTAGE'" />
            <div>{{ row.sold_count_delta ? row.sold_count_delta : 0 }}%</div>
          </div>
        </div>
      </template>
    </MiTableColumn>
    <MiTableColumn prop="sold_amount" :label="$t('Salary.SumCost')" width="200px" sortable="custom">
      <template #default="{ row }">
        <div class="services-table__column">
          {{ row.sold_amount ? row.sold_amount : 0 }}
          <div :class="['services-table__column-value', chooseClassAmount(row)]">
            <MiIcon
              class="services-table__column-value-icon"
              :icon="row.sold_amount_delta >= 0 ? 'INCREASE_PERCENTAGE' : 'DECREASE_PERCENTAGE'" />
            <div>{{ row.sold_amount_delta ? row.sold_amount_delta : 0 }}%</div>
          </div>
        </div>
      </template>
    </MiTableColumn>
    <MiTableColumn prop="price" :label="$t('Common.Price')" />
    <MiTableColumn prop="actions" :label="$t('Common.Actions')" width="150px">
      <template #default="{ row }">
        <div class="services-table__actions">
          <MiButton type="primary" size="small" plain @click.stop="editService(row)">
            {{ $t('Common.Edit') }}
          </MiButton>
        </div>
      </template>
    </MiTableColumn>
  </MiTable>
</template>

<script>
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { PAGE_SIZES } from '~shared/config';
import { amplitudeService, formatPrice } from '~shared/lib';
import { MiTableColumn, MiButton, MiTable, MiIcon } from '~shared/ui';

import ServiceCrudModal from '@/components/accounting/modals/ServiceCrudModal/index.vue';

export default {
  name: 'ServicesTable',
  components: { MiTableColumn, MiButton, MiIcon, MiTable },
  emits: [
    'update:page',
    'update:perPage',
    'item:update',
    'item:delete',
    'sort-change',
    'table:reload',
  ],
  props: {
    /** @type {Array<ServiceGroupDto|object>} */
    items: Array,
    loading: Boolean,
    percentage: Number,
    total: [Number, String],
    page: [Number, String],
    perPage: [Number, String],
    defaultSort: Object,
  },
  data() {
    return {
      deleteLoading: false,
      deletingItemId: null,
    };
  },
  computed: {
    itemsWithPayload() {
      return this.items.map((elem) => ({
        ...elem,
        _price: formatPrice({ price: elem.price }),
      }));
    },
    hasItems() {
      return !!this.items.length;
    },
    pageCount() {
      return Math.ceil(this.total / this.perPage);
    },
    pageSizes() {
      return PAGE_SIZES;
    },
  },
  methods: {
    async editService(row) {
      amplitudeService.logEvent('open_modal_edit_service');
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: ServiceCrudModal,
        payload: { id: row.id },
      });

      if (!(action instanceof GlobalModalClose)) {
        this.$emit('table:reload', action.data);
      }
    },

    chooseClassCount(row) {
      return `services-table__column-value_${
        this.getBooleanSoldCountDelta(row) ? 'success' : 'danger'
      }`;
    },

    chooseClassAmount(row) {
      return `services-table__column-value_${
        this.getBooleanSoldAmountDelta(row) ? 'success' : 'danger'
      }`;
    },

    getBooleanSoldCountDelta(row) {
      return row.sold_count_delta >= 0;
    },

    getBooleanSoldAmountDelta(row) {
      return row.sold_amount_delta >= 0;
    },

    onSortChange({ prop, order }) {
      this.$emit('sort-change', { prop, order });
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
