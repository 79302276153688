import { ElNotification } from 'element-plus';

import {
  CreateTemplateRequestPayload,
  CreateTemplateResponseData,
  GetTemplateByIdResponseData,
  GetTemplateListResponseData,
  TemplateFullDto,
  UpdateTemplateRequestPayload,
  UpdateTemplateResponseData,
} from '../types';

import { getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';

const urlPath = 'g2g/templates';

export const getList = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<GetTemplateListResponseData>(
      joinQueryParams({
        url: urlPath,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getById = async (id: TemplateFullDto['id']) => {
  try {
    const response = await apiService.api.instance.get<GetTemplateByIdResponseData>(
      `${urlPath}/${id}`
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getByIdWithHeader = async (
  id: TemplateFullDto['id'],
  query: { appointment_id: number }
) => {
  try {
    const response = await apiService.api.instance.get<GetTemplateByIdResponseData>(
      joinQueryParams({
        url: `${urlPath}/${id}/with-header`,
        query,
      })
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const create = async (payload: CreateTemplateRequestPayload) => {
  try {
    const response = await apiService.api.instance.post<CreateTemplateResponseData>(
      `${urlPath}/create`,
      payload
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const update = async (id: TemplateFullDto['id'], payload: UpdateTemplateRequestPayload) => {
  try {
    const response = await apiService.api.instance.put<UpdateTemplateResponseData>(
      `${urlPath}/${id}/update`,
      payload
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const remove = async (id: TemplateFullDto['id']) => {
  try {
    return await apiService.api.instance.delete(`${urlPath}/${id}/delete`);
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
