import { ROUTE_PATH } from '~shared/config';
import { onlyUserHasRoleMiddleware, onlyUserIsAuthMiddleware } from '~shared/lib';

export const PATRONAGE_LIST_ROUTE = {
  name: 'PatronageList',
  path: ROUTE_PATH.patronages.list,
  component: () => import('./PatronageList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Analytics.Planned',
  },
};

export const PATRONAGE_PLANNING_LIST_ROUTE = {
  name: 'PatronagePlanningList',
  path: ROUTE_PATH.patronages.planning,
  component: () => import('./PatronagePlanningList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Analytics.Planning',
  },
};

export const PATRONAGE_PATIENT_ROUTE = {
  name: 'PatronagePatient',
  path: ROUTE_PATH.patronages.patronage.rawPath,
  component: () => import('./PatronagePatient/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'nurse']),
  ],
  meta: {
    title: 'Patronage.ExaminationPatient',
  },
  props: true,
};
