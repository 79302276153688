import { ElNotification } from 'element-plus';
import { AxiosError, GenericAbortSignal } from 'axios';

import {
  AppointmentFullDto,
  AppointmentStatus,
  GetAppointmentListResponseData,
  UpdatePatientDiseaseCodesPayload,
  DoctorCalendarAppointmentParams,
  GetDoctorCalendarAppointmentListResponseData,
  RescheduleAppointment,
  PutRescheduleAppointmentResponseData,
} from '~shared/api';
import { getApiErrorMessage, joinQueryParams, useAbortable } from '~shared/lib';
import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';

const urlPath = 'g2g/appointments';

export const getList = async (query: QueryParams = {}, signal?: GenericAbortSignal) => {
  try {
    const response = await apiService.api.instance.get<GetAppointmentListResponseData>(
      joinQueryParams({
        url: urlPath,
        query,
      }),
      {
        signal,
      }
    );
    return {
      ...response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError && err.code === 'ERR_CANCELED') return;
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const abortableGetList = useAbortable(getList);

export const getById = async (id: number | string) => {
  try {
    const response = await apiService.api.instance.get<{ data: AppointmentFullDto }>(
      `${urlPath}/${id}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

// TODO: types
export const create = async (payload: unknown) => {
  try {
    const response = await apiService.api.instance.post(`${urlPath}/create`, payload);
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const updateStatus = async (id: number | string, status: AppointmentStatus) => {
  try {
    const response = await apiService.api.instance.put<{ data: AppointmentFullDto }>(
      `${urlPath}/${id}/status/${status}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const updatePatientDiseaseCodes = async (payload: UpdatePatientDiseaseCodesPayload) => {
  try {
    await apiService.api.instance.put(`${urlPath}/${payload.appointmentId}/codes`, payload);
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const doctorCalendarAppointment = async (query: DoctorCalendarAppointmentParams) => {
  try {
    const response =
      await apiService.api.instance.get<GetDoctorCalendarAppointmentListResponseData>(
        joinQueryParams({
          url: `appointments/doctor-calendar`,
          query,
        })
      );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
export const rescheduleAppointment = async (
  appointmentId: number,
  payload: RescheduleAppointment
) => {
  try {
    const response = await apiService.api.instance.put<PutRescheduleAppointmentResponseData>(
      `appointments/${appointmentId}/reschedule`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
