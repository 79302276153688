<template>
  <CreateG2GAppointmentModal
    v-if="isG2gAppointmentModal"
    :id="id"
    :set-default-my-doctor="setDefaultMyDoctor"
    :disable-default-action="disableDefaultAction"
    :initial-data="initialData"
    v-model="modalVisible"
    @search-patient="$emit('search-patient')"
    @created-successfully="$emit('created-successfully', $event)" />
  <CreateB2GAppointmentModal
    :examination-plan="examinationPlan"
    :close-after-create="closeAfterCreate"
    :default-doctor="setDefaultMyDoctor"
    :initial-data="b2gInitialData"
    v-model:visible="modalVisible"
    v-else-if="isB2gAppointmentModal"
    @slot-busy="$emit('slot-busy')"
    @created-successfully="$emit('created-successfully')" />
</template>

<script setup lang="ts">
import { computed, onUnmounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';

import { CreateG2GAppointmentModal } from '../CreateG2GAppointmentModal';
import { CreateB2GAppointmentModal } from '../CreateB2GAppointmentModal';

import { useAppConfigStore } from '~entities/session';
import { AnyObject } from '~shared/types';
import { amplitudeService } from '~shared/lib';

export type CreateAppointmentModalProps = {
  modelValue: boolean;
  id?: number | string;
  disableDefaultAction?: boolean;
  setDefaultMyDoctor?: boolean;
  examinationPlan?: boolean;
  closeAfterCreate?: boolean;
  initialData?: {
    start_at?: string;
    end_at?: string;
    doctor_id?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    patient?: any;
    doctor?: { id: number; name: string };
    parent_appointment_id?: number;
    medical_case_id?: number;
  };
};

const props = withDefaults(defineProps<CreateAppointmentModalProps>(), {
  disableDefaultAction: true,
  examinationPlan: false,
  closeAfterCreate: false,
  initialData: () => ({}),
});

const route = useRoute();

// костыль
const b2gInitialData = computed(() => {
  if (props.initialData.patient?.name) {
    const [surname, name, ...patronymicParts] = props.initialData.patient.name
      .split(' ')
      .filter((f: string) => !!f);

    return {
      ...props.initialData,
      patient: {
        ...props.initialData.patient,
        surname,
        name,
        patronymic: patronymicParts.join(' '),
      },
    };
  } else {
    return props.initialData;
  }
});

const emit = defineEmits<{
  'update:modelValue': [v: boolean];
  'created-successfully': [appointmentData?: AnyObject];
  'search-patient': [];
  'slot-busy': [];
}>();

const isB2gInterface = computed(() => featureFlags.value?.['user.interface'] === 'b2g');

const isB2gPlusInterface = computed(
  () =>
    featureFlags.value?.['user.interface'] === 'b2g' && featureFlags.value?.['clinic.cashRegister']
);

const isB2gAppointmentModal = computed(
  () => isB2gPlusInterface.value || featureFlags.value?.['user.appointmentModalFlow'] === 'b2g'
);
const isG2gAppointmentModal = computed(() => {
  if (route.name === 'LABORATORY_ORDERS') {
    return true;
  } else {
    return featureFlags.value?.['user.appointmentModalFlow'] === 'g2g' && !isB2gPlusInterface.value;
  }
});
const sendAmplitudeOpenModal = () => {
  if (isB2gInterface.value) {
    amplitudeService.logEvent('create_appointment_modal.open');
  }
};

const modalVisible = computed({
  get() {
    return props.modelValue;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

const appConfigStore = useAppConfigStore();
const { featureFlags } = storeToRefs(appConfigStore);

onUnmounted(() => {
  emit('update:modelValue', false);
});

watch(
  () => modalVisible.value,
  (val) => {
    if (val) {
      sendAmplitudeOpenModal();
    }
  }
);
</script>
