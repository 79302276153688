import { BeneficiaryCategoryEnum } from '~shared/api/gen/v2';
import { I18nService } from '~shared/lib';

export const beneficiaryCategoryOptions: Array<{
  value: BeneficiaryCategoryEnum;
  label: string;
}> = [
  {
    value: 'cancer_patients',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.cancer_patients'),
  },
  {
    value: 'tuberculosis_patients',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.tuberculosis_patients'),
  },
  {
    value: 'endocrine_disease_patients',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.endocrine_disease_patients'),
  },
  {
    value: 'hemodialysis_patients',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.hemodialysis_patients'),
  },
  {
    value: 'war_veterans_1941_1945',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.war_veterans_1941_1945'),
  },
  {
    value: 'labor_front_participants',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.labor_front_participants'),
  },
  {
    value: 'chernobyl_liquidators',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.chernobyl_liquidators'),
  },
  {
    value: 'international_soldiers',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.international_soldiers'),
  },
  {
    value: 'childhood_disability',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.childhood_disability'),
  },
  {
    value: 'disabled_groups_i_ii',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.disabled_groups_i_ii'),
  },
  {
    value: 'orphans',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.orphans'),
  },
  {
    value: 'unemployed_pensioners',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.unemployed_pensioners'),
  },
  {
    value: 'low_income_families',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.low_income_families'),
  },
  {
    value: 'conscripts',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.conscripts'),
  },
  {
    value: 'deceased_military_families',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.deceased_military_families'),
  },
  {
    value: 'children_with_pathology',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.children_with_pathology'),
  },
  {
    value: 'pregnant_women_with_pathology',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.pregnant_women_with_pathology'),
  },
  {
    value: 'std_patients',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.std_patients'),
  },
  {
    value: 'medical_workers',
    label: I18nService.t('Patients.BeneficiaryCategoryEnum.medical_workers'),
  },
];
