import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware } from '~shared/lib';

export const OAUTH_ROUTE = {
  name: 'OAuth',
  path: ROUTE_PATH.oauth,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'OAuth',
  },
};
