import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const CHECKUP_TEMPLATES_ROUTE = {
  name: 'CheckupTemplates',
  path: ROUTE_PATH.templates.checkups,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['doctor', 'nurse'])],
  meta: {
    title: 'Templates.CheckupTemplates',
  },
};
