import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const DISABLED_PEOPLE_MAIN_LIST_ROUTE = {
  name: 'DisabledPeopleMainList',
  path: ROUTE_PATH.disabledPeople.mainList,
  component: () => import('./DisabledPeopleMainList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Analytics.DisabledPeople',
  },
};

export const DISABLED_PEOPLE_CODE_LIST_ROUTE = {
  name: 'DisabledPeopleCodeList',
  path: ROUTE_PATH.disabledPeople.codeList.rawPath,
  component: () => import('./DisabledPeopleCodeList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Analytics.DisabledPeople',
  },
};

export const DISABLED_PEOPLE_PATIENT_TRANSFERRED_ROUTE = {
  name: 'DisabledPeoplePatientTransferred',
  path: ROUTE_PATH.disabledPeople.changeList,
  component: () => import('./DisabledPeoplePatientTransferred/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Analytics.DisabledPeople',
  },
};
