import { ISOStringToDateAppFormat } from '../../date';
import { useQuery } from './useQuery';

import { DATE_PERIOD } from '~shared/config';

export function useDatePeriod(defaultValue?: string[], fieldName?: string) {
  return useQuery({
    field: fieldName ?? DATE_PERIOD,
    defaultValue: defaultValue ?? [
      ISOStringToDateAppFormat(new Date().toISOString()),
      ISOStringToDateAppFormat(new Date().toISOString()),
    ],
    valueIsArray: true,
  });
}
