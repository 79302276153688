<template>
  <MiSimpleTable :max-height="400" table-class="service-products-table" :data="props.items">
    <MiTableColumn prop="title" :label="$t('Nomenclature.NameOfProduct')" />
    <!-- <MiTableColumn prop="price" :label="$t('Common.Price')" /> -->
    <MiTableColumn prop="count" :label="$t('Analytics.Quantity')" />
    <!-- <MiTableColumn prop="total_amount" :label="$t('Salary.SumCost')" /> -->

    <MiTableColumn prop="actions">
      <template #default="{ row }">
        <div class="service-products-table-actions">
          <MiButton
            class="service-products-table-actions__delete-button"
            type="primary"
            text
            @click.stop="$emit('table:delete', row.id)">
            <template #icon>
              <MiIcon icon="CLOSE" />
            </template>
          </MiButton>
        </div>
      </template>
    </MiTableColumn>
  </MiSimpleTable>
</template>

<script lang="ts">
export default {
  name: 'ServiceProductsTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { MiTableColumn, MiButton, MiIcon, MiSimpleTable } from '~shared/ui';

import { ExpenseProduct } from '@/types/api';

defineEmits(['table:delete']);

const props = defineProps<{
  items: ExpenseProduct[];
}>();
</script>

<style lang="scss" src="./index.scss" />
