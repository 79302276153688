import { ElNotification } from 'element-plus';

import { OutcomeDto, OutcomePayloadDto } from '../types';

import { apiService, MetaDto } from '~shared/api';
import { joinQueryParams, clearObjectEmptyValues, getApiErrorMessage } from '~shared/lib';
import { QueryParams } from '~shared/types';

const urlPrefix = 'outcomes';

export const getItems = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{ data: OutcomeDto[]; meta: MetaDto }>(
      joinQueryParams({
        url: urlPrefix,
        query: clearObjectEmptyValues(query),
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createItem = async (payload: OutcomePayloadDto) => {
  try {
    const response = await apiService.api.instance.post<{ data: OutcomeDto }>(
      `${urlPrefix}/create`,
      payload
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
