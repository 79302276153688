import { ElNotification } from 'element-plus';

import { FileDto } from '../types';

import { getApiErrorMessage } from '~shared/lib';
import { apiService } from '~shared/api';

const urlPath = 'files';

export const create = async (payload: FormData) => {
  try {
    return await apiService.api.instance.post<{ data: FileDto[] }>(`${urlPath}/create`, payload, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
    throw new Error('file is not uploaded');
  }
};

export const remove = async ({ fileId }: { fileId?: number | null }) => {
  try {
    if (!fileId) throw new Error('File Id in not provided');
    const response = await apiService.api.instance.delete<{ data: FileDto }>(
      `${urlPath}/${fileId}/delete`
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
    throw new Error('file is not deleted');
  }
};
