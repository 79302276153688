<template>
  <MiSimpleTable height="600px" table-class="outcome-products-table" :data="props.items">
    <MiTableColumn prop="product.title" :label="$t('Nomenclature.NameOfProduct')" />
    <MiTableColumn prop="product.article" :label="$t('Nomenclature.VendorCode')" width="150px" />
    <MiTableColumn prop="type" :label="$t('Outcome.OutcomeType')" width="150px">
      <template #default="{ row }">
        {{ row.type === 'sale' ? $t('Outcome.Sale') : $t('Outcome.WriteOff') }}
      </template></MiTableColumn
    >
    <MiTableColumn prop="count" :label="$t('Nomenclature.Qty')" width="200px" />
    <MiTableColumn prop="warehouse.title" :label="$t('Warehouse.Warehouse')" width="220px" />
    <MiTableColumn prop="actions">
      <template #default="{ row }">
        <div class="outcome-products-table-actions">
          <MiButton
            class="outcome-products-table-actions__delete-button"
            type="primary"
            text
            @click.stop="$emit('table:delete', row.product_id)">
            <template #icon> <MiIcon icon="CLOSE" /></template>
          </MiButton>
        </div>
      </template>
    </MiTableColumn>
  </MiSimpleTable>
</template>

<script lang="ts">
export default {
  name: 'OutcomeProductsTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { MiTableColumn, MiButton, MiSimpleTable, MiIcon } from '~shared/ui';
import { OutcomeProductDto } from '~shared/api/common';

defineEmits(['table:delete']);

const props = defineProps<{ items: OutcomeProductDto[] }>();
</script>

<style lang="scss" src="./index.scss" />
