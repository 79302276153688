import { array, object, string, number } from 'yup';

import { I18nService } from '~shared/lib';

const onlyZeroReg = /^0+$/;
export const validationSchema = object().shape({
  // market_calculations: object({
  //   margin: string().test(
  //     'margin',
  //     `${I18nService.t('Validation.RequiredField')}`,
  //     (value, context) => {
  //       if (context.from) {
  //         if (!context.from[1]?.value.change || (context.from[1]?.value.change && value?.length)) {
  //           return true;
  //         }
  //       }
  //
  //       return false;
  //     }
  //   ),
  // }),
  type: string(),
  slot_duration: number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : +value))
    .test('only zero value', `${I18nService.t('Validation.OnlyZeroValues')}`, (val, ctx) => {
      return !onlyZeroReg.test(ctx.originalValue);
    })
    .when(['type'], (fields, schema) => {
      if (fields[0] === 'consultation' || fields[0] === 'procedure') {
        return schema.required(I18nService.t('Validation.RequiredField'));
      }
      return schema;
    }),
  doctor_ids: array().when(['type'], (fields, schema) => {
    if (fields[0] === 'consultation' || fields[0] === 'procedure') {
      return schema.min(1, I18nService.t('Validation.RequiredField'));
    }
    return schema;
  }),
  warehouse_id: number()
    .nullable()
    .when(['type'], (fields, schema) => {
      if (fields[0] === 'product') {
        return schema.required(I18nService.t('Validation.RequiredField'));
      }
      return schema;
    }),
  title: object({
    ru: string().trim().nullable().required(I18nService.t('Validation.RequiredField')),
    uz: string().trim().nullable().required(I18nService.t('Validation.RequiredField')),
    en: string().trim().nullable().required(I18nService.t('Validation.RequiredField')),
  }),
  category_id: number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .required(I18nService.t('Validation.RequiredField')),
  price: number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .test('only zero', `${I18nService.t('Validation.OnlyZeroValues')}`, (value, ctx) => {
      return !onlyZeroReg.test(ctx.originalValue);
    })
    .required(I18nService.t('Validation.RequiredField')),
  expenses_categories: array().of(
    object({
      value: number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .required(I18nService.t('Validation.RequiredField')),
    })
  ),
});
