<template>
  <MiInput class="mi-search-input" v-bind="$props" v-model="value">
    <template #prefix>
      <MiIcon icon="SEARCH" />
    </template>
  </MiInput>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { InputProps } from 'element-plus';

import { MiIcon } from '..';

import { MiInput } from '~shared/ui';

export type MiSearchInputProps = { noEmptyStringValue?: boolean } & Partial<InputProps>;

const props = withDefaults(defineProps<MiSearchInputProps>(), {
  validateEvent: true,
  noEmptyStringValue: true,
});
const emit = defineEmits<{
  (event: 'update:modelValue', value: string | number | undefined | null): void;
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val: string | number | undefined | null) {
    if (typeof val === 'string' && val.startsWith(' ')) return;

    emit('update:modelValue', val);
  },
});

watch(
  () => value.value,
  (v) => {
    if (v === '' && props.noEmptyStringValue) {
      value.value = undefined;
    }
  }
);
</script>

<style lang="scss" src="./index.scss" />
