import { ElNotification } from 'element-plus';

import {
  apiService,
  AddPregnancyRequestDto,
  PregnancyDto,
  PregnancyUpdateRequestDto,
  MetaDto,
} from '~shared/api';
import { getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { QueryParams } from '~shared/types';

const urlPath = 'g2g/pregnancies';

export const getList = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{ data: PregnancyDto[]; meta: MetaDto }>(
      joinQueryParams({
        url: `${urlPath}/${query.id}`,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const create = async (payload: AddPregnancyRequestDto) => {
  try {
    const response = await apiService.api.instance.post<{ data: PregnancyDto }>(
      `${urlPath}/create`,
      payload
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const update = async (id: PregnancyDto['id'], payload: PregnancyUpdateRequestDto) => {
  try {
    const response = await apiService.api.instance.put<{ data: PregnancyDto[] }>(
      `${urlPath}/${id}/close`,
      payload
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
