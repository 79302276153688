import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';
import { ROUTE_PATH } from '~shared/config';

export const INVOICE_LIST_ROUTE = {
  name: 'InvoiceList',
  path: ROUTE_PATH.cashRegister.invoices,
  component: () => import('./ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'accountant']),
  ],
  meta: {
    title: 'CashRegister.Invoices',
  },
};
