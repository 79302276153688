import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const EMERGENCY_ROOM_LIST_ROUTE = {
  name: 'EmergencyRoomList',
  path: ROUTE_PATH.emergencyRoom.list,
  component: () => import('./EmergencyRoomList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'nurse', 'manager']),
  ],
  meta: {
    title: 'Hospitalization.EmergencyRoom',
  },
};

export const EMERGENCY_ROOM_REFERRAL_ROUTE = {
  name: 'EmergencyRoomReferral',
  path: ROUTE_PATH.emergencyRoom.referral.raw,
  component: () => import('./EmergencyRoomReferral/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'nurse', 'manager']),
  ],
  meta: {
    title: 'Hospitalization.EmergencyRoom',
  },
};

export const EMERGENCY_ROOM_WITHOUT_REFERRAL_ROUTE = {
  name: 'EmergencyRoomWithoutReferral',
  path: ROUTE_PATH.emergencyRoom.withoutReferral,
  component: () => import('./EmergencyRoomWithoutReferral/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'nurse', 'manager']),
  ],
  meta: {
    title: 'Hospitalization.EmergencyRoom',
  },
};

export const EMERGENCY_ROOM_PATIENT_CHECKUP_ROUTE = {
  name: 'EmergencyRoomPatientCheckup',
  path: ROUTE_PATH.emergencyRoom.patientCheckup.raw,
  component: () => import('./EmergencyRoomPatientCheckup/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Hospitalization.EmergencyRoom',
  },
};

export const EMERGENCY_ROOM_PATIENT_CHECKUP_EDIT_ROUTE = {
  name: 'EmergencyRoomPatientCheckupEdit',
  path: ROUTE_PATH.emergencyRoom.patientCheckupEdit.raw,
  component: () => import('./EmergencyRoomPatientCheckupEdit/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Hospitalization.EmergencyRoom',
  },
};

export const COVER_SHEET_FORM003_ROUTE = {
  name: 'CoverSheetForm003',
  path: ROUTE_PATH.emergencyRoom.form003.raw,
  component: () => import('./CoverSheetForm003/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Form003.TitlePage',
  },
};

export const EXAMINATION_PLAN_ROUTE = {
  name: 'ExaminationPlan',
  path: ROUTE_PATH.emergencyRoom.examinationPlan.raw,
  component: () => import('./ExaminationPlan/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Common.ExaminationPlan',
  },
};

export const BIRTH_PROTOCOL_ROUTE = {
  name: 'BirthProtocol',
  path: ROUTE_PATH.emergencyRoom.birthProtocol.raw,
  component: () => import('./BirthProtocol/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Hospitalization.SurgicalIntervention',
  },
};

export const STATISTICAL_MAP_FORM066_ROUTE = {
  name: 'StatisticalMapForm066',
  path: ROUTE_PATH.emergencyRoom.form066.raw,
  component: () => import('./StatisticalMapForm066/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Form066.StatisticalMapForm066',
  },
};

export const SURGERY_EDIT_ROUTE = {
  name: 'SurgeryEdit',
  path: ROUTE_PATH.emergencyRoom.surgeryEdit.raw,
  component: () => import('./SurgeryEdit/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Hospitalization.SurgicalIntervention',
  },
};
