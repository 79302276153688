import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const PAID_PATIENTS_LIST_ROUTE = {
  name: 'PaidPatientList',
  path: ROUTE_PATH.paidPatients,
  component: () => import('./ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'doctor', 'manager', 'nurse']),
  ],
  meta: {
    title: 'Contingent.PaidDepartment',
  },
};
