<template>
  <ElTag :type="value ? props.mapStatus[value] : 'info'" effect="light" :size="size">
    {{ title }}
  </ElTag>
</template>

<script lang="ts">
export default {
  name: 'StatusTag',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ElTag } from 'element-plus';

import { SizeType, MapStatusType } from '@/components/common/StatusTag/types';

type Props = {
  value?: string;
  mapStatus?: MapStatusType;
  title: string;
  size?: SizeType;
};

const props = withDefaults(defineProps<Props>(), {
  size: 'small',
  mapStatus: () => ({
    created: 'warning',
    accepted: 'success',
    canceled: 'danger',
  }),
});
</script>

<style lang="scss">
.income-tag {
  padding: 12px;
  width: 100px;
}
</style>
