import { ROUTE_PATH } from '~shared/config';
import { onlyUserNotAuthMiddleware } from '~shared/lib';

export const FORGOT_PASSWORD_ROUTE = {
  name: 'ForgotPassword',
  path: ROUTE_PATH.auth.forgotPassword,
  component: () => import('./ui'),
  beforeEnter: [onlyUserNotAuthMiddleware],
  meta: {
    title: 'Common.ForgotPassword',
  },
};
