<template>
  <div>
    <div class="printer-document-content-block__editor-label" v-if="block.label">
      {{ block.label }}
    </div>
    <div id="tinymce">
      <span v-html="block.basicData"></span>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-internal-modules
import { PrinterDocumentContentBlock } from '../../../models/PrinterDocumentContentBlock.model';

export default {
  name: 'PrinterDocumentContentTinymce',
  props: {
    block: [PrinterDocumentContentBlock, Object],
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
