<template>
  <ElDialog
    width="100%"
    class="transfer-goods-crud-modal"
    :title="$t('TransferGoods.TransferGoodsWarehouse')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <VeeForm class="transfer-goods-crud-modal__form">
      <TransferGoodsForm @close:modal="$emit('update:modelValue')" />
    </VeeForm>
  </ElDialog>
</template>
<script lang="ts">
export default {
  name: 'TransferGoodsCrudModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { Form as VeeForm } from 'vee-validate';
import { ElDialog } from 'element-plus';

import TransferGoodsForm from '@/components/warehouse/modals/TransferGoodsCrudModal/TransferGoodsForm/index.vue';
</script>

<style lang="scss" src="./index.scss" />
