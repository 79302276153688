import { ElNotification } from 'element-plus';

import {
  CreateDispensaryPayload,
  DispensaryDto,
  GetDispensariesResponse,
  UpdateDispensaryPayload,
} from '../types';

import { apiService } from '~shared/api';
import { getApiErrorMessage } from '~shared/lib';

const urlPath = 'g2g/dispensaries';

export const getByUserId = async (id?: number | string) => {
  try {
    if (!id) {
      throw new Error('Id is not provided');
    }

    const response = await apiService.api.instance.get<GetDispensariesResponse>(`${urlPath}/${id}`);

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const create = async (payload: CreateDispensaryPayload) => {
  try {
    const response = await apiService.api.instance.post<GetDispensariesResponse>(
      `${urlPath}/create`,
      payload
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const update = async (payload: UpdateDispensaryPayload, dispensaryId?: number) => {
  try {
    if (!dispensaryId) {
      throw new Error('dispensaryId is not provided');
    }

    const response = await apiService.api.instance.put<DispensaryDto>(
      `${urlPath}/${dispensaryId}/update`,
      payload
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const deregistration = async (dispensaryId?: number) => {
  try {
    if (!dispensaryId) {
      throw new Error('dispensaryId is not provided');
    }

    const response = await apiService.api.instance.put<DispensaryDto>(
      `${urlPath}/${dispensaryId}/deregistration`
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
