<template>
  <ElCheckbox class="mi-checkbox" v-bind="props" v-model="value" @change="$emit('change', $event)">
    <template #default v-if="$slots.default">
      <slot />
    </template>
  </ElCheckbox>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ElCheckbox, CheckboxProps, CheckboxValueType } from 'element-plus';

export type MiCheckboxProps = Partial<CheckboxProps>;

const props = withDefaults(defineProps<MiCheckboxProps>(), {
  validateEvent: false,
});

const emit = defineEmits<{
  'update:modelValue': [e: CheckboxValueType | undefined];
  'change': [e: CheckboxValueType | undefined];
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val: CheckboxValueType | undefined) {
    emit('update:modelValue', val);
  },
});
</script>

<style lang="scss" src="./index.scss" />
