import { ElNotification } from 'element-plus';

import {
  CreateInvoicePayload,
  CreateTransactionPayload,
  GetInvoiceByIdResponseData,
  GetInvoiceListResponseData,
  InvoiceFullDto,
  InvoiceStatisticResponseData,
  TransactionDto,
  UpdateInvoicePayload,
} from '../types';

import { findCurrentEnv, getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';

const urlPath = 'g2g/invoices';
export const exportDataURL = findCurrentEnv('API_URL') + `exports/invoices`;

export const getList = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<GetInvoiceListResponseData>(
      joinQueryParams({
        url: urlPath,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getListByHospitalization = async (id: number) => {
  try {
    const response = await apiService.api.instance.get<GetInvoiceListResponseData>(
      `${urlPath}/${id}/invoices`
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const cancel = async (id: number) => {
  try {
    const response = await apiService.api.instance.put<{ data: InvoiceFullDto }>(
      `${urlPath}/${id}/canceled`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getPaymentDrivers = async () => {
  try {
    const response = await apiService.api.instance.get<{ data: string[] }>(`payment_drivers`);

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createTransaction = async (payload: CreateTransactionPayload) => {
  try {
    const response = await apiService.api.instance.post<{ data: TransactionDto }>(
      `transactions/create`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getStatistic = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{ data: InvoiceStatisticResponseData }>(
      joinQueryParams({
        url: 'statistics/invoices',
        query,
      })
    );

    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const create = async (payload: CreateInvoicePayload) => {
  try {
    const response = await apiService.api.instance.post<GetInvoiceByIdResponseData>(
      `${urlPath}/create`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getById = async (invoiceId: number) => {
  try {
    const response = await apiService.api.instance.get<GetInvoiceByIdResponseData>(
      `${urlPath}/${invoiceId}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const update = async (invoiceId: number, payload: UpdateInvoicePayload) => {
  try {
    const response = await apiService.api.instance.put<GetInvoiceByIdResponseData>(
      `${urlPath}/${invoiceId}/update`,
      payload
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
