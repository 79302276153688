/* eslint-disable no-console */
import noop from 'lodash.noop';
import { mapState, mapActions } from 'vuex';
import { ElPageHeader } from 'element-plus';

import { ANALYSIS_LIST_ROUTE } from '~pages/AnalysisList';
import { MainLayout } from '~widgets/layouts';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { MiFilterBox, MiButton } from '~shared/ui';
import { usePage, usePerPage, useGetDataByInterval, compareQueriesThenLoadData } from '~shared/lib';

import CreateOrEditReferencesValueModal from '@/components/laboratory/referencesValues/CreateOrEditReferencesValueModal/index.vue';
import ReferencesValuesTable from '@/components/laboratory/referencesValues/ReferencesValuesTable/index.vue';
import { Analysis } from '@/models/laboratory/Analysis.model';
import { LABORATORY_REFERENCES_GROUPS_ROUTE } from '@/router/laboratory.routes';
import { misApi } from '@/shared/api';

export default {
  name: 'VReferencesValues',
  components: { MiButton, MainLayout, MiFilterBox, ReferencesValuesTable, ElPageHeader },
  props: {
    referencesGroupId: [String, Number],
    analysisId: [String, Number],
  },
  setup: () => ({
    page: usePage(),
    perPage: usePerPage(),

    getDataByInterval: noop,
  }),

  data() {
    return {
      /** @type {ReferencesGroupDto|object} referencesGroup */
      referencesGroup: null,
      referencesGroupLoading: false,

      /** @type {Analysis|object} */
      analysis: null,
      analysisLoading: false,
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.referencesValues.data,
      total: (state) => state.referencesValues.total,
      loading: (state) => state.referencesValues.loading,
    }),

    title() {
      let title = '';
      const value = this.referencesGroup?.title || this.analysis?.title || '';
      if (this.referencesGroupId) title = this.$t('Laboratory.ReferencesGroup');
      if (this.analysisId) title = this.$t('Laboratory.Analysis.Analysis');

      return `${title}: ${value}`;
    },

    queryWatchers() {
      return {
        page: this.page.value,
        per_page: this.perPage.value,
        references_group_id: this.referencesGroupId,
        analysis_id: this.analysisId,
      };
    },
  },

  watch: {
    queryWatchers: {
      handler(value, oldValue) {
        compareQueriesThenLoadData({
          query: value,
          oldQuery: oldValue,
          getData: this.getDataByInterval,
          reset: this.page.reset,
          fieldsForResetPage: ['references_group_id'],
        });
      },
      deep: true,
    },

    referencesGroupId: {
      handler(value) {
        if (value) this.getReferencesGroup();
      },
      immediate: true,
    },
    analysisId: {
      handler(value) {
        if (value) this.getAnalysis();
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      setData: 'referencesValues/setData',
      setLoading: 'referencesValues/setLoading',
      createItem: 'referencesValues/createItem',
      editItem: 'referencesValues/editItem',
      deleteItem: 'referencesValues/deleteItem',
    }),

    async getReferencesGroup() {
      this.referencesGroupLoading = true;
      const response = await misApi.common.references.getReferencesGroupById(
        this.referencesGroupId
      );
      if (response?.data) {
        this.referencesGroup = response.data.data;
      }
      this.referencesGroupLoading = false;
    },

    async getAnalysis() {
      this.analysisLoading = true;
      const { analysis } = await Analysis.findOneById(this.analysisId);
      this.analysis = analysis;
      this.analysisLoading = false;
    },

    async getData() {
      this.setLoading(true);

      const response = await misApi.common.references.getReferencesValueItems(this.queryWatchers);
      if (response?.data) {
        const { data, meta } = response.data;
        this.setData({
          items: data,
          total: meta.total,
          overwriteDataState: true,
        });
      }

      this.setLoading(false);
    },

    async create() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditReferencesValueModal,
        payload: {
          data: {
            references_group_id: this.referencesGroupId ? +this.referencesGroupId : null,
            references_group: this.referencesGroup,
            analysis_id: this.analysisId ? +this.analysisId : null,
            analysis: this.analysis,
          },
        },
      });

      if (!(action instanceof GlobalModalClose)) this.createItem(action.data.referencesValue);
    },

    backHandler() {
      if (this.referencesGroupId) this.$router.push(LABORATORY_REFERENCES_GROUPS_ROUTE.path);
      if (this.analysisId) this.$router.push(ANALYSIS_LIST_ROUTE.path);

      this.$router.back();
    },
  },

  mounted() {
    this.getDataByInterval = useGetDataByInterval(this.getData);
    this.getDataByInterval();
  },
};
