<template>
  <canvas class="qr-code" ref="refQrCode"></canvas>
</template>

<script setup lang="ts">
import QrCode, { QRCodeErrorCorrectionLevel } from 'qrcode';
import { ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    payload: {
      data?: string;
      errorCorrectionLevel?: QRCodeErrorCorrectionLevel;
      color?: string;
    };
  }>(),
  {
    payload: () => ({
      data: '',
      errorCorrectionLevel: 'H',
      color: '#74A3FF',
    }),
  }
);

const refQrCode = ref();

watch(
  () => props.payload,
  (value) => {
    if (value) {
      const { data, errorCorrectionLevel, color } = value;
      QrCode.toCanvas(refQrCode.value, data || 'test', {
        errorCorrectionLevel: errorCorrectionLevel || 'H',
        color: {
          dark: color,
        },
        scale: 6,
        margin: 0,
      });
    }
  },
  {
    immediate: true,
    deep: true,
  }
);
</script>
