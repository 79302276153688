import { ElNotification } from 'element-plus';
import { AxiosError, GenericAbortSignal } from 'axios';

import { GetDefaultDirectoryListResponseData } from '../../types';

import { getApiErrorMessage, joinQueryParams, useAbortable } from '~shared/lib';
import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';

const urlPath = 'g2g/laboratory-departments';

export const getList = async (query: QueryParams = {}, signal?: GenericAbortSignal) => {
  try {
    const response = await apiService.api.instance.get<GetDefaultDirectoryListResponseData>(
      joinQueryParams({
        url: urlPath,
        query,
      }),
      {
        signal,
      }
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    if (err instanceof AxiosError && err.code === 'ERR_CANCELED') return;
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const abortableGetList = useAbortable(getList);
