<template>
  <MiTable
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <MiTableColumn prop="product.article" :label="$t('Nomenclature.VendorCode')" />
    <MiTableColumn prop="product.title" :label="$t('Outcome.Name')" />
    <MiTableColumn prop="count_outcome" :label="$t('Outcome.Outcome')" />
    <MiTableColumn prop="outcome_date" :label="$t('Outcome.Date')" />
    <MiTableColumn prop="left_count" :label="$t('Outcome.Remainder')" />
    <MiTableColumn prop="warehouse.title" :label="$t('Income.Warehouse')" />
    <MiTableColumn prop="type" :label="$t('Outcome.FlowType')">
      <template #default="{ row }">
        {{ row.type === 'sale' ? $t('Outcome.Sale') : $t('Outcome.WriteOff') }}
      </template>
    </MiTableColumn>
  </MiTable>
</template>

<script lang="ts">
import { PropType } from 'vue';

import { OutcomeDto } from '~shared/api/common';
import { MiTableColumn, MiTable } from '~shared/ui';

export default {
  name: 'OutcomesTable',
  components: {
    MiTableColumn,
    MiTable,
  },
  emits: ['update:page', 'update:perPage', 'modal:open'],
  props: {
    loading: { type: Boolean, required: true },
    items: { type: Array as PropType<OutcomeDto[]>, required: true },
    page: { type: Number, required: true },
    perPage: { type: Number, required: true },
    total: { type: Number, required: true },
  },
};
</script>

<style lang="scss" scoped></style>
