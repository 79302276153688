import { misApi } from '~shared/api';
import { findCurrentEnv } from '~shared/lib';

class PrinterService {
  async printBraceletByPatientId(patientId: number | string) {
    const response = await misApi.g2g.printer.printBracelet(patientId);
    if (response?.data) {
      await this.print({
        printerAddress: response.data.printer_address,
        task: response.data.task,
      });
    }
  }

  async print(payload: { printerAddress: string; task: string }) {
    const response = await fetch(findCurrentEnv('PRINTER_PROXY'), {
      method: 'POST',
      body: JSON.stringify({
        printer_address: payload.printerAddress,
        message: payload.task,
      }),
    });
    return response.json();
  }
}

export const printerService = new PrinterService();
