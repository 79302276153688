<template>
  <nav class="navbar-menu">
    <ElMenu
      :default-active="route.path"
      :collapse="mainNavBarCollapsed"
      :default-openeds="defaultOpeneds"
      router>
      <template v-for="item in menuItems">
        <template v-if="item.submenu?.length">
          <ElSubMenu
            :index="item.key"
            :key="item.key"
            :expand-open-icon="ChevronDownIcon"
            :expand-close-icon="ChevronDownIcon">
            <template #title>
              <ElIcon v-if="item.icon">
                <MiIcon :icon="item.icon" />
              </ElIcon>
              <span>{{ item.title }}</span>
            </template>
            <ElMenuItemGroup>
              <template #title v-if="mainNavBarCollapsed">
                {{ item.title }}
              </template>
              <ElMenuItem
                v-for="subItem in item.submenu"
                :index="subItem.key"
                :key="subItem.key"
                @click="handleMenuItemClick(subItem.routePath)">
                <ElIcon v-if="subItem.icon">
                  <MiIcon :icon="subItem.icon" />
                </ElIcon>
                <template #title>
                  {{ subItem.title }}
                </template>
              </ElMenuItem>
            </ElMenuItemGroup>
          </ElSubMenu>
        </template>

        <template v-else>
          <ElMenuItem
            :index="item.key"
            :key="item.key"
            @click="handleMenuItemClick(item.routePath)">
            <ElIcon v-if="item.icon">
              <MiIcon :icon="item.icon" />
            </ElIcon>
            <template #title>{{ item.title }}</template>
          </ElMenuItem>
        </template>
      </template>
    </ElMenu>
  </nav>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { ElMenu, ElMenuItem, ElSubMenu, ElIcon, ElMenuItemGroup } from 'element-plus';

import { getB2gNavbarItems, getG2gNavbarItems } from '../../lib';
import { ChevronDownIcon } from './ChevronDownIcon';

import { useSessionStore, useUserConfigStore, useAppConfigStore } from '~entities/session';
import { GetSidebarMenuItemsParams } from '~shared/types';
import { MiIcon } from '~shared/ui';

const route = useRoute();
const router = useRouter();

const sessionStore = useSessionStore();
const appConfigStore = useAppConfigStore();
const { user } = storeToRefs(sessionStore);
const { featureFlags } = storeToRefs(appConfigStore);
const { mainNavBarCollapsed } = storeToRefs(useUserConfigStore());

const doctorId = computed(() => user.value?.doctor_id);
const microdistrictId = computed(() => user.value?.microdistrict?.id);

const getSidebarMenuItems = (params: GetSidebarMenuItemsParams) => {
  return featureFlags.value?.['user.interface'] === 'g2g'
    ? getG2gNavbarItems(params)
    : getB2gNavbarItems(params);
};

const menuItems = computed(() =>
  user.value?.role
    ? getSidebarMenuItems({
        doctorId: doctorId.value,
        userRole: user.value.role,
        microdistrictId: microdistrictId.value,
        isCKB: featureFlags.value?.['clinic.createHospital'] === false,
        isCashRegister: featureFlags.value?.['clinic.cashRegister'] === true,
        isPatronage: featureFlags.value?.['clinic.patronage'] === true,
      })
    : []
);

const defaultOpeneds = computed(
  (): string[] =>
    menuItems.value
      .map((item) => item.prefix)
      .filter((prefix) => !!prefix && route.path.startsWith(prefix)) as string[]
);

const handleMenuItemClick = (path: string | undefined) => {
  if (!path) return;
  router.push(path);
};
</script>

<style lang="scss" src="./index.scss" />
