import { ElNotification } from 'element-plus';

import { GetServiceGroupListResponseData } from '~shared/api';
import { apiService } from '~shared/api';
import { getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { QueryParams } from '~shared/types';

const urlPath = '/services';

export const getGroupList = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<GetServiceGroupListResponseData>(
      joinQueryParams({
        url: `${urlPath}/group/list`,
        query,
      })
    );
    return {
      data: response.data.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getGroupListV2 = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<GetServiceGroupListResponseData>(
      joinQueryParams({
        url: `${urlPath}/group/list`,
        query,
      })
    );
    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
